import * as React from "react";

import { cn } from "@/lib/utils";

const TimeInput = React.forwardRef<
  HTMLInputElement,
  Omit<React.ComponentProps<"input">, "type">
>(({ className, ...props }, ref) => {
  return (
    <input
      type={"time"}
      className={cn(
        "h-10 border border-border rounded-md w-full px-3 py-2 text-sm placeholder:text-muted-foreground bg-background focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
        "focus:border-secondary transition-colors duration-200",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
TimeInput.displayName = "Input";

const Input = React.forwardRef<HTMLInputElement, React.ComponentProps<"input">>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-base file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
          "focus:border-secondary transition-colors duration-200",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input, TimeInput };
