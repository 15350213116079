import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { Opportunity } from "@/queries/candidate/opportunities";

export const withdrawlReasons = [
  "Compensation",
  "Requirements or skill mismatch",
  "Too many hours",
  "Too few hours",
  "Start date doesn't align",
  "Lack of industry fit",
  "Lack of availability",
];

interface DeclineOpportunityParams {
  openingId: string;
  candidateStatusId: string;
  declineReasons: (typeof withdrawlReasons)[number][];
  otherDeclineReason?: string;
}

export const useDeclineOpportunity = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      openingId,
      candidateStatusId,
      declineReasons,
      otherDeclineReason,
    }: DeclineOpportunityParams) => {
      const token = await getAccessTokenSilently();

      await request({
        method: "POST",
        path: `/api/jobOpening/${openingId}/candidate/${candidateStatusId}/withdraw`,
        token,
        body: JSON.stringify({
          declineReasons,
          otherDeclineReason,
        }),
      });
    },
    onMutate: async ({
      openingId,
      candidateStatusId,
    }: DeclineOpportunityParams) => {
      await qc.cancelQueries({
        queryKey: QueryKeys.candidateOpportunities,
      });

      const prev = qc.getQueryData<Opportunity[]>(
        QueryKeys.candidateOpportunities,
      );

      if (prev) {
        let opportunity = prev.find(
          ({ id, candidateStatus }) =>
            id === openingId && candidateStatus.id === candidateStatusId,
        );
        if (opportunity) {
          opportunity = {
            ...opportunity,
            candidateStatus: {
              ...opportunity.candidateStatus,
              status: "Not Interested",
            },
          };
        }

        qc.setQueryData(QueryKeys.candidateOpportunities, [
          ...prev.filter(
            ({ id, candidateStatus }) =>
              id !== openingId && candidateStatus.id !== candidateStatusId,
          ),
          opportunity,
        ]);
      }

      return { prev, openingId, candidateStatusId };
    },
    onError: (_, __, context) => {
      qc.setQueryData(QueryKeys.candidateOpportunities, context?.prev);
    },
    onSettled: () => {
      void qc.invalidateQueries({
        queryKey: QueryKeys.candidateOpportunities,
      });
    },
  });

  return {
    declineOpportunity: mutate,
    isDeclining: isPending,
    declineError: error,
  };
};
