import { StripeAccount, useStripeAccountQuery } from "@/queries/stripe";
import { LoaderCircle } from "lucide-react";
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import useStripeConnect from "@/hooks/useStripeConnect";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const CandidatePaymentInfo = () => {
  const { stripeAccount, stripeAccountLoading } = useStripeAccountQuery();

  if (!stripeAccount || stripeAccountLoading) {
    return (
      <div className="h-[30vh] max-h-[500px] w-full aspect-square flex items-center justify-center">
        <LoaderCircle className="size-6 text-secondary animate-spin" />
      </div>
    );
  }

  return <ConnectWrapper account={stripeAccount} />;
};

const ConnectWrapper = ({ account }: { account: StripeAccount }) => {
  const connectInstance = useStripeConnect(account.id);

  if (!connectInstance) {
    return <p>loading connect instance</p>;
  }

  return (
    <ConnectComponentsProvider connectInstance={connectInstance}>
      {!account.onboardingSubmitted ? (
        <ConnectAccountOnboarding onExit={() => undefined} />
      ) : (
        <PostOnboardingWrapper />
      )}
    </ConnectComponentsProvider>
  );
};

const PostOnboardingWrapper = () => {
  const { stripeAccount } = useStripeAccountQuery();

  return (
    <Tabs defaultValue="account" className="min-h-[min(30vh,300px)] space-y-4">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="account">Account</TabsTrigger>
        {!!stripeAccount?.chargesEnabled && !!stripeAccount?.payoutsEnabled && (
          <TabsTrigger value="payouts">Payouts</TabsTrigger>
        )}
      </TabsList>
      <TabsContent value="account">
        <ConnectAccountManagement />
      </TabsContent>
      {!!stripeAccount?.chargesEnabled && !!stripeAccount?.payoutsEnabled && (
        <TabsContent value="payouts">
          <ConnectPayouts />
        </TabsContent>
      )}
    </Tabs>
  );
};
