import { FileAudio2 } from "lucide-react";

import DocumentSearch from "@/assets/search.svg";

import Dashboard from "@/components/pages/dashboard";
import { Button } from "@/components/ui/button";

export const SearchingSection = () => {
  return (
    <Dashboard.Collapsible defaultOpen>
      <Dashboard.CollapsibleHeader>
        <Dashboard.CollapsibleTitle>Match Progress</Dashboard.CollapsibleTitle>
      </Dashboard.CollapsibleHeader>
      <Dashboard.CollapsibleContent>
        <div className="w-full flex flex-col lg:flex-row bg-border/50 rounded-xl p-6 xl:pr-64 gap-8 items-center">
          <img
            src={DocumentSearch}
            alt="Document Search Icon"
            className="w-fit h-full object-cover"
          />
          <div className="w-[-webkit-fill-available] flex flex-col">
            <h3 className="text-lg font-semibold">Searching for a Match</h3>
            <p className="text-md text-muted-foreground">
              You&apos;ve completed your onboarding tasks and are ready to
              receive fractional leadership opportunities! In the meantime,
              review our candidate resources including interview prep guides.
              Want to be more involved? Consider joining our Fractional
              Leadership Forum.
            </p>
            <div className="flex flex-row gap-4 mt-4">
              <Button
                variant="outline"
                onClick={() => {
                  window.open("https://venturous.work/blog", "_blank");
                }}
              >
                <FileAudio2 />
                View all resources
              </Button>
              {/*<Button variant="outline">
                <TabletSmartphone />
                Connect with the community
              </Button>*/}
            </div>
          </div>
        </div>
      </Dashboard.CollapsibleContent>
    </Dashboard.Collapsible>
  );
};
