import { useAuth0 } from "@auth0/auth0-react";
import { useProfileQuery } from "../user/profile";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import request from "@/lib/api/handler";

export interface Executive {
  id: string;
  name?: string;
  profilePicture?: string;
  role?: string;
  engagementType?: string;
  experience?: string;
  startDate?: string;
  linkedIn?: string;
  cultureLink?: string;
  biography?: string;
}

export interface Company {
  id?: string;
  name?: string;
  location?: string;
  logoLink?: string;
  foundedYear?: string;
  companySize?: string;
  fundsRaised?: string;
  lastFundraise?: string;
  cultureStatements?: string;
  linkedIn?: string;
  websiteLink?: string;
  aboutUs?: string;
  missionStatement?: string;
  profileComplete?: boolean;
  onboardingComplete?: boolean;
  executiveTeam: Executive[];
}

export const companyUserAccountTypeList = ["Admin", "Collaborator"] as const;
export type CompanyUserAccountType =
  (typeof companyUserAccountTypeList)[number];

export interface TeamMember {
  id: string;
  userId: string;
  name?: string;
  emails: string[];
  profilePictureLink?: string;
  accountType: CompanyUserAccountType;
  onboarded?: boolean;
}

export const useCompanyTeamQuery = () => {
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();
  const cid = profile?.companyAccounts[0]?.companyId;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.companyTeam,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!cid,
    async queryFn() {
      const token = await getAccessTokenSilently();
      const resp: TeamMember[] = await request({
        method: "GET",
        path: `/api/company/${cid}/team`,
        token,
      });

      return resp;
    },
  });

  return {
    companyTeamLoading: isLoading,
    companyTeam: data ?? null,
    companyTeamError: error ?? null,
  };
};
