import { useMemo, useState } from "react";

import { cn } from "@/lib/utils";
import { Spinner } from "@fluentui/react-components";
import { LayoutList, Search, SquareKanban } from "lucide-react";
import CandidateKanban from "./candidate-kanban";
import CandidateTable from "./candidate-table";
import CandidateDetailsDrawer from "./candidate-drawer";

import {
  AdminPipelineCandidate,
  useAdminCandidatePipelineQuery,
} from "@/queries/admin/candidate-pipeline";

const CandidatePipeline = () => {
  const [selectedCandidate, setSelectedCandidate] =
    useState<AdminPipelineCandidate>();
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState<"kanban" | "table">("kanban");

  const { adminCandidatePipeline, adminCandidatePipelineLoading } =
    useAdminCandidatePipelineQuery();

  const candidates = useMemo(() => {
    if (searchString) {
      const filterString = searchString.toLowerCase();

      return adminCandidatePipeline?.filter(
        (candidate) =>
          candidate.stage !== "Joined" &&
          candidate.stage !== "Dropped out" &&
          candidate.stage !== "Rejected" &&
          candidate.name?.toLowerCase().includes(filterString),
      );
    } else {
      return adminCandidatePipeline?.filter(
        (candidate) =>
          candidate.stage !== "Joined" &&
          candidate.stage !== "Dropped out" &&
          candidate.stage !== "Rejected",
      );
    }
  }, [adminCandidatePipeline, searchString]);

  const count = useMemo(() => candidates?.length, [candidates]);

  if (adminCandidatePipelineLoading) {
    return (
      <div>
        <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
          Onboarding
        </h3>
        <div className="w-full flex-col justify-around align-middle">
          <Spinner size="huge" />
          <p className="text-center text-base font-medium text-[#727E94]">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
        Onboarding
      </h3>
      <div className="w-full flex flex-col gap-4 items-start self-stretch">
        <div className="flex flex-col md:flex-row md:items-center justify-between self-stretch">
          <p className="text-lg text-[#181d27] font-semibold">
            {`${count} ${count === 1 ? "Candidate" : "Candidates"}`}
          </p>
          <div className="flex flex-row items-center gap-3">
            <div
              className={cn(
                "flex flex-row items-center gap-0.5",
                "border-b border-[#57576B] transition-colors focus-within:border-black",
              )}
            >
              <Search />
              <input
                type="text"
                className={cn(
                  "flex h-10 w-full min-w-48 px-3 py-2 text-base",
                  "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
                )}
                placeholder="Search for any candidate"
                value={searchString}
                onChange={(ev) => {
                  ev.preventDefault();
                  setSearchString(ev.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-start bg-[#F9F9FB] rounded-lg">
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "kanban" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("kanban")}
              >
                <SquareKanban />
              </div>
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "table" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("table")}
              >
                <LayoutList />
              </div>
            </div>
          </div>
        </div>
        {view === "kanban" && (
          <CandidateKanban
            candidates={candidates}
            setSelectedCandidate={setSelectedCandidate}
          />
        )}
        {view === "table" && (
          <CandidateTable
            candidates={candidates}
            setSelectedCandidate={setSelectedCandidate}
          />
        )}
      </div>
      <CandidateDetailsDrawer
        candidate={selectedCandidate}
        setOpen={() => setSelectedCandidate(undefined)}
      />
    </div>
  );
};

export default CandidatePipeline;
