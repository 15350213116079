import { useParams } from "react-router-dom";

import { useCandidateOpportunities } from "@/queries/candidate/opportunities";
import { Loader2 } from "lucide-react";
import { NewCandidateOpportunityTile } from "@/elements/OpportunityTile";

const OpportunityPage = () => {
  const { openingId } = useParams();
  const { candidateOpportunities, candidateOpportunitiesLoading } =
    useCandidateOpportunities();

  if (candidateOpportunitiesLoading) {
    return (
      <div className="mx-auto">
        <Loader2 className="animate-spin" />
      </div>
    );
  } else if (
    candidateOpportunities?.find((opportunity) => opportunity.id === openingId)
  ) {
    return (
      <NewCandidateOpportunityTile
        opening={candidateOpportunities.find(
          (opportunity) => opportunity.id === openingId,
        )}
      />
    );
  } else {
    return (
      <div className="mx-auto">
        <h1 className="text-2xl font-semibold">Opportunity not found</h1>
      </div>
    );
  }
};

export default OpportunityPage;
