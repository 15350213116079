/* eslint-disable @typescript-eslint/no-misused-promises */
import { useLayoutEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { CreditCard } from "lucide-react";
import { usePaymentInfoDialogState } from "./store";
import { useShallow } from "zustand/shallow";
import { loadStripe } from "@stripe/stripe-js";
import { useReqType } from "@/stores/req-type";
import { CompanyPaymentInfo } from "./company";
import { CandidatePaymentInfo } from "./candidate";
import { ScrollArea } from "@/components/ui/scroll-area";

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLIC_KEY as string,
);

const PaymentInfoDialog = () => {
  const [open, setOpen, title] = usePaymentInfoDialogState(
    useShallow((s) => [s.open, s.setOpen, s.title]),
  );
  const [reqType, switchValue] = useReqType(
    useShallow((s) => [s.reqType, s.switch]),
  );
  const isCompany = reqType.admin ? switchValue === "company" : reqType.company;

  useLayoutEffect(() => {
    // if the dialog is closed and the body is disabled, re-enable it
    // this is preventing an odd edge case where if you're signing into a stripe
    // bank account and spam the close buttons to exit everything (inclduding dialog)
    // very quickly, the body will remain "frozen"
    if (
      !open &&
      document.getElementsByTagName("body")[0]?.style.pointerEvents === "none"
    ) {
      setTimeout(() => {
        document
          .getElementsByTagName("body")[0]!
          .style.removeProperty("pointer-events");
      }, 300);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="w-full max-w-[min(700px,calc(100vw-2rem))]">
        <DialogHeader className="flex-row items-center justify-start gap-2">
          <div className="size-10 rounded-full border-[6px] border-border flex items-center justify-center box-content">
            <CreditCard className="text-accent size-6" />
          </div>
          <DialogTitle className="!m-0">{title}</DialogTitle>
          <DialogDescription className="sr-only">
            Dialog for viewing, editing, or adding payment information for the
            profile
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh]">
          <div className="px-3">
            {isCompany && <CompanyPaymentInfo stripePromise={stripePromise} />}
            {!isCompany && <CandidatePaymentInfo />}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

/*
          <div className="h-[30vh] max-h-[500px] w-full aspect-square flex items-center justify-center">
            <LoaderCircle className="size-6 text-secondary animate-spin" />
          </div>
*/

export default PaymentInfoDialog;
