import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { Contract, ConvertsToFullTime, JobOpeningStatus } from "@/types";

export interface AdminPipelineJob {
  id: string;
  role?: string;
  jobDescriptionLink?: string;
  maskedJobDescriptionLink?: string;
  shortDescription?: string;
  keyResponsibilities?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  convertsToFullTime?: ConvertsToFullTime;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  noteForVenturous?: string;
  status: JobOpeningStatus;
  aIVjoHeading?: string;
  aIVjoDescription?: string;
  candidateCount: number;
  company: {
    id: string;
    name?: string;
    logoLink?: string;
  };
  contracts: Contract[];
  notes?: string;
  createdAt: string;
}

export const useAdminJobPipelineQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: adminJobPipeline,
    error: adminJobPipelineError,
    isLoading: adminJobPipelineLoading,
  } = useQuery({
    queryKey: QueryKeys.adminJobPipeline,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return await request<AdminPipelineJob[]>({
        method: "GET",
        path: "/api/admin/job-pipeline",
        token,
      });
    },
  });

  return {
    adminJobPipeline,
    adminJobPipelineError,
    adminJobPipelineLoading,
  };
};
