import { getCurrentTimezone, timezones } from "@/lib/timezone";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
  SelectValue,
} from "./select";

const TimezoneSelect = ({
  timezone,
  onTimezoneChange,
  defaultToCurrentTimezone,
}: {
  timezone?: string;
  onTimezoneChange?: (timezone: string) => void;
  defaultToCurrentTimezone?: boolean;
}) => {
  const mytz = defaultToCurrentTimezone ? getCurrentTimezone() : undefined;

  return (
    <Select
      defaultValue={timezone ?? mytz ?? undefined}
      value={timezone}
      onValueChange={onTimezoneChange}
    >
      <SelectTrigger className="w-full max-w-[250px]">
        <SelectValue placeholder="Select a timezone" />
      </SelectTrigger>
      <SelectContent>
        {timezones.map((group, i) => (
          <SelectGroup key={i}>
            <SelectLabel>{group.name}</SelectLabel>
            {group.timezones.map((tz, j) => (
              <SelectItem key={j} value={tz.abbr}>
                {tz.name}
              </SelectItem>
            ))}
          </SelectGroup>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TimezoneSelect;
