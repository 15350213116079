import Logo from "@/assets/logo_name.svg";
import { Children, ReactNode, useMemo, isValidElement, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  ChevronsUpDown,
  DollarSign,
  LogOut,
  Menu,
  Settings,
  User,
} from "lucide-react";
import { useProfileQuery } from "@/queries/user/profile";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavLayout } from "@/stores/nav-layout";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { useDebouncedDims } from "@/hooks/useDebouncedDims";
import { useReqType } from "@/stores/req-type";
import PaymentInfoDialog from "./dialogs/payment-info";
import { usePaymentInfoDialogState } from "./dialogs/payment-info/store";
import { useToggle } from "@/hooks/useToggle";

const Main = ({ children }: { children?: ReactNode }) => {
  const setNavItems = useNavLayout((s) => s.setNavItems);

  useEffect(() => {
    const navItems = Children.toArray(children).filter(
      (child) =>
        isValidElement(child) &&
        child.type &&
        (child.type as { displayName?: string }).displayName === "NavItems",
    );

    setNavItems(navItems);
  }, [setNavItems, children]);

  return (
    <>
      <nav
        id="top-bar"
        className={cn(
          "max-w-full bg-background h-16 border-b border-border grid grid-cols-[auto,1fr] md:grid-cols-[auto,1fr,auto] grid-rows-1 px-4 py-2 items-center top-0 sticky gap-x-4 z-50",
        )}
      >
        <div id="top-bar-logo" className="flex items-center gap-1 md:pr-4">
          <img
            className="w-32 h-9 object-contain"
            src={Logo}
            alt="Venturous logo"
          />
        </div>
        {children}
      </nav>
      <SidebarWatcher />
      <PaymentInfoDialog />
    </>
  );
};

const SidebarWatcher = () => {
  const setSidebarOpen = useNavLayout((s) => s.setSidebarOpen);
  const { width } = useDebouncedDims(10);
  if (width >= 768 && useNavLayout.getState().sidebarOpen) {
    setSidebarOpen(false);
  }

  return null;
};

const Sidebar = () => {
  const open = useNavLayout((s) => s.sidebarOpen);
  const setOpen = useNavLayout((s) => s.setSidebarOpen);
  const navItems = useNavLayout((s) => s.navItems);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="icon" variant="ghost" className="md:hidden ml-auto">
          <Menu className="text-accent-og-foreground" />
        </Button>
      </SheetTrigger>
      <SheetContent
        className={cn(
          "pt-0 max-h-screen",
          "max-[340px]:max-w-[100vw] max-[340px]:w-full max-[340px]:pl-4 max-[340px]:left-0",
        )}
      >
        <SheetHeader className="h-16 py-2 flex items-center flex-row gap-0 space-y-0">
          <SheetTitle className="sr-only">Navigation</SheetTitle>
          <SheetDescription className="sr-only">
            Sidebar navigation menu
          </SheetDescription>
          <div id="top-bar-logo" className="flex items-center gap-1 md:pr-4">
            <img
              className="w-full h-9 object-contain"
              src={Logo}
              alt="Venturous logo"
            />
          </div>
        </SheetHeader>
        <div
          className="group flex flex-col justify-between"
          data-dir="vertical"
        >
          {navItems}
        </div>
        <Profile layout="sidebar" />
      </SheetContent>
    </Sheet>
  );
};

const NavItems = ({ children }: { children: ReactNode }) => {
  return (
    <ul
      className={cn(
        "items-center gap-1 hidden md:flex",
        "group-data-[dir=vertical]:flex group-data-[dir=vertical]:flex-col",
        "group-data-[dir=vertical]:w-full group-data-[dir=vertical]: group-data-[dir=vertical]: group-data-[dir=vertical]: group-data-[dir=vertical]: ",
      )}
    >
      {children}
    </ul>
  );
};
NavItems.displayName = "NavItems";

const isNavItemActive = (
  pathname: string,
  href: string,
  matchingPaths: string[],
) => [href, ...matchingPaths].find((p) => pathname === p.toLowerCase());

const NavItem = ({
  href,
  title,
  icon,
  externalLink,
  matchingPaths = [],
  activeOverride = false,
}: {
  href: string;
  title: string;
  icon?: ReactNode;
  externalLink?: boolean;
  matchingPaths?: string[];
  activeOverride?: boolean;
}) => {
  const navigate = useNavigate();
  const setSidebarOpen = useNavLayout((s) => s.setSidebarOpen);
  const { pathname } = useLocation();
  const isActive = useMemo(
    () => activeOverride || isNavItemActive(pathname, href, matchingPaths),
    [pathname, href, matchingPaths, activeOverride],
  );

  return (
    <li className="group-data-[dir=vertical]:w-full">
      <Button
        variant="ghostWithIcon"
        className={cn(
          "text-sm px-3.5 py-2.5 rounded-md hover:bg-accent-og leading-none whitespace-nowrap",
          "group-data-[dir=vertical]:w-full group-data-[dir=vertical]:justify-start group-data-[dir=vertical]: group-data-[dir=vertical]: group-data-[dir=vertical]: ",
          {
            "text-secondary font-semibold bg-accent/15": isActive,
          },
        )}
        onClick={() => {
          setSidebarOpen(false);
          externalLink ? window.open(href, "_blank") : navigate(href);
        }}
      >
        {icon}
        {title}
      </Button>
    </li>
  );
};
NavItem.displayName = "NavItem";

const Profile = ({ layout }: { layout?: "default" | "sidebar" }) => {
  const [pfpError, togglePfpError] = useToggle();
  const { profile } = useProfileQuery();
  const { loginWithRedirect, logout } = useAuth0();
  const switchValue = useReqType((s) => s.switch);
  const setSwitch = useReqType((s) => s.setSwitch);

  const navigate = useNavigate();

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  if (!profile) {
    return (
      <div className="p-6 flex gap-4 items-center">
        <Button
          style={{
            padding: "12px 16px",
            fontSize: "14px",
            borderRadius: "8px",
          }}
          variant="outline"
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
        <Button
          style={{
            padding: "12px 16px",
            fontSize: "14px",
            borderRadius: "8px",
            background: "linear-gradient(180deg, #003C32 0%, #00332A 100%)",
          }}
          variant="secondary"
          onClick={handleLogin}
        >
          Log In
        </Button>
      </div>
    );
  }

  return (
    <DropdownMenu>
      {layout && layout === "sidebar" ? (
        <DropdownMenuTrigger
          className={cn(
            "absolute w-[calc(100%-2rem)] bottom-6 h-auto",
            "flex justify-between items-center px-2 py-2",
            "border-border border rounded-md hover:bg-muted",
          )}
        >
          <div className="flex items-center shrink overflow-hidden">
            <Avatar className="size-8 min-w-8">
              {profile?.profilePictureLink && (
                <AvatarImage
                  src={profile?.profilePictureLink}
                  className="object-cover object-center"
                />
              )}
              <AvatarFallback>
                <User className="size-6 text-accent-og-foreground" />
              </AvatarFallback>
            </Avatar>
            <div className="space-y-0.5 text-left pl-2 flex-1 min-w-0 flex-col">
              <h6 className="leading-none font-semibold text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                {profile?.name}
              </h6>
              <p className="leading-none font-normal text-xs text-muted-foreground overflow-hidden text-ellipsis whitespace-nowrap">
                {profile?.emails[0]}
              </p>
            </div>
          </div>
          <ChevronsUpDown className="size-4 min-w-4 text-accent-og-foreground" />
        </DropdownMenuTrigger>
      ) : (
        <DropdownMenuTrigger asChild>
          <Button
            size="icon"
            className={cn(
              "hidden md:flex relative size-10 md:min-w-10 bg-border rounded-full hover:bg-border overflow-hidden",
              "ring-2 ring-transparent hover:ring-accent/40 data-[state=open]:ring-accent",
            )}
          >
            <User className="size-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-accent-og-foreground z-10" />
            {profile?.profilePictureLink && !pfpError && (
              <img
                src={profile?.profilePictureLink}
                alt="User profile picture"
                className="absolute w-full h-full object-cover object-center z-20"
                onError={() => togglePfpError(true)}
              />
            )}
          </Button>
        </DropdownMenuTrigger>
      )}
      <DropdownMenuContent
        align="end"
        className="max-md:w-[var(--radix-dropdown-menu-trigger-width)] md:min-w-48"
      >
        <DropdownMenuGroup>
          <DropdownMenuLabel>My Profile</DropdownMenuLabel>
          {switchValue === "candidate" ||
          profile.companyAccounts[0]?.accountType === "Admin" ? (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() =>
                  usePaymentInfoDialogState.getState().setOpen(true)
                }
              >
                <DollarSign className="size-4" />
                <span>Payments</span>
              </DropdownMenuItem>
            </>
          ) : null}
          <DropdownMenuItem onClick={() => navigate("/settings")}>
            <Settings className="size-4" />
            <span>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        {profile?.userType.includes("Admin") && (
          <>
            <DropdownMenuLabel>User Mode</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup
              value={switchValue === "company" ? "company" : "candidate"}
              onValueChange={(v) => setSwitch(v as "company" | "candidate")}
            >
              <DropdownMenuRadioItem value="candidate">
                Candidate
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem value="company">
                Company
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
                federated: true,
              },
            }).catch(console.error);
          }}
        >
          <LogOut className="size-4 text-destructive" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const TopBar = {
  Main,
  NavItems,
  NavItem,
  Profile,
  Sidebar,
};

export default TopBar;
