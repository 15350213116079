import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { cn } from "@/lib/utils";
import { ChevronsRight, Loader2, MoveUpRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
} from "@/components/ui/sheet";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";

import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

import {
  AdminJobCandidateDetails,
  AdminJobDetails,
} from "@/queries/admin/job-details";

import { sortExperiences } from "@/redux/features/candidateProfileSlice";
import { useSetCandidateOpportunityStatus } from "@/mutations/admin/set-candidate-opportunity-status";
import {
  candidateOpportunityNotesFormSchema,
  CandidateOpportunityNotesFormSchema,
  useSetCandidateOpportunityNotes,
} from "@/mutations/admin/set-candidate-opportunity-notes";

const BIOGRAPHY_MAX_LINES = 6;
const MAX_LINES_CLASS = "line-clamp-6";

const CandidateDetailsDrawer = ({
  job,
  candidate,
  setOpen,
}: {
  job: AdminJobDetails;
  candidate?: AdminJobCandidateDetails;
  setOpen: (v: boolean) => void;
}) => {
  const { setCandidateOpportunityStatus, isSettingCandidateOpportunityStatus } =
    useSetCandidateOpportunityStatus(job.id);

  const hideStatusControls = useMemo(
    () =>
      candidate?.opportunityStatus === "Accepted by Client" ||
      candidate?.opportunityStatus === "Not Interested" ||
      candidate?.opportunityStatus === "Rejected for Role",
    [candidate?.opportunityStatus],
  );

  const { textColor, backgroundColor } = useMemo(() => {
    let backgroundColor: string, textColor: string;
    switch (candidate?.opportunityStatus) {
      case "Identified":
      case "Not Interested":
        backgroundColor = "bg-red-100";
        textColor = "text-red-600";
        break;
      case "Checking Interest":
        backgroundColor = "bg-pink-100";
        textColor = "text-pink-600";
        break;
      case "Screening":
        backgroundColor = "bg-orange-100";
        textColor = "text-orange-600";
        break;
      case "Possible Fit":
        backgroundColor = "bg-blue-100";
        textColor = "text-blue-600";
        break;
      case "Shortlisted":
        backgroundColor = "bg-purple-100";
        textColor = "text-purple-600";
        break;
      case "Making Offer":
        backgroundColor = "bg-yellow-100";
        textColor = "text-yellow-600";
        break;
      case "Accepted by Client":
        backgroundColor = "bg-green-100";
        textColor = "text-green-600";
        break;
      case "Rejected for Role":
        backgroundColor = "bg-violet-100";
        textColor = "text-violet-600";
        break;
      default:
        backgroundColor = "";
        textColor = "";
    }

    return { textColor, backgroundColor };
  }, [candidate?.opportunityStatus]);

  if (!candidate) {
    return;
  }

  return (
    <Sheet open={Boolean(candidate)} onOpenChange={setOpen}>
      <SheetContent className="w-full max-w-full sm:max-w-md md:max-w-lg bg-background line-clamp">
        <SheetTitle className="sr-only">Candidate Details</SheetTitle>
        <SheetDescription className="sr-only">
          Details on a candidate in the pipeline
        </SheetDescription>
        <ScrollArea className="h-[calc(100vh-4.75rem)]" type="scroll">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start gap-4">
              <div className="flex flex-col items-start gap-6 self-stretch">
                {Boolean(candidate.profilePictureLink) && (
                  <div className="h-24 w-24 rounded-full overflow-hidden">
                    <img
                      className="h-full w-full object-cover"
                      src={candidate.profilePictureLink}
                    />
                  </div>
                )}
                <div className="flex flex-col items-start gap-0.5 self-stretch">
                  <div className="flex flex-row items-center gap-4">
                    <p className="text-xl text-[#181d27] font-semibold leading-[30px]">
                      {candidate.name}
                    </p>
                    <div
                      className={cn(
                        "py-1 px-2.5 flex flex-row items-center gap-2 rounded-full",
                        backgroundColor,
                      )}
                    >
                      <p
                        className={cn(
                          "text-xs font-semibold tracking-[-0.24px]",
                          textColor,
                        )}
                      >
                        {candidate.opportunityStatus}
                      </p>
                    </div>
                  </div>
                  <p className="text-base text-[#5c6060]">
                    {candidate.titleAndEmployer}
                  </p>
                </div>
              </div>
              {!hideStatusControls && (
                <div className="flex flex-row items-center gap-2">
                  <Button
                    variant="destructive"
                    disabled={isSettingCandidateOpportunityStatus}
                    onClick={() =>
                      setCandidateOpportunityStatus({
                        statusId: candidate.candidateStatusId,
                        status: "Rejected for Role",
                      })
                    }
                  >
                    Decline
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={isSettingCandidateOpportunityStatus}
                    onClick={() =>
                      setCandidateOpportunityStatus({
                        statusId: candidate.candidateStatusId,
                        currentStatus: candidate.opportunityStatus,
                      })
                    }
                  >
                    Advance <ChevronsRight />
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start gap-6 self-stretch">
              <DetailOverview candidate={candidate} />
              <MatchCriteria job={job} candidate={candidate} />
              <Experiences candidate={candidate} />
              <Notes job={job} candidate={candidate} />
            </div>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const DetailOverview = ({
  candidate,
}: {
  candidate: AdminJobCandidateDetails;
}) => {
  const [readMore, setReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetHeight, scrollHeight } = ref.current;

      if (readMore) {
        // If readMore is true, check how many lines of text there are
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const lines = offsetHeight / lineHeight;

        // If there are more than BIOGRAPHY_MAX_LINES, then everything is fine
        if (lines > BIOGRAPHY_MAX_LINES) {
          return;
        } else {
          // If not, the text is not being truncated anyway and we can reset
          setIsTruncated(false);
          setReadMore(false);
        }
      } else if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        // If readMore is not true, then check if the text is being cut off by the line clamp
        setIsTruncated(true);
        setReadMore(false);
      } else {
        // In all other cases, reset
        setIsTruncated(false);
        setReadMore(false);
      }
    }
  }, [ref, readMore, candidate, candidate?.biography]);

  return (
    <>
      <div className="flex flex-col items-start gap-2 self-stretch">
        <p className="text-sm text-[#535862] font-medium">Location</p>
        <p className="text-base text-[#414651] font-medium">
          {candidate.location ?? "Unknown"}
        </p>
      </div>
      {Boolean(candidate.personalWebsite) && (
        <div className="flex flex-col items-start gap-2 self-stretch">
          <p className="text-sm text-[#535862] font-medium">Personal Website</p>
          <a
            href={candidate.personalWebsite}
            target="_blank"
            rel="noreferrer"
            className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
          >
            <span className="flex flex-row items-center gap-0.5">
              {candidate.personalWebsite}
              <MoveUpRight size={16} />
            </span>
          </a>
        </div>
      )}
      {Boolean(candidate.resumeLink) && (
        <div className="flex flex-col items-start gap-2 self-stretch">
          <p className="text-sm text-[#535862] font-medium">Resume</p>
          <a
            href={candidate.resumeLink}
            target="_blank"
            rel="noreferrer"
            className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
          >
            <span className="flex flex-row items-center gap-0.5">
              Resume
              <MoveUpRight size={16} />
            </span>
          </a>
        </div>
      )}
      {Boolean(candidate.linkedIn) && (
        <div className="flex flex-col items-start gap-2 self-stretch">
          <p className="text-sm text-[#535862] font-medium">LinkedIn</p>
          <a
            href={candidate.linkedIn}
            target="_blank"
            rel="noreferrer"
            className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
          >
            <span className="flex flex-row items-center gap-0.5">
              {candidate.linkedIn}
              <MoveUpRight size={16} />
            </span>
          </a>
        </div>
      )}
      <div className="flex flex-col items-start gap-2 self-stretch">
        <p className="text-sm text-[#535862] font-medium">Email</p>
        <a
          href={`mailto:${candidate.email}`}
          target="_blank"
          rel="noreferrer"
          className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
        >
          <span className="flex flex-row items-center gap-0.5">
            {candidate.email}
            <MoveUpRight size={16} />
          </span>
        </a>
      </div>
      <div className="flex flex-col items-start gap-2 self-stretch">
        <p className="text-sm text-[#535862] font-medium">Profile</p>
        <a
          href={`/candidate/${candidate.userId}/edit`}
          target="_blank"
          rel="noreferrer"
          className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
        >
          <span className="flex flex-row items-center gap-0.5">
            Profile
            <MoveUpRight size={16} />
          </span>
        </a>
      </div>
      {Boolean(candidate.attioId) && (
        <div className="flex flex-col items-start gap-2 self-stretch">
          <p className="text-sm text-[#535862] font-medium">Attio Profile</p>
          <a
            href={`https://app.attio.com/venturous/person/${candidate.attioId}/activity`}
            target="_blank"
            rel="noreferrer"
            className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
          >
            <span className="flex flex-row items-center gap-0.5">
              Attio
              <MoveUpRight size={16} />
            </span>
          </a>
        </div>
      )}
      {Boolean(candidate.matchSummary) && (
        <>
          <div className="h-[1px] w-full bg-[#E9EAEB]" />
          <div className="flex flex-col items-start gap-2 self-stretch">
            <p className="text-base text-[#181D27] font-medium">
              AI Match Summary
            </p>
            <p className="text-base text-[#535862] whitespace-break-spaces">
              {candidate.matchSummary}
            </p>
          </div>
        </>
      )}
      {Boolean(candidate.biography) && (
        <>
          <div className="h-[1px] w-full bg-[#E9EAEB]" />
          <div className="flex flex-col items-start gap-2 self-stretch">
            <p className="text-base text-[#181D27] font-medium">About me</p>
            <p
              ref={ref}
              className={cn(
                "text-base text-[#535862] whitespace-break-spaces",
                readMore || MAX_LINES_CLASS,
              )}
            >
              {candidate.biography}
            </p>
            {!isTruncated ? null : readMore ? (
              <p
                className="text-sm text-[#6941C6] font-semibold hover:underline cursor-pointer"
                onClick={() => setReadMore(false)}
              >
                View less
              </p>
            ) : (
              <p
                className="text-sm text-[#6941C6] font-semibold hover:underline cursor-pointer"
                onClick={() => setReadMore(true)}
              >
                View more
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

const MatchCriteria = ({
  job,
  candidate,
}: {
  job: AdminJobDetails;
  candidate: AdminJobCandidateDetails;
}) => {
  if (!job.matchCriteria1 && !job.matchCriteria2 && !job.matchCriteria3) {
    return;
  }

  return (
    <>
      <div className="h-[1px] w-full bg-[#E9EAEB]" />
      <div className="flex flex-col items-start gap-2 self-stretch">
        <p className="text-base text-[#181D27] font-medium">Match Criteria</p>
        <MatchTile
          criteria={job.matchCriteria1}
          answer={candidate.matchCriteria[0]}
          quote={candidate.matchCriteriaQuote[0]}
        />
        <MatchTile
          criteria={job.matchCriteria2}
          answer={candidate.matchCriteria[1]}
          quote={candidate.matchCriteriaQuote[1]}
        />
        <MatchTile
          criteria={job.matchCriteria3}
          answer={candidate.matchCriteria[2]}
          quote={candidate.matchCriteriaQuote[2]}
        />
      </div>
    </>
  );
};

const MatchTile = ({
  criteria,
  answer,
  quote,
}: {
  criteria?: string;
  answer?: string;
  quote?: string;
}) => {
  return (
    <div className="flex flex-col p-3 gap-3 flex-1 items-start self-stretch rounded-xl bg-[#ED5C380D]">
      <div className="flex flex-col gap-[2px] flex-start self-stretch">
        <p className="text-base font-semibold text-[#22234A] whitespace-break-space">
          {criteria}
        </p>
        <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
          {answer}
        </p>
      </div>
      {quote ? (
        <div className="flex flex-col gap-2 p-3 flex-1 items-start self-stretch rounded-xl bg-white">
          <p className="text-xs font-medium text-[#4E4F6C]">
            From the Candidate
          </p>
          <p className="text-sm font-medium text-[#4E4F6C]">
            &quot;{quote}&quot;
          </p>
        </div>
      ) : null}
    </div>
  );
};

const Experiences = ({
  candidate,
}: {
  candidate: AdminJobCandidateDetails;
}) => {
  const sortedExperiences = useMemo(() => {
    if (candidate?.experiences) {
      return [...candidate.experiences].sort(sortExperiences);
    }
  }, [candidate?.experiences]);

  if (!sortedExperiences?.length) {
    return;
  }
  return (
    <>
      <div className="h-[1px] w-full bg-[#E9EAEB]" />
      <div className="flex flex-col items-start gap-2 self-stretch">
        <p className="text-base text-[#181D27] font-medium">Experiences</p>
        {sortedExperiences?.map((experience) => {
          const startString =
            dateStringToLocalDate(experience.startDate)?.toLocaleDateString(
              "en-US",
              {
                month: "short",
                year: "numeric",
              },
            ) ?? "Unknown";
          const endString =
            dateStringToLocalDate(experience.endDate)?.toLocaleDateString(
              "en-US",
              {
                month: "short",
                year: "numeric",
              },
            ) ?? "Present";
          return (
            <div
              key={`experience-${experience.id}`}
              className="py-5 px-4 flex flex-col items-start gap-6 self-stretch border border-[#E9EAEB] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] rounded-xl"
            >
              <div className="flex flex-row items-center gap-3 self-stretch">
                {Boolean(experience.companyLogoLink) && (
                  <div className="h-12 w-12 rounded-full overflow-hidden">
                    <img
                      className="h-full w-full object-cover"
                      src={experience.companyLogoLink}
                    />
                  </div>
                )}
                <div className="flex-1 flex flex-col">
                  <p className="text-base text-[#181D27] font-semibold">
                    {experience.jobTitle}
                  </p>
                  <p className="text-sm text-[#535862]">
                    {experience.companyName}
                  </p>
                </div>
              </div>
              <p className="text-sm text-[#535862]">{`${startString} – ${endString}`}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

const Notes = ({
  job,
  candidate,
}: {
  job: AdminJobDetails;
  candidate: AdminJobCandidateDetails;
}) => {
  const { setCandidateOpportunityNotes, isSettingCandidateOpportunityNotes } =
    useSetCandidateOpportunityNotes(job.id, candidate.candidateStatusId);

  const form = useForm<CandidateOpportunityNotesFormSchema>({
    resolver: zodResolver(candidateOpportunityNotesFormSchema),
    defaultValues: {
      notes: candidate.notes ?? "",
    },
  });

  return (
    <>
      <div className="h-[1px] w-full bg-[#E9EAEB]" />
      <div className="flex flex-col gap-4 self-stretch">
        <Form {...form}>
          <form>
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Internal Notes</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Internal Notes"
                      className="min-h-12"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
          <div className="w-full flex flex-row justify-end">
            <Button
              disabled={
                !form.formState.isValid || isSettingCandidateOpportunityNotes
              }
              onClick={() => setCandidateOpportunityNotes(form.getValues())}
            >
              {isSettingCandidateOpportunityNotes && (
                <Loader2 className="animate-spin" />
              )}
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CandidateDetailsDrawer;
