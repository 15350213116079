import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";

export interface AdminCompany {
  id: string;
  name?: string;
  logoLink?: string;
  location?: string;
  linkedin?: string;
  websiteLink?: string;
  acknowledged: boolean;
}

export const useAdminCompaniesQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: adminCompanies,
    error: adminCompaniesError,
    isLoading: adminCompaniesLoading,
  } = useQuery({
    queryKey: QueryKeys.adminCompanies,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return request<AdminCompany[]>({
        method: "GET",
        path: `/api/admin/companies`,
        token,
      });
    },
  });

  return {
    adminCompanies,
    adminCompaniesError,
    adminCompaniesLoading,
  };
};
