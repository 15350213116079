/* eslint-disable @typescript-eslint/no-misused-promises */
import { ReactNode, useEffect, useState } from "react";
import {
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  CompanyOpportunityFormSchema,
  companyOpportunityFormSchema,
  companyOpportunityInterviewSchema,
  CompanyOpportunityInterviewSchema,
} from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import FilePond from "@/components/ui/file-pond";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@fluentui/react-components";
import { ReOrderDotsVerticalFilled } from "@fluentui/react-icons";
import PreprocessIcon from "@/assets/preprocess_icon.svg";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ArrowUpRightFromSquare } from "lucide-react";

import { AdminJobDetails } from "@/queries/admin/job-details";
import useCompanyOpportunityMutation from "./mutations";

import { CompanyJobOpening } from "@/types";

interface Props {
  opening?: CompanyJobOpening | AdminJobDetails;
  defaultTitle?: string;
  modal?: boolean;
  includeMaskedJobDescription?: boolean;
  redirectAfterCreation?: false;
  onSuccess?: () => void;
}

const tabsList = ["Job Details", "Interviews"] as const;
type Tab = (typeof tabsList)[number];

function getMaskedJobDescriptionLink(job: CompanyJobOpening | AdminJobDetails) {
  return (job as AdminJobDetails)?.maskedJobDescriptionLink;
}

const CompanyOpportunityForm = ({
  opening,
  defaultTitle,
  modal,
  includeMaskedJobDescription,
  redirectAfterCreation,
  onSuccess: successCallback,
}: Props) => {
  const [tab, setTab] = useState<Tab>(tabsList[0]);

  const {
    mutate,
    isPending: mutationLoading,
    isError: mutationError,
  } = useCompanyOpportunityMutation(
    opening ? "update" : "create",
    opening?.id,
    redirectAfterCreation,
    successCallback,
  );

  const form = useForm<CompanyOpportunityFormSchema>({
    resolver: async (data, context, opts) => {
      if (opening && !data.jobDescription) {
        data.jobDescription = new File([], "");
      }

      return zodResolver(companyOpportunityFormSchema)(data, context, opts);
    },
    defaultValues: {
      jobTitle: opening?.role ?? defaultTitle ?? "",
      scope: opening?.scope ?? "",
      canLeadToFullTime: opening?.convertsToFullTime ?? "",
      budget: opening?.budget ?? "",
      idealStartDate: opening?.desiredStartDate
        ? new Date(opening?.desiredStartDate ?? "")
        : undefined,
      briefDescription: opening?.shortDescription ?? "",
      keyResponsibilities: opening?.keyResponsibilities ?? "",
      matchCriteria1: opening?.matchCriteria1 ?? "",
      matchCriteria2: opening?.matchCriteria2 ?? "",
      matchCriteria3: opening?.matchCriteria3 ?? "",
      additionalInformation: opening?.noteForVenturous ?? "",
      interviews: opening?.interviews ?? [],
    },
  });

  const interviewFieldArray = useFieldArray({
    control: form.control,
    name: "interviews",
  });

  function onNext(_data: CompanyOpportunityFormSchema) {
    setTab("Interviews");
  }

  function onSubmit(data: CompanyOpportunityFormSchema) {
    if (mutationLoading) return;
    mutate(data);
  }

  return (
    <Form {...form}>
      <form className="space-y-4 w-full">
        {mutationError && (
          <p className="text-center w-full border border-destructive rounded-md text-destructive p-2 bg-destructive/10">
            Something went wrong when {opening ? "updating" : "creating"} the
            job opening, please try again later.
          </p>
        )}
        {tab === "Job Details" ? (
          <DetailsTab
            form={form}
            opening={opening}
            modal={modal}
            includeMaskedJobDescription={includeMaskedJobDescription}
            onSubmit={form.handleSubmit(onNext)}
          />
        ) : (
          <InterviewTab
            form={form}
            fields={interviewFieldArray}
            mutationLoading={mutationLoading}
            setTab={setTab}
            onSubmit={form.handleSubmit(onSubmit)}
          />
        )}
      </form>
    </Form>
  );
};

const DetailsTab = (props: {
  form: UseFormReturn<CompanyOpportunityFormSchema>;
  opening: CompanyJobOpening | AdminJobDetails | undefined;
  modal: boolean | undefined;
  includeMaskedJobDescription?: boolean;
  onSubmit: () => void;
}) => {
  const maskedJobDescriptionLink =
    props.opening && getMaskedJobDescriptionLink(props.opening);

  return (
    <>
      <FormField
        control={props.form.control}
        name="jobTitle"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Job Title</FormLabel>
            <FormControl>
              <Input {...field} placeholder="CEO" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={props.form.control}
        name="jobDescription"
        render={({ field }) => (
          <FormItem>
            <div className="flex gap-1 items-center">
              <FormLabel required>Job Description</FormLabel>
              {props.opening?.jobDescriptionLink && (
                <a
                  href={props.opening?.jobDescriptionLink}
                  target="_blank"
                  rel="noreferrer"
                  className="font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                >
                  View Current
                  <ArrowUpRightFromSquare className="size-[.9rem] inline-block align-text-bottom ml-0.5" />
                </a>
              )}
            </div>
            <FormControl>
              <FilePond
                description="Please upload an editable file, such as a Word Document."
                value={field.value ? [field.value] : []}
                accept={""}
                onChange={(files) => {
                  field.onChange(files[0]);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {props.includeMaskedJobDescription && (
        <FormField
          control={props.form.control}
          name="maskedJobDescription"
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-1 items-center">
                <FormLabel required>Masked Job Description</FormLabel>
                {maskedJobDescriptionLink && (
                  <a
                    href={maskedJobDescriptionLink}
                    target="_blank"
                    rel="noreferrer"
                    className="font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                  >
                    View Current
                    <ArrowUpRightFromSquare className="size-[.9rem] inline-block align-text-bottom ml-0.5" />
                  </a>
                )}
              </div>
              <FormControl>
                <FilePond
                  description="Please upload an editable file, such as a Word Document."
                  value={field.value ? [field.value] : []}
                  accept={""}
                  onChange={(files) => {
                    field.onChange(files[0]);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <TwoColumnWrapper>
        <FormField
          control={props.form.control}
          name="scope"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>
                What is the scope of the engagement?
              </FormLabel>
              <FormControl>
                <Input {...field} placeholder="2 days a week / 8 months" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={props.form.control}
          name="canLeadToFullTime"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>
                Could this lead to a full-time position?
              </FormLabel>
              <FormControl>
                <Select
                  name={field.name}
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={field.disabled}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={"Select one"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Yes">Yes</SelectItem>
                    <SelectItem value="No">No</SelectItem>
                    <SelectItem value="Unsure">Unsure</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </TwoColumnWrapper>

      <TwoColumnWrapper>
        <FormField
          control={props.form.control}
          name="budget"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>What is your budget for this role?</FormLabel>
              <FormControl>
                <Input {...field} placeholder="$75 - 150/hr or $4,000/month" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={props.form.control}
          name="idealStartDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Ideal start date</FormLabel>
              <FormControl>
                <DatePicker
                  value={field.value}
                  onChange={field.onChange}
                  modal={props.modal}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </TwoColumnWrapper>

      <FormField
        control={props.form.control}
        name="briefDescription"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>
              Please provide a brief description of the role
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                placeholder="Covering day-to-day financial operations, financial planning, and financial analysis"
                className="min-h-12"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={props.form.control}
        name="keyResponsibilities"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>
              What are the key responsibilities of the role?
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                placeholder="Managing the financial operations of the company"
                className="min-h-12"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="space-y-2">
        <Label>What are the three most important skills for this role?</Label>

        <FormField
          control={props.form.control}
          name="matchCriteria1"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  placeholder="Experience with SaaS and healthcare acquisitions"
                  className="min-h-12"
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={props.form.control}
          name="matchCriteria2"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  placeholder="Executive presence with the board"
                  className="min-h-12"
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={props.form.control}
          name="matchCriteria3"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  {...field}
                  placeholder="Willingness to perform financial functions from basic to executive levels"
                  className="min-h-12"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={props.form.control}
        name="additionalInformation"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              Is there anything else you would like to share with Venturous
              about this job opportunity?
            </FormLabel>
            <FormControl>
              <Textarea
                {...field}
                placeholder="Experience with SaaS and healthcare acquisitions"
                className="min-h-12"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="flex justify-end">
        <Button type="submit" className="mt-2" onClick={props.onSubmit}>
          Next
        </Button>
      </div>
    </>
  );
};

const InterviewTab = (props: {
  form: UseFormReturn<CompanyOpportunityFormSchema>;
  fields: UseFieldArrayReturn<CompanyOpportunityFormSchema, "interviews", "id">;
  mutationLoading: boolean;
  setTab: (tab: Tab) => void;
  onSubmit: () => void;
}) => {
  const [selectedInterviewIndex, setSelectedInterviewIndex] = useState<
    number | undefined
  >();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      // Ids are idx + 1 because ids of 0 seem to cause issues
      const oldIndex = (active.id as number) - 1;
      const newIndex = (over.id as number) - 1;

      props.fields.move(oldIndex, newIndex);
    }
  };

  const addInterview = (interview: CompanyOpportunityInterviewSchema) => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    setSelectedInterviewIndex(undefined);

    props.fields.append(interview);
  };

  const updateInterview = (interview: CompanyOpportunityInterviewSchema) => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    setSelectedInterviewIndex(undefined);
    props.fields.update(selectedInterviewIndex, interview);
  };

  const deleteInterview = () => {
    if (selectedInterviewIndex === undefined) {
      return;
    }

    props.fields.remove(selectedInterviewIndex);
    setSelectedInterviewIndex(undefined);
  };

  return (
    <>
      <div className="mb-8 flex flex-col items-center">
        <h4 className="text-md font-semibold w-fit">
          Add each step in your interview process
        </h4>
        <p className="text-md w-fit">
          You can skip and come back to this anytime.
        </p>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => handleDragEnd(event)}
      >
        <SortableContext
          items={props.fields.fields.map(
            (_: CompanyOpportunityInterviewSchema, idx: number) => idx + 1,
          )}
          strategy={verticalListSortingStrategy}
        >
          {props.fields.fields.map(
            (interview: CompanyOpportunityInterviewSchema, idx: number) => (
              <SortableItem
                key={`interview-${interview.id ?? idx}`}
                // ID of 0 seems to cause issues, so increment by 1
                id={idx + 1}
                interview={interview}
                onClick={() => setSelectedInterviewIndex(idx)}
              />
            ),
          )}
        </SortableContext>
      </DndContext>
      <Card
        className="w-full flex !flex-row items-center justify-center gap-2 cursor-pointer"
        onClick={() => setSelectedInterviewIndex(props.fields.fields.length)}
      >
        {/* Full width plus sign */}
        <p className="font-semibold">&#65291; Add another interview</p>
      </Card>

      <div className="flex justify-between">
        <Button
          type="button"
          className="mt-2"
          variant="outline"
          loading={props.mutationLoading}
          onClick={() => props.setTab("Job Details")}
        >
          Back
        </Button>
        <Button
          type="submit"
          className="mt-2"
          loading={props.mutationLoading}
          onClick={props.onSubmit}
        >
          Save
        </Button>
      </div>
      <InterviewForm
        interview={
          selectedInterviewIndex === undefined
            ? undefined
            : props.fields.fields[selectedInterviewIndex]
        }
        open={selectedInterviewIndex !== undefined}
        setOpen={(v: boolean) => v || setSelectedInterviewIndex(undefined)}
        save={
          selectedInterviewIndex !== undefined &&
          props.fields.fields[selectedInterviewIndex]
            ? updateInterview
            : addInterview
        }
        delete={deleteInterview}
      />
    </>
  );
};

const InterviewForm = (props: {
  interview?: CompanyOpportunityInterviewSchema;
  open: boolean;
  setOpen: (v: boolean) => void;
  save: (v: CompanyOpportunityInterviewSchema) => void;
  delete: () => void;
}) => {
  const form = useForm<CompanyOpportunityInterviewSchema>({
    resolver: async (data, context, opts) => {
      return zodResolver(companyOpportunityInterviewSchema)(
        data,
        context,
        opts,
      );
    },
    defaultValues: {
      id: props.interview?.id,
      name: props.interview?.name ?? "",
      duration: props.interview?.duration ?? 30,
    },
  });

  useEffect(() => {
    form.reset({
      id: props.interview?.id,
      name: props.interview?.name ?? "",
      duration: props.interview?.duration ?? 30,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.interview]);

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent className="w-full max-w-[min(calc(100vw-2rem),768px)] p-0">
        <DialogHeader className="p-6">
          <div className="flex items-center gap-2">
            <img src={PreprocessIcon} alt="Preprocess Icon" className="" />
            <DialogTitle>
              {props.interview ? "Update" : "Add"} an Interview
            </DialogTitle>
          </div>
          <DialogDescription className="sr-only">
            Enter the details of the interview here and click &quot;Save&quot;
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
          <Form {...form}>
            <div className="space-y-4 w-full">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Interview Name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="First Interview" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="duration"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>Duration (minutes)</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        min={1}
                        step={1}
                        placeholder="30"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div
                className={`flex ${props.interview ? "justify-between" : "justify-end"}`}
              >
                {props.interview && (
                  <Button
                    type="button"
                    className="mt-2"
                    variant="destructive"
                    onClick={props.delete}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="button"
                  className="mt-2"
                  onClick={form.handleSubmit(props.save)}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

const SortableItem = (props: {
  id: number;
  interview: CompanyOpportunityInterviewSchema;
  onClick: () => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="w-full flex !flex-row items-center gap-2"
      onClick={props.onClick}
    >
      <div className="h-6 w-6 flex justify-center" {...listeners}>
        <ReOrderDotsVerticalFilled className="m-auto" />
      </div>
      <div className="flex-1 flex gap-2 items-center justify-between">
        <p className="font-semibold">{props.interview.name}</p>
        <p>{props.interview.duration} mins</p>
      </div>
    </Card>
  );
};

const TwoColumnWrapper = (props: { children: ReactNode }) => {
  return (
    <div className="grid gap-4 grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
      {props.children}
    </div>
  );
};

export default CompanyOpportunityForm;
