import request from "@/lib/api/handler";
import { setUser, User } from "@/redux/features/authSlice";
import { useAppDispatch } from "@/redux/hooks";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

export const useCompleteOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const invalidate = useInvalidateQuery();
  const { getAccessTokenSilently } = useAuth0();

  const {
    mutate,
    isPending: completeOnboardingPending,
    error: completeOnboardingError,
  } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      return await request<User>({
        method: "POST",
        path: `/api/candidate/register`,
        token,
      });
    },
    onSuccess: async (result) => {
      void dispatch(setUser(result));
      await invalidate(QueryKeys.myProfile);
      navigate("/landing");
    },
  });

  return {
    completeOnboarding: mutate,
    completeOnboardingPending,
    completeOnboardingError,
  };
};
