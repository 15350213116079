import { useLayoutEffect, useMemo, useRef, useState } from "react";

import { cn } from "@/lib/utils";
import { ChevronsRight, MoveUpRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
} from "@/components/ui/sheet";
import { ScrollArea } from "@/components/ui/scroll-area";

import { AdminPipelineCandidate } from "@/queries/admin/candidate-pipeline";
import { useSetCandidateStage } from "@/mutations/admin/set-candidate-stage";
import { CandidateWaitingRoomStatus } from "@/types";

import { sortExperiences } from "@/redux/features/candidateProfileSlice";
import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

const NEXT_STAGE_MAP: Record<
  CandidateWaitingRoomStatus,
  CandidateWaitingRoomStatus
> = {
  New: "Invited to Apply",
  "Invited to Apply": "Application Under Review",
  "Application Under Review": "Interviewing",
  Interviewing: "Interview Under Review",
  "Interview Under Review": "Taking/Sent MAP",
  "Taking/Sent MAP": "MAP Under Review",
  "MAP Under Review": "Joined",
  Joined: "Joined",
  "Dropped out": "Dropped out",
  Rejected: "Rejected",
};

const BIOGRAPHY_MAX_LINES = 6;
const MAX_LINES_CLASS = "line-clamp-6";

const CandidateDetailsDrawer = ({
  candidate,
  setOpen,
}: {
  candidate?: AdminPipelineCandidate;
  setOpen: (v: boolean) => void;
}) => {
  const [readMore, setReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const { setCandidateStage, isSettingCandidateStage } = useSetCandidateStage();

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetHeight, scrollHeight } = ref.current;

      if (readMore) {
        // If readMore is true, check how many lines of text there are
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const lines = offsetHeight / lineHeight;

        // If there are more than BIOGRAPHY_MAX_LINES, then everything is fine
        if (lines > BIOGRAPHY_MAX_LINES) {
          return;
        } else {
          // If not, the text is not being truncated anyway and we can reset
          setIsTruncated(false);
          setReadMore(false);
        }
      } else if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        // If readMore is not true, then check if the text is being cut off by the line clamp
        setIsTruncated(true);
        setReadMore(false);
      } else {
        // In all other cases, reset
        setIsTruncated(false);
        setReadMore(false);
      }
    }
  }, [ref, readMore, candidate, candidate?.biography]);

  const sortedExperiences = useMemo(() => {
    if (candidate?.experiences) {
      return [...candidate.experiences].sort(sortExperiences);
    }
  }, [candidate?.experiences]);

  if (!candidate) {
    return;
  }

  return (
    <Sheet open={Boolean(candidate)} onOpenChange={setOpen}>
      <SheetContent className="w-full max-w-full sm:max-w-md md:max-w-lg bg-background line-clamp">
        <SheetTitle className="sr-only">Candidate Details</SheetTitle>
        <SheetDescription className="sr-only">
          Details on a candidate in the pipeline
        </SheetDescription>
        <ScrollArea className="h-[calc(100vh-4.75rem)]" type="scroll">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start gap-4">
              <div className="flex flex-col items-start gap-6 self-stretch">
                {Boolean(candidate.profilePictureLink) && (
                  <div className="h-24 w-24 rounded-full overflow-hidden">
                    <img
                      className="h-full w-full object-cover"
                      src={candidate.profilePictureLink}
                    />
                  </div>
                )}

                <div className="flex flex-col items-start gap-0.5 self-stretch">
                  <p className="text-xl text-[#181d27] font-semibold leading-[30px]">
                    {candidate.name}
                  </p>
                  <p className="text-base text-[#5c6060]">
                    {candidate.titleAndEmployer}
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <Button
                  variant="destructive"
                  disabled={isSettingCandidateStage}
                  onClick={() => {
                    setCandidateStage({
                      candidateId: candidate.candidateId,
                      stage: "Rejected",
                    });
                    setOpen(false);
                  }}
                >
                  Decline
                </Button>
                <Button
                  variant="secondary"
                  disabled={isSettingCandidateStage}
                  onClick={() => {
                    setCandidateStage({
                      candidateId: candidate.candidateId,
                      stage: NEXT_STAGE_MAP[candidate.stage],
                    });
                    setOpen(false);
                  }}
                >
                  Advance <ChevronsRight />
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-start gap-6 self-stretch">
              <div className="flex flex-col items-start gap-2 self-stretch">
                <p className="text-sm text-[#535862] font-medium">Location</p>
                <p className="text-base text-[#414651] font-medium">
                  {candidate.location ?? "Unknown"}
                </p>
              </div>
              {Boolean(candidate.personalWebsite) && (
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-sm text-[#535862] font-medium">
                    Personal Website
                  </p>
                  <a
                    href={candidate.personalWebsite}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                  >
                    <span className="flex flex-row items-center gap-0.5">
                      {candidate.personalWebsite}
                      <MoveUpRight size={16} />
                    </span>
                  </a>
                </div>
              )}
              {Boolean(candidate.resumeLink) && (
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-sm text-[#535862] font-medium">Resume</p>
                  <a
                    href={candidate.resumeLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                  >
                    <span className="flex flex-row items-center gap-0.5">
                      Resume
                      <MoveUpRight size={16} />
                    </span>
                  </a>
                </div>
              )}
              {Boolean(candidate.linkedIn) && (
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-sm text-[#535862] font-medium">LinkedIn</p>
                  <a
                    href={candidate.linkedIn}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                  >
                    <span className="flex flex-row items-center gap-0.5">
                      {candidate.linkedIn}
                      <MoveUpRight size={16} />
                    </span>
                  </a>
                </div>
              )}
              <div className="flex flex-col items-start gap-2 self-stretch">
                <p className="text-sm text-[#535862] font-medium">Email</p>
                <a
                  href={`mailto:${candidate.email}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                >
                  <span className="flex flex-row items-center gap-0.5">
                    {candidate.email}
                    <MoveUpRight size={16} />
                  </span>
                </a>
              </div>
              <div className="flex flex-col items-start gap-2 self-stretch">
                <p className="text-sm text-[#535862] font-medium">Profile</p>
                <a
                  href={`/candidate/${candidate.userId}/edit`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                >
                  <span className="flex flex-row items-center gap-0.5">
                    Profile
                    <MoveUpRight size={16} />
                  </span>
                </a>
              </div>
              {Boolean(candidate.attioId) && (
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-sm text-[#535862] font-medium">
                    Attio Profile
                  </p>
                  <a
                    href={`https://app.attio.com/venturous/person/${candidate.attioId}/activity`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                  >
                    <span className="flex flex-row items-center gap-0.5">
                      Attio
                      <MoveUpRight size={16} />
                    </span>
                  </a>
                </div>
              )}
              {Boolean(candidate.biography) && (
                <>
                  <div className="h-[1px] w-full bg-[#E9EAEB]" />
                  <div className="flex flex-col items-start gap-2 self-stretch">
                    <p className="text-base text-[#181D27] font-medium">
                      About me
                    </p>
                    <p
                      ref={ref}
                      className={cn(
                        "text-base text-[#535862] whitespace-break-spaces",
                        readMore || MAX_LINES_CLASS,
                      )}
                    >
                      {candidate.biography}
                    </p>
                    {!isTruncated ? null : readMore ? (
                      <p
                        className="text-sm text-[#6941C6] font-semibold hover:underline cursor-pointer"
                        onClick={() => setReadMore(false)}
                      >
                        View less
                      </p>
                    ) : (
                      <p
                        className="text-sm text-[#6941C6] font-semibold hover:underline cursor-pointer"
                        onClick={() => setReadMore(true)}
                      >
                        View more
                      </p>
                    )}
                  </div>
                </>
              )}
              {Boolean(sortedExperiences?.length) && (
                <>
                  <div className="h-[1px] w-full bg-[#E9EAEB]" />
                  {sortedExperiences?.map((experience) => {
                    const startString =
                      dateStringToLocalDate(
                        experience.startDate,
                      )?.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      }) ?? "Unknown";
                    const endString =
                      dateStringToLocalDate(
                        experience.endDate,
                      )?.toLocaleDateString("en-US", {
                        month: "short",
                        year: "numeric",
                      }) ?? "Present";
                    return (
                      <div
                        key={`experience-${experience.id}`}
                        className="py-5 px-4 flex flex-col items-start gap-6 self-stretch border border-[#E9EAEB] shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] rounded-xl"
                      >
                        <div className="flex flex-row items-center gap-3 self-stretch">
                          {Boolean(experience.companyLogoLink) && (
                            <div className="h-12 w-12 rounded-full overflow-hidden">
                              <img
                                className="h-full w-full object-cover"
                                src={experience.companyLogoLink}
                              />
                            </div>
                          )}
                          <div className="flex-1 flex flex-col">
                            <p className="text-base text-[#181D27] font-semibold">
                              {experience.jobTitle}
                            </p>
                            <p className="text-sm text-[#535862]">
                              {experience.companyName}
                            </p>
                          </div>
                        </div>
                        <p className="text-sm text-[#535862]">{`${startString} – ${endString}`}</p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

export default CandidateDetailsDrawer;
