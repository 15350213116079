import { useAvailabilitySheet, useAvailabilitySheetState } from "./store";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { CandidateJobOpening } from "@/types";
import { Check, Repeat } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import TimezoneSelect from "@/components/ui/timezone";
import SpecificAvailability from "./specific-availability";
import AvailabilityDay from "./availability-day";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useSaveCandidateAvailability } from "./mutations";

const CandidateAvailabilitySheet = () => {
  const [open, setOpen, opportunity, interview, successOpen, setSuccessOpen] =
    useAvailabilitySheetState();

  const { saveCandidateAvailability, candidateAvailabilityMutPending } =
    useSaveCandidateAvailability(opportunity?.id ?? "", interview?.id ?? "");

  return (
    <>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className="w-full max-w-full sm:max-w-md md:max-w-lg bg-background">
          <SheetHeader className="pb-6">
            <SheetTitle>Schedule an Interview</SheetTitle>
            <SheetDescription className="sr-only">
              Enter your availability for an interview for the selected
              opportunity
            </SheetDescription>
          </SheetHeader>
          <ScrollArea className="h-[calc(100vh-4.75rem)]" type="scroll">
            {opportunity && <OpportunityInfo opportunity={opportunity} />}
            <AvailabilitySection duration={interview?.duration} />
            <SpecificAvailability />
            <Button
              variant={"secondary"}
              className="mt-8 w-full"
              onClick={() => saveCandidateAvailability()}
              loading={candidateAvailabilityMutPending}
            >
              Save Availability
            </Button>
            <Button
              variant="outline"
              className="mt-2 w-full"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </ScrollArea>
        </SheetContent>
      </Sheet>
      <Dialog open={successOpen} onOpenChange={setSuccessOpen}>
        <DialogContent>
          <DialogHeader>
            <div className="space-y-4">
              <div className="flex items-center justify-center size-12 box-content border-[6px] border-accent-og rounded-full">
                <Check className="size-7 text-secondary" />
              </div>
              <DialogTitle>
                Thank you for submitting your availability!
              </DialogTitle>
              <DialogDescription>
                You will receive a meeting invite sent to your email once the
                employer selects a time/date to meet.
              </DialogDescription>
            </div>

            <DialogFooter className="w-full justify-center">
              <DialogClose asChild>
                <Button
                  variant="secondary"
                  className="mt-4 w-full"
                  onClick={() => setOpen(false)}
                >
                  Return To Dashboard
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CandidateAvailabilitySheet;

const OpportunityInfo = ({
  opportunity,
}: {
  opportunity: CandidateJobOpening;
}) => {
  return (
    <div className="border border-border rounded-md p-4 w-full">
      <div className="flex items-center justify-start [&>*]:pb-4 gap-3">
        <div>
          <Avatar className="size-10">
            {opportunity.company?.logoLink && (
              <AvatarImage src={opportunity.company?.logoLink} />
            )}
            <AvatarFallback>
              {opportunity.company?.name
                ?.split(" ")
                .slice(0, 2)
                .map((w) => w.charAt(0))
                .join("")}
            </AvatarFallback>
          </Avatar>
        </div>
        <div className="border-b border-border w-full space-y-1">
          <h6 className="text-muted-foreground text-sm leading-none">
            {opportunity.company?.name}
          </h6>
          <h5 className="font-semibold text-base leading-none">
            {opportunity.role}
          </h5>
        </div>
      </div>
      <div className="pl-12 py-4 flex flex-wrap gap-x-4 gap-y-2 whitespace-nowrap">
        <div className="flex items-center gap-1">
          <p className="text-muted-foreground leading-none text-sm">Time</p>
          <p className="bg-accent-og rounded-lg leading-none px-2.5 py-1.5 text-sm">
            {opportunity.scope?.split(" / ")[0]}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <p className="text-muted-foreground leading-none text-sm">
            Start Date
          </p>
          <p className="bg-accent-og rounded-lg leading-none px-2.5 py-1.5 text-sm">
            {opportunity.desiredStartDate
              ? Date.parse(opportunity.desiredStartDate)
                ? new Date(
                    Date.parse(opportunity.desiredStartDate),
                  ).toDateString()
                : opportunity.desiredStartDate
              : "N/A"}
          </p>
        </div>
        <div className="flex items-center gap-1">
          <p className="text-muted-foreground leading-none text-sm">
            Est. Length
          </p>
          <p className="bg-accent-og rounded-lg leading-none px-2.5 py-1.5 text-sm">
            {opportunity.scope?.split(" / ")[1] ?? "N/A"}
          </p>
        </div>
      </div>
    </div>
  );
};

const AvailabilitySection = ({ duration }: { duration?: number }) => {
  const days = useAvailabilitySheet((s) => s.dayAvailabilities);
  const setDays = useAvailabilitySheet((s) => s.setDayAvailabilities);
  const timezone = useAvailabilitySheet((s) => s.timezone);
  const setTimezone = useAvailabilitySheet((s) => s.setTimezone);

  return (
    <div className="pt-4">
      <SheetTitle>Your Availability</SheetTitle>
      <div className="pt-2 pb-4">
        <div className="flex items-center gap-1">
          <Repeat className="size-4" />
          <p className="font-semibold leading-none text-sm">Weekly Hours</p>
        </div>
        <p className="text-sm text-muted-foreground">
          {duration
            ? `What times are you available for a ${duration} minute interview`
            : "What times are you available for an interview?"}
        </p>
      </div>
      <TimezoneSelect timezone={timezone} onTimezoneChange={setTimezone} />
      <div className="space-y-3 pt-6">
        {Array.from({ length: 7 }).map((_, i) => (
          <AvailabilityDay
            key={`availability-day-${i}`}
            day={i}
            entries={days[i] ?? []}
            setEntries={(e) => {
              setDays([...days.slice(0, i), e, ...days.slice(i + 1)]);
            }}
          />
        ))}
      </div>
    </div>
  );
};
