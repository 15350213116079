import { useState } from "react";

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tooltip } from "@/components/ui/tooltip";
import { FileText } from "lucide-react";

import { Avatar } from "@fluentui/react-components";
import { CandidateJobOpening } from "@/types";
import dateStringToLocalDate from "@/utils/dateStringToLocalDate";
import {
  DollarSquareSVG,
  LinkedInSVG,
  MoneyReceiveSVG,
  MonitorMobileSVG,
  PeopleSVG,
  ReceiptEditSVG,
} from "@/assets/svgs";

export const OpportunityDetailsDialog = ({
  opening,
}: {
  opening: CandidateJobOpening;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Tooltip content="See details">
          <Button variant="outline" size="icon">
            <FileText />
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <DialogTitle>Opportunity Details</DialogTitle>
          <DialogDescription>
            Additional details about this opportunity
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh] pr-3 gap-3">
          <div className="flex flex-col gap-5">
            <div className="flex gap-3 self-stretch items-center">
              <Avatar
                image={{ src: opening.company?.logoLink }}
                name={opening.company?.name}
                size={48}
              />
              <div className="flex-1 flex flex-col gap-1">
                <p className="text-base font-semibold text-[#22234A]">
                  {opening.role}
                </p>
                <p className="text-sm font-normal text-[#727E94]">
                  {opening.company?.name}
                </p>
              </div>
              {opening.candidateStatus.status !== "Checking Interest" ? (
                <Button
                  onClick={() =>
                    window.open(
                      `/opening/${opening.id}/application/${opening.candidateStatus.id}`,
                      "_blank",
                    )
                  }
                >
                  Preview Application
                </Button>
              ) : null}
            </div>
            <div className="w-full h-[1px] bg-[#e0e0e0]" />
            <div className="flex flex-col gap-2 self-stretch flex-wrap">
              <h2 className="text-xl font-semibold text-[#22234A]">
                Job Details
              </h2>
              <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                {opening.shortDescription}
              </p>
            </div>
            <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
              <OpportunityKeyPoint title="Scope" value={opening?.scope} />
              <OpportunityKeyPoint
                title="Desired Start Date"
                value={dateStringToLocalDate(
                  opening.desiredStartDate,
                )?.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              />
            </div>
            <div className="w-full h-[1px] bg-[#e0e0e0]" />
            <div className="flex flex-col gap-2 self-stretch flex-wrap">
              <h2 className="text-xl font-semibold text-[#22234A]">
                Key Responsibilities
              </h2>
              <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                {opening.keyResponsibilities}
              </p>
            </div>
            <div className="w-full h-[1px] bg-[#e0e0e0]" />
            <div className="flex flex-col gap-2 self-stretch flex-wrap">
              <h2 className="text-xl font-semibold text-[#22234A]">About Us</h2>
              <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                {opening.company?.aboutUs}
              </p>
            </div>
            <div className="flex flex-col gap-2 self-stretch flex-wrap">
              <h3 className="text-base font-semibold text-[#22234A]">
                Background
              </h3>
              <div className="p-4 flex flex-col gap-2 self-stretch border border-[#F2F2F2] rounded-xl">
                <div className="flex flex-col self-stretch">
                  <BackgroundItem
                    icon={
                      <ReceiptEditSVG
                        className="flex-shrink-0"
                        height="24"
                        width="24"
                      />
                    }
                    name="Founded"
                    value={opening.company?.foundedYear}
                  />
                  <BackgroundItem
                    icon={
                      <PeopleSVG
                        className="flex-shrink-0"
                        height="24"
                        width="24"
                      />
                    }
                    name="Company Size"
                    value={opening.company?.companySize}
                  />
                  <BackgroundItem
                    icon={
                      <DollarSquareSVG
                        className="flex-shrink-0"
                        height="24"
                        width="24"
                      />
                    }
                    name="Funds Raised"
                    value={opening.company?.fundsRaised}
                  />
                  <BackgroundItem
                    icon={
                      <MoneyReceiveSVG
                        className="flex-shrink-0"
                        height="24"
                        width="24"
                      />
                    }
                    name="Last Fundraise"
                    value={opening.company?.lastFundraise}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 self-stretch flex-wrap">
              <h3 className="text-base font-semibold text-[#22234A]">
                Company Links
              </h3>
              <div className="flex gap-2 self-stretch">
                <LinkButton
                  icon={
                    <LinkedInSVG
                      className="flex-shrink-0"
                      height="20"
                      width="20"
                    />
                  }
                  text="Linkedin"
                  link={opening.company?.linkedIn}
                />
                <LinkButton
                  icon={
                    <MonitorMobileSVG
                      className="flex-shrink-0"
                      height="20"
                      width="20"
                    />
                  }
                  text="Website"
                  link={opening.company?.websiteLink}
                />
                <Button
                  variant="secondary"
                  onClick={() =>
                    window.open(`/company/${opening.company?.id}`, "_blank")
                  }
                >
                  Company Profile
                </Button>
              </div>
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

const LinkButton = ({
  icon,
  text,
  link,
}: {
  icon: JSX.Element;
  text: string;
  link?: string;
}) => {
  if (!link) {
    return;
  }

  return (
    <Button
      variant="outline"
      onClick={() =>
        window.open(
          link.startsWith("http") ? link : `https://${link}`,
          "_blank",
        )
      }
    >
      {icon}
      {text}
    </Button>
  );
};

const BackgroundItem = ({
  icon,
  name,
  value,
}: {
  icon: JSX.Element;
  name: string;
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-3 self-stretch justify-between py-4 border-b border-[#F7F7F7] first:pt-0 last:pb-0 last:border-b-0">
      <div className="flex gap-3">
        {icon}
        <p className="text-sm font-medium text-[#22234A]">{name}</p>
      </div>
      <p className="text-sm font-medium text-[#22234A]">{value}</p>
    </div>
  );
};

const OpportunityKeyPoint = ({
  title,
  value,
}: {
  title: "Scope" | "Pay" | "Desired Start Date";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1">
      <p className="text-sm font-medium text-[#727E94]">{title}:</p>
      <p className="text-sm font-semibold text-[#22234A]">{value}</p>
    </div>
  );
};
