import { ReactNode } from "react";

import { ArrowLeft, ArrowRight, ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const TableWrapper = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="flex flex-col self-stretch border border-[#e9eaeb] rounded-xl shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] overflow-clip">
      {children}
    </div>
  );
};

const TableHeading = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="px-6 py-5 flex flex-row gap-4 self-stretch border-b border-b-[#e9eaeb]">
      {children}
    </div>
  );
};

const TableBody = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="overflow-auto">
      <table className="w-full overflow-auto">{children}</table>
    </div>
  );
};

const TableRow = ({ children }: { children?: ReactNode }) => {
  return <tr className="even:bg-[#fdfdfd]">{children}</tr>;
};

const TableRowItem = ({
  heading,
  button,
  children,
  sortDirection,
  onClick,
}: {
  heading?: boolean;
  button?: boolean;
  children?: ReactNode;
  sortDirection?: -1 | 1;
  onClick?: () => void;
}) => {
  const className = cn(
    "px-6 py-3 gap-3 border-b border-b-[#E9EAEB]",
    button && "w-1",
    onClick && "cursor-pointer",
    heading && " text-xs font-semibold text-[#717680] leading-[18px]",
  );

  return heading ? (
    <th className={className} onClick={onClick}>
      <div className="flex flex-row gap-1 items-center">
        {children}
        {onClick ? (
          <div className="flex flex-col">
            <ChevronUp
              size={8}
              color={sortDirection === 1 ? "#0C0D0D" : "#A4A7AE"}
            />
            <ChevronDown
              size={8}
              color={sortDirection === -1 ? "#0C0D0D" : "#A4A7AE"}
            />
          </div>
        ) : null}
      </div>
    </th>
  ) : (
    <td className={className}>{children}</td>
  );
};

const TableFooter = ({ children }: { children?: ReactNode }) => {
  return (
    <div className="px-6 py-5 flex flex-row gap-4 self-stretch items-center">
      {children}
    </div>
  );
};

const TablePagination = ({
  currentPage,
  totalPages,
  navigateFn,
}: {
  currentPage: number;
  totalPages: number;
  navigateFn: (page: number) => void;
}) => {
  const beforePages: ReactNode[] = [];
  const afterPages: ReactNode[] = [];

  // We want to add at most 4 navigation buttons
  const MAX_EXTRA_ICONS = Math.min(4, totalPages - 1);
  const DEFAULT_SPLIT = MAX_EXTRA_ICONS / 2;

  // Attempt to allocate two to each side of the current page button
  let beforeButtons = Math.min(currentPage - 1, DEFAULT_SPLIT);
  let afterButtons = Math.min(totalPages - currentPage, DEFAULT_SPLIT);
  const unusedIcons = MAX_EXTRA_ICONS - beforeButtons - afterButtons;

  // If not all buttons were used (e.g. because we are at the first or last page)
  // attempt to reallocate the unused slots
  if (unusedIcons) {
    if (beforeButtons === DEFAULT_SPLIT) {
      beforeButtons = Math.min(currentPage - 1, beforeButtons + unusedIcons);
    } else if (afterButtons === DEFAULT_SPLIT) {
      afterButtons = Math.min(
        totalPages - currentPage,
        afterButtons + unusedIcons,
      );
    }
  }

  // If not on the first page, always allow navigating to it
  if (beforeButtons) {
    beforePages.push(
      <Button
        key={`pagination-1`}
        variant="outline"
        size="sm"
        onClick={() => navigateFn(1)}
      >
        1
      </Button>,
    );
  }

  // If past the second page, will need an additional element to the left
  if (beforeButtons >= 2) {
    // If there will be a gap between the first and subsequent elements, add ellipsis
    if (beforeButtons + 1 < currentPage) {
      beforePages.push(
        <span key={`pagination-before`} className="mt-auto">
          ...
        </span>,
      );
    }

    for (let i = beforeButtons - 1; i > 0; i--) {
      beforePages.push(
        <Button
          key={`pagination-${currentPage - i}`}
          variant="outline"
          size="sm"
          onClick={() => navigateFn(currentPage - i)}
        >
          {currentPage - i}
        </Button>,
      );
    }
  }

  // If not on the last page
  if (afterButtons) {
    // Allow navigating to the next ones
    for (let i = 1; i < afterButtons; i++) {
      afterPages.push(
        <Button
          key={`pagination-${currentPage + i}`}
          variant="outline"
          size="sm"
          onClick={() => navigateFn(currentPage + i)}
        >
          {currentPage + i}
        </Button>,
      );
    }

    // We will always allow navigation to the last page
    // If there is a gap between the icon shown and the final page, add ellipses
    if (currentPage + afterButtons < totalPages) {
      afterPages.push(
        <span key={`pagination-after`} className="mt-auto">
          ...
        </span>,
      );
    }

    // Allow navigating to the final page
    afterPages.push(
      <Button
        key={`pagination-${totalPages}`}
        variant="outline"
        size="sm"
        onClick={() => navigateFn(totalPages)}
      >
        {totalPages}
      </Button>,
    );
  }

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        disabled={currentPage === 1}
        onClick={() => navigateFn(currentPage - 1)}
      >
        <ArrowLeft size={20} className="!stroke-[#414651]" />
        <span className="hidden md:block">Previous</span>
      </Button>
      <div className="flex-1 flex flex-row gap-0.5 justify-center">
        {beforePages}
        <div className="h-9 px-3 inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors border bg-accent-og text-accent-og-foreground border-accent-og">
          {currentPage}
        </div>
        {afterPages}
      </div>
      <Button
        variant="outline"
        size="sm"
        disabled={currentPage === totalPages}
        onClick={() => navigateFn(currentPage + 1)}
      >
        <span className="hidden md:block">Next</span>
        <ArrowRight size={20} className="!stroke-[#414651]" />
      </Button>
    </>
  );
};

export {
  TableWrapper,
  TableHeading,
  TableBody,
  TableRow,
  TableRowItem,
  TableFooter,
  TablePagination,
};
