"use client";

import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { Asterisk } from "lucide-react";

const labelVariants = cva(
  "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & {
      required?: boolean;
    }
>(({ className, required, ...props }, ref) => {
  return (
    <div className="flex items-center justify-start">
      <LabelPrimitive.Root
        ref={ref}
        className={cn(labelVariants(), "text-[15px]", className)}
        {...props}
      />
      {required && <Asterisk className="size-[.6rem] text-destructive mb-1" />}
    </div>
  );
});
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
