import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/redux/hooks";

import {
  PlusCircle,
  CopyPlus,
  User,
  Users,
  FileCheck,
  Contact,
  ListCheck,
  Loader2,
  MapPin,
} from "lucide-react";

import { NeedHelpMessage } from "@/components/pages/dashboard/need-help";
import Dashboard from "@/components/pages/dashboard";
import { Button } from "@/components/ui/button";
import { OpportunityRow } from "@/components/opportunity";
import { SearchingSection } from "@/components/pages/dashboard/searching";
import { CommunitySection } from "@/components/pages/dashboard/community";
import { ExperienceAddOrEditDialog } from "../CandidateProfileEdit";

import { useCandidateResourcesQuery } from "@/queries/candidate/resources";
import { useMyCandidateQuery } from "@/queries/candidate/my-candidate";
import { useProfileQuery } from "@/queries/user/profile";
import {
  Opportunity,
  useCandidateOpportunities,
} from "@/queries/candidate/opportunities";
import { useCompleteTask } from "@/mutations/candidate/dashboard/todo";

const CandidateDashboard = () => {
  const { profile, profileLoading } = useProfileQuery();
  const { candidate, candidateLoading } = useMyCandidateQuery();
  const { candidateOpportunities } = useCandidateOpportunities();

  const [showOpportunities, setShowOpportunities] = useState<boolean>(false);

  useMemo(() => {
    if (candidateOpportunities) {
      setShowOpportunities(
        candidateOpportunities.filter(
          ({ status, candidateStatus }) =>
            status !== "Role Filled (lost)" &&
            status !== "Role Filled (won)" &&
            candidateStatus.status !== "Not Interested" &&
            candidateStatus.status !== "Rejected for Role",
        ).length > 0,
      );
    }
  }, [candidateOpportunities]);

  return (
    <Dashboard.Root
      messages={[
        candidate?.applicationComplete && <AddExperienceAlert key="alert" />,
        <NeedHelpMessage key="need-help" />,
      ]}
    >
      <Dashboard.ProfileRow>
        <Dashboard.ProfileInfo
          title={profile?.name}
          description={
            candidate ? (
              <span className="flex flex-row gap-4 items-center mt-2">
                <span className="flex flex-row items-center gap-2">
                  <User className="size-4 text-muted-foreground" />
                  <span className="text-sm text-muted-foreground">
                    {candidate.titleAndEmployer}
                  </span>
                </span>
                <span className="flex flex-row items-center gap-2">
                  <MapPin className="size-4 text-muted-foreground" />
                  <span className="text-sm text-muted-foreground">
                    {candidate.location}
                  </span>
                </span>
              </span>
            ) : (
              `Loading...`
            )
          }
          fallbackIcon={User}
          src={profile?.profilePictureLink}
          loading={profileLoading || candidateLoading}
        />
      </Dashboard.ProfileRow>
      <Dashboard.MainContent>
        <CommunitySection />
        {showOpportunities ? (
          <MatchesSection />
        ) : candidate?.applicationComplete ? (
          <SearchingSection />
        ) : null}
        <ApprovalSection />
        <SelectedResourcesSection />
      </Dashboard.MainContent>
    </Dashboard.Root>
  );
};

export default CandidateDashboard;

const AddExperienceAlert = () => {
  const { profile } = useProfileQuery();
  const user = useAppSelector((state) => state.auth.user);

  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <Dashboard.Message className="flex flex-col bg-border/50 rounded-xl p-6 gap-4">
      <div className="size-14 bg-secondary flex flex-col items-center justify-center rounded-lg">
        <Users className="size-6 text-secondary-foreground" />
      </div>
      <h2 className="mt-2 text-xl">Boost Your Profile</h2>
      <p className="text-md text-muted-foreground">
        Add your latest experience and keep your profile up to date
      </p>
      <Button
        variant="secondary"
        className="w-fit"
        onClick={() => {
          user?.onboarded ? setOpen(true) : navigate("/onboarding");
        }}
      >
        <PlusCircle />
        Add Experience
      </Button>
      {profile && (
        <ExperienceAddOrEditDialog
          profileId={profile.id}
          mode="add"
          isOpen={open}
          setOpenStateFn={setOpen}
        />
      )}
    </Dashboard.Message>
  );
};

const MatchesSection = () => {
  const { candidateOpportunities, candidateOpportunitiesLoading } =
    useCandidateOpportunities();

  const [openOpportunities, setOpenOpportunities] = useState<Opportunity[]>([]);

  useMemo(() => {
    if (candidateOpportunities !== null) {
      setOpenOpportunities(
        candidateOpportunities.filter(
          ({ status, candidateStatus }) =>
            status !== "Role Filled (lost)" &&
            status !== "Role Filled (won)" &&
            candidateStatus.status !== "Not Interested" &&
            candidateStatus.status !== "Rejected for Role",
        ),
      );
    }
  }, [candidateOpportunities]);

  return (
    <Dashboard.Collapsible defaultOpen>
      <Dashboard.CollapsibleHeader>
        <Dashboard.CollapsibleTitle className="flex flex-row items-center gap-2">
          Your Matches
          {candidateOpportunitiesLoading ? (
            <Loader2 className="animate-spin size-4" />
          ) : (
            <p className="w-fit text-xs bg-border/50 border rounded-full px-2 py-1">
              {openOpportunities.length} new
            </p>
          )}
        </Dashboard.CollapsibleTitle>
      </Dashboard.CollapsibleHeader>
      <Dashboard.CollapsibleContent>
        <div className="w-full flex flex-col rounded-lg border">
          {openOpportunities.map((opportunity) => (
            <OpportunityRow key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>
      </Dashboard.CollapsibleContent>
    </Dashboard.Collapsible>
  );
};

const ApprovalSection = () => {
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.user);
  const { candidate, candidateLoading } = useMyCandidateQuery();
  const { completeProfile, completeCulture, completeTaskPending } =
    useCompleteTask();

  const [progress, setProgress] = useState<number>(0);

  useMemo(() => {
    let tempProgress = 0;
    if (candidate) {
      if (candidate.profileComplete) tempProgress += 25;
      if (candidate.applicationReviewed) tempProgress += 25;
      if (candidate.interviewComplete) tempProgress += 25;
      if (candidate.cultureComplete) tempProgress += 25;
    }

    setProgress(tempProgress);
  }, [candidate]);

  if (progress === 100) {
    return;
  }

  return (
    <Dashboard.Collapsible defaultOpen={progress !== 100 ? true : false}>
      <Dashboard.CollapsibleHeader>
        <div>
          <Dashboard.CollapsibleTitle>Your Approval</Dashboard.CollapsibleTitle>
          <p className="w-fit text-xs bg-border/50 border rounded-full px-2 py-1 group-hover:text-decoration-none">
            {progress === 100 ? "Complete" : "In Progress"}
          </p>
        </div>
        <Dashboard.CollapsibleProgress
          loading={candidateLoading}
          value={progress}
        />
      </Dashboard.CollapsibleHeader>
      <Dashboard.CollapsibleContent>
        <Dashboard.TodoContainer>
          <Dashboard.TodoItem
            icon={FileCheck}
            title="Application approval"
            description="Your application goes through the review and approval process"
            completed={candidate?.applicationReviewed}
            loading={candidateLoading}
            secondary
          ></Dashboard.TodoItem>
          <Dashboard.TodoItem
            icon={Contact}
            title="Candidate interview"
            description="Meet with our team to discuss your skills, experience, and career goals"
            completed={candidate?.interviewComplete}
            loading={candidateLoading}
            secondary
          >
            {candidate?.applicationReviewed &&
              !candidate?.interviewComplete && (
                <Dashboard.TodoItemActions>
                  <Button
                    variant="secondary"
                    onClick={() => navigate("/candidate-interview")}
                  >
                    Complete Video Interview
                  </Button>
                </Dashboard.TodoItemActions>
              )}
          </Dashboard.TodoItem>
          <Dashboard.TodoItem
            icon={ListCheck}
            title="Cultural fit evaluation"
            description="Take a quick quiz to see how your values and work style align"
            completed={candidate?.cultureComplete}
            loading={candidateLoading}
            secondary
          >
            {candidate?.interviewComplete && !candidate?.cultureComplete && (
              <Dashboard.TodoItemActions>
                <Button
                  variant="secondary"
                  disabled={completeTaskPending}
                  onClick={completeCulture}
                >
                  {completeTaskPending && <Loader2 className="animate-spin" />}
                  Mark as Complete
                </Button>
              </Dashboard.TodoItemActions>
            )}
          </Dashboard.TodoItem>
          <Dashboard.TodoItem
            icon={CopyPlus}
            title="Complete your profile"
            description={
              candidate?.applicationComplete
                ? "Complete your profile to join the community"
                : "Once you have been approved, you will be able to complete your profile"
            }
            completed={candidate?.profileComplete}
            loading={candidateLoading}
            secondary
          >
            {candidate?.applicationComplete && !candidate?.profileComplete && (
              <Dashboard.TodoItemActions>
                <Button
                  variant="ghost"
                  disabled={completeTaskPending}
                  onClick={completeProfile}
                >
                  {completeTaskPending && <Loader2 className="animate-spin" />}
                  Mark as Complete
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigate(user?.onboarded ? "/profile/edit" : "/onboarding");
                  }}
                >
                  Get Started
                </Button>
              </Dashboard.TodoItemActions>
            )}
          </Dashboard.TodoItem>
        </Dashboard.TodoContainer>
      </Dashboard.CollapsibleContent>
    </Dashboard.Collapsible>
  );
};

const SelectedResourcesSection = () => {
  const { candidateResources, candidateResourcesLoading } =
    useCandidateResourcesQuery();

  return (
    <Dashboard.ResourcesCollapsible
      resources={candidateResources ?? []}
      loading={candidateResourcesLoading}
    />
  );
};
