import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { useAuth0 } from "@auth0/auth0-react";
import request from "@/lib/api/handler";
import { CandidateAvailability, weekdayList } from "@/types";
import {
  DayAvailabilities,
  SpecificAvailabilities,
  useAvailabilitySheet,
} from "@/components/dialogs/candidate/availability/store";
import { AvailabilityDate, AvailabilityTime } from "@/lib/datetime";

export const useCandidateAvailabilityQuery = (
  openingID = "",
  interviewID = "",
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.candidateAvailability(openingID, interviewID),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<{
        general: CandidateAvailability[];
        specific: CandidateAvailability[];
      }>({
        method: "GET",
        path: `/api/jobOpening/${openingID}/interview/${interviewID}/availability/self`,
        token,
      });
      let tzo = "";

      const general = Array.from(
        { length: 7 },
        () => [],
      ) as DayAvailabilities[];
      resp.general
        .filter(a => a.weekday !== undefined)
        .forEach(a => {
          if (!tzo) {
            tzo =
              new AvailabilityTime(a.startTime, "isotime").getTimezone() ?? "";
          }

          general[weekdayList.indexOf(a.weekday!)]?.push({
            id: a.id,
            start: new AvailabilityTime(a.startTime, "isotime"),
            end: new AvailabilityTime(a.endTime, "isotime"),
          });
        });

      const specific = resp.specific
        .filter(e => e.dates !== undefined)
        .reduce((acc, curr) => {
          const startDate = curr.dates?.[0];
          const endDate = curr.dates?.at(-1);
          if (!startDate || !endDate) return acc;
          if (!tzo) {
            tzo =
              new AvailabilityTime(curr.startTime, "isotime").getTimezone() ??
              "";
          }

          const i = acc.findIndex(e => e.startDate.sameDay(e.endDate));
          if (i < 0) {
            acc.push({
              startDate: new AvailabilityDate(startDate, "isodate"),
              endDate: new AvailabilityDate(endDate, "isodate"),
              times: [
                {
                  id: curr.id,
                  start: new AvailabilityTime(curr.startTime, "isotime"),
                  end: new AvailabilityTime(curr.endTime, "isotime"),
                },
              ],
            });
          } else {
            acc[i]?.times.push({
              id: curr.id,
              start: new AvailabilityTime(curr.startTime, "isotime"),
              end: new AvailabilityTime(curr.endTime, "isotime"),
            });
          }

          return acc;
        }, [] as SpecificAvailabilities[]);

      if (!general.some(e => e.length > 0)) {
        for (let i = 1; i < 6; i++) {
          general[i] = [
            {
              start: AvailabilityTime.newPlaceholder(),
              end: AvailabilityTime.newPlaceholder(),
            },
          ];
        }
      }

      general.forEach((e, i) => {
        general[i] = e.sort((a, b) => a.start.cmp(b.start));
      });

      specific.sort((a, b) => a.startDate.cmp(b.startDate));
      specific.forEach((e, i) => {
        specific[i]!.times = e.times.sort((a, b) => a.start.cmp(b.start));
      });

      useAvailabilitySheet.getState().setDayAvailabilities(general);
      useAvailabilitySheet.getState().setSpecificAvailabilities(specific);
      if (tzo) {
        useAvailabilitySheet.getState().setTimezone(tzo);
      }
      return { general, specific };
    },
    enabled: !!openingID && !!interviewID,
  });

  return {
    candidateAvailabilityLoading: isLoading,
    candidateAvailability: data,
    candidateAvailabilityError: error,
  };
};
