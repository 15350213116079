import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Link, Spinner } from "@fluentui/react-components";
import { getCompanyClaimStatus } from "../redux/features/companyProfileSlice";

import "@/styles.css";

const JoinTeam = () => {
  const [dataRequested, setDataRequested] = useState(false);

  const user = useAppSelector((state) => state.auth.user);
  const claim = useAppSelector((state) => state.companyProfile.companyClaim);
  const loadingStatus = useAppSelector((state) => state.companyProfile.status);

  const { companyId } = useParams();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = !dataRequested || loadingStatus !== "succeeded";

  // Request data on load or when companyId changes
  useEffect(() => {
    if (!companyId) {
      setDataRequested(false);
      return;
    }

    const controller = new AbortController();

    void dispatch(
      getCompanyClaimStatus({
        id: companyId,
        signal: controller.signal,
      }),
    );
    setDataRequested(true);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    document.title = "Venturous";
  }, []);

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `/company/${companyId}/join`,
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `/company/${companyId}/join`,
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  if (!companyId || isLoading || loading) {
    return (
      <div
        className="h-screen w-screen flex items-center justify-center"
        style={{
          background: "linear-gradient(117deg, #1D5249 0%, #002922 92.33%)",
        }}
      >
        <div className="flex-1 max-w-[min(800px,90%)] flex flex-col gap-8 p-6 text-center items-center justify-center m-auto rounded-xl bg-white shadow-[0_20px_24px_-4px_rgba(16,24,40,0.08),0_8px_8px_-4px_rgba(16,24,40,0.03)]">
          <Spinner size="huge" />
          <div className="flex flex-col gap-1 items-stretch text-center">
            <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
              Fetching data
            </p>
            <p className="text-sm font-medium text-[#727E94]">
              Just a few seconds left
            </p>
          </div>
        </div>
      </div>
    );
  }

  // If logged in, check if the user is associated with the company already
  if (isAuthenticated) {
    return (
      <div
        className="h-screen w-screen flex items-center justify-center"
        style={{
          background: "linear-gradient(117deg, #1D5249 0%, #002922 92.33%)",
        }}
      >
        <div className="flex flex-col gap-4 max-w-[min(800px,90%)] p-6 text-center items-center justify-center m-auto rounded-xl bg-white shadow-[0_20px_24px_-4px_rgba(16,24,40,0.08),0_8px_8px_-4px_rgba(16,24,40,0.03)]">
          {claim?.name ? (
            <>
              <Avatar
                image={{ src: claim.logoLink }}
                name={claim.name}
                size={72}
                style={{ width: "88px", height: "88px" }}
              />
              <h2 className="text-4xl font-semibold text-[#22234A]">
                Hi {claim?.name}!
              </h2>
            </>
          ) : null}
          <h1 className="text-4xl font-semibold text-[#22234A]">
            Welcome to Venturous
          </h1>
          <p className="text-xl font-medium text-[#22234A]">
            Venturous offers a best-in-class, personalized network of healthcare
            experts for companies to engage talent faster and more
            cost-effectively than other options.
          </p>
          <br />
          {user?.companyAccounts.some(
            (account) => account.companyId === companyId,
          ) ? (
            <>
              <p className="text-xl font-medium text-[#22234A]">
                You&apos;ve joined this team! Take a look around and review your
                company&apos;s profile and any opportunities they&apos;ve shared
                with Venturous.
              </p>
              <div className="flex flex-col gap-4 items-center">
                <Button
                  style={{ height: "50px", width: "300px", fontSize: "20px" }}
                  appearance="primary"
                  size="medium"
                  onClick={() => navigate("/dashboard")}
                >
                  Continue
                </Button>
              </div>
            </>
          ) : (
            <p className="text-xl font-medium text-[#22234A]">
              You have not been invited to join this team. If you followed an
              invite link, please make sure you logged in using the same email
              address that received the invitation.
            </p>
          )}
        </div>
      </div>
    );
  }

  // If not logged in, prompt the user to sign up or log in
  return (
    <div
      className="h-screen w-screen flex items-center justify-center"
      style={{
        background: "linear-gradient(117deg, #1D5249 0%, #002922 92.33%)",
      }}
    >
      <div className="flex flex-col gap-4 max-w-[min(800px,90%)] p-6 text-center items-center justify-center m-auto rounded-xl bg-white shadow-[0_20px_24px_-4px_rgba(16,24,40,0.08),0_8px_8px_-4px_rgba(16,24,40,0.03)]">
        {claim?.name ? (
          <>
            <Avatar
              image={{ src: claim.logoLink }}
              name={claim.name}
              size={72}
              style={{ width: "88px", height: "88px" }}
            />
            <h2 className="text-4xl font-semibold text-[#22234A]">
              Hi {claim?.name}!
            </h2>
          </>
        ) : null}
        <h1 className="text-4xl font-semibold text-[#22234A]">
          Welcome to Venturous
        </h1>
        <p className="text-xl font-medium text-[#22234A]">
          Venturous offers a best-in-class, personalized network of healthcare
          experts for companies to engage talent faster and more
          cost-effectively than other options.
        </p>
        <br />
        <p className="text-xl font-medium text-[#22234A]">
          To join your team, sign up or log in.
        </p>
        <div className="flex flex-col gap-4 items-center">
          <Button
            style={{ height: "50px", width: "300px", fontSize: "20px" }}
            appearance="primary"
            size="medium"
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
          <Link onClick={handleLogin}>Log In</Link>
        </div>
      </div>
    </div>
  );
};

export default JoinTeam;
