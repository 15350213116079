import { useQueryClient } from "@tanstack/react-query";

export const QueryKeys = {
  adminDashboard: ["adminDashboard"],
  adminCandidatePipeline: ["adminCandidatePipeline"],
  adminApprovedCandidates: ["adminApprovedCandidates"],
  adminCompanies: ["adminCompanies"],
  adminJobPipeline: ["adminJobPipeline"],
  adminJobDetails: (id: string) => ["adminJobDetails", id],
  stripeCustomerID: (isCompany: boolean) => [
    "stripe",
    "customerID",
    isCompany ? "company" : "user",
  ],
  stripePaymentMethod: (isCompany: boolean) => [
    "stripe",
    "paymentMethod",
    isCompany ? "company" : "user",
  ],
  stripeAccount: ["stripe", "accountID"],
  stripeAccountSessionSecret: (accountID: string) => [
    "stripe",
    "accountSessionSecret",
    accountID,
  ],
  myProfile: ["myProfile"],
  myCompany: ["myCompany"],
  myCandidate: ["myCandidate"],
  companyOpportunities: ["companyOpportunities"],
  companyOpportunity: (id: string) => ["companyOpportunity", id],
  companyTeam: ["companyTeam"],
  companyResources: ["companyResources"],
  candidateResources: ["candidateResources"],
  candidateOpportunities: ["candidateOpportunities"],
  candidateAvailability: (oid: string, iid: string) => [
    "candidateAvailability",
    oid,
    iid,
  ],
  candidateScreeningInterview: ["candidateScreeningInterview"],
};

export const useInvalidateQuery = () => {
  const qc = useQueryClient();

  const invalidate = async (queryKey: (string | number)[]) => {
    await qc.invalidateQueries({
      queryKey,
    });
  };

  return invalidate;
};
