import { Popover, PopoverTrigger, PopoverContent } from "./popover";
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "./command";
import { Button } from "./button";
import { ChevronsUpDown, Check } from "lucide-react";

import { cn } from "@/lib/utils";

export const MultiSelect = ({
  values,
  setValues,
  options,
}: {
  values: string[];
  setValues: (values: string[]) => void;
  options: string[];
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "w-full flex flex-row items-center justify-between",
            !values && "text-muted-foreground",
          )}
          childFullWidth
        >
          {values.length > 0
            ? values.join(", ").length > 40
              ? `${values.join(", ").substring(0, 40)}...`
              : values.join(", ")
            : "Select a value"}
          <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search values..." />
          <CommandList>
            <CommandEmpty>No value found.</CommandEmpty>
            <CommandGroup>
              {options.map(option => (
                <CommandItem
                  value={option}
                  key={option}
                  onSelect={() => {
                    if (values.includes(option)) {
                      setValues(values.filter(value => value !== option));
                    } else {
                      setValues([...values, option]);
                    }
                  }}
                >
                  {option}
                  <Check
                    className={cn(
                      "ml-auto",
                      values.includes(option) ? "opacity-100" : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
