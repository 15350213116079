import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import request from "@/lib/api/handler";

export const useCandidateScreeningInterviewQuery = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.candidateScreeningInterview,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<string>({
        method: "GET",
        path: "/api/candidate/screening-interview",
        token,
      });

      return resp;
    },
  });

  return {
    candidateScreeningInterviewLoading: isLoading,
    candidateScreeningInterview: data ?? null,
    candidateScreeningInterviewError: error ?? null,
  };
};
