import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Building2, PlusCircle } from "lucide-react";

import { useProfileQuery } from "@/queries/user/profile";
import { useState } from "react";
import CompanyAddTeamForm from "@/components/forms/company/add-team";

export const AddTeamMemberDialog = ({
  trigger,
}: {
  trigger?: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);

  const { profileLoading } = useProfileQuery();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger ? (
          trigger
        ) : (
          <Button
            variant="secondary"
            className="w-fit"
            disabled={profileLoading}
          >
            <PlusCircle />
            Invite Team Member
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className="w-full p-0 sm:max-w-2xl"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogHeader className="p-6 flex-shrink">
          <div className="flex items-center gap-2">
            <div className="flex size-12 bg-[rgba(0,60,50,0.10)] border-4 border-[rgba(0,60,50,0.05)] rounded-full">
              <Building2 className="m-auto size-7 text-[#013930]" />
            </div>
            <DialogTitle>Invite Team Member</DialogTitle>
          </div>
        </DialogHeader>
        <ScrollArea className="sm:max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
          <CompanyAddTeamForm onSuccess={() => setOpen(false)} />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
