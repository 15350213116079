import { z } from "zod";
import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { AdminJobDetails } from "@/queries/admin/job-details";

export const jobNotesFormSchema = z.object({
  notes: z.string({ required_error: "Note is required" }),
});

export type JobNotesFormSchema = z.infer<typeof jobNotesFormSchema>;

export const useSetJobNotes = (jobId: string) => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ notes }: JobNotesFormSchema) => {
      const token = await getAccessTokenSilently();

      return request<AdminJobDetails>({
        method: "PATCH",
        path: `/api/admin/job_opening/${jobId}`,
        token,
        body: JSON.stringify({
          notes,
        }),
      });
    },
    onSuccess: (data) => {
      // Invalidate pipeline data
      void qc.invalidateQueries({
        queryKey: QueryKeys.adminJobPipeline,
      });

      // Fetch any existing data for the job updated
      const prev = qc.getQueryData<AdminJobDetails>(
        QueryKeys.adminJobDetails(data.id),
      );

      // If there is existing data, update it
      if (prev) {
        qc.setQueryData(QueryKeys.adminJobDetails(data.id), {
          ...prev,
          ...data,
        });
      } else {
        // If the entry couldn't be found, invalidate and fetch new data
        void qc.invalidateQueries({
          queryKey: QueryKeys.adminJobDetails(data.id),
        });
      }
    },
  });

  return {
    setJobNotes: mutate,
    isSettingJobNotes: isPending,
    setJobNotesError: error,
  };
};
