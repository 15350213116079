import { ReactElement } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAdminDashboardQuery } from "@/queries/admin/dashboard";

import Dashboard from "@/components/pages/dashboard";
import { cn } from "@/lib/utils";
import {
  BriefcaseBusiness,
  Building2,
  House,
  SquareKanban,
  UserRoundCheck,
} from "lucide-react";

const AdminLayout = () => {
  return (
    <Dashboard.Root messages={[<NavSection key="nav" />]}>
      <Dashboard.MainContent className="min-w-0">
        <Outlet />
      </Dashboard.MainContent>
    </Dashboard.Root>
  );
};

export default AdminLayout;

const NavSection = () => {
  const { adminDashboard } = useAdminDashboardQuery();

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dashboard.Message className="flex flex-col gap-0 bg-border/50 rounded-xl p-2">
      <NavSectionItem
        icon={<House />}
        title="Dashboard"
        selected={
          location.pathname === "/new-admin" ||
          location.pathname.startsWith("/new-admin/dashboard")
        }
        setSection={() => navigate("/new-admin/dashboard")}
      />
      <NavSectionItem
        icon={<SquareKanban />}
        title="Onboarding"
        count={adminDashboard?.openApplicationsCount}
        selected={location.pathname.startsWith("/new-admin/onboarding")}
        setSection={() => navigate("/new-admin/onboarding")}
      />
      <NavSectionItem
        icon={<UserRoundCheck />}
        title="Candidates"
        selected={location.pathname.startsWith("/new-admin/candidates")}
        setSection={() => navigate("/new-admin/candidates")}
      />
      <NavSectionItem
        icon={<Building2 />}
        title="Companies"
        count={adminDashboard?.newCompaniesCount}
        selected={location.pathname.startsWith("/new-admin/company")}
        setSection={() => navigate("/new-admin/company")}
      />
      <NavSectionItem
        icon={<BriefcaseBusiness />}
        title="Jobs"
        count={adminDashboard?.openJobsCount}
        selected={location.pathname.startsWith("/new-admin/job")}
        setSection={() => navigate("/new-admin/job")}
      />
    </Dashboard.Message>
  );
};

const NavSectionItem = ({
  icon,
  title,
  count,
  selected,
  setSection,
}: {
  icon: ReactElement;
  title: string;
  count?: number;
  selected: boolean;
  setSection: () => void;
}) => (
  <div
    className={cn(
      "group",
      "w-full py-2 px-3 flex flex-row items-center gap-3 rounded-md cursor-pointer border border-transparent",
      "[&_svg.lucide]:stroke-[#07574b]",
      "hover:bg-white hover:border-[#e9eaeb]",
      selected && "bg-white border-[#e9eaeb]",
    )}
    onClick={setSection}
  >
    <div className="flex-1 flex flex-row gap-2">
      {icon}
      <p
        className={cn(
          "text-base text-[#65657B] font-semibold group-hover:text-[#0c0d0d]",
          selected && "text-[#0c0d0d]",
        )}
      >
        {title}
      </p>
    </div>
    {Boolean(count) && (
      <div className="w-fit py-0.5 px-2 bg-[#fafafa] border border-[#e9eaeb] rounded-full">
        <p className="text-xs text-[#181d27] font-medium">{count}</p>
      </div>
    )}
  </div>
);
