import { ReactNode } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { IconProp } from "@/types";
import { Checkbox } from "../../ui/checkbox";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";
import { CompanyResource } from "@/queries/company/resources";

interface BaseProps {
  children: ReactNode;
  className?: string;
}

const Root = ({
  children,
  messages,
}: {
  children: ReactNode;
  messages?: ReactNode[];
}) => {
  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-[1fr,3fr] lg:grid-cols-[1fr,4fr] xl:grid-cols-[1fr,5fr]",
        messages && messages.length > 0 && "gap-x-6",
      )}
    >
      <div className="w-[calc(100%+3rem)] h-24 bg-gradient-to-r from-50% from-secondary-dark to-100% to-secondary md:col-span-2 -ml-6" />
      <MessageArea>{messages}</MessageArea>
      {children}
    </div>
  );
};

const ProfileRow = ({ children, className }: BaseProps) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col gap-y-12 py-6 gap-x-0 items-center",
        "md:col-span-2 md:justify-between md:flex-row md:gap-y-12 gap-x-4",
        className,
      )}
    >
      {children}
    </div>
  );
};

const ProfileInfo = ({
  title,
  description,
  className,
  loading,
  fallbackIcon: FallbackIcon,
  src,
}: {
  className?: string;
  title?: ReactNode;
  description?: ReactNode;
  loading?: boolean;
  src?: string;
  fallbackIcon: IconProp;
}) => {
  return (
    <div className={cn("w-full flex items-center gap-4 h-12", className)}>
      <Avatar
        className={
          "relative size-36 -mt-14 bg-background border-4 border-background shadow-md"
        }
      >
        {loading ? (
          <div className="size-full bg-muted animate-pulse" />
        ) : (
          <>
            {src && <AvatarImage src={src} />}
            <AvatarFallback>
              <FallbackIcon />
            </AvatarFallback>
          </>
        )}
      </Avatar>

      {loading ? (
        <div className="space-y-1">
          <Skeleton className="w-36 h-5" />
          <Skeleton className="w-24 h-4" />
        </div>
      ) : (
        <div>
          {title && (
            <h1 className="text-2xl font-bold leading-none">{title}</h1>
          )}
          {description && (
            <p className="text-sm text-muted-foreground">{description}</p>
          )}
        </div>
      )}
    </div>
  );
};

const ProfileActions = ({ children, className }: BaseProps) => {
  return (
    <div
      className={cn(
        "flex items-center gap-x-4 gap-y-2 flex-wrap [&>*]:grow w-full",
        "md:w-auto md:[&>*]:grow-0 md:flex-nowrap",
        className,
      )}
    >
      {children}
    </div>
  );
};

const MessageArea = ({
  children,
  className,
}: BaseProps & { children?: ReactNode }) => {
  if (!children) return null;

  return (
    <div
      className={cn(
        "space-y-4 row-start-3 md:row-start-3 md:col-start-1 pt-4",
        className,
      )}
    >
      {children}
    </div>
  );
};
MessageArea.displayName = "MessageArea";

const Message = ({
  children,
  className,
  onClick,
}: BaseProps & {
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col bg-background border border-border rounded-xl p-6 gap-4",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
Message.displayName = "Message";

const MainContent = ({ children, className }: BaseProps) => {
  return (
    <div className={cn("space-y-4 md:row-start-3 md:col-start-2", className)}>
      {children}
    </div>
  );
};
MainContent.displayName = "MainContent";

const Collapsible = ({
  children,
  className,
  defaultOpen,
}: BaseProps & { defaultOpen?: boolean; value?: string }) => {
  return (
    <Accordion
      type="single"
      defaultValue={defaultOpen ? "toggler" : undefined}
      className={cn(className)}
      collapsible
    >
      <AccordionItem value="toggler" className="border-b-0">
        {children}
      </AccordionItem>
    </Accordion>
  );
};

const CollapsibleHeader = ({ children, className }: BaseProps) => {
  return (
    <AccordionTrigger className="hover:no-underline group">
      <div
        className={cn(
          "w-full flex flex-col items-start gap-2",
          "md:flex-row md:items-center md:justify-between",
          className,
        )}
      >
        {children}
      </div>
    </AccordionTrigger>
  );
};

const CollapsibleProgress = ({
  loading,
  value,
}: {
  loading: boolean;
  value?: number;
}) => {
  return (
    <div className="flex flex-row items-center gap-2 pr-4">
      <p
        className={cn(
          "text-sm text-muted-foreground !text-decoration-none whitespace-nowrap",
          loading && "text-transparent bg-muted animate-pulse rounded-sm",
        )}
      >
        {value}% complete
      </p>
      <Progress
        className={cn("w-48 md:max-lg:w-28 h-2 bg-accent-og shrink", {
          "bg-muted animate-pulse": loading,
        })}
        value={loading ? 0 : value}
      />
    </div>
  );
};

const CollapsibleTitle = ({ children, className }: BaseProps) => {
  return (
    <h3
      className={cn(
        "text-2xl font-semibold group-hover:underline text-left",
        className,
      )}
    >
      {children}
    </h3>
  );
};

const CollapsibleContent = ({
  children,
  className,
  id,
}: BaseProps & { id?: string }) => {
  return (
    <AccordionContent id={id} className={cn(className)}>
      {children}
    </AccordionContent>
  );
};

const TodoContainer = ({ children, className }: BaseProps) => {
  return (
    <div
      className={cn(
        "w-full flex flex-col rounded-lg border divide-y divide-border",
        className,
      )}
    >
      {children}
    </div>
  );
};

const TodoItem = ({
  className,
  children,
  completed,
  loading,
  title,
  description,
  icon: Icon,
  onCompleteClick,
}: {
  className?: string;
  children?: ReactNode;
  completed?: boolean;
  loading?: boolean;
  title: string;
  description: string;
  icon: IconProp;
  onCompleteClick?: () => void;
}) => {
  return (
    <div
      data-completed={`${Boolean(completed)}`}
      className={cn(
        "w-full flex flex-row items-center px-6 p-4 gap-4 group",
        !!completed && "group-data-[completed=true]",
        className,
      )}
    >
      <Checkbox
        title="Mark as complete"
        className={cn(
          "size-5 border-border border-[1.5px] rounded-md cursor-pointer",
          {
            "data-[state=checked]:border-primary": completed,
            "border-muted bg-muted": loading,
            "pointer-events-none": !onCompleteClick || completed,
          },
        )}
        checked={completed}
        onClick={onCompleteClick}
      />
      {loading ? (
        <Skeleton className="size-12 rounded-full" />
      ) : (
        <div className="size-12 rounded-full flex flex-col justify-center items-center shadow">
          <Icon className="text-secondary size-5" />
        </div>
      )}
      <div className="flex flex-col">
        <h4
          className={cn(
            "text-md font-semibold w-fit",
            loading &&
              "animate-pulse bg-muted text-transparent rounded-sm mb-1",
          )}
        >
          {title}
        </h4>
        <p
          className={cn(
            "text-md w-fit",
            loading && "animate-pulse bg-muted text-transparent rounded-sm",
          )}
        >
          {description}
        </p>
      </div>
      {completed && (
        <div className="flex flex-row items-center gap-4 ml-auto">
          <Button
            variant="ghost"
            disabled
            className={cn(
              "text-primary !opacity-100",
              loading && "animate-pulse bg-muted text-transparent rounded-sm",
            )}
          >
            Completed
          </Button>
        </div>
      )}

      {!completed && children}
    </div>
  );
};

const TodoItemActions = ({ children, className }: BaseProps) => {
  return (
    <div className={cn("flex flex-row items-center gap-4 ml-auto", className)}>
      {children}
    </div>
  );
};

const ResourcesCollapsible = <T extends CompanyResource>(props: {
  resources: T[];
  loading: boolean;
}) => {
  return (
    <Collapsible defaultOpen>
      <CollapsibleHeader>
        <CollapsibleTitle>Selected Resources</CollapsibleTitle>
      </CollapsibleHeader>
      <CollapsibleContent
        id="dashboard-resources"
        className={cn(
          "w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 min-[1800px]:grid-cols-6 gap-4",
          {
            "grid-cols-1": !props.loading && !props.resources?.length,
          },
        )}
      >
        <ResourceContent {...props} />
      </CollapsibleContent>
    </Collapsible>
  );
};

const ResourceContent = <T extends CompanyResource>({
  resources,
  loading,
}: {
  resources: T[];
  loading: boolean;
}) => {
  if (loading) {
    return <ResourceSkeletons />;
  } else if (!resources.length) {
    return <p className="text-md">No resources available</p>;
  }

  return resources.map(resource => (
    <ResourceItem key={`resource-item-${resource.id}`} resource={resource} />
  ));
};

const ResourceItem = <T extends CompanyResource>({
  resource,
}: {
  resource: T;
}) => {
  return (
    <div
      key={`company-resource-${resource.id}`}
      className={cn(
        "w-full space-y-2 hidden [&:not(:nth-child(4)~div)]:block",
        "2xl:[&:not(:nth-child(5)~div)]:block min-[1800px]:[&:not(:nth-child(6)~div)]:block",
      )}
    >
      <img
        src={resource.image}
        alt={resource.headline}
        className="w-full h-48 object-cover rounded-t-md"
      />
      <h4 className="text-lg font-semibold">{resource.headline}</h4>
      <p className="text-md">{resource.summary}</p>
    </div>
  );
};

const ResourceSkeletons = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, i) => (
        <div key={`resource-skeleton-${i}`} className="w-full space-y-2">
          <Skeleton className="w-full h-48 rounded-t-md" />
          <Skeleton className="w-3/4 h-6" />
          <div className="space-y-1">
            <Skeleton className="w-full h-4" />
            <Skeleton className="w-full h-4" />
            <Skeleton className="w-1/3 h-4" />
          </div>
        </div>
      ))}
    </>
  );
};

const Dashboard = {
  Root,
  ProfileRow,
  ProfileInfo,
  ProfileActions,
  Message,
  MainContent,
  Collapsible,
  CollapsibleProgress,
  CollapsibleHeader,
  CollapsibleTitle,
  CollapsibleContent,
  TodoContainer,
  TodoItem,
  TodoItemActions,
  ResourcesCollapsible,
};

export default Dashboard;
