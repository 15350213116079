import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

import { useProfileQuery } from "@/queries/user/profile";
import { toast } from "sonner";

export const useResetTeamMemberPassword = () => {
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();

  const cid = profile?.companyAccounts[0]?.companyId;

  const { mutate, isPending, error } = useMutation({
    mutationFn: async (userId: string) => {
      const token = await getAccessTokenSilently();

      await request({
        method: "POST",
        path: `/api/company/${cid}/team/${userId}/reset-password`,
        token,
      });
    },
    onSettled: (_, error) => {
      if (!error) {
        toast.success("Password reset email sent!");
      } else {
        toast.error("Failed to send password reset email, please try again");
      }
    },
  });

  return {
    resetTeamMemberPassword: mutate,
    isResettingTeamMemberPassword: isPending,
    teamMemberError: error,
  };
};
