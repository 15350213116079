import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { JobOpeningStatus } from "@/types";
import { AdminSummary } from "@/queries/admin/dashboard";
import { AdminPipelineJob } from "@/queries/admin/job-pipeline";
import { AdminJobDetails } from "@/queries/admin/job-details";

type SetJobStatusParams =
  | {
      jobId: string;
      status?: JobOpeningStatus;
      currentStatus: JobOpeningStatus;
    }
  | {
      jobId: string;
      status: JobOpeningStatus;
      currentStatus?: JobOpeningStatus;
    };

const NEXT_STATUS_MAP: Record<JobOpeningStatus, JobOpeningStatus> = {
  "New Opening": "Creating Job Spec",
  "Creating Job Spec": "Identifying Candidates",
  "Identifying Candidates": "Presenting Shortlist",
  "Presenting Shortlist": "Role Filled (won)",
  "Role Filled (won)": "Role Filled (won)",
  "Role Filled (lost)": "Role Filled (lost)",
};

export const useSetJobStatus = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      jobId,
      status,
      currentStatus,
    }: SetJobStatusParams) => {
      const token = await getAccessTokenSilently();

      return request<AdminPipelineJob>({
        method: "PATCH",
        path: `/api/admin/job_opening/${jobId}`,
        token,
        body: JSON.stringify({
          status: status ?? NEXT_STATUS_MAP[currentStatus],
        }),
      });
    },
    onSuccess: (data) => {
      // Check if the job reached a final stage and should be dropped from the pipeline
      const done =
        data.status === "Role Filled (won)" ||
        data.status === "Role Filled (lost)";

      // Fetch any existing data
      const prevPipeline = qc.getQueryData<AdminPipelineJob[]>(
        QueryKeys.adminJobPipeline,
      );

      // If there is existing data, update it
      if (prevPipeline) {
        // Make a copy and check find the updated element
        const newData = [...prevPipeline];
        const itemIndex = newData.findIndex((entry) => entry.id === data.id);

        // If the element is found
        if (itemIndex !== -1 && newData[itemIndex]) {
          // Remove it if the candidate reached a final stage
          if (done) {
            newData.splice(itemIndex, 1);
          } else {
            // Update it otherwise
            newData[itemIndex] = data;
          }

          qc.setQueryData(QueryKeys.adminJobPipeline, newData);
        } else {
          // If the entry couldn't be found, invalidate and fetch new data
          void qc.invalidateQueries({
            queryKey: QueryKeys.adminJobPipeline,
          });
        }
      }

      const prevDetails = qc.getQueryData<AdminJobDetails>(
        QueryKeys.adminJobDetails(data.id),
      );

      // If there is existing data, update it
      if (prevDetails) {
        // Update the data
        qc.setQueryData(QueryKeys.adminJobDetails(data.id), {
          ...prevDetails,
          ...data,
        });
      }

      // If the candidate reached a final stage, update the dashboard number
      if (done) {
        const dashboardPrev = qc.getQueryData<AdminSummary>(
          QueryKeys.adminDashboard,
        );

        if (dashboardPrev) {
          qc.setQueryData(QueryKeys.adminDashboard, {
            ...dashboardPrev,
            openJobsCount: dashboardPrev.openJobsCount - 1,
          });
        }
      }
    },
  });

  return {
    setJobStatus: mutate,
    isSettingJobStatus: isPending,
    setJobStatusError: error,
  };
};
