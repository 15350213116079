import { useState, useEffect } from "react";
import {
  StripeConnectInstance,
  loadConnectAndInitialize,
} from "@stripe/connect-js";
import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";

export const useStripeConnect = (connectedAccountId: string) => {
  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const connect = loadConnectAndInitialize({
      publishableKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY as string,
      fetchClientSecret: async () => {
        const token = await getAccessTokenSilently();
        const resp = await request<{ secret: string }>({
          method: "POST",
          path: "/api/candidate/stripe/account-session",
          token,
          body: JSON.stringify({ accountId: connectedAccountId }),
        });

        if (!resp) {
          throw new Error("Stripe Account Session not found");
        }

        return resp.secret;
      },
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "hsl(172, 79%, 25%)",
        },
      },
    });

    setStripeConnectInstance(connect);
  }, [connectedAccountId, getAccessTokenSilently]);

  return stripeConnectInstance;
};

export default useStripeConnect;
