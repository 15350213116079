import { posthog } from "posthog-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { useProfileQuery } from "@/queries/user/profile";

import StockImage from "@/assets/preprocess_clip.jpeg";

import { Briefcase, Globe, LogIn, LucideProps } from "lucide-react";
import { PrescreenLayout } from "@/components/prescreen";
import { Button } from "@/components/ui/button";

const StyledPreprocessRadio = <T extends string>({
  value,
  selected,
  setSelected,
  title,
  subtitle,
  description,
  footer,
  icon: Icon,
}: {
  value: T;
  selected: T;
  setSelected: React.Dispatch<React.SetStateAction<T>>;
  title: string;
  subtitle: string;
  description: string;
  footer: string;
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
}) => {
  return (
    <div
      className={`w-full flex flex-col rounded-lg border ring ring-offset-1 ring-border cursor-pointer ${
        selected === value ? "border-secondary/80 border-2 ring-accent/20" : ""
      }`}
      onClick={() => setSelected(value)}
    >
      <div
        className={`w-full flex flex-row gap-4 items-center p-4 ${selected === value ? "border-b-2 border-secondary/80" : "border-b"}`}
      >
        <div
          className={`size-10 p-2 rounded-md ${selected === value ? "bg-gradient-to-bl from-accent to-secondary-dark border border-secondary" : "border"}`}
        >
          <Icon
            className={`size-full ${selected === value ? "text-secondary-foreground" : "text-muted-foreground"}`}
          />
        </div>
        <h4 className="text-lg font-semibold">{title}</h4>
        <div
          className={`ml-auto size-5 flex-shrink-0 rounded-full ring-offset-2 ${selected === value ? "border-[6px] border-accent ring-accent ring-1" : "border ring-border ring-2"}`}
        />
      </div>
      <div className={`w-full flex flex-col md:flex-row justify-between p-4`}>
        <div className="flex flex-col">
          <h5 className="text-md text-secondary font-semibold">{subtitle}</h5>
          <h5 className="text-lg text-muted-foreground">{description}</h5>
        </div>
        <div className="h-fit w-fit rounded-md flex flex-row items-center gap-2 border py-1 px-3 text-xs">
          <div className="size-1 bg-accent rounded-full" />
          {footer}
        </div>
      </div>
    </div>
  );
};

const Preprocess = () => {
  const [selected, setSelected] = useState<"candidate" | "company">(
    "candidate",
  );

  const { profile, profileLoading } = useProfileQuery();

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && profile?.userType.length) {
      navigate("/landing");
    }
  }, [isAuthenticated, profile, navigate]);

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleNext = () =>
    void (async () => {
      posthog.capture("lets_get_started");
        
      if (isAuthenticated) {
        return navigate(
          selected === "candidate"
            ? "/pre-screen/candidate/two"
            : "/pre-screen/company/two",
        );
      }

      await loginWithRedirect({
        appState: {
          returnTo:
            selected === "candidate"
              ? "/pre-screen/candidate/two"
              : "/pre-screen/company/two",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  if (
    isLoading ||
    (isAuthenticated && (profileLoading || profile?.userType.length))
  ) {
    return;
  }

  return (
    <PrescreenLayout
      title={"Let's Get Started"}
      secondarySubtitle={"Hello there."}
      subtitle={`How can we help you?`}
      step={"1/3"}
      form={
        <>
          <div className="flex flex-col gap-4">
            <StyledPreprocessRadio
              value="candidate"
              selected={selected}
              setSelected={setSelected}
              title={"I'm ready to work"}
              subtitle={"For Candidates"}
              description={"Seeking leadership roles in early-stage startups."}
              footer={"Find your role"}
              icon={Briefcase}
            />
            <StyledPreprocessRadio
              value="company"
              selected={selected}
              setSelected={setSelected}
              title={"I'm ready to hire a candidate"}
              subtitle={"For Companies"}
              description={"Connect my company to seasoned executives "}
              footer={"Find your fit"}
              icon={Globe}
            />
            {!isAuthenticated && (
              <div className="w-full flex flex-col rounded-lg border ring ring-offset-1 ring-border">
                <div className="flex flex-row gap-4 items-center justify-between p-4">
                  <div className="flex flex-row gap-4 items-center">
                    <div className="size-10 p-2 rounded-md border">
                      <LogIn className="size-full text-muted-foreground" />
                    </div>
                    <h4 className="text-lg font-semibold">
                      Already have an account?
                    </h4>
                  </div>
                  <Button variant="secondary" onClick={handleLogin}>
                    Sign Back In
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex flex-col md:flex-row justify-end gap-2">
            <Button onClick={handleNext}>Next: Your Information</Button>
          </div>
        </>
      }
      infoCard={{
        background: StockImage,
        title: "The <b><i>#1 Platform</i></b> for Fractional Executives",
        description:
          "We help startups grow faster by connecting them with experienced leaders. Our goal? Turn great ideas into real success stories.",
        footer: true,
      }}
    />
  );
};

export default Preprocess;
