import { useState } from "react";
import {
  Button,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTrigger,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@fluentui/react-components";
import { useDialogStyles } from "@/fluentStyles";
import { CloseCircleSVG } from "@/assets/svgs";

const OpportunityExplanationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dialogStyle = useDialogStyles();

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary" shape="circular">
          How It Works
        </Button>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              How it works
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <ol className="list-[auto] pl-5">
              <li className="text-base font-medium text-[#22234A] py-1">
                <p className="text-base font-medium text-[#22234A]">
                  Share some information about the job opportunity you&apos;re
                  hiring for.
                </p>
              </li>
              <li className="text-base font-medium text-[#22234A] py-1">
                <p className="text-base font-medium text-[#22234A]">
                  Outline what your interview process looks like.
                </p>
              </li>
              <li className="text-base font-medium text-[#22234A] py-1">
                <p className="text-base font-medium text-[#22234A]">
                  When you&apos;re ready, hit save.
                </p>
              </li>
              <li className="text-base font-medium text-[#22234A] py-1">
                <p className="text-base font-medium text-[#22234A]">
                  The Venturous team will review and reach back out to you for
                  additional information if necessary.
                </p>
              </li>
              <li className="text-base font-medium text-[#22234A] py-1">
                <p className="text-base font-medium text-[#22234A]">
                  You&apos;ll be notified once a candidate shortlist is ready.
                </p>
              </li>
            </ol>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <Button className="w-full" appearance="secondary">
                  Close
                </Button>
              </DialogTrigger>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default OpportunityExplanationDialog;
