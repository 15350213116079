import { create } from "zustand";

export const usePaymentInfoDialogState = create<{
  open: boolean;
  setOpen: (open: boolean) => void;
  mode: "view" | "edit";
  setMode: (mode: "view" | "edit") => void;
  title: string;
  setTitle: (title: string) => void;
}>(set => ({
  open: false,
  setOpen: open => set({ open, mode: "view" }),
  mode: "view",
  setMode: mode => set({ mode }),
  title: "Payment Information",
  setTitle: title => set({ title }),
}));
