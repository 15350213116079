import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { useProfileQuery } from "@/queries/user/profile";
import { toast } from "sonner";
import { CompanyUserAccountType } from "@/redux/features/companyProfileSlice";
import { TeamMember } from "@/queries/company/team";

export const useUpdateTeamMember = () => {
  const qc = useQueryClient();
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();

  const cid = profile?.companyAccounts[0]?.companyId;

  const { mutate, isPending, error } = useMutation<
    TeamMember,
    Error,
    { userId: string; accountType: CompanyUserAccountType }
  >({
    mutationFn: async (data) => {
      const token = await getAccessTokenSilently();

      return await request({
        method: "PATCH",
        path: `/api/company/${cid}/team/${data.userId}`,
        body: JSON.stringify({
          accountType: data.accountType,
        }),
        token,
      });
    },
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error updating team member, please try again");
      } else {
        toast.success("Team member updated!");
      }

      await qc.invalidateQueries({
        queryKey: QueryKeys.companyTeam,
      });
    },
  });

  return {
    updateTeamMember: mutate,
    isUpdatingTeamMember: isPending,
    teamMemberError: error,
  };
};
