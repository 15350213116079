import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import {
  AdminJobCandidateDetails,
  AdminJobDetails,
} from "@/queries/admin/job-details";

export const useGetNewCandidateMatches = (jobOpeningId = "") => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      return request<AdminJobCandidateDetails[]>({
        method: "POST",
        path: `/api/admin/job_opening/${jobOpeningId}/generate_matches`,
        token,
      });
    },
    onSuccess: (data) => {
      // Invalidate pipeline data
      void qc.invalidateQueries({
        queryKey: QueryKeys.adminJobPipeline,
      });

      // Fetch any existing data for the job updated
      const prev = qc.getQueryData<AdminJobDetails>(
        QueryKeys.adminJobDetails(jobOpeningId),
      );

      // If there is existing data, update it
      if (prev) {
        qc.setQueryData(QueryKeys.adminJobDetails(jobOpeningId), {
          ...prev,
          candidateCount: prev.candidateCount + data.length,
          candidates: [...prev.candidates, ...data],
        });
      } else {
        // If the entry couldn't be found, invalidate and fetch new data
        void qc.invalidateQueries({
          queryKey: QueryKeys.adminJobDetails(jobOpeningId),
        });
      }
    },
  });

  return {
    getNewCandidateMatches: mutate,
    isGettingNewCandidateMatches: isPending,
    getNewCandidateMatchesError: error,
  };
};
