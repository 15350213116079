import { useEffect, useMemo, useState } from "react";

// import { EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  TableWrapper,
  TableBody,
  TableRow,
  TableRowItem,
  TableFooter,
  TablePagination,
} from "@/components/ui/table";
import { Avatar } from "@fluentui/react-components";

import { AdminCompany } from "@/queries/admin/companies";
import { useSetCompanyAcknowledged } from "@/mutations/admin/set-company-acknowledged";

const SORTABLE_FIELDS = ["name", "new"] as const;
export type SortableField = (typeof SORTABLE_FIELDS)[number];

const ENTRIES_PER_PAGE = 10;

const CompaniesTable = ({
  companies,
  //   setSelectedCompany,
}: {
  companies?: AdminCompany[];
  //   setSelectedCompany: (v: AdminCompany | undefined) => void;
}) => {
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState<SortableField>("name");
  const [sortDirection, setSortDirection] = useState<1 | -1>(1);

  const sortedCompanies: AdminCompany[] | undefined = useMemo(() => {
    if (!companies) {
      return;
    }

    switch (sortField) {
      case "name":
        return [...companies].sort(
          (a, b) =>
            (a[sortField] ?? "").localeCompare(b[sortField] ?? "") *
            sortDirection,
        );
      case "new":
        return [...companies].sort((a, b) =>
          a.acknowledged === b.acknowledged
            ? 0 // If both have or haven't been acknowledged, preserve order
            : a.acknowledged
              ? sortDirection // Otherwise, sort them relative to each other based on sort direction
              : -sortDirection,
        );
    }
  }, [companies, sortField, sortDirection]);

  const numberOfPages = useMemo(() => {
    if (!sortedCompanies) {
      return 1;
    }

    return Math.ceil(sortedCompanies.length / ENTRIES_PER_PAGE);
  }, [sortedCompanies]);

  const currentPageEntries = useMemo(
    () =>
      sortedCompanies?.slice(
        (page - 1) * ENTRIES_PER_PAGE,
        page * ENTRIES_PER_PAGE,
      ),
    [sortedCompanies, page],
  );

  const onHeadingClick = (field: SortableField) => {
    if (field !== sortField) {
      setSortField(field);
      setSortDirection(1);
    } else {
      setSortDirection((prev) => (prev === 1 ? -1 : 1));
    }
  };

  useEffect(() => {
    if (page > numberOfPages && page !== 1) {
      setPage(numberOfPages || 1);
    }
  }, [page, numberOfPages]);

  return (
    <div className="py-6 self-stretch flex flex-col lg:flex-row gap-x-6 gap-y-2">
      <div className="flex-1 min-w-0">
        <TableWrapper>
          <TableBody>
            <thead>
              <TableRow>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("name")}
                  sortDirection={
                    sortField === "name" ? sortDirection : undefined
                  }
                >
                  Name
                </TableRowItem>
                <TableRowItem heading>Profile</TableRowItem>
                <TableRowItem heading>LinkedIn</TableRowItem>
                <TableRowItem heading>Website</TableRowItem>
                <TableRowItem heading>Location</TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("new")}
                  sortDirection={
                    sortField === "new" ? sortDirection : undefined
                  }
                >
                  New?
                </TableRowItem>
                <TableRowItem heading button></TableRowItem>
                {/* <TableRowItem heading button></TableRowItem> */}
              </TableRow>
            </thead>
            <tbody>
              {currentPageEntries?.map((entry) => (
                <Row
                  key={`company-${entry.id}`}
                  company={entry}
                  //   selectCompany={() => setSelectedCompany(entry)}
                />
              ))}
            </tbody>
          </TableBody>
          {numberOfPages > 1 ? (
            <TableFooter>
              <TablePagination
                currentPage={page}
                totalPages={numberOfPages}
                navigateFn={setPage}
              />
            </TableFooter>
          ) : null}
        </TableWrapper>
      </div>
    </div>
  );
};

const Row = ({
  company,
  //   selectCompany,
}: {
  company: AdminCompany;
  //   selectCompany: () => void;
}) => {
  const { setCompanyAcknowledged, isSettingCompanyAcknowledged } =
    useSetCompanyAcknowledged(company.id);

  return (
    <TableRow>
      <TableRowItem>
        <div className="flex flex-row gap-3 items-center">
          <Avatar
            image={{ src: company.logoLink }}
            name={company.name}
            size={40}
          />
          <span className="text-[#0C0D0D] text-sm font-medium">
            {company.name}
          </span>
        </div>
      </TableRowItem>
      <TableRowItem>
        <a
          href={`/company/${company.id}/edit`}
          target="_blank"
          rel="noreferrer"
          className="text-xs text-[#646cff] font-medium hover:underline"
        >
          Link
        </a>
      </TableRowItem>
      <TableRowItem>
        {Boolean(company.linkedin) && (
          <a
            href={company.linkedin}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        )}
      </TableRowItem>
      <TableRowItem>
        {Boolean(company.websiteLink) && (
          <a
            href={company.websiteLink}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        )}
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{company.location}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">
          {company.acknowledged ? "No" : "Yes"}
        </span>
      </TableRowItem>
      <TableRowItem>
        {!company.acknowledged && (
          <Button
            variant="secondary"
            size="sm"
            disabled={isSettingCompanyAcknowledged}
            onClick={() => setCompanyAcknowledged()}
          >
            Mark as Seen
          </Button>
        )}
      </TableRowItem>
      {/* <TableRowItem>
        <EllipsisVertical
          className="cursor-pointer"
          size={16}
          stroke="#B1B9B8"
          onClick={selectCompany}
        />
      </TableRowItem> */}
    </TableRow>
  );
};

export default CompaniesTable;
