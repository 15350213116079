import { useReqType } from "@/stores/req-type";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./utils";
import request from "@/lib/api/handler";
import { PaymentMethod } from "@stripe/stripe-js";

export interface StripeAccount {
  id: string;
  chargesEnabled: boolean;
  onboardingSubmitted: boolean;
  payoutsEnabled: boolean;
}

export const useStripeCustomerIDQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { switch: switchType, reqType } = useReqType();
  const isCompany = reqType.admin ? switchType === "company" : reqType.company;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.stripeCustomerID(isCompany),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<string>({
        method: "GET",
        path: `/api/${isCompany ? "company" : "user"}/stripe`,
        token,
      });

      if (!resp) {
        throw new Error("Stripe ID not found");
      }

      return resp;
    },
  });

  return {
    stripeCustomerIDLoading: isLoading,
    stripeCustomerID: data ?? null,
    stripeCustomerIDError: error ?? null,
  };
};

export const useStripePaymentMethodQuery = (disabled?: boolean) => {
  const { getAccessTokenSilently } = useAuth0();
  const { switch: switchType, reqType } = useReqType();
  const isCompany = reqType.admin ? switchType === "company" : reqType.company;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.stripePaymentMethod(isCompany),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !disabled,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<PaymentMethod>({
        method: "GET",
        path: `/api/${isCompany ? "company" : "user"}/stripe/method`,
        token,
      });

      return resp ?? undefined;
    },
  });

  return {
    paymentMethodLoading: isLoading,
    paymentMethod: data ?? null,
    paymentMethodError: error ?? null,
  };
};

export const useStripeAccountQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { switch: switchType, reqType } = useReqType();
  const isCompany = reqType.admin ? switchType === "company" : reqType.company;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.stripeAccount,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !isCompany,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const account = await request<StripeAccount>({
        method: "GET",
        path: "/api/candidate/stripe/account",
        token,
      });

      if (!account?.id) {
        throw new Error("There was an error fetching the Stripe account");
      }

      return account;
    },
  });

  return {
    stripeAccountLoading: isLoading,
    stripeAccount: data ?? null,
    stripeAccountError: error ?? null,
  };
};

export const useStripeAccountSessionSecretQuery = (accountID: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.stripeAccountSessionSecret(accountID),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!accountID,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<{ secret: string }>({
        method: "POST",
        path: "/api/candidate/stripe/account-session",
        token,
        body: JSON.stringify({ accountId: accountID }),
      });

      if (!resp) {
        throw new Error("Stripe Account Session not found");
      }

      return resp.secret;
    },
  });

  return {
    sessionSecretLoading: isLoading,
    sessionSecret: data ?? null,
    sessionSecretError: error ?? null,
  };
};
