import { useMemo, useState } from "react";

import { cn } from "@/lib/utils";
import { Spinner } from "@fluentui/react-components";
import { Search } from "lucide-react";

import ApprovedCandidatesTable from "./approved-candidates-table";
import ApprovedCandidateDrawer from "./approved-candidate-drawer";

import { AdminPipelineCandidate } from "@/queries/admin/candidate-pipeline";
import { useAdminApprovedCandidatesQuery } from "@/queries/admin/approved-candidates";

const ApprovedCandidates = () => {
  const [selectedCandidate, setSelectedCandidate] =
    useState<AdminPipelineCandidate>();
  const [searchString, setSearchString] = useState("");

  const { adminApprovedCandidates, adminApprovedCandidatesLoading } =
    useAdminApprovedCandidatesQuery();

  const candidates = useMemo(() => {
    if (searchString) {
      const filterString = searchString.toLowerCase();

      return adminApprovedCandidates?.filter((candidate) =>
        candidate.name?.toLowerCase().includes(filterString),
      );
    } else {
      return adminApprovedCandidates;
    }
  }, [adminApprovedCandidates, searchString]);

  const count = useMemo(() => candidates?.length, [candidates]);

  if (adminApprovedCandidatesLoading) {
    return (
      <div>
        <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
          Candidates
        </h3>
        <div className="w-full flex-col justify-around align-middle">
          <Spinner size="huge" />
          <p className="text-center text-base font-medium text-[#727E94]">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
        Candidates
      </h3>
      <div className="w-full flex flex-col gap-4 items-start self-stretch">
        <div className="flex flex-col md:flex-row md:items-center justify-between self-stretch">
          <p className="text-lg text-[#181d27] font-semibold">
            {`${count} ${count === 1 ? "Candidate" : "Candidates"}`}
          </p>
          <div className="flex flex-row items-center gap-3">
            <div
              className={cn(
                "flex flex-row items-center gap-0.5",
                "border-b border-[#57576B] transition-colors focus-within:border-black",
              )}
            >
              <Search />
              <input
                type="text"
                className={cn(
                  "flex h-10 w-full min-w-48 px-3 py-2 text-base",
                  "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
                )}
                placeholder="Search for any candidate"
                value={searchString}
                onChange={(ev) => {
                  ev.preventDefault();
                  setSearchString(ev.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <ApprovedCandidatesTable
          candidates={candidates}
          setSelectedCandidate={setSelectedCandidate}
        />
      </div>
      <ApprovedCandidateDrawer
        candidate={selectedCandidate}
        setOpen={() => setSelectedCandidate(undefined)}
      />
    </div>
  );
};

export default ApprovedCandidates;
