import Dashboard from "./index";
import { MessageCircleQuestion } from "lucide-react";

export const NeedHelpMessage = () => {
  return (
    <Dashboard.Message className="flex flex-row items-center justify-start  w-full bg-border/50 rounded-xl p-6 gap-2 gap-x-4">
      <div className="size-12 bg-background border flex items-center justify-center rounded-lg min-w-12 basis-12">
        <MessageCircleQuestion className="size-4" />
      </div>
      <div>
        <h2 className="text-lg whitespace-nowrap">Have a question?</h2>
        <p className="text-md">
          Please email{" "}
          <span className="text-primary font-semibold">
            support@venturous.work
          </span>{" "}
          to reach our team
        </p>
      </div>
    </Dashboard.Message>
  );
};
