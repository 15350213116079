import { posthog } from "posthog-js";
import { useEffect, useMemo } from "react";
import request from "@/lib/api/handler";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@/queries/utils";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import StockImage from "@/assets/preprocess_candidate.jpg";

import { PrescreenLayout } from "@/components/prescreen";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Loader2, ArrowRight, AlertTriangle } from "lucide-react";

import { useSetUserPrescreenIntent } from "@/mutations/user/set-user-prescreen-intent";

const formSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string().transform((val, ctx) => {
    const number = val.replace(/\D/g, "");

    if (number.length !== 10) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_string,
        message: "Phone number must be 10 digits",
        validation: "regex",
      });

      return z.NEVER;
    }

    return number;
  }),
  marketingConsent: z.boolean().default(false),
});

type FormSchema = z.infer<typeof formSchema>;

const CandidatePrescreenTwo = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const { setUserPrescreenIntent } = useSetUserPrescreenIntent();

  const qc = useQueryClient();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  const { isPending, isSuccess, mutate, error } = useMutation<
    void,
    Error,
    FormSchema
  >({
    mutationKey: ["pre-screen", "candidate", "your-information"],
    mutationFn: async (variables) => {
      const token = await getAccessTokenSilently();
      await request({
        method: "PATCH",
        path: "/api/user/",
        body: JSON.stringify({
          ...variables,
          name: `${variables.firstName} ${variables.lastName}`,
        }),
        token,
      });
    },
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: QueryKeys.myProfile,
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUserPrescreenIntent("Candidate"), []);

  useMemo(() => {
    if (isSuccess) {
      posthog.capture("candidate_basic_info");
      navigate("/pre-screen/candidate/three");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <PrescreenLayout
      title={"Thank you for your interest"}
      subtitle={"Next, we need some information to begin your application"}
      step={"2/3"}
      form={
        <Form {...form}>
          {error && (
            <>
              <div className="w-full flex flex-row items-center gap-4 bg-card border rounded-md p-8">
                <AlertTriangle />
                {error.message}
              </div>
            </>
          )}
          <form className="w-full grid grid-cols-2 gap-4 bg-card border rounded-md p-8">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Jane" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Doe" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel required>Phone Number</FormLabel>
                  <FormControl>
                    <Input placeholder="123-456-7890" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
          <div className="w-full flex flex-row gap-4">
            <Checkbox
              checked={form.getValues().marketingConsent}
              onCheckedChange={(state) => {
                form.setValue(
                  "marketingConsent",
                  typeof state === "string" ? false : state,
                );
              }}
            />
            <p className="text-sm">
              I agree to receive automated text messages and emails from
              Venturous for marketing updates. Consent is a not a condition of
              purchase. Reply HELP for help and STOP to unsubscribe. Msg
              frequency varies. Msg and data rates may apply. Subject to Terms
              and Privacy Notice.
            </p>
          </div>
          <div className="w-full h-[1px] bg-border" />
          <div className="w-full flex flex-row justify-end">
            <Button
              disabled={!form.formState.isValid || isPending ? true : false}
              onClick={() => void form.handleSubmit((data) => mutate(data))()}
            >
              Next: 5 Minute Questionnaire
              {isPending ? (
                <Loader2 className="animate-spin" />
              ) : (
                <ArrowRight />
              )}
            </Button>
          </div>
        </Form>
      }
      infoCard={{
        background: StockImage,
        title: "Pre-Screening",
        subtitle:
          "Take the first step toward joining Venturous, where seasoned professionals and visionary startups connect.",
        description:
          " As a valued member, you’ll gain access to exclusive opportunities, career-advancing resources, and tools to thrive in the world of fractional leadership.",
        footer: true,
      }}
    />
  );
};

export default CandidatePrescreenTwo;
