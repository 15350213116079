import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import {
  companyEmployeesList,
  industryList,
  roleList,
  useCaseList,
  workTypeList,
} from "../../redux/features/candidateProfileSlice";
import {
  CandidateProfileField,
  MaxHeightSelectArrayField,
  MaxHeightTextField,
  SelectArrayField,
  StringArrayField,
} from "./CustomFields";
import { candidateWaitingRoomStatusList } from "../../types";

const statusChoices = candidateWaitingRoomStatusList.map((type) => ({
  id: type,
  name: type,
}));
const workTypeChoices = workTypeList.map((type) => ({
  id: type,
  name: type,
}));
const roleChoices = roleList.map((type) => ({
  id: type,
  name: type,
}));
const industryChoices = industryList.map((type) => ({
  id: type,
  name: type,
}));
const useCaseChoices = useCaseList.map((type) => ({
  id: type,
  name: type,
}));
const headcountChoices = companyEmployeesList.map((type) => ({
  id: type,
  name: type,
}));

const filters = [
  <ReferenceInput key="user" source="user" reference="users" />,
  <SelectInput key="stage" source="stage" choices={statusChoices} />,
  <TextInput key="headline" source="headline" />,
  <NumberInput key="years_of_experience" source="years_of_experience" />,
  <NumberInput
    key="years_of_healthcare_experience"
    source="years_of_healthcare_experience"
  />,
  <TextInput key="resume_link" source="resume_link" />,
  <TextInput key="personal_website" source="personal_website" />,
  // <TextInput key="biography" source="biography" multiline />,
  <SelectArrayInput
    key="ideal_work_types"
    source="ideal_work_types"
    choices={workTypeChoices}
  />,
  <SelectInput
    key="primary_role"
    source="primary_role"
    choices={roleChoices}
  />,
  <SelectArrayInput
    key="ideal_roles"
    source="ideal_roles"
    choices={roleChoices}
  />,
  <TextInput key="ideal_roles_other" source="ideal_roles_other" />,
  <SelectArrayInput
    key="ideal_subindustries"
    source="ideal_subindustries"
    choices={industryChoices}
  />,
  <SelectArrayInput
    key="ideal_use_case"
    source="ideal_use_case"
    choices={useCaseChoices}
  />,
  <SelectArrayInput
    key="ideal_company_headcount"
    source="ideal_company_headcount"
    choices={headcountChoices}
  />,
  <TextInput key="top_skills" source="top_skills" />,
  <TextInput key="secondary_skills" source="secondary_skills" />,
  <TextInput key="additional_skills" source="additional_skills" />,
  <BooleanInput key="availability_full_time" source="availability_full_time" />,
  <BooleanInput
    key="availability_fractional_time"
    source="availability_fractional_time"
  />,
  <BooleanInput key="availability_advisory" source="availability_advisory" />,
  <BooleanInput key="availability_board" source="availability_board" />,
  <TextInput key="availability_time" source="availability_time" />,
  <DateInput key="availability_start_date" source="availability_start_date" />,
  <TextInput key="retainer_daily" source="retainer_daily" />,
  <TextInput key="retainer_hourly" source="retainer_hourly" />,
  <BooleanInput key="profile_ready" source="profile_ready" />,
  <BooleanInput key="onboarded" source="onboarded" />,
  <DateInput key="onboarded_on" source="onboarded_on" />,
  <DateInput key="member_since" source="member_since" />,
  <DateInput key="rejected_on" source="rejected_on" />,
  <DateInput key="created_at" source="created_at" />,
];

export const CandidateList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="user" reference="users" />
      <CandidateProfileField />
      <SelectField source="stage" choices={statusChoices} />
      <MaxHeightTextField source="headline" />
      <NumberField source="years_of_experience" />
      <NumberField source="years_of_healthcare_experience" />
      <UrlField source="resume_link" content="Link" />
      <UrlField source="personal_website" content="Link" />
      {/* <TextField source="biography" /> */}
      <MaxHeightSelectArrayField source="ideal_work_types" />
      <SelectField source="primary_role" choices={roleChoices} />
      <MaxHeightSelectArrayField source="ideal_roles" />
      <TextField source="ideal_roles_other" />
      <MaxHeightSelectArrayField source="ideal_subindustries" />
      <MaxHeightSelectArrayField source="ideal_use_case" />
      <MaxHeightSelectArrayField source="ideal_company_headcount" />
      <MaxHeightSelectArrayField source="top_skills" />
      <MaxHeightSelectArrayField source="secondary_skills" />
      <MaxHeightSelectArrayField source="additional_skills" />
      <BooleanField source="availability_full_time" />
      <BooleanField source="availability_fractional_time" />
      <BooleanField source="availability_advisory" />
      <BooleanField source="availability_board" />
      <TextField source="availability_time" />
      <DateField source="availability_start_date" />
      <TextField source="retainer_daily" />
      <TextField source="retainer_hourly" />
      <BooleanField source="profile_ready" />
      <BooleanField source="culture_complete" />
      <BooleanField source="interview_complete" />
      <BooleanField source="profile_complete" />
      <BooleanField source="onboarded" />
      <DateField source="onboarded_on" />
      <DateField source="member_since" />
      <DateField source="rejected_on" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const CandidateShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="user" reference="users" />
      <CandidateProfileField />
      <SelectField source="stage" choices={statusChoices} />
      <TextField source="headline" />
      <NumberField source="years_of_experience" />
      <NumberField source="years_of_healthcare_experience" />
      <UrlField source="resume_link" content="Link" />
      <UrlField source="personal_website" content="Link" />
      <TextField source="biography" />
      <SelectField source="primary_role" choices={roleChoices} />
      <SelectArrayField source="ideal_work_types" />
      <SelectArrayField source="ideal_roles" />
      <TextField source="ideal_roles_other" />
      <SelectArrayField source="ideal_subindustries" />
      <SelectArrayField source="ideal_use_case" />
      <SelectArrayField source="ideal_company_headcount" />
      <StringArrayField source="top_skills" />
      <StringArrayField source="secondary_skills" />
      <StringArrayField source="additional_skills" />
      <BooleanField source="availability_full_time" />
      <BooleanField source="availability_fractional_time" />
      <BooleanField source="availability_advisory" />
      <BooleanField source="availability_board" />
      <TextField source="availability_time" />
      <DateField source="availability_start_date" />
      <TextField source="retainer_daily" />
      <TextField source="retainer_hourly" />
      <StringArrayField source="questionnaire_business_principles" />
      <StringArrayField source="questionnaire_peer_description" />
      <StringArrayField source="questionnaire_communication_skills" />
      <TextField source="personality_result" />
      <TextField source="personality_detail" />
      <TextField source="behavior_result" />
      <TextField source="behavior_detail" />
      <TextField source="motivators_result" />
      <TextField source="motivators_detail" />
      <BooleanField source="profile_ready" />
      <BooleanField source="culture_complete" />
      <BooleanField source="interview_complete" />
      <BooleanField source="profile_complete" />
      <BooleanField source="onboarded" />
      <DateField source="onboarded_on" />
      <DateField source="member_since" />
      <DateField source="rejected_on" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CandidateCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <SelectInput source="stage" choices={statusChoices} />
      <TextInput source="headline" />
      <NumberInput source="years_of_experience" />
      <NumberInput source="years_of_healthcare_experience" />
      <TextInput source="resume_link" />
      <TextInput source="personal_website" />
      <TextInput source="biography" multiline />
      {/* <SelectArrayInput source="ideal_work_types" choices={workTypeChoices} />
      <SelectArrayInput source="ideal_roles" choices={roleChoices} />
      <TextInput source="ideal_roles_other" />
      <SelectArrayInput
        source="ideal_subindustries"
        choices={industryChoices}
      />
      <SelectArrayInput source="ideal_use_case" choices={useCaseChoices} />
      <SelectArrayInput
        source="ideal_company_headcount"
        choices={headcountChoices}
      />
      <TextInput source="top_skills" />
      <TextInput source="secondary_skills" />
      <TextInput source="additional_skills" /> */}
      <BooleanInput source="availability_full_time" />
      <BooleanInput source="availability_fractional_time" />
      <BooleanInput source="availability_advisory" />
      <BooleanInput source="availability_board" />
      <TextInput source="availability_time" />
      <DateInput source="availability_start_date" />
      <TextInput source="retainer_daily" />
      <TextInput source="retainer_hourly" />
      {/* <TextInput source="questionnaire_business_principles" />
      <TextInput source="questionnaire_peer_description" />
      <TextInput source="questionnaire_communication_skills" /> */}
      <TextInput source="personality_result" />
      <TextInput source="personality_detail" multiline />
      <TextInput source="behavior_result" />
      <TextInput source="behavior_detail" multiline />
      <TextInput source="motivators_result" />
      <TextInput source="motivators_detail" multiline />
      <BooleanInput source="profile_ready" />
      <BooleanInput source="culture_complete" />
      <BooleanInput source="interview_complete" />
      <BooleanInput source="profile_complete" />
      <BooleanInput source="onboarded" />
      <DateInput source="onboarded_on" />
      <DateInput source="member_since" />
      <DateInput source="rejected_on" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const CandidateEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <SelectInput source="stage" choices={statusChoices} />
      <TextInput source="headline" />
      <NumberInput source="years_of_experience" />
      <NumberInput source="years_of_healthcare_experience" />
      <TextInput source="resume_link" />
      <TextInput source="personal_website" />
      <TextInput source="biography" multiline />
      {/* <SelectArrayInput source="ideal_work_types" choices={workTypeChoices} />
      <SelectArrayInput source="ideal_roles" choices={roleChoices} />
      <TextInput source="ideal_roles_other" />
      <SelectArrayInput
        source="ideal_subindustries"
        choices={industryChoices}
      />
      <SelectArrayInput source="ideal_use_case" choices={useCaseChoices} />
      <SelectArrayInput
        source="ideal_company_headcount"
        choices={headcountChoices}
      />
      <TextInput source="top_skills" />
      <TextInput source="secondary_skills" />
      <TextInput source="additional_skills" /> */}
      <BooleanInput source="availability_full_time" />
      <BooleanInput source="availability_fractional_time" />
      <BooleanInput source="availability_advisory" />
      <BooleanInput source="availability_board" />
      <TextInput source="availability_time" />
      <DateInput source="availability_start_date" />
      <TextInput source="retainer_daily" />
      <TextInput source="retainer_hourly" />
      {/* <TextInput source="questionnaire_business_principles" />
      <TextInput source="questionnaire_peer_description" />
      <TextInput source="questionnaire_communication_skills" /> */}
      <TextInput source="personality_result" />
      <TextInput source="personality_detail" multiline />
      <TextInput source="behavior_result" />
      <TextInput source="behavior_detail" multiline />
      <TextInput source="motivators_result" />
      <TextInput source="motivators_detail" multiline />
      <BooleanInput source="profile_ready" />
      <BooleanInput source="culture_complete" />
      <BooleanInput source="interview_complete" />
      <BooleanInput source="profile_complete" />
      <BooleanInput source="onboarded" />
      <DateInput source="onboarded_on" />
      <DateInput source="member_since" />
      <DateInput source="rejected_on" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
