import { useAuth0 } from "@auth0/auth0-react";
import { useProfileQuery } from "../user/profile";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import request from "@/lib/api/handler";

export interface Executive {
  id: string;
  name?: string;
  profilePicture?: string;
  role?: string;
  engagementType?: string;
  experience?: string;
  startDate?: string;
  linkedIn?: string;
  cultureLink?: string;
  biography?: string;
}

//type ExecutiveAddRequest = Omit<Executive, "id" | "cultureLink">;

export interface Company {
  id?: string;
  name?: string;
  location?: string;
  logoLink?: string;
  foundedYear?: string;
  companySize?: string;
  fundsRaised?: string;
  lastFundraise?: string;
  cultureStatements?: string;
  linkedIn?: string;
  websiteLink?: string;
  aboutUs?: string;
  missionStatement?: string;
  profileComplete?: boolean;
  onboardingComplete?: boolean;
  executiveTeam: Executive[];
}

//export type CompanyAddRequest = Omit<Company, "id" | "executiveTeam">;

export const useMyCompanyQuery = () => {
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();
  const cid = profile?.companyAccounts[0]?.companyId;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.myCompany,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!cid,
    async queryFn() {
      const token = await getAccessTokenSilently();
      const resp: Company = await request({
        method: "GET",
        path: `/api/company/${cid}`,
        token,
      });

      return resp;
    },
  });

  return {
    companyLoading: isLoading,
    company: data ?? null,
    companyError: error ?? null,
  };
};
