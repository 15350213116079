import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

export const useSetUserPrescreenIntent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async (value: "Company" | "Candidate") => {
      const token = await getAccessTokenSilently();

      await request({
        method: "PATCH",
        path: "/api/user/",
        body: JSON.stringify({ userPrescreenIntent: value }),
        token,
      });
    },
  });

  return {
    setUserPrescreenIntent: mutate,
    isSettingUserPrescreenIntent: isPending,
    setUserPrescreenIntentError: error,
  };
};
