import { useMutation } from "@tanstack/react-query";
import { CompanyOpportunityFormSchema } from "./schema";
import { useAuth0 } from "@auth0/auth0-react";
import request from "@/lib/api/handler";
import localDateToDateString from "@/utils/localDateToDateString";
import { CompanyJobOpening } from "@/types";
import { useNavigate } from "react-router-dom";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";
import { toast } from "sonner";

const useCompanyOpportunityMutation = (
  mode: "create" | "update",
  id?: string,
  redirect = false,
  onSuccess?: () => void,
) => {
  const navigate = useNavigate();
  const invalidate = useInvalidateQuery();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (data: CompanyOpportunityFormSchema) => {
      if (mode === "update" && !id) {
        throw new Error("No ID provided for update operation");
      }

      const token = await getAccessTokenSilently();
      const body = buildFormData(data);

      if (
        mode === "update" &&
        data.jobDescription.name === "" &&
        data.jobDescription.size === 0
      ) {
        body.delete("jobDescription");
      }

      const response: CompanyJobOpening = await request({
        method: mode === "create" ? "POST" : "PATCH",
        token,
        path: mode === "create" ? "/api/jobOpening" : `/api/jobOpening/${id}`,
        body,
        contentType: "application/x-www-form-urlencoded",
      });

      return response;
    },
    async onSettled(data, error) {
      if (!error && data?.id && mode === "create") {
        redirect && navigate("/opening/" + data.id);
        onSuccess && onSuccess();
      }

      if (!error) {
        toast.success(
          `Opportunity ${mode === "create" ? "created" : "updated"} successfully!`,
        );
        onSuccess && onSuccess();
      } else {
        toast.error(
          `Failed to ${mode === "create" ? "create" : "update"} opportunity`,
        );
      }

      await invalidate(QueryKeys.companyOpportunities);
      await invalidate(QueryKeys.companyOpportunity(data?.id ?? ""));
      await invalidate(QueryKeys.adminJobDetails(data?.id ?? ""));
    },
  });
};

function buildFormData(data: CompanyOpportunityFormSchema) {
  const body = new FormData();

  body.append("role", data.jobTitle);
  body.append("scope", data.scope);
  body.append("budget", data.budget);
  body.append("desiredStartDate", localDateToDateString(data.idealStartDate));
  body.append("shortDescription", data.briefDescription);
  body.append("keyResponsibilities", data.keyResponsibilities);
  body.append("convertsToFullTime", data.canLeadToFullTime);
  body.append("matchCriteria1", data.matchCriteria1 ?? "");
  body.append("matchCriteria2", data.matchCriteria2 ?? "");
  body.append("matchCriteria3", data.matchCriteria3 ?? "");
  body.append("noteForVenturous", data.additionalInformation ?? "");
  body.append("jobDescription", data.jobDescription);

  if (data.maskedJobDescription) {
    body.append("maskedJobDescription", data.maskedJobDescription);
  }

  data.interviews?.forEach((interview, idx) => {
    if (interview.id) {
      body.append(`interviews[${idx}][id]`, interview.id);
    }

    if (interview.name) {
      body.append(`interviews[${idx}][name]`, interview.name);
    }

    if (interview.duration) {
      body.append(
        `interviews[${idx}][duration]`,
        interview.duration.toString(),
      );
    }
  });

  return body;
}

export default useCompanyOpportunityMutation;
