import { useAuth0 } from "@auth0/auth0-react";
import { useProfileQuery } from "../user/profile";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import request from "@/lib/api/handler";

export interface Candidate {
  id: string;
  name: string;
  emails: string[];
  location: string;
  titleAndEmployer: string;
  yearsOfExperience: number;
  yearsOfHealthcareExperience: number;
  biography: string;
  idealWorkType: string[];
  idealRoles: string[];
  idealSubindustries: string[];
  idealUseCase: string[];
  idealCompanyHeadcount: string[];
  topSkills: string[];
  secondarySkills: string[];
  additionalSkills: string[];
  availabilityFullTime: boolean;
  availabilityFractional: boolean;
  availabilityAdvisory: boolean;
  availabilityBoard: boolean;
  availabilityTime: string;
  retainerDaily: string;
  retainerHourly: string;
  questionnaireBusinessPrinciples: string[];
  questionnairePeerDescription: string[];
  questionnaireCommunicationSkills: string[];
  applicationReviewed: boolean;
  cultureComplete: boolean;
  interviewComplete: boolean;
  profileComplete: boolean;
  applicationComplete: boolean;
}

export const useMyCandidateQuery = () => {
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();
  const uid = profile?.id;

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.myCandidate,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!profile?.candidateId,
    async queryFn() {
      const token = await getAccessTokenSilently();
      const resp: { candidate: Candidate } = await request({
        method: "GET",
        path: `/api/candidate/${uid}`,
        token,
      });

      return resp.candidate;
    },
  });

  return {
    candidateLoading: isLoading,
    candidate: data ?? null,
    candidateError: error ?? null,
  };
};
