import { ReactNode } from "react";
import { create } from "zustand";

interface NavLayoutStore {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  navItems: ReactNode;
  setNavItems: (items: ReactNode) => void;
}

export const useNavLayout = create<NavLayoutStore>(set => ({
  sidebarOpen: false,
  setSidebarOpen: open => set({ sidebarOpen: open }),
  navItems: null,
  setNavItems: items => set({ navItems: items }),
}));
