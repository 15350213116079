import request from "@/lib/api/handler";

import { useProfileQuery } from "@/queries/user/profile";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import {
  Candidate,
  useMyCandidateQuery,
} from "@/queries/candidate/my-candidate";

export const useCompleteTask = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfileQuery();
  const { candidate } = useMyCandidateQuery();

  const {
    mutate,
    isPending: completeTaskPending,
    error: completeTaskError,
  } = useMutation({
    mutationFn: async (task: "profile" | "interview" | "culture") => {
      const token = await getAccessTokenSilently();
      const body = {
        emails: candidate?.emails,
        ...(task === "profile"
          ? { profileComplete: true }
          : task === "interview"
            ? { interviewComplete: true }
            : { cultureComplete: true }),
      };

      await request({
        method: "PATCH",
        path: `/api/candidate/${profile?.id}`,
        token,
        body: JSON.stringify(body),
      });
    },
    onMutate: async (task) => {
      await qc.cancelQueries({
        queryKey: QueryKeys.myCandidate,
      });

      const prev = qc.getQueryData<Candidate>(QueryKeys.myCandidate);

      if (prev) {
        qc.setQueryData(QueryKeys.myCandidate, {
          ...prev,
          ...(task === "profile"
            ? { profileComplete: true }
            : task === "interview"
              ? { interviewComplete: true }
              : { cultureComplete: true }),
        });
      }

      return { prev, task };
    },
    onError: (_, __, context) => {
      qc.setQueryData(QueryKeys.myCandidate, context?.prev);
    },
    onSettled: () => {
      void qc.invalidateQueries({
        queryKey: QueryKeys.myCandidate,
      });
    },
  });

  return {
    completeProfile: () => mutate("profile"),
    completeInterview: () => mutate("interview"),
    completeCulture: () => mutate("culture"),
    completeTaskPending,
    completeTaskError,
  };
};
