import { useNavigate } from "react-router-dom";
import { posthog } from "posthog-js";
import StockImage from "@/assets/preprocess_candidate.jpg";
import CompanyOpportunityForm from "@/components/forms/company/opportunity";
import PreprocessIcon from "@/assets/preprocess_icon.svg";
import { PrescreenLayout } from "@/components/prescreen";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";

const CompanyPrescreenJobs = () => {
  const [open, setOpen] = useState(false);
  const [jobTitle, setJobTitle] = useState("");

  const navigate = useNavigate();

  return (
    <PrescreenLayout
      title={"Would you like to add a job?"}
      form={
        <>
          <div className="w-full bg-card border rounded-md p-8 space-y-2">
            <Label required>Job Title</Label>
            <Input
              placeholder="Fractional Chief Marketing Officer"
              value={jobTitle}
              onChange={(v) => setJobTitle(v.target.value)}
            />
          </div>
          <div className="w-full flex flex-row justify-end gap-4">
            <Button variant="outline">
              <Link to="/landing"> Skip For Now</Link>
            </Button>
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogTrigger asChild>
                <Button disabled={!jobTitle}>Add A Job</Button>
              </DialogTrigger>
              <DialogContent className="w-full max-w-[min(calc(100vw-2rem),768px)] p-0">
                <DialogHeader className="p-6">
                  <div className="flex items-center gap-2">
                    <img
                      src={PreprocessIcon}
                      alt="Preprocess Icon"
                      className=""
                    />
                    <DialogTitle>Add a Job Opportunity</DialogTitle>
                  </div>
                  <DialogDescription className="sr-only">
                    Enter the details of the job opportunity here and click
                    &quot;Save&quot;
                  </DialogDescription>
                </DialogHeader>

                <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
                  <CompanyOpportunityForm
                    modal
                    defaultTitle={jobTitle}
                    onSuccess={() => {
                      setOpen(false);
                      posthog.capture("company_add_job_optional");
                      navigate("/landing");
                    }}
                  />
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </div>
        </>
      }
      infoCard={{
        background: StockImage,
        backgroundClass: "w-fit h-full",
        title: "Here's how it works",
        subtitle:
          "Share some details about your job opening then we'll start connecting you with pre-screened candidates.",
      }}
    />
  );
};

export default CompanyPrescreenJobs;
