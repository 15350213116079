import { Outlet, useLocation } from "react-router-dom";
import TopBar from "../top-bar";
import { useMyCandidateQuery } from "@/queries/candidate/my-candidate";
import { useReqType } from "@/stores/req-type";
import CandidateAvailabilitySheet from "../dialogs/candidate/availability";
import { Lock } from "lucide-react";

const DashboardLayout = () => {
  const { candidate: candidateObj } = useMyCandidateQuery();

  const { company, candidate, admin } = useReqType((s) => s.reqType);

  const { pathname } = useLocation();

  return (
    <div id="dashboard-wrapper" className="bg-background">
      <TopBar.Main>
        <TopBar.NavItems>
          {(company || candidate || admin) && (
            <>
              <TopBar.NavItem href="/dashboard" title="Home" />
              <TopBar.NavItem href="/opportunities" title="Opportunities" />
            </>
          )}
          {company && (
            <>
              <TopBar.NavItem
                href="/company/profile/edit"
                title="Company Profile"
              />
              <TopBar.NavItem href="/team" title="Team" />
            </>
          )}
          {candidate && (
            <>
              <TopBar.NavItem
                href="/profile/edit"
                title="My Profile"
                icon={
                  candidateObj && !candidateObj.applicationComplete ? (
                    <Lock />
                  ) : undefined
                }
              />
              <CircleNavItem />
            </>
          )}
          {admin && (
            <>
              <TopBar.NavItem
                href="/new-admin/dashboard"
                title="Admin Dashboard"
                activeOverride={pathname.startsWith("/new-admin")}
              />
              <TopBar.NavItem
                href="/admin"
                title="Old Admin"
                activeOverride={pathname.startsWith("/admin")}
              />
            </>
          )}
        </TopBar.NavItems>
        <TopBar.Profile />
        {(company || candidate || admin) && <TopBar.Sidebar />}
      </TopBar.Main>
      <main className="px-6">
        <Outlet />
      </main>
      {candidate && <CandidateAvailabilitySheet />}
    </div>
  );
};

const CircleNavItem = () => {
  const { candidate } = useMyCandidateQuery();

  if (candidate?.applicationComplete) {
    return (
      <TopBar.NavItem
        href="https://bit.ly/CircleVenturous"
        title="Community"
        externalLink
      />
    );
  }
};

export default DashboardLayout;
