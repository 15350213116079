import PreprocessIcon from "@/assets/preprocess_icon.svg";

import { InfoCard, InfoCardProps } from "./InfoCard";

import { ArrowUpRight } from "lucide-react";

export const PrescreenLayout = ({
  title,
  secondarySubtitle,
  subtitle,
  step,
  form,
  infoCard,
}: {
  title: string;
  secondarySubtitle?: string;
  subtitle?: string;
  step?: string;
  form: React.ReactNode;
  infoCard: InfoCardProps;
}) => (
  <div className="absolute top-0 left-0 bg-background w-screen h-screen flex flex-col md:flex-row gap-16 md:items-center md:justify-center p-8 z-10 overflow-y-auto">
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex flex-row items-center gap-4 border-b pb-4">
        <img src={PreprocessIcon} alt="Preprocess Icon" className="" />
        <h2 className="text-3xl text-foreground font-bold">{title}</h2>
        {step && (
          <div className="ml-auto rounded-md flex flex-row items-center gap-2 bg-border py-1 px-3">
            <ArrowUpRight className="size-4" /> {step}
          </div>
        )}
      </div>
      {(secondarySubtitle ?? subtitle) ? (
        <div className="flex flex-row items-center gap-2">
          {secondarySubtitle && (
            <h3 className="text-lg text-accent font-semibold">
              {secondarySubtitle}
            </h3>
          )}
          {subtitle && <h3 className="text-lg font-semibold">{subtitle}</h3>}
        </div>
      ) : null}
      {form}
    </div>
    <div className="hidden md:block">
      <InfoCard {...infoCard} />
    </div>
  </div>
);
