import { cn } from "@/lib/utils";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";

const ReadMoreSection = ({
  maxLines = 6,
  textClassName,
  readMoreClassName,
  children,
}: {
  maxLines?: number;
  textClassName?: string;
  readMoreClassName?: string;
  children?: ReactNode;
}) => {
  const ref = useRef(null);
  const [readMore, setReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetHeight, scrollHeight } = ref.current;

      if (readMore) {
        // If readMore is true, check how many lines of text there are
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const lines = offsetHeight / lineHeight;

        // If there are more than max lines, then everything is fine
        if (lines > maxLines) {
          return;
        } else {
          // If not, the text is not being truncated anyway and we can reset
          setIsTruncated(false);
          setReadMore(false);
        }
      } else if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        // If readMore is not true, then check if the text is being cut off by the line clamp
        setIsTruncated(true);
        setReadMore(false);
      } else {
        // In all other cases, reset
        setIsTruncated(false);
        setReadMore(false);
      }
    }
  }, [ref, readMore, children, maxLines]);

  return (
    <>
      <p
        ref={ref}
        className={cn(
          "text-sm font-medium text-[#4E4F6C] whitespace-break-spaces",
          textClassName,
        )}
        style={
          readMore
            ? {}
            : {
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: maxLines,
              }
        }
      >
        {children}
      </p>
      {!isTruncated ? null : readMore ? (
        <p
          className={cn("edit-text w-fit", readMoreClassName)}
          onClick={() => setReadMore(false)}
        >
          View less
        </p>
      ) : (
        <p
          className={cn("edit-text w-fit", readMoreClassName)}
          onClick={() => setReadMore(true)}
        >
          View more
        </p>
      )}
    </>
  );
};

export default ReadMoreSection;
