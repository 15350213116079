import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const filters = [
  <ReferenceInput key="contract_id" source="contract" reference="contracts" />,
  <ReferenceInput
    key="job_opening_id"
    source="job_opening"
    reference="job_openings"
  />,
  <BooleanInput key="show_candidate" source="show_candidate" />,
  <BooleanInput key="show_company" source="show_company" />,
  <DateInput key="created_at" source="created_at" />,
];

export const ContractVisibilitiesList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="contract_id" reference="contracts" />
      <ReferenceField source="job_opening_id" reference="job_openings" />
      <BooleanField source="show_candidate" />
      <BooleanField source="show_company" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ContractVisibilitiesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="contract_id" reference="contracts" />
      <ReferenceField source="job_opening_id" reference="job_openings" />
      <BooleanField source="show_candidate" />
      <BooleanField source="show_company" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const ContractVisibilitiesCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="contract_id" reference="contracts" />
      <ReferenceInput source="job_opening_id" reference="job_openings" />
      <BooleanInput key="show_candidate" source="show_candidate" />
      <BooleanInput key="show_company" source="show_company" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const ContractVisibilitiesEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="contract_id" reference="contracts" />
      <ReferenceInput source="job_opening_id" reference="job_openings" />
      <BooleanInput key="show_candidate" source="show_candidate" />
      <BooleanInput key="show_company" source="show_company" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
