import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { ReactNode, useEffect } from "react";
import { login, logout } from "./redux/features/authSlice";
import { useProfileQuery } from "./queries/user/profile";
import { useMyCandidateQuery } from "@/queries/candidate/my-candidate";
import { useMyCompanyQuery } from "@/queries/company/my-company";
import { useReqType } from "./stores/req-type";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createLightTheme, FluentProvider } from "@fluentui/react-components";
import CacheBuster from "react-cache-buster";
import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { usePostHog } from "posthog-js/react";
import packageInfo from "../package.json";
import {
  CircleCheck,
  CircleX,
  Info,
  LoaderCircle,
  TriangleAlert,
} from "lucide-react";
import { Toaster } from "./components/ui/sonner";

//import Navigation from "./elements/Navigation";
//import Footer from "./elements/Footer";
import PrivateRoute from "./utils/PrivateRoute";
import ErrorDialog from "./elements/ErrorDialog";
import Login from "./pages/Login";
import ClaimProfile from "./pages/ClaimProfile";
import JoinTeam from "./pages/JoinTeam";
import Onboarding from "./pages/Onboarding";
import CandidateProfile from "./pages/CandidateProfile";
import CandidateProfileEdit from "./pages/CandidateProfileEdit";
import CompanyProfile from "./pages/CompanyProfile";
import Team from "./pages/company/team";
import CompanyOpportunityDashboard from "./pages/CompanyOpportunityDashboard";
import CompanyOpportunity from "./pages/company/opportunity";
import Landing from "./pages/Landing";
import ReactAdmin from "./pages/Admin";
import AdminLayout from "./pages/admin/layout";
import AdminDashboard from "./pages/admin/dashboard";
import CandidatePipeline from "./pages/admin/candidate-pipeline/candidate-pipeline";
import ApprovedCandidates from "./pages/admin/approved-candidates";
import Companies from "./pages/admin/companies";
import JobPipeline from "./pages/admin/job-pipeline/job-pipeline";
import JobDetails from "./pages/admin/job-details";
import Error404Page from "./pages/Error404Page";
import Preprocess from "./pages/prescreen";
import CompanyPrescreenTwo from "./pages/prescreen/company/YourInformation";
import CompanyPrescreenThree from "./pages/prescreen/company/CompanyInformation";
import CompanyPrescreenJobs from "./pages/prescreen/company/AddJob";
import CandidatePrescreenTwo from "./pages/prescreen/candidate/CandidateInformation";
import EditCompanyOpportunityPage from "./pages/company/opportunity/edit";
import CreateCompanyOpportunityPage from "./pages/company/opportunity/create";
import CandidatePrescreenQuestionnaire from "./pages/prescreen/candidate/Questionnaire";
import CandidateDashboard from "./pages/candidate/dashboard";
import CompanyDashboard from "./pages/company/dashboard";
import DashboardLayout from "./components/layouts/dashboard";
import CandidateOpportunities from "./pages/candidate/opportunities";
import CompanyOpportunities from "./pages/company/opportunity/dashboard";
import OpportunityPage from "./pages/candidate/opportunity";
import CandidateInterview from "./pages/candidate/interview";
import Settings from "./pages/Settings";

const theme = {
  ...createLightTheme({
    10: "#020403",
    20: "#101C19",
    30: "#152E29",
    40: "#183C35",
    50: "#1B4A41",
    60: "#1D584D",
    70: "#1E675A",
    80: "#1E7667",
    90: "#1D8575",
    100: "#1C9582",
    110: "#1AA590",
    120: "#4CB29F",
    130: "#6EBFAE",
    140: "#8CCBBD",
    150: "#A8D8CD",
    160: "#C4E4DC",
  }),
  fontFamilyBase: `'Gilroy', 'Inter', 'Archivo', 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif`,
};

const Auth0ProviderWithNavigate = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: "http://localhost:5000",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const Auth0ReduxConnector = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const loggedIn = useAppSelector((state) => state.auth.isAuthenticated);
  const user = useAppSelector((state) => state.auth.user);
  const { candidate } = useMyCandidateQuery();
  const { company } = useMyCompanyQuery();
  const { pathname } = useLocation();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (isAuthenticated && !loggedIn) {
        // Fetch from server
        const token = await getAccessTokenSilently();
        void dispatch(login(token));
      } else if (!isAuthenticated && loggedIn) {
        // Logout
        void dispatch(logout());
      } else if (
        isAuthenticated &&
        loggedIn &&
        pathname.startsWith("/pre-screen") &&
        pathname !== "/pre-screen/company/jobs" &&
        pathname !== "/pre-screen/company/three" &&
        user?.userType.length
      ) {
        // Redirect to dashboard if user is already onboarded
        navigate("/dashboard");
      }
    })();
  }, [
    isAuthenticated,
    loggedIn,
    pathname,
    user?.onboarded,
    user?.userType,
    getAccessTokenSilently,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        emails: user.emails,
        name: user.name,
        isCandidate: user.userType?.includes("Candidate"),
        isCompany: user.userType?.includes("Company"),
        isAdmin: user.userType?.includes("Admin"),
        completedOnboarding: user.onboarded,
        cultureComplete: candidate?.cultureComplete,
        interviewComplete: candidate?.interviewComplete,
        profileComplete: candidate?.profileComplete,
        applicationComplete: candidate?.applicationComplete,
        companyName: company?.name,
      });
    } else {
      posthog.reset();
    }
  }, [user, candidate, company, posthog]);

  return undefined;
};

const queryClient = new QueryClient({});

function App() {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <FluentProvider
        theme={theme}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          background: "transparent",
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <Auth0ReduxConnector />
              {/*<Navigation />*/}
              <ErrorDialog />
              <div className="body">
                <Routes>
                  <Route
                    path="/" // TODO: Add home page
                    element={<Preprocess />}
                  />

                  <Route path="/login" element={<Login />} />

                  <Route path="/pre-screen" element={<Preprocess />} />

                  <Route element={<AuthedLayout />}>
                    <Route path="/pre-screen">
                      <Route path="company" element={<Outlet />}>
                        <Route path="two" element={<CompanyPrescreenTwo />} />
                        <Route
                          path="three"
                          element={<CompanyPrescreenThree />}
                        />
                        <Route path="jobs" element={<CompanyPrescreenJobs />} />
                      </Route>

                      <Route path="candidate" element={<Outlet />}>
                        <Route path="two" element={<CandidatePrescreenTwo />} />
                        <Route
                          path="three"
                          element={<CandidatePrescreenQuestionnaire />}
                        />
                      </Route>
                    </Route>

                    <Route element={<DashboardLayout />}>
                      <Route path="/settings" element={<Settings />} />

                      <Route
                        path="/dashboard"
                        element={
                          <Switch
                            company={<CompanyDashboard />}
                            candidate={<CandidateDashboard />}
                          />
                        }
                      />

                      <Route
                        path="/opportunities"
                        element={
                          <Switch
                            company={<CompanyOpportunities />}
                            candidate={<CandidateOpportunities />}
                          />
                        }
                      />

                      <Route
                        path="/profile/edit"
                        element={
                          <PrivateRoute allowedUserTypes={["Candidate"]}>
                            <CandidateProfileEdit />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/company/profile/edit"
                        element={
                          <PrivateRoute allowedUserTypes={["Company"]}>
                            <CompanyProfile mode="edit" />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/team"
                        element={
                          <PrivateRoute allowedUserTypes={["Company"]}>
                            <Team />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/opening/"
                        element={
                          <PrivateRoute allowedUserTypes={["Company"]}>
                            <CompanyOpportunityDashboard />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/opening/create"
                        element={<CreateCompanyOpportunityPage />}
                      />
                      <Route
                        path="/opening/:openingId/"
                        element={<CompanyOpportunity />}
                      />
                      <Route
                        path="/opening/:openingId/edit"
                        element={<EditCompanyOpportunityPage />}
                      />
                      <Route
                        path="/opening/:openingId/candidate"
                        element={<OpportunityPage />}
                      />

                      <Route
                        path="/admin/*"
                        element={
                          <PrivateRoute>
                            <ReactAdmin />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/new-admin/*"
                        element={
                          <PrivateRoute>
                            <AdminLayout />
                          </PrivateRoute>
                        }
                      >
                        <Route index element={<AdminDashboard />} />
                        <Route path="dashboard" element={<AdminDashboard />} />
                        <Route
                          path="onboarding"
                          element={<CandidatePipeline />}
                        />
                        <Route
                          path="candidates"
                          element={<ApprovedCandidates />}
                        />
                        <Route path="company" element={<Companies />} />
                        <Route path="job" element={<JobPipeline />} />
                        <Route path="job/:openingId" element={<JobDetails />} />
                      </Route>
                    </Route>
                  </Route>

                  <Route element={<DashboardLayout />}>
                    <Route
                      path="/opening/:openingId/application/:candidateStatusId"
                      element={<CandidateProfile />}
                    />
                    <Route
                      path="/company/:companyId"
                      element={<CompanyProfile mode="view" />}
                    />
                    <Route
                      path="/candidate/:candidateId/edit"
                      element={
                        <PrivateRoute
                          extendAllowedUserIdsWithParamList={["candidateId"]}
                        >
                          <CandidateProfileEdit />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/company/:companyId/edit"
                      element={
                        <PrivateRoute allowedUserTypes={["Company"]}>
                          <CompanyProfile mode="edit" />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/candidate-interview"
                      element={
                        <PrivateRoute allowedUserTypes={["Candidate"]}>
                          <CandidateInterview />
                        </PrivateRoute>
                      }
                    ></Route>
                  </Route>

                  <Route path="/claim/:companyId" element={<ClaimProfile />} />
                  <Route
                    path="/company/:companyId/claim"
                    element={<ClaimProfile />}
                  />

                  <Route
                    path="/company/:companyId/join"
                    element={<JoinTeam />}
                  />

                  <Route
                    path="/landing"
                    element={
                      <PrivateRoute allowedUserTypes={["Candidate", "Company"]}>
                        <Landing />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/onboarding"
                    element={
                      <PrivateRoute allowedUserTypes={["Candidate"]}>
                        <Onboarding />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/opening/"
                    element={
                      <PrivateRoute allowedUserTypes={["Company"]}>
                        <CompanyOpportunityDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/opening/create"
                    element={<CreateCompanyOpportunityPage />}
                  />

                  <Route
                    path="/opening/:openingId/"
                    element={<CompanyOpportunity />}
                  />

                  <Route
                    path="/opening/:openingId/edit"
                    element={<EditCompanyOpportunityPage />}
                  />

                  <Route path="*" element={<Error404Page />} />
                </Routes>
                <Toaster
                  position="top-right"
                  toastOptions={{
                    classNames: {
                      default:
                        "bg-card border border-border text-card-foreground shadow-md",
                      content: "gap-0",
                      title: "text-sm font-semibold",
                      description: "text-xs leading-none text-muted-foreground",
                    },
                  }}
                  icons={{
                    success: <CircleCheck className="size-5 text-secondary" />,
                    info: <Info className="size-5 text-secondary" />,
                    loading: (
                      <LoaderCircle className="size-5 text-secondary animate-spin" />
                    ),
                    error: <CircleX className="size-5 text-destructive" />,
                    warning: (
                      <TriangleAlert className="size-5 text-yellow-600" />
                    ),
                  }}
                  offset={{
                    top: 80,
                    right: 12,
                  }}
                />
              </div>
              {/*<Footer />*/}
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </QueryClientProvider>
      </FluentProvider>
    </CacheBuster>
  );
}

const AuthedLayout = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { profileLoading, profileError } = useProfileQuery();

  if (!isLoading && !isAuthenticated) {
    return <Navigate to="/" />;
  } else if (profileLoading) {
    return <LoaderPage />;
  } else if (profileError) {
    return <div>Error...</div>;
  }

  return <Outlet />;
};

const Switch = ({
  candidate,
  company,
  admin,
}: {
  candidate: ReactNode;
  company: ReactNode;
  admin?: ReactNode;
}) => {
  const reqType = useReqType((s) => s.reqType);
  const switchValue = useReqType((s) => s.switch);
  const setSwitch = useReqType((s) => s.setSwitch);

  if (reqType.admin && !admin) {
    if (!switchValue) {
      setSwitch("candidate");
    }

    return switchValue === "candidate" ? candidate : company;
  }

  if (reqType.candidate) {
    return candidate;
  } else if (reqType.company) {
    return company;
  } else if (reqType.admin && admin) {
    return admin;
  } else {
    return <Redirect to="/" />;
  }
};

/*const TypeRestrictedRoute = ({
  allowed,
}: {
  allowed:
    | "candidate"
    | "company"
    | "admin"
    | ("candidate" | "company" | "admin")[];
}) => {
  const reqType = useReqType(s => s.reqType);

  if (
    (Array.isArray(allowed)
      ? allowed.some(a => reqType[a])
      : reqType[allowed]) ||
    reqType.admin
  ) {
    return <Outlet />;
  } else {
    return <Redirect to="/login" />;
  }
  };*/

const LoaderPage = () => {
  return (
    <main className="w-screen h-screen overflow-hidden flex items-center justify-center">
      <LoaderCircle className="size-12 text-accent animate-spin" />
    </main>
  );
};

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return <LoaderPage />;
};

export default App;
