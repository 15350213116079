import { posthog } from "posthog-js";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import StockImage from "@/assets/preprocess_company_information.jpg";

import { PrescreenLayout } from "@/components/prescreen";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Loader2, ArrowRight, AlertTriangle } from "lucide-react";

import request from "@/lib/api/handler";
import { useAppDispatch } from "@/redux/hooks";
import { setUser } from "@/redux/features/authSlice";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";
import { User } from "@/queries/user/profile";

const formSchema = z.object({
  name: z.string(),
  location: z.string(),
  linkedIn: z.preprocess(
    (arg) => {
      if (typeof arg === "string" && arg === "") {
        return undefined;
      } else {
        return arg;
      }
    },
    z
      .string()
      .regex(/^(http(s)?:\/\/)?www\.?linkedin\.com\/.*$/, {
        message: 'A LinkedIn URL should start with "https://www.linkedin.com/"',
      })
      .optional(),
  ),
});

type FormSchema = z.infer<typeof formSchema>;

const CompanyPrescreenThree = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useAppDispatch();
  const invalidate = useInvalidateQuery();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  const { isPending, mutate, error } = useMutation<User, Error, FormSchema>({
    mutationKey: ["pre-screen", "company", "company-information"],
    mutationFn: async (variables) => {
      const token = await getAccessTokenSilently();
      return await request<User>({
        method: "POST",
        path: "/api/company/",
        body: JSON.stringify({
          ...variables,
        }),
        token,
      });
    },
    onSuccess: (result) => {
      navigate("/pre-screen/company/jobs");
      void dispatch(setUser(result));
      posthog.capture("company_basic_info");
      void invalidate(QueryKeys.myProfile);
    },
  });

  return (
    <PrescreenLayout
      title={"Company Information"}
      subtitle={"Next, we need some information about your company"}
      step={"3/3"}
      form={
        <Form {...form}>
          {error && (
            <>
              <div className="w-full flex flex-row items-center gap-4 bg-card border rounded-md p-8">
                <AlertTriangle />
                {error.message}
              </div>
            </>
          )}
          <form className="w-full grid grid-cols-2 gap-4 bg-card border rounded-md p-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Company Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Acme, Inc." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>Company Location</FormLabel>
                  <FormControl>
                    <Input placeholder="New York, NY" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="linkedIn"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Company LinkedIn Profile</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="https://www.linkedin.com/yourcompany"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
          <div className="w-full flex flex-row justify-end">
            <Button
              disabled={!form.formState.isValid || isPending ? true : false}
              onClick={() => mutate(form.getValues())}
            >
              Next: Add A Job
              {isPending ? (
                <Loader2 className="animate-spin" />
              ) : (
                <ArrowRight />
              )}
            </Button>
          </div>
        </Form>
      }
      infoCard={{
        background: StockImage,
        title: "Connect To Leaders",
        subtitle:
          "With an average of 15+ years of experience, cutting hiring timelines by 50% and costs by up to 20% compared to traditional methods.",
        footer: true,
      }}
    />
  );
};

export default CompanyPrescreenThree;
