import { useMemo } from "react";

import { cn } from "@/lib/utils";
import { Avatar } from "@fluentui/react-components";
import { ChevronsRight, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";

import { AdminPipelineCandidate } from "@/queries/admin/candidate-pipeline";
import { useSetCandidateStage } from "@/mutations/admin/set-candidate-stage";
import { CandidateWaitingRoomStatus } from "@/types";

const CandidateKanban = ({
  candidates,
  setSelectedCandidate,
}: {
  candidates?: AdminPipelineCandidate[];
  setSelectedCandidate: (v: AdminPipelineCandidate | undefined) => void;
}) => {
  return (
    <div className="flex flex-row gap-2 self-stretch overflow-auto">
      <PipelineStageColumn
        stage="New"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="Invited to Apply"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="Application Under Review"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="Interviewing"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="Interview Under Review"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="Taking/Sent MAP"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
      <PipelineStageColumn
        stage="MAP Under Review"
        candidates={candidates}
        setSelectedCandidate={setSelectedCandidate}
      />
    </div>
  );
};

const PipelineStageColumn = ({
  stage,
  candidates,
  setSelectedCandidate,
}: {
  stage: CandidateWaitingRoomStatus;
  candidates?: AdminPipelineCandidate[];
  setSelectedCandidate: (v: AdminPipelineCandidate | undefined) => void;
}) => {
  const filteredCandidates = useMemo(
    () => candidates?.filter((candidate) => candidate.stage === stage),
    [stage, candidates],
  );

  let dotColor: string, backgroundColor: string, textColor: string;
  switch (stage) {
    case "New":
      backgroundColor = "bg-red-100";
      textColor = "text-red-600";
      dotColor = "bg-red-600";
      break;
    case "Invited to Apply":
      backgroundColor = "bg-pink-100";
      textColor = "text-pink-600";
      dotColor = "bg-pink-600";
      break;
    case "Application Under Review":
      backgroundColor = "bg-orange-100";
      textColor = "text-orange-600";
      dotColor = "bg-orange-600";
      break;
    case "Interviewing":
      backgroundColor = "bg-blue-100";
      textColor = "text-blue-600";
      dotColor = "bg-blue-600";
      break;
    case "Interview Under Review":
      backgroundColor = "bg-purple-100";
      textColor = "text-purple-600";
      dotColor = "bg-purple-600";
      break;
    case "Taking/Sent MAP":
      backgroundColor = "bg-yellow-100";
      textColor = "text-yellow-600";
      dotColor = "bg-yellow-600";
      break;
    case "MAP Under Review":
      backgroundColor = "bg-green-100";
      textColor = "text-green-600";
      dotColor = "bg-green-600";
      break;
    default:
      backgroundColor = "bg-violet-100";
      textColor = "text-violet-600";
      dotColor = "bg-violet-600";
  }

  return (
    <div className="min-w-72 p-1 flex-1 flex flex-col gap-2 bg-[#f9f9fb] border border-[#e9eaeb] rounded-[10px]">
      <div className="h-10 py-[21px] px-4 flex flex-row gap-2 self-stretch items-center">
        <div className="flex-1 flex items-center gap-2">
          <div className={cn("h-2.5 w-2.5 rounded-full", dotColor)} />
          <p className="text-base text-[#0c0d0d] font-semibold text-nowrap">
            {stage}
          </p>
          {Boolean(filteredCandidates?.length) && (
            <div className="p-2 min-h-2.5 min-w-2.5 flex justify-center align-middle rounded shadow-[0px_3px_12px_0px_rgba(28,28,28,0.20)]">
              <span className="text-xs text-[#0c0d0d] font-semibold tracking-[-0.24px]">
                {filteredCandidates?.length}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-1 self-stretch">
        {filteredCandidates?.map((candidate) => (
          <PipelineCandidateTile
            key={`candidate-${candidate.candidateId}`}
            candidate={candidate}
            selectCandidate={() => setSelectedCandidate(candidate)}
            textColor={textColor}
            backgroundColor={backgroundColor}
          />
        ))}
      </div>
    </div>
  );
};

const PipelineCandidateTile = ({
  candidate,
  backgroundColor,
  textColor,
  selectCandidate,
}: {
  candidate: AdminPipelineCandidate;
  backgroundColor: string;
  textColor: string;
  selectCandidate: () => void;
}) => {
  const { setCandidateStage, isSettingCandidateStage } = useSetCandidateStage();

  return (
    <div className="p-4 flex flex-col items-start self-stretch gap-1 bg-white border border-[#e9eaeb] rounded-[10px]">
      <div className="flex flex-row items-center justify-between self-stretch">
        <div className="flex flex-row items-center gap-1">
          <Avatar
            image={{ src: candidate.profilePictureLink }}
            name={candidate.name}
            size={24}
          />
          <p className="text-sm text-[#0c0d0d] font-semibold leading-6">
            {candidate.name}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <div
            className={cn(
              "py-1 px-2.5 flex flex-row items-center gap-2 rounded-full",
              backgroundColor,
            )}
          >
            <p
              className={cn(
                "text-xs font-semibold tracking-[-0.24px]",
                textColor,
              )}
            >
              {candidate.stage}
            </p>
          </div>
          <EllipsisVertical
            className="cursor-pointer"
            size={16}
            stroke="#B1B9B8"
            onClick={selectCandidate}
          />
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch gap-1">
        <PipelineCandidateInfo title="Primary Role" value={candidate.role} />
        <PipelineCandidateInfo
          title="Years Experience"
          value={candidate.yearsOfExperience}
        />
        <PipelineCandidateInfo
          title="Years HC Experience"
          value={candidate.yearsOfHealthcareExperience}
        />
        <PipelineCandidateInfo
          title="Profile"
          link={`/candidate/${candidate.userId}/edit`}
        />
        <PipelineCandidateInfo title="LinkedIn" link={candidate.linkedIn} />
        <PipelineCandidateInfo title="Resume" link={candidate.resumeLink} />
        <PipelineCandidateInfo
          title="Attio"
          link={
            candidate.attioId
              ? `https://app.attio.com/venturous/person/${candidate.attioId}/activity`
              : undefined
          }
        />
      </div>
      <div className="flex flex-row justify-between self-stretch gap-1.5">
        <Button
          variant="destructive"
          size="sm"
          disabled={isSettingCandidateStage}
          onClick={() =>
            setCandidateStage({
              candidateId: candidate.candidateId,
              stage: "Rejected",
            })
          }
        >
          Decline
        </Button>
        <Button
          variant="secondary"
          size="sm"
          disabled={isSettingCandidateStage}
          onClick={() =>
            setCandidateStage({
              candidateId: candidate.candidateId,
              currentStage: candidate.stage,
            })
          }
        >
          Advance <ChevronsRight />
        </Button>
      </div>
    </div>
  );
};

const PipelineCandidateInfo = ({
  title,
  value,
  link,
}: {
  title:
    | "Primary Role"
    | "Years Experience"
    | "Years HC Experience"
    | "Profile"
    | "LinkedIn"
    | "Resume"
    | "Attio";
  value?: string | number;
  link?: string;
}) => {
  if (
    !link &&
    (title === "Profile" ||
      title === "LinkedIn" ||
      title === "Resume" ||
      title === "Attio")
  ) {
    return;
  }

  return (
    <div className="flex flex-col items-start self-stretch gap-2 border-b last:border-b-0 border-[rgba(24,24,27,0.12)]">
      <div className="pt-0.5 px-1.5 flex flex-row items-center justify-between self-stretch">
        <div className="flex flex-row items-center self-stretch gap-1">
          <p className="text-[10px] text-[#141515] font-medium">{title}</p>
        </div>
        {title === "Profile" ||
        title === "LinkedIn" ||
        title === "Resume" ||
        title === "Attio" ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        ) : (
          <p className="text-xs text-[#5c6060] font-medium">
            {value ?? "Unknown"}
          </p>
        )}
      </div>
    </div>
  );
};

export default CandidateKanban;
