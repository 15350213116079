import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  SelectArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { SelectArrayField } from "./CustomFields";
import { resourceAudienceList } from "../../types";

const audienceChoices = resourceAudienceList.map((type) => ({
  id: type,
  name: type,
}));

const filters = [
  <TextInput key="headline" source="headline" />,
  <TextInput key="summary" source="summary" />,
  <TextInput key="image" source="image" />,
  <TextInput key="link" source="link" />,
  <SelectArrayInput
    key="audience"
    source="audience"
    choices={audienceChoices}
  />,
];

export const ResourcesList = () => (
  <List filters={filters}>
    <Datagrid>
      <TextField source="headline" />
      <TextField source="summary" />
      <TextField source="image" />
      <TextField source="link" />
      <SelectArrayField source="audience" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ResourcesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="headline" />
      <TextField source="summary" />
      <TextField source="image" />
      <TextField source="link" />
      <SelectArrayField source="audience" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const ResourcesCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="headline" />
      <TextInput source="summary" />
      <TextInput source="image" />
      <TextInput source="link" />
      <SelectArrayInput source="audience" choices={audienceChoices} />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const ResourcesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="headline" />
      <TextInput source="summary" />
      <TextInput source="image" />
      <TextInput source="link" />
      <SelectArrayInput source="audience" choices={audienceChoices} />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
