import { User } from "@/queries/user/profile";
import { create } from "zustand";

export type ReqType = "candidate" | "company" | "admin";

interface ReqTypeStore {
  reqType: {
    candidate: boolean;
    company: boolean;
    admin: boolean;
  };
  switch: "candidate" | "company" | null;
  setSwitch: (type: "candidate" | "company" | null) => void;
  setReqType: (user: User) => void;
}

const getExistingSwitchValue = () => {
  const switchValue = localStorage.getItem("switch");
  if (switchValue === "candidate" || switchValue === "company") {
    return switchValue;
  }

  return null;
};

export const useReqType = create<ReqTypeStore>((set, get) => ({
  reqType: {
    candidate: false,
    company: false,
    admin: false,
  },
  switch: getExistingSwitchValue(),
  setSwitch(type) {
    if (!get().reqType.admin) {
      set({ switch: null });

      if (get().reqType.candidate && get().reqType.company) {
        if (type === "candidate") {
          set({ reqType: { ...get().reqType, company: false } });
        } else {
          set({ reqType: { ...get().reqType, candidate: false } });
        }
      }
    }

    set({ switch: type });
    if (type === null) {
      localStorage.removeItem("switch");
    } else {
      localStorage.setItem("switch", type);
    }
  },
  setReqType: ({ userType }) =>
    set({
      reqType: {
        candidate: userType.includes("Candidate"),
        company: userType.includes("Company"),
        admin: userType.includes("Admin"),
      },
    }),
}));
