"use client";

import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { FormControl } from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface Props<T> {
  range?: boolean;
  value: T;
  onChange: (date: T) => void;
  modal?: boolean;
}

const DatePicker = ({ value, onChange, modal }: Props<Date | undefined>) => {
  return (
    <Popover modal={modal}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full pl-3 text-left font-normal justify-start",
            "hover:bg-background hover:border-secondary",
            "[&_svg.lucide]:stroke-foreground",
            {
              "text-muted-foreground hover:text-muted-foreground": !value,
              "hover:text-foreground": value,
            },
          )}
          wrapperClassName="justify-start w-full"
        >
          <CalendarIcon className="size-4" />
          {value ? format(value, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={value}
          onSelect={onChange}
          disabled={date =>
            date < new Date(new Date().setDate(new Date().getDate() - 14))
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

const DateRangePicker = ({
  value,
  onChange,
  modal,
}: Props<{ from: Date | undefined; to: Date | undefined }>) => {
  return (
    <Popover modal={modal}>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant={"outline"}
            className={cn(
              "w-[240px] pl-3 text-left font-normal",
              !value && "text-muted-foreground",
            )}
          >
            {value?.from ? (
              value.to ? (
                <>
                  {format(value.from, "LLL dd, y")} -{" "}
                  {format(value.to, "LLL dd, y")}
                </>
              ) : (
                format(value.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="range"
          defaultMonth={value.from}
          numberOfMonths={2}
          selected={value}
          onSelect={d =>
            onChange({
              from: d?.from,
              to: d?.to,
            })
          }
          disabled={date => date > new Date() || date < new Date("1900-01-01")}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export { DatePicker, DateRangePicker };
