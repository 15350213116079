import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { IExperience, Role } from "@/redux/features/candidateProfileSlice";
import { CandidateWaitingRoomStatus } from "@/types";

export interface AdminPipelineCandidate {
  id: string;
  userId: string;
  candidateId: string;
  attioId?: string;
  name?: string;
  email: string;
  stage: CandidateWaitingRoomStatus;
  profilePictureLink?: string;
  titleAndEmployer?: string;
  yearsOfExperience?: number;
  yearsOfHealthcareExperience?: number;
  location?: string;
  biography?: string;
  role?: Role;
  linkedIn?: string;
  personalWebsite?: string;
  resumeLink?: string;
  experiences: IExperience[];
  notes?: string;
}

export const useAdminCandidatePipelineQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: adminCandidatePipeline,
    error: adminCandidatePipelineError,
    isLoading: adminCandidatePipelineLoading,
  } = useQuery({
    queryKey: QueryKeys.adminCandidatePipeline,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return await request<AdminPipelineCandidate[]>({
        method: "GET",
        path: "/api/admin/candidate-pipeline",
        token,
      });
    },
  });

  return {
    adminCandidatePipeline,
    adminCandidatePipelineError,
    adminCandidatePipelineLoading,
  };
};
