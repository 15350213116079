import axios, { AxiosError, AxiosHeaders, isAxiosError } from "axios";

const request = async <T>({
  method,
  path,
  body,
  token,
  contentType = "application/json",
}: {
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  path: `/${string}`;
  body?: BodyInit;
  token?: string;
  contentType?:
    | "application/json"
    | "multipart/form-data"
    | "application/x-www-form-urlencoded";
}): Promise<T> => {
  try {
    const headers = new AxiosHeaders();
    headers.setContentType(contentType);

    if (token) {
      headers.setAuthorization(`Bearer ${token}`);
    }

    const response = await axios.request<T>({
      method,
      url: `${path}`,
      headers,
      data: body,
    });

    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error;
    } else if (error instanceof Error) {
      throw new AxiosError(error.message);
    } else {
      throw new AxiosError(
        `An unexpected error occurred for ${method} ${path}`,
      );
    }
  }
};

export default request;
