import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { Loader2 } from "lucide-react";

import {
  useDeclineOpportunity,
  withdrawlReasons,
} from "@/mutations/candidate/opening/decline";
import { MultiSelect } from "@/components/ui/multi-select";

const declineOpportunitySchema = z.object({
  openingId: z.string(),
  candidateStatusId: z.string(),
  declineReasons: z
    .array(
      z
        .string()
        .refine(
          (string) => withdrawlReasons.includes(string),
          "The reason(s) provided are invalid.",
        ),
    )
    .nonempty(),
  otherDeclineReason: z.string().optional(),
});

export const DeclineOpportunityDialog = ({
  openingId,
  candidateStatusId,
  button,
}: {
  openingId: string;
  candidateStatusId: string;
  button?: React.ReactNode;
}) => {
  const form = useForm<z.infer<typeof declineOpportunitySchema>>({
    resolver: zodResolver(declineOpportunitySchema),
    mode: "onChange",
    defaultValues: {
      openingId,
      candidateStatusId,
      declineReasons: [],
      otherDeclineReason: "",
    },
  });

  const { declineOpportunity, isDeclining } = useDeclineOpportunity();

  const [open, setOpen] = useState<boolean>(false);

  function onSubmit() {
    if (isDeclining) return;
    void form.handleSubmit((data) => declineOpportunity(data))();
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {button ? button : <Button>Decline</Button>}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Decline Opportunity</DialogTitle>
          <DialogDescription>
            Please let us know why you are declining this opportunity below!
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="w-full flex flex-col gap-4">
            <FormField
              control={form.control}
              name="declineReasons"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reasons</FormLabel>
                  <FormControl>
                    <MultiSelect
                      values={field.value}
                      setValues={field.onChange}
                      options={withdrawlReasons}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="otherDeclineReason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Other Reason(s)</FormLabel>
                  <FormControl>
                    <Textarea {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogFooter>
          <Button variant="ghost" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={isDeclining || !form.formState.isValid}
            variant="secondary"
            onClick={onSubmit}
          >
            {isDeclining && <Loader2 className="animate-spin" />}
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
