import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import {
  AdminJobCandidateDetails,
  AdminJobDetails,
} from "@/queries/admin/job-details";
import { CandidateApplicationStatus } from "@/types";

type SetCandidateOpportunityStatusParams =
  | {
      statusId: string;
      status?: CandidateApplicationStatus;
      currentStatus: CandidateApplicationStatus;
    }
  | {
      statusId: string;
      status: CandidateApplicationStatus;
      currentStatus?: CandidateApplicationStatus;
    };

const NEXT_STATUS_MAP: Record<
  CandidateApplicationStatus,
  CandidateApplicationStatus
> = {
  Identified: "Checking Interest",
  "Checking Interest": "Screening",
  Screening: "Possible Fit",
  "Possible Fit": "Shortlisted",
  Shortlisted: "Making Offer",
  "Making Offer": "Accepted by Client",
  "Accepted by Client": "Accepted by Client",
  "Rejected for Role": "Rejected for Role",
  "Not Interested": "Not Interested",
};

export const useSetCandidateOpportunityStatus = (openingId: string) => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      statusId,
      status,
      currentStatus,
    }: SetCandidateOpportunityStatusParams) => {
      const token = await getAccessTokenSilently();

      return request<AdminJobCandidateDetails>({
        method: "PATCH",
        path: `/api/admin/candidate_status/${statusId}`,
        token,
        body: JSON.stringify({
          status: status ?? NEXT_STATUS_MAP[currentStatus],
        }),
      });
    },
    onSuccess: (data) => {
      // Fetch any existing data
      const prev = qc.getQueryData<AdminJobDetails>(
        QueryKeys.adminJobDetails(openingId),
      );

      // If there is existing data, update it
      if (prev) {
        // Make a copy and check find the updated element
        const candidates = [...prev.candidates];
        const candidateIndex = candidates.findIndex(
          (candidate) => candidate.candidateId === data.candidateId,
        );

        // If the element is found
        if (candidateIndex !== -1 && candidates[candidateIndex]) {
          // Update it
          candidates[candidateIndex] = data;

          qc.setQueryData(QueryKeys.adminJobDetails(openingId), {
            ...prev,
            candidates,
          });
        } else {
          // If the entry couldn't be found, invalidate and fetch new data
          void qc.invalidateQueries({
            queryKey: QueryKeys.adminJobDetails(openingId),
          });
        }
      }
    },
  });

  return {
    setCandidateOpportunityStatus: mutate,
    isSettingCandidateOpportunityStatus: isPending,
    setCandidateOpportunityStatusError: error,
  };
};
