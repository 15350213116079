import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Avatar, Spinner } from "@fluentui/react-components";
import {
  EllipsisVertical,
  LayoutList,
  Loader2,
  Search,
  SquareKanban,
} from "lucide-react";

import {
  AdminJobCandidateDetails,
  useAdminJobDetailsQuery,
} from "@/queries/admin/job-details";
import { useGetNewCandidateMatches } from "@/mutations/admin/get-new-candidate-matches";
import { useGenerateVjo } from "@/mutations/admin/generate-vjo";

import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

import ReadMoreSection from "@/elements/ReadMore";
import CandidateKanban from "./candidate-kanban";
import CandidateTable from "./candidate-table";
import CandidateDetailsDrawer from "./candidate-drawer";
import JobDetailsDrawer from "./job-drawer";

const JobDetails = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCandidate, setSelectedCandidate] =
    useState<AdminJobCandidateDetails>();
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState<"kanban" | "table">("kanban");

  const { openingId } = useParams();

  const { adminJobDetails: job, adminJobDetailsLoading } =
    useAdminJobDetailsQuery(openingId);
  const { getNewCandidateMatches, isGettingNewCandidateMatches } =
    useGetNewCandidateMatches(openingId);
  const { generateVjo, isGeneratingVjo } = useGenerateVjo(openingId ?? "");

  const candidates = useMemo(() => {
    if (searchString) {
      const filterString = searchString.toLocaleLowerCase();

      /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
      return job?.candidates?.filter((candidate) =>
        candidate.name?.toLocaleLowerCase().includes(filterString),
      );
      /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
    } else if (job?.candidates) {
      return [...job.candidates];
    }
  }, [job, searchString]);

  const { textColor, backgroundColor } = useMemo(() => {
    let backgroundColor: string, textColor: string;
    switch (job?.status) {
      case "New Opening":
        backgroundColor = "bg-red-100";
        textColor = "text-red-600";
        break;
      case "Creating Job Spec":
        backgroundColor = "bg-pink-100";
        textColor = "text-pink-600";
        break;
      case "Identifying Candidates":
        backgroundColor = "bg-orange-100";
        textColor = "text-orange-600";
        break;
      case "Presenting Shortlist":
        backgroundColor = "bg-blue-100";
        textColor = "text-blue-600";
        break;
      default:
        backgroundColor = "bg-violet-100";
        textColor = "text-violet-600";
    }

    return { textColor, backgroundColor };
  }, [job?.status]);

  if (adminJobDetailsLoading || !job || !openingId) {
    return (
      <div>
        <p className="py-4 text-lg text-[#181d27] font-semibold">Jobs</p>
        <div className="w-full flex-col justify-around align-middle">
          <Spinner size="huge" />
          <p className="text-center text-base font-medium text-[#727E94]">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="py-4 w-full flex flex-col gap-4 items-start self-stretch">
        <div className="pl-3 flex flex-col self-stretch justify-center">
          <div className="flex self-stretch items-center justify-between gap-2">
            <div className="flex self-stretch items-center gap-2">
              <Avatar
                image={{ src: job.company.logoLink }}
                name={job.company.name}
                className="h-[44px] w-[44px]"
                size={48}
              />
              <p className="text-lg text-[#181D27] font-semibold leading-6">
                {job.role}
              </p>
              <span className="px-1 text-xs text-[#71948E] bg-[#F9FBFB] border border-[#CAD3D2] rounded-3xl">
                {job.candidateCount}
              </span>
              <div
                className={cn(
                  "py-1 px-2.5 flex flex-row items-center gap-2 rounded-full",
                  backgroundColor,
                )}
              >
                <p
                  className={cn(
                    "text-xs font-semibold tracking-[-0.24px]",
                    textColor,
                  )}
                >
                  {job.status}
                </p>
              </div>
            </div>
            <EllipsisVertical
              className="cursor-pointer"
              onClick={() => setShowDetails(true)}
            />
          </div>
          <div className="md:pl-14 flex flex-col">
            <div className="flex flex-col md:flex-row self-stretch gap-3">
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Time</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.scope ?? "Not Specified"}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Pay Rate</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.budget ?? "Not Specified"}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Start Date</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.desiredStartDate
                    ? dateStringToLocalDate(
                        job.desiredStartDate,
                      )?.toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    : "Not Specified"}
                </span>
              </div>
            </div>
            <ReadMoreSection
              maxLines={3}
              textClassName="text-[#5C6060]"
              readMoreClassName="font-semibold text-[#6941C6]"
            >
              {job.aIVjoDescription}
            </ReadMoreSection>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mnd:items-center justify-between self-stretch gap-2">
          <div className="flex flex-row items-center gap-4">
            <p className="text-lg text-[#181d27] font-semibold">Candidates</p>
            {job.aIVjoDescription ? (
              <Button
                disabled={isGettingNewCandidateMatches || isGeneratingVjo}
                variant="secondary"
                onClick={() => getNewCandidateMatches()}
              >
                {isGettingNewCandidateMatches ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Generate Matches
              </Button>
            ) : (
              <Button
                disabled={
                  !job.jobDescriptionLink ||
                  isGettingNewCandidateMatches ||
                  isGeneratingVjo
                }
                variant="secondary"
                onClick={() => generateVjo()}
              >
                {isGeneratingVjo ? <Loader2 className="animate-spin" /> : null}
                Generate VJO
              </Button>
            )}
          </div>
          <div className="flex flex-row items-center gap-3">
            <div
              className={cn(
                "flex flex-row items-center gap-0.5",
                "border-b border-[#57576B] transition-colors focus-within:border-black",
              )}
            >
              <Search />
              <input
                type="text"
                className={cn(
                  "flex h-10 w-full min-w-48 px-3 py-2 text-base",
                  "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
                )}
                placeholder="Search for a candidate"
                value={searchString}
                onChange={(ev) => {
                  ev.preventDefault();
                  setSearchString(ev.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-start bg-[#F9F9FB] rounded-lg">
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "kanban" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("kanban")}
              >
                <SquareKanban />
              </div>
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "table" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("table")}
              >
                <LayoutList />
              </div>
            </div>
          </div>
        </div>
        {view === "kanban" && (
          <CandidateKanban
            openingId={openingId}
            candidates={candidates}
            setSelectedCandidate={setSelectedCandidate}
          />
        )}
        {view === "table" && (
          <CandidateTable
            openingId={openingId}
            candidates={candidates}
            setSelectedCandidate={setSelectedCandidate}
          />
        )}
      </div>
      <CandidateDetailsDrawer
        job={job}
        candidate={selectedCandidate}
        setOpen={() => setSelectedCandidate(undefined)}
      />
      <JobDetailsDrawer
        job={job}
        open={showDetails}
        setOpen={() => setShowDetails(false)}
      />
    </div>
  );
};

export default JobDetails;
