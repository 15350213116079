import { posthog } from "posthog-js";
import { Widget } from "@typeform/embed-react";

import PreprocessIcon from "@/assets/preprocess_icon.svg";
import SwirlArrow from "@/assets/swirl_arrow.svg";
import { Edit, FileText, Sparkles, User, Users } from "lucide-react";

import { useProfileQuery } from "@/queries/user/profile";
import { useCompleteOnboarding } from "@/mutations/candidate/onboarding/complete";

const CandidatePrescreenQuestionnaire = () => {
  const { profile, profileLoading } = useProfileQuery();
  const { completeOnboarding } = useCompleteOnboarding();

  if (!profile || profileLoading) {
    return;
  }

  return (
    <div className="absolute top-0 left-0 bg-background w-screen h-screen flex flex-col md:flex-row gap-16 md:items-center md:justify-center p-8 z-10 overflow-y-auto">
      <div className="w-full flex flex-col gap-4">
        <div className="w-full flex flex-row items-center gap-4 border-b pb-4">
          <img src={PreprocessIcon} alt="Preprocess Icon" className="" />
          <h2 className="text-3xl text-foreground font-bold">
            Complete Your Application
          </h2>
          <div className="ml-auto rounded-md flex flex-row items-center gap-2 bg-border py-1 px-3">
            &lt; 5 mins
          </div>
        </div>
        <div className="flex flex-row items-center gap-2">
          <h3 className="text-lg font-semibold">
            Here&apos;s what to expect next
          </h3>
        </div>
        <div className="w-full flex flex-col">
          <div className="relative w-full flex flex-row gap-4 border border-secondary bg-background rounded-lg p-6 items-center">
            <div className="size-12 p-3 rounded-md bg-gradient-to-br from-accent to-secondary border border-secondary">
              <User className="size-full text-secondary-foreground" />
            </div>
            <div className="w-full flex flex-col">
              <h4 className="text-md font-semibold">Complete Questionnaire</h4>
              <p className="text-sm">
                Tell us more about you so we can create a better match
              </p>
            </div>
            <img
              className="absolute bottom-[1rem] right-[-3rem] object-contain"
              src={SwirlArrow}
              alt="Arrow with swirl"
            />
          </div>
          <div className="relative w-full flex flex-row gap-4 p-6 items-center">
            <div className="absolute left-[calc(3rem-1px)] w-[2px] h-full bg-border z-0" />
            <div className="relative size-12 p-3 bg-background rounded-lg border z-10">
              <Users className="size-full text-muted-foreground" />
            </div>
            <div className="w-full flex flex-col">
              <h4 className="text-md font-semibold">Candidate Interview</h4>
              <p className="text-sm">
                Schedule an interview with our Talent Coordinator
              </p>
            </div>
          </div>
          <div className="relative w-full flex flex-row gap-4 p-6 items-center">
            <div className="absolute left-[calc(3rem-1px)] w-[2px] h-full bg-border z-0" />
            <div className="relative size-12 p-3 bg-background rounded-lg border z-10">
              <Edit className="size-full text-muted-foreground" />
            </div>
            <div className="w-full flex flex-col">
              <h4 className="text-md font-semibold">Complete Profile</h4>
              <p className="text-sm">Tell us more about you in your profile</p>
            </div>
          </div>
          <div className="relative w-full flex flex-row gap-4 p-6 items-center">
            <div className="absolute left-[calc(3rem-1px)] w-[2px] h-full bg-border z-0" />
            <div className="relative size-12 p-3 bg-background rounded-lg border z-10">
              <FileText className="size-full text-muted-foreground" />
            </div>
            <div className="w-full flex flex-col">
              <h4 className="text-md font-semibold">Cultural Fit Evaluation</h4>
              <p className="text-sm">Complete a Cultural Assessment</p>
            </div>
          </div>
          <div className="relative w-full flex flex-row gap-4 p-6 items-center">
            <div className="absolute left-[calc(3rem-1px)] top-0 w-[2px] h-[50%] bg-border z-0" />
            <div className="relative size-12 p-3 bg-background rounded-lg border z-10">
              <Sparkles className="size-full text-muted-foreground" />
            </div>
            <div className="w-full flex flex-col">
              <h4 className="text-md font-semibold">Ready For Opportunities</h4>
              <p className="text-sm">Get matched with companies</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full rounded-lg flex flex-col gap-8 items-center justify-center bg-gradient-to-br from-accent to-secondary-dark to-40% text-center p-12 border-2 border-secondary">
        <Widget
          id="G7vw11AM"
          hidden={{
            user_id: profile.id,
            name: profile.name ?? "",
            email: profile.emails[0] ?? "",
            is_dev: (import.meta.env.VITE_IS_PROD_BUILD !== "true").toString(),
          }}
          className="w-full h-full bg-transparent"
          onSubmit={() => {
            posthog.capture("candidate_typeform");
            completeOnboarding();
          }}
        />
      </div>
    </div>
  );
};

export default CandidatePrescreenQuestionnaire;
