import { useState } from "react";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useMyCandidateQuery } from "@/queries/candidate/my-candidate";

export const CommunitySection = () => {
  const [open, setOpen] = useState(true);

  const { candidate } = useMyCandidateQuery();

  if (!candidate?.applicationComplete) {
    return;
  }

  return (
    <div
      className={`w-full ${open ? "flex" : "hidden"} flex-col lg:flex-row bg-[#563880] rounded-xl mt-4 p-6 gap-8 items-center`}
    >
      <div className="w-[-webkit-fill-available] flex flex-col">
        <div className="flex justify-between">
          <h3 className="text-lg font-semibold text-white">
            Join Our Community
          </h3>
          <X
            className="size-4 min-w-4 hover:cursor-pointer text-white hover:opacity-80"
            onClick={() => setOpen(false)}
          />
        </div>

        <p className="text-md font-medium text-white xl:pr-64">
          Join <strong>Venturous on Circle</strong>, our exclusive community for
          top fractional executives, where you can{" "}
          <strong>
            network, share insights, and access exclusive learning opportunities
          </strong>
          . Be part of a{" "}
          <strong>
            curated group of leaders shaping the future of work—connect, learn,
            and grow with us!
          </strong>
        </p>
        <div className="flex flex-row gap-4 mt-4">
          <Button
            variant="outline"
            onClick={() =>
              window.open("https://bit.ly/CircleVenturous", "_blank")
            }
          >
            Connect with the community
          </Button>
        </div>
      </div>
    </div>
  );
};
