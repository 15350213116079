import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { Button as InternalButton } from "../elements/Button";
import { getOpenings } from "../redux/features/companyOpeningSlice";
import { NewOpportunityTile } from "../elements/OpportunityTile";
import { CompanyJobOpening } from "../types";

import "@/styles.css";

const CompanyOpportunityDashboard = () => {
  const [dataRequested, setDataRequested] = useState(false);

  const jobOpenings = useAppSelector(state => state.companyOpening.openings);
  const loadingStatus = useAppSelector(state => state.companyOpening.status);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const loaded = dataRequested && loadingStatus === "succeeded";

  useEffect(() => {
    const controller = new AbortController();

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(
        getOpenings({
          token,
          signal: controller.signal,
        }),
      );
      setDataRequested(true);
    })();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Venturous Job Opportunities";
  }, []);

  const newOpenings = useMemo(
    () =>
      jobOpenings.filter(
        ({ status }) =>
          status !== "Role Filled (lost)" && status !== "Role Filled (won)",
      ),
    [jobOpenings],
  );

  const closedOpenings = useMemo(
    () =>
      jobOpenings.filter(
        ({ status }) =>
          status === "Role Filled (lost)" || status === "Role Filled (won)",
      ),
    [jobOpenings],
  );

  return (
    <>
      <div className="flex flex-col gap-4 py-4">
        <NewOpportunities openings={newOpenings} loaded={loaded} />
        <ClosedOpportunities openings={closedOpenings} loaded={loaded} />
      </div>
    </>
  );
};

const NewOpportunities = ({
  openings,
  loaded,
}: {
  openings: CompanyJobOpening[];
  loaded: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-[#22234A]">
          New Job Opportunities
        </h2>
        <InternalButton
          type="primary"
          onClick={() => navigate("/opening/create")}
        >
          Add Job Opportunity
        </InternalButton>
      </div>
      {!loaded ? (
        <NewOpportunityTile />
      ) : openings.length ? (
        openings.map(opening => (
          <NewOpportunityTile key={`opening-${opening.id}`} opening={opening} />
        ))
      ) : (
        <div className="card flex-col gap-4 w-full">
          <p className="text-base font-medium text-[#727E94]">
            You have no open job opportunities at this time, consider adding
            one!
          </p>
          <p className="text-base font-medium text-[#727E94]">
            Venturous helps place board members, advisors, and fractional or
            full-time executives.
          </p>
        </div>
      )}
    </div>
  );
};

const ClosedOpportunities = ({
  openings,
  loaded,
}: {
  openings: CompanyJobOpening[];
  loaded: boolean;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg font-semibold text-[#22234A]">
        Closed Job Opportunities
      </h2>
      {!loaded ? (
        <ClosedOpportunityTile />
      ) : openings.length ? (
        openings.map(opening => (
          <ClosedOpportunityTile
            key={`opening-${opening.id}`}
            opening={opening}
          />
        ))
      ) : (
        <div className="card flex-col gap-4 w-full">
          <p className="text-base font-medium text-[#727E94]">
            You haven&apos;t filled any job opportunities with Venturous yet.
          </p>
        </div>
      )}
    </div>
  );
};

const ClosedOpportunityTile = ({
  opening,
}: {
  opening?: CompanyJobOpening;
}) => {
  const navigate = useNavigate();

  if (!opening) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center">
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-base font-semibold text-[#22234A]">
            {opening?.role}
          </p>
        </div>
        <Button
          appearance="secondary"
          shape="circular"
          onClick={() => navigate(`/opening/${opening.id}`)}
        >
          <p className="text-sm font-semibold text-[#4E4F6C]">See Details</p>
        </Button>
      </div>
    </div>
  );
};

export default CompanyOpportunityDashboard;
