import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { useProfileQuery } from "../user/profile";
import { CompanyJobOpening } from "@/types";
import request from "@/lib/api/handler";

export const useCompanyOpportunitiesQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfileQuery();

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.companyOpportunities,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!profile?.companyAccounts.length,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return request<CompanyJobOpening[]>({
        method: "GET",
        path: "/api/jobOpening?candidate=true",
        token,
      });
    },
  });

  return {
    opportunitiesLoading: isLoading,
    opportunities: data ?? null,
    opportunitiesError: error ?? null,
  };
};

export const useCompanyOpportunityQuery = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.companyOpportunity(id ?? ""),
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return request<CompanyJobOpening>({
        method: "GET",
        token,
        path: `/api/jobOpening/${id}?candidates=true&interviews=true`,
      });
    },
    enabled: !!id,
  });

  return {
    opportunityLoading: isLoading,
    opportunityData: data ?? null,
    opportunityError: error ?? null,
  };
};
