import { getCurrentTimezone } from "@/lib/timezone";
import { useShallow } from "zustand/shallow";
import { CandidateInterview, CandidateJobOpening } from "@/types";
import { create } from "zustand";
import { AvailabilityDate, AvailabilityTime } from "../../../../lib/datetime";

export interface TimeRange {
  id?: string;
  start: AvailabilityTime;
  end: AvailabilityTime;
}

export type DayAvailabilities = TimeRange[];

export interface SpecificAvailabilities {
  startDate: AvailabilityDate;
  endDate: AvailabilityDate;
  times: TimeRange[];
}

export const emptyWeek = Array.from({ length: 7 }, () => []).map((_, i) =>
  i > 0 && i < 6
    ? [
        {
          start: AvailabilityTime.newPlaceholder(),
          end: AvailabilityTime.newPlaceholder(),
        },
      ]
    : [],
);

interface AvailabilitySheet {
  opportunity: CandidateJobOpening | null;
  setOpportunity: (opportunity: CandidateJobOpening) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  successOpen: boolean;
  setSuccessOpen: (successOpen: boolean) => void;
  timezone: string;
  setTimezone: (timezone: string) => void;
  interview: CandidateInterview | null;
  setInterview: (interview: CandidateInterview) => void;
  // form state
  dayAvailabilities: DayAvailabilities[];
  setDayAvailabilities: (dayAvailabilities: DayAvailabilities[]) => void;
  specificAvailabilities: SpecificAvailabilities[];
  setSpecificAvailabilities: (
    specificAvailabilities: SpecificAvailabilities[],
  ) => void;
  // specific availability popover
  specificOpen: boolean;
  setSpecificOpen: (specificOpen: boolean) => void;
  specificAvailabilitiesForm: {
    selectedDate?: {
      from: AvailabilityDate | undefined;
      to?: AvailabilityDate;
    };
    timeEntries: TimeRange[];
  };
  setSpecificAvailabilitiesForm: (
    callback: (
      data: AvailabilitySheet["specificAvailabilitiesForm"],
    ) => AvailabilitySheet["specificAvailabilitiesForm"],
  ) => void;
  clearSpecificAvailabilitiesForm: () => void;
  confirmSpecificAvailabilities: () => void;
}

export const useAvailabilitySheet = create<AvailabilitySheet>((set, get) => ({
  opportunity: null,
  setOpportunity: opportunity => {
    const open = opportunity === null ? false : true;
    set({ opportunity, open });
  },
  open: false,
  setOpen: open => {
    if (open) {
      set({ open });
      return;
    }

    set({ open });
    // timeout to not have layout shift when closing
    setTimeout(() => {
      get().clearSpecificAvailabilitiesForm();
      set({
        open,
        opportunity: null,
        specificOpen: false,
        specificAvailabilities: [],
        dayAvailabilities: [],
        timezone: getCurrentTimezone() ?? "utc",
      });
    }, 100);
  },
  successOpen: false,
  setSuccessOpen: successOpen => set({ successOpen }),
  timezone: getCurrentTimezone() ?? "utc",
  setTimezone: timezone => {
    const dayAvailabilities = get().dayAvailabilities;
    dayAvailabilities.forEach(day =>
      day.forEach(e => {
        e.start.setOffset(timezone);
        e.end.setOffset(timezone);
      }),
    );

    const specificAvailabilities = get().specificAvailabilities;
    specificAvailabilities.forEach(e =>
      e.times.forEach(t => {
        t.start.setOffset(timezone);
        t.end.setOffset(timezone);
      }),
    );

    set({
      timezone,
      dayAvailabilities,
      specificAvailabilities,
    });
  },
  interview: null,
  setInterview: interview => set({ interview }),
  dayAvailabilities: emptyWeek,
  setDayAvailabilities: dayAvailabilities => {
    set({ dayAvailabilities });
  },
  specificAvailabilities: [],
  setSpecificAvailabilities: specificAvailabilities => {
    specificAvailabilities.sort((a, b) => a.startDate.cmp(b.startDate));
    set({ specificAvailabilities });
  },
  specificOpen: false,
  setSpecificOpen: specificOpen => set({ specificOpen }),
  specificAvailabilitiesForm: {
    selectedDate: undefined,
    timeEntries: [
      {
        start: AvailabilityTime.newPlaceholder(),
        end: AvailabilityTime.newPlaceholder(),
      },
    ],
  },
  setSpecificAvailabilitiesForm: callback => {
    const d = callback(get().specificAvailabilitiesForm);
    d.timeEntries = d.timeEntries.sort((a, b) => a.start.cmp(b.start));
    set({
      specificAvailabilitiesForm: d,
    });
  },
  clearSpecificAvailabilitiesForm: () =>
    set({
      specificAvailabilitiesForm: {
        selectedDate: undefined,
        timeEntries: [
          {
            start: AvailabilityTime.newPlaceholder(),
            end: AvailabilityTime.newPlaceholder(),
          },
        ],
      },
    }),
  confirmSpecificAvailabilities: () => {
    const { selectedDate, timeEntries } = get().specificAvailabilitiesForm;
    if (
      !selectedDate?.to ||
      !selectedDate?.from ||
      !timeEntries.length ||
      timeEntries.find(t => !t.start || !t.end)
    )
      return;

    const specificAvailabilities = [...get().specificAvailabilities];
    specificAvailabilities.push({
      startDate: selectedDate.from,
      endDate: selectedDate.to,
      times: timeEntries,
    });

    set({ specificAvailabilities, specificOpen: false });
    get().clearSpecificAvailabilitiesForm();
  },
}));

export const useAvailabilitySheetState = () =>
  useAvailabilitySheet(
    useShallow(
      s =>
        [
          s.open,
          s.setOpen,
          s.opportunity,
          s.interview,
          s.successOpen,
          s.setSuccessOpen,
          s.setDayAvailabilities,
          s.setSpecificAvailabilities,
        ] as const,
    ),
  );
