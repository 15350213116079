import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { cn } from "@/lib/utils";
import { Spinner } from "@fluentui/react-components";
import { LayoutList, Search, SquareKanban } from "lucide-react";

import {
  AdminPipelineJob,
  useAdminJobPipelineQuery,
} from "@/queries/admin/job-pipeline";
import JobKanban from "./job-kanban";
import JobTable from "./job-table";

const JobPipeline = () => {
  // const [_selectedJob, setSelectedJob] = useState<AdminPipelineJob>();
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState<"kanban" | "table">("kanban");

  const navigate = useNavigate();

  const { adminJobPipeline, adminJobPipelineLoading } =
    useAdminJobPipelineQuery();

  const jobs = useMemo(() => {
    if (searchString) {
      const filterString = searchString.toLocaleLowerCase();

      /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
      return adminJobPipeline?.filter(
        (job) =>
          (job.status === "New Opening" ||
            job.status === "Creating Job Spec" ||
            job.status === "Identifying Candidates" ||
            job.status === "Presenting Shortlist") &&
          (job.role?.toLocaleLowerCase().includes(filterString) ||
            job.company.name?.toLocaleLowerCase().includes(filterString) ||
            job.status.toLocaleLowerCase().includes(filterString)),
      );
      /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
    } else {
      return adminJobPipeline?.filter(
        (job) =>
          job.status === "New Opening" ||
          job.status === "Creating Job Spec" ||
          job.status === "Identifying Candidates" ||
          job.status === "Presenting Shortlist",
      );
    }
  }, [adminJobPipeline, searchString]);

  const setSelectedJob = useCallback(
    (job?: AdminPipelineJob) => job && navigate(`/new-admin/job/${job.id}`),
    [navigate],
  );

  if (adminJobPipelineLoading) {
    return (
      <div>
        <p className="py-4 text-lg text-[#181d27] font-semibold">Jobs</p>
        <div className="w-full flex-col justify-around align-middle">
          <Spinner size="huge" />
          <p className="text-center text-base font-medium text-[#727E94]">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="py-4 w-full flex flex-col gap-4 items-start self-stretch">
        <div className="flex flex-col md:flex-row md:items-center justify-between self-stretch">
          <p className="text-lg text-[#181d27] font-semibold">Jobs</p>
          <div className="flex flex-row items-center gap-3">
            <div
              className={cn(
                "flex flex-row items-center gap-0.5",
                "border-b border-[#57576B] transition-colors focus-within:border-black",
              )}
            >
              <Search />
              <input
                type="text"
                className={cn(
                  "flex h-10 w-full min-w-48 px-3 py-2 text-base",
                  "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
                )}
                placeholder="Search for any job"
                value={searchString}
                onChange={(ev) => {
                  ev.preventDefault();
                  setSearchString(ev.target.value);
                }}
              />
            </div>
            <div className="flex flex-row items-start bg-[#F9F9FB] rounded-lg">
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "kanban" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("kanban")}
              >
                <SquareKanban />
              </div>
              <div
                className={cn(
                  "h-10 w-10 flex items-center justify-center rounded-[10px]",
                  "cursor-pointer hover:bg-white hover:border hover:border-[#E5E5EC] hover:shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                  view === "table" &&
                    "bg-white border border-[#E5E5EC] shadow-[0px_1px_2px_0px_rgba(82,88,102,0.06)]",
                )}
                onClick={() => setView("table")}
              >
                <LayoutList />
              </div>
            </div>
          </div>
        </div>
        {view === "kanban" && (
          <JobKanban jobs={jobs} selectJob={setSelectedJob} />
        )}
        {view === "table" && (
          <JobTable jobs={jobs} selectJob={setSelectedJob} />
        )}
      </div>
    </div>
  );
};

export default JobPipeline;
