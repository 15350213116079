import request from "@/lib/api/handler";
import { CompanyUserAccountType } from "@/redux/features/companyProfileSlice";
import { useReqType } from "@/stores/req-type";
import { UserType } from "@/types";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";

export interface CompanyAccount {
  companyId: string;
  accountType: CompanyUserAccountType;
}

export interface User {
  id: string;
  name?: string;
  emails: string[];
  profilePictureLink?: string;
  onboarded?: boolean;
  userType: UserType[];
  companyAccounts: CompanyAccount[];
  marketingConsent: boolean;
  candidateId?: string;
}

export const useProfileQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const setReqType = useReqType((s) => s.setReqType);
  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = useQuery({
    queryKey: ["myProfile"],
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      const user = await request<User>({
        method: "GET",
        path: "/api/user/getUser",
        token,
      });

      setReqType(user);
      return user;
    },
  });

  return { profile, profileError, profileLoading };
};
