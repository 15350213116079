import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";

interface MakeOfferToCandidateParams {
  openingId: string;
  candidateStatusId: string;
}

export const useMakeOfferToCandidate = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      openingId,
      candidateStatusId,
    }: MakeOfferToCandidateParams) => {
      const token = await getAccessTokenSilently();

      await request({
        method: "POST",
        path: `/api/jobOpening/${openingId}/candidate/${candidateStatusId}/makeOffer`,
        token,
      });
    },
    onSettled: async (_, __, { openingId }) => {
      await qc.invalidateQueries({
        queryKey: QueryKeys.companyOpportunities,
      });

      await qc.invalidateQueries({
        queryKey: QueryKeys.companyOpportunity(openingId),
      });
    },
  });

  return {
    makeOfferToCandidate: mutate,
    isOffering: isPending,
    offerError: error,
  };
};
