import request from "@/lib/api/handler";
import { Company } from "@/queries/company/my-company";
import { useProfileQuery } from "@/queries/user/profile";
import { QueryKeys } from "@/queries/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCompleteTask = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfileQuery();
  const cid = profile?.companyAccounts[0]?.companyId;

  const {
    mutate,
    isPending: completeTaskPending,
    error: completeTaskError,
  } = useMutation({
    mutationFn: async (task: "onboarding" | "profile") => {
      const token = await getAccessTokenSilently();
      const body =
        task === "onboarding"
          ? { onboardingComplete: true }
          : { profileComplete: true };

      await request({
        method: "PATCH",
        path: `/api/company/${cid}`,
        token,
        body: JSON.stringify(body),
      });
    },
    onMutate: async (task) => {
      await qc.cancelQueries({
        queryKey: QueryKeys.myCompany,
      });

      const prev = qc.getQueryData<Company>(QueryKeys.myCompany);

      if (prev) {
        qc.setQueryData(QueryKeys.myCompany, {
          ...prev,
          ...(task === "onboarding"
            ? { onboardingComplete: true }
            : { profileComplete: true }),
        });
      }

      return { prev, task };
    },
    onError: (_, __, context) => {
      qc.setQueryData(QueryKeys.myCompany, context?.prev);
    },
    onSettled: () => {
      void qc.invalidateQueries({
        queryKey: QueryKeys.myCompany,
      });
    },
  });

  return {
    completeOnboarding: () => mutate("onboarding"),
    completeProfile: () => mutate("profile"),
    completeTaskPending,
    completeTaskError,
  };
};
