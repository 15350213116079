import { Button } from "@/components/ui/button";
import { Building, TriangleAlert, CopyPlus, User } from "lucide-react";
import { useToggle } from "@/hooks/useToggle";
import { useMyCompanyQuery } from "@/queries/company/my-company";
import { useCompanyOpportunitiesQuery } from "@/queries/company/opportunities";
import { useCompanyResourcesQuery } from "@/queries/company/resources";
import { useNavigate } from "react-router-dom";
import Dashboard from "@/components/pages/dashboard";
import { useCompleteTask } from "@/mutations/company/dashboard/todo";
import { NeedHelpMessage } from "@/components/pages/dashboard/need-help";
import CompanyScheduleInterviewSheet from "@/components/dialogs/company/schedule-interview";
import { AddOpportunityDialog } from "@/components/dialogs/company/opportunity/add-opportunity";

const CompanyDashboard = () => {
  const navigate = useNavigate();
  const { companyLoading, company } = useMyCompanyQuery();
  const [oppDialogOpen, toggleOppDialog] = useToggle();
  /*
  const { opportunityData, opportunityLoading } = useCompanyOpportunityQuery(
    "3713c6b3-a35e-421b-82eb-7d8b28904410",
  );

  useEffect(() => {
    if (opportunityData && !opportunityLoading) {
      useCompanyScheduleInterviewSheet
        .getState()
        .setOpportunity(opportunityData);
      useCompanyScheduleInterviewSheet
        .getState()
        .setInterview(opportunityData.interviews!.at(0)!);
      useCompanyScheduleInterviewSheet
        .getState()
        .setCandidate(opportunityData.candidates!.at(0)!);

      useCompanyScheduleInterviewSheet.getState().setOpen(true);
    }
  }, [opportunityData, opportunityLoading]);
  */

  return (
    <Dashboard.Root
      messages={[
        !company?.profileComplete && (
          <CompleteCompanyProfileAlert key="alert" />
        ),
        <NeedHelpMessage key="need-help" />,
      ]}
    >
      <Dashboard.ProfileRow>
        <Dashboard.ProfileInfo
          title={company?.name}
          description={company?.location}
          fallbackIcon={Building}
          src={company?.logoLink}
          loading={companyLoading}
        />
        <Dashboard.ProfileActions>
          <Button
            variant="secondary"
            disabled={companyLoading}
            onClick={() => navigate("/company/profile/edit")}
          >
            <Building />
            Update Company Profile
          </Button>
          <AddOpportunityDialog
            open={oppDialogOpen}
            setOpen={toggleOppDialog}
          />
        </Dashboard.ProfileActions>
      </Dashboard.ProfileRow>
      <Dashboard.MainContent>
        <CompanySetupSection toggleOppDialog={toggleOppDialog} />
        <SelectedResourcesSection />
      </Dashboard.MainContent>
      <CompanyScheduleInterviewSheet />
    </Dashboard.Root>
  );
};

export default CompanyDashboard;

const CompleteCompanyProfileAlert = () => {
  const navigate = useNavigate();

  return (
    <Dashboard.Message className="flex flex-col bg-primary/5 border border-primary/20 rounded-xl p-6 gap-4">
      <div className="size-14 bg-primary flex flex-col items-center justify-center rounded-lg">
        <TriangleAlert className="size-8 text-primary fill-primary-foreground" />
      </div>
      <h2 className="mt-2 text-xl">Customize Your Profile</h2>
      <p className="text-md">
        Companies who complete their profile are 15% more likely to be matched
        with a candidate!
      </p>
      <p className="text-md text-muted-foreground">
        Your profile tells candidates about your company and what opportunities
        you have available.
      </p>
      <Button
        className="w-fit"
        onClick={() => navigate("/company/profile/edit")}
      >
        Get Started
      </Button>
    </Dashboard.Message>
  );
};

const CompanySetupSection = ({
  toggleOppDialog,
}: {
  toggleOppDialog: (v?: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { companyLoading, company } = useMyCompanyQuery();
  const { completeOnboarding, completeProfile } = useCompleteTask();
  const { opportunities } = useCompanyOpportunitiesQuery();
  const hasOpportunities = !!opportunities?.length;

  const completedPercentage = (() => {
    let done = 0;
    hasOpportunities && done++;
    company?.profileComplete && done++;
    company?.onboardingComplete && done++;
    return Math.floor((done / 3) * 100);
  })();

  return (
    <Dashboard.Collapsible defaultOpen>
      <Dashboard.CollapsibleHeader>
        <Dashboard.CollapsibleTitle>
          Set Up Your Venturous Account
        </Dashboard.CollapsibleTitle>
        <Dashboard.CollapsibleProgress
          loading={companyLoading}
          value={completedPercentage}
        />
      </Dashboard.CollapsibleHeader>
      <Dashboard.CollapsibleContent>
        <Dashboard.TodoContainer>
          <Dashboard.TodoItem
            icon={CopyPlus}
            title="Add an opportunity"
            description="Create your first job listing"
            completed={hasOpportunities}
            loading={companyLoading}
          >
            <Dashboard.TodoItemActions>
              <Button variant="ghost" onClick={() => toggleOppDialog()}>
                Get Started
              </Button>
            </Dashboard.TodoItemActions>
          </Dashboard.TodoItem>
          <Dashboard.TodoItem
            icon={Building}
            title="Customize company profile"
            description="Complete the profile candidates see"
            completed={company?.profileComplete}
            loading={companyLoading}
            onCompleteClick={() => completeProfile()}
          >
            <Dashboard.TodoItemActions>
              <Button
                variant="ghost"
                onClick={() => navigate("/company/profile/edit")}
              >
                Get Started
              </Button>
            </Dashboard.TodoItemActions>
          </Dashboard.TodoItem>
          <Dashboard.TodoItem
            icon={User}
            title="Onboarding call"
            description="To complete the onboarding process, please schedule a 30 minute intro call with us"
            completed={company?.onboardingComplete}
            loading={companyLoading}
            onCompleteClick={() => completeOnboarding()}
          >
            <Dashboard.TodoItemActions>
              <Button
                variant="ghost"
                onClick={() => {
                  window.open(
                    "https://calendly.com/kevin-venturous/30min",
                    "_blank",
                  );
                }}
              >
                Schedule
              </Button>
            </Dashboard.TodoItemActions>
          </Dashboard.TodoItem>
        </Dashboard.TodoContainer>
      </Dashboard.CollapsibleContent>
    </Dashboard.Collapsible>
  );
};

const SelectedResourcesSection = () => {
  const { companyResources, companyResourcesLoading } =
    useCompanyResourcesQuery();

  return (
    <Dashboard.ResourcesCollapsible
      resources={companyResources ?? []}
      loading={companyResourcesLoading}
    />
  );
};
