import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  SimpleForm,
  SimpleShowLayout,
  Show,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";

const filters = [
  <TextInput key="name" source="name" />,
  <TextInput key="link" source="link" />,
  <DateInput key="created_at" source="created_at" />,
];

export const ContractList = () => (
  <List filters={filters}>
    <Datagrid>
      <TextField source="name" />
      <UrlField source="link" content="Link" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ContractShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <UrlField source="link" content="Link" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const ContractCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="link" />
    </SimpleForm>
  </Create>
);

export const ContractEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="link" />
    </SimpleForm>
  </Edit>
);
