import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCandidateScreeningInterviewQuery } from "@/queries/candidate/screening-interview";
import { useMyCandidateQuery } from "@/queries/candidate/my-candidate";
import { Spinner } from "@fluentui/react-components";
import { Button } from "@/components/ui/button";
import { useCompleteTask } from "@/mutations/candidate/dashboard/todo";

interface HireflixEventShape {
  type: string;
  payload?: Record<string, unknown>;
  description?: string;
}

const CandidateInterview = () => {
  const { candidate, candidateLoading } = useMyCandidateQuery();
  const { candidateScreeningInterview, candidateScreeningInterviewError } =
    useCandidateScreeningInterviewQuery();
  const { completeInterview } = useCompleteTask();

  const [complete, setComplete] = useState(false);

  const navigate = useNavigate();

  const onEventReceived = useCallback(
    (event: MessageEvent<HireflixEventShape>) => {
      // On every event received, filter for events that are coming from the Hireflix iFrame.
      if (
        candidateScreeningInterview &&
        candidateScreeningInterview.includes(event.origin) &&
        event.data.type === "interview.finished"
      ) {
        setComplete(true);
        completeInterview();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [candidateScreeningInterview],
  );

  useEffect(() => {
    window.addEventListener("message", onEventReceived);

    return () => {
      window.removeEventListener("message", onEventReceived);
    };
  }, [onEventReceived]);

  if (complete) {
    return (
      <div className="min-h-[calc(100vh-48px)] -mx-6 -mt-4 px-6 pt-8 pb-4 flex flex-col gap-7 items-center justify-center">
        <div className="w-full h-full max-w-3xl flex flex-col gap-2 justify-center">
          <div className="flex flex-col gap-1 text-center">
            <p className="text-3xl font-semibold text-black">
              Thank you for completing the pre-screening interview for the
              Venturous Community. Our Talent Coordinator is reviewing your
              answers and will be in touch shortly.
              <br />
              <br />
              In the meantime, browse candidate resources here or reach out to
              use with questions: info@venturous.work
            </p>
          </div>
        </div>
        <Button
          className=""
          variant="secondary"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </Button>
      </div>
    );
  }

  if (!candidateLoading && candidate?.interviewComplete) {
    return (
      <div className="min-h-[calc(100vh-48px)] -mx-6 -mt-4 px-6 pt-8 pb-4 flex flex-col gap-7 items-center justify-center">
        <div className="flex-1 w-full h-full max-w-screen-xl flex flex-col gap-2 justify-center bg-[#f2f2f2] rounded-lg">
          <div className="flex flex-col gap-1 text-center">
            <p className="text-3xl font-semibold text-black">
              You have already completed your interview with Venturous!
            </p>
          </div>
        </div>
        <Button
          className=""
          variant="secondary"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </Button>
      </div>
    );
  }

  if (candidateScreeningInterviewError) {
    return (
      <div className="min-h-[calc(100vh-48px)] -mx-6 -mt-4 px-6 pt-8 pb-4 flex flex-col gap-7 items-center justify-center">
        <div className="flex-1 w-full h-full max-w-screen-xl flex flex-col gap-2 justify-center bg-[#f2f2f2] rounded-lg">
          <div className="flex flex-col gap-1 text-center">
            <p className="text-3xl font-semibold text-black">
              Something went wrong and our team has been alerted, please try
              again later.
            </p>
          </div>
        </div>
        <Button
          className=""
          variant="secondary"
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </Button>
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-48px)] -mx-6 -mt-4 px-6 pt-8 pb-4 flex flex-col gap-7 items-center">
      {!candidateScreeningInterview || candidateLoading ? (
        <div className="flex-1 w-full h-full max-w-screen-xl flex flex-col gap-2 justify-center bg-[#f2f2f2] rounded-lg">
          <Spinner size="huge" />
          <div className="flex flex-col gap-1 text-center">
            <p className="text-2xl leading-6 font-semibold tracking-[-0.18px] text-[#22234A]">
              Preparing your interview...
            </p>
          </div>
        </div>
      ) : (
        <iframe
          className="w-full max-w-screen-xl flex-1 p-0"
          src={candidateScreeningInterview}
          allowFullScreen={false}
          allow="camera;microphone"
        />
      )}
      <div className="w-full flex flex-row">
        <Button variant="secondary" onClick={() => navigate("/dashboard")}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CandidateInterview;
