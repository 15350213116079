import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { AdminPipelineJob } from "./job-pipeline";
import { AdminPipelineCandidate } from "./candidate-pipeline";
import {
  CandidateApplicationStatus,
  CompanyInterview,
  Contract,
} from "@/types";

export interface AdminJobCandidateDetails extends AdminPipelineCandidate {
  candidateStatusId: string;
  opportunityStatus: CandidateApplicationStatus;
  matchCriteria: string[];
  matchCriteriaQuote: string[];
  matchSummary?: string;
  notes?: string;
}

export interface AdminJobDetails extends AdminPipelineJob {
  candidates: AdminJobCandidateDetails[];
  interviews?: CompanyInterview[];
  contracts: Contract[];
}

export const useAdminJobDetailsQuery = (openingId = "") => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: adminJobDetails,
    error: adminJobDetailsError,
    isLoading: adminJobDetailsLoading,
  } = useQuery({
    queryKey: QueryKeys.adminJobDetails(openingId),
    enabled: !!openingId,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return await request<AdminJobDetails>({
        method: "GET",
        path: `/api/admin/job_opening/${openingId}`,
        token,
      });
    },
  });

  return {
    adminJobDetails,
    adminJobDetailsError,
    adminJobDetailsLoading,
  };
};
