import { PayloadAction, SerializedError, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { completeOnboarding, login } from "./authSlice";
import {
  getCandidateOpeningProfile,
  updateCandidateProfile,
  addCandidateReference,
  addCandidateProject,
  updateCandidateProject,
  deleteCandidateProject,
  addCandidateExperience,
  updateCandidateExperience,
  deleteCandidateExperience,
} from "./candidateProfileSlice";
import { applyForOpening, getCandidateOpenings } from "./candidateOpeningSlice";
import {
  createOpening,
  getOpening,
  getOpenings,
  makeOfferToCandidateCandidate,
  rejectCandidate,
  scheduleInterview,
} from "./companyOpeningSlice";
import {
  claimCompanyProfile,
  getCompanyClaimStatus,
  getCompanyProfile,
  updateCompanyProfile,
} from "./companyProfileSlice";
import { ErrorPayload, ErrorType } from "../../types";

// Define a type for the slice state
interface IErrorState {
  errorType: null | ErrorType;
  errorMessage: null | string;
  refreshOnClose: boolean;
}

// Define the initial state using that type
const initialState: IErrorState = {
  errorType: null,
  errorMessage: null,
  refreshOnClose: false,
};

const errorReducer = (
  state: IErrorState,
  action: PayloadAction<unknown, string, unknown, SerializedError>,
) => {
  const payload = action.payload as
    | {
        data?: ErrorPayload;
        status?: number;
      }
    | undefined;

  if (action.error.name === "CanceledError") {
    return;
  } else if (!payload?.data) {
    state.errorType = "UNEXPECTED";
    state.errorMessage =
      "An unexpected error occured. It has been logged and sent to our team.";
    state.refreshOnClose = true;
  } else if (payload.status === 401) {
    if (payload.data.error === "TOKEN_ERROR") {
      state.errorType = "TOKEN_ERROR";
      state.errorMessage = payload.data.message;
    } else {
      state.errorType = "UNAUTHORIZED";
      state.errorMessage = "Access denied due to invalid credentials.";
    }
  } else {
    const { error, message } = payload.data;
    switch (error) {
      case "SERVER_ERROR":
        state.errorType = "SERVER_ERROR";
        state.errorMessage =
          "A network error occured, please try again. If the issue persists, please contact support.";
        state.refreshOnClose = true;
        break;
      case "GENERIC_BAD_INPUT":
        // Bad inputs should not be permitted, so report them
        Sentry.captureMessage(`${error}: ${message}`, "error");
        state.errorType = "GENERIC_BAD_INPUT";
        state.errorMessage =
          "A network error occured, please try again. If the issue persists, please contact support.";
        break;
      case "SPECIFIC_BAD_INPUT":
        // Bad inputs should not be permitted, so report them
        Sentry.captureMessage(`${error}: ${message}`, "error");
        state.errorType = "SPECIFIC_BAD_INPUT";
        state.errorMessage = message;
        break;
      default:
        // Report errors that don't match
        Sentry.captureMessage(`${error}: ${message}`, "error");
        state.errorType = "UNEXPECTED";
        state.errorMessage =
          "An unexpected error occured. It has been logged and sent to our team.";
        state.refreshOnClose = true;
    }
  }
};

export const authSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorType = null;
      state.errorMessage = null;
      state.refreshOnClose = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.rejected, errorReducer)
      .addCase(completeOnboarding.rejected, errorReducer)
      .addCase(getCandidateOpeningProfile.rejected, errorReducer)
      .addCase(updateCandidateProfile.rejected, errorReducer)
      .addCase(addCandidateReference.rejected, errorReducer)
      .addCase(addCandidateProject.rejected, errorReducer)
      .addCase(updateCandidateProject.rejected, errorReducer)
      .addCase(deleteCandidateProject.rejected, errorReducer)
      .addCase(addCandidateExperience.rejected, errorReducer)
      .addCase(updateCandidateExperience.rejected, errorReducer)
      .addCase(deleteCandidateExperience.rejected, errorReducer)
      .addCase(getCandidateOpenings.rejected, errorReducer)
      .addCase(applyForOpening.rejected, errorReducer)
      .addCase(getCompanyProfile.rejected, errorReducer)
      .addCase(getCompanyClaimStatus.rejected, errorReducer)
      .addCase(claimCompanyProfile.rejected, errorReducer)
      .addCase(updateCompanyProfile.rejected, errorReducer)
      .addCase(getOpening.rejected, errorReducer)
      .addCase(getOpenings.rejected, errorReducer)
      .addCase(createOpening.rejected, errorReducer)
      .addCase(scheduleInterview.rejected, errorReducer)
      .addCase(makeOfferToCandidateCandidate.rejected, errorReducer)
      .addCase(rejectCandidate.rejected, errorReducer);
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;
