import { CandidateStatus, CompanyInterview, CompanyJobOpening } from "@/types";
import { create } from "zustand";
import { AvailabilityTime } from "../../../../lib/datetime";

interface CompanyScheduleInterviewSheetStore {
  open: boolean;
  setOpen: (open: boolean) => void;
  opportunity: CompanyJobOpening | null;
  setOpportunity: (opportunity: CompanyJobOpening | null) => void;
  interview: CompanyInterview | null;
  setInterview: (interview: CompanyInterview | null) => void;
  candidate: CandidateStatus | null;
  setCandidate: (candidate: CandidateStatus | null) => void;
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date | undefined) => void;
  time: AvailabilityTime;
  setTime: (time: AvailabilityTime) => void;
  invalid: boolean;
  setInvalid: (invalid: boolean) => void;
  successOpen: boolean;
  setSuccessOpen: (successOpen: boolean) => void;
  isUpdating: boolean;
  setIsUpdating: (isUpdating: boolean) => void;
}

export const useCompanyScheduleInterviewSheet =
  create<CompanyScheduleInterviewSheetStore>((set, get) => ({
    open: false,
    setOpen: open => {
      if (open && (!get().opportunity || !get().interview)) return;

      let toset: Record<string, unknown> = { open };
      if (!open) {
        toset = {
          ...toset,
          opportunity: null,
          interview: null,
          candidate: null,
          isUpdating: false,
        };
      }

      set(toset);
    },
    opportunity: null,
    setOpportunity: opportunity => set({ opportunity }),
    interview: null,
    setInterview: interview => set({ interview }),
    candidate: null,
    setCandidate: candidate => set({ candidate }),
    selectedDate: undefined,
    setSelectedDate: date => set({ selectedDate: date }),
    time: AvailabilityTime.newPlaceholder(),
    setTime: time => set({ time }),
    invalid: false,
    setInvalid: invalid => set({ invalid }),
    successOpen: false,
    setSuccessOpen: successOpen => set({ successOpen }),
    isUpdating: false,
    setIsUpdating: isUpdating => set({ isUpdating }),
  }));
