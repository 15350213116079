import React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/lib/utils";

const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.TooltipTrigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.TooltipTrigger>
>(({ children, className, content, ...props }, ref) => (
  <TooltipPrimitive.TooltipProvider delayDuration={500} {...props}>
    <TooltipPrimitive.Root>
      <TooltipPrimitive.Trigger ref={ref} asChild {...props}>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          className={cn(
            "px-[15px] py-2.5 bg-[white] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] rounded",
            "text-base leading-none",
            "select-none will-change-[transform,opacity]",
            className,
          )}
        >
          {content}
          <TooltipPrimitive.TooltipArrow className="fill-white" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.TooltipProvider>
));
Tooltip.displayName = TooltipPrimitive.TooltipProvider.displayName;

export { Tooltip };
