import { useMemo } from "react";

import { cn } from "@/lib/utils";
import { Avatar, InfoLabel } from "@fluentui/react-components";
import { ChevronsRight, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";

import { AdminJobCandidateDetails } from "@/queries/admin/job-details";
import { useSetCandidateOpportunityStatus } from "@/mutations/admin/set-candidate-opportunity-status";
import {
  CandidateApplicationStatus,
  candidateApplicationStatusList,
} from "@/types";

const CandidateKanban = ({
  openingId,
  candidates,
  setSelectedCandidate,
}: {
  openingId: string;
  candidates?: AdminJobCandidateDetails[];
  setSelectedCandidate: (v: AdminJobCandidateDetails | undefined) => void;
}) => {
  return (
    <div className="flex flex-row gap-2 self-stretch overflow-auto">
      {candidateApplicationStatusList.map((status) => (
        <PipelineStageColumn
          key={`kanban-candidate-${status}`}
          openingId={openingId}
          status={status}
          candidates={candidates}
          setSelectedCandidate={setSelectedCandidate}
        />
      ))}
    </div>
  );
};

const PipelineStageColumn = ({
  openingId,
  status,
  candidates,
  setSelectedCandidate,
}: {
  openingId: string;
  status: CandidateApplicationStatus;
  candidates?: AdminJobCandidateDetails[];
  setSelectedCandidate: (v: AdminJobCandidateDetails | undefined) => void;
}) => {
  const filteredCandidates = useMemo(
    () =>
      candidates?.filter((candidate) => candidate.opportunityStatus === status),
    [status, candidates],
  );
  const atFinalStatus = useMemo(
    () =>
      status === "Accepted by Client" ||
      status === "Not Interested" ||
      status === "Rejected for Role",
    [status],
  );

  let dotColor: string, backgroundColor: string, textColor: string;
  switch (status) {
    case "Identified":
    case "Not Interested":
      backgroundColor = "bg-red-100";
      textColor = "text-red-600";
      dotColor = "bg-red-600";
      break;
    case "Checking Interest":
      backgroundColor = "bg-pink-100";
      textColor = "text-pink-600";
      dotColor = "bg-pink-600";
      break;
    case "Screening":
      backgroundColor = "bg-orange-100";
      textColor = "text-orange-600";
      dotColor = "bg-orange-600";
      break;
    case "Possible Fit":
      backgroundColor = "bg-blue-100";
      textColor = "text-blue-600";
      dotColor = "bg-blue-600";
      break;
    case "Shortlisted":
      backgroundColor = "bg-purple-100";
      textColor = "text-purple-600";
      dotColor = "bg-purple-600";
      break;
    case "Making Offer":
      backgroundColor = "bg-yellow-100";
      textColor = "text-yellow-600";
      dotColor = "bg-yellow-600";
      break;
    case "Accepted by Client":
      backgroundColor = "bg-green-100";
      textColor = "text-green-600";
      dotColor = "bg-green-600";
      break;
    case "Rejected for Role":
      backgroundColor = "bg-violet-100";
      textColor = "text-violet-600";
      dotColor = "bg-violet-600";
  }

  return (
    <div className="min-w-72 p-1 flex-1 flex flex-col gap-2 bg-[#f9f9fb] border border-[#e9eaeb] rounded-[10px]">
      <div className="h-10 py-[21px] px-4 flex flex-row gap-2 self-stretch items-center">
        <div className="flex-1 flex items-center gap-2">
          <div className={cn("h-2.5 w-2.5 rounded-full", dotColor)} />
          <div className="flex items-center gap">
            <p className="text-base text-[#0c0d0d] font-semibold text-nowrap">
              {status}
            </p>
            {status === "Checking Interest" ? (
              <InfoLabel
                className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
                size="small"
                info={<p>Emails the candidate to request their opt-in</p>}
              />
            ) : status === "Shortlisted" ? (
              <InfoLabel
                className="text-sm font-semibold tracking-[-0.18px] text-[#727E94]"
                size="small"
                info={<p>Visible to company</p>}
              />
            ) : null}
          </div>
          {Boolean(filteredCandidates?.length) && (
            <div className="p-2 min-h-2.5 min-w-2.5 flex justify-center align-middle rounded shadow-[0px_3px_12px_0px_rgba(28,28,28,0.20)]">
              <span className="text-xs text-[#0c0d0d] font-semibold tracking-[-0.24px]">
                {filteredCandidates?.length}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-1 self-stretch">
        {filteredCandidates?.map((candidate) => (
          <PipelineCandidateTile
            key={`candidate-${candidate.candidateId}`}
            openingId={openingId}
            candidate={candidate}
            textColor={textColor}
            backgroundColor={backgroundColor}
            disableControls={atFinalStatus}
            selectCandidate={() => setSelectedCandidate(candidate)}
          />
        ))}
      </div>
    </div>
  );
};

const PipelineCandidateTile = ({
  openingId,
  candidate,
  backgroundColor,
  textColor,
  disableControls,
  selectCandidate,
}: {
  openingId: string;
  candidate: AdminJobCandidateDetails;
  backgroundColor: string;
  textColor: string;
  disableControls: boolean;
  selectCandidate: () => void;
}) => {
  const { setCandidateOpportunityStatus, isSettingCandidateOpportunityStatus } =
    useSetCandidateOpportunityStatus(openingId);

  return (
    <div className="p-4 flex flex-col items-start self-stretch gap-1 bg-white border border-[#e9eaeb] rounded-[10px]">
      <div className="flex flex-row items-center justify-between self-stretch">
        <div className="flex flex-row items-center gap-1">
          <Avatar
            image={{ src: candidate.profilePictureLink }}
            name={candidate.name}
            size={24}
          />
          <p className="text-sm text-[#0c0d0d] font-semibold leading-6">
            {candidate.name}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <div
            className={cn(
              "py-1 px-2.5 flex flex-row items-center gap-2 rounded-full",
              backgroundColor,
            )}
          >
            <p
              className={cn(
                "text-xs font-semibold tracking-[-0.24px]",
                textColor,
              )}
            >
              {candidate.opportunityStatus}
            </p>
          </div>
          <EllipsisVertical
            className="cursor-pointer"
            size={16}
            stroke="#B1B9B8"
            onClick={selectCandidate}
          />
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch gap-1">
        <PipelineCandidateInfo title="Primary Role" value={candidate.role} />
        <PipelineCandidateInfo
          title="Years Experience"
          value={candidate.yearsOfExperience}
        />
        <PipelineCandidateInfo
          title="Years HC Experience"
          value={candidate.yearsOfHealthcareExperience}
        />
        <PipelineCandidateInfo
          title="Profile"
          link={`/candidate/${candidate.userId}/edit`}
          expectLink
        />
        <PipelineCandidateInfo
          title="Company Profile"
          link={`/opening/${openingId}/application/${candidate.candidateStatusId}`}
          expectLink
        />
        <PipelineCandidateInfo
          title="LinkedIn"
          link={candidate.linkedIn}
          expectLink
        />
        <PipelineCandidateInfo
          title="Attio"
          link={
            candidate.attioId
              ? `https://app.attio.com/venturous/person/${candidate.attioId}/activity`
              : undefined
          }
          expectLink
        />
        <PipelineCandidateInfo
          title="AI Match?"
          value={candidate.matchSummary ? "Yes" : "No"}
        />
      </div>
      {!disableControls && (
        <div className="flex flex-row justify-between self-stretch gap-1.5">
          <Button
            variant="destructive"
            size="sm"
            disabled={isSettingCandidateOpportunityStatus}
            onClick={() =>
              setCandidateOpportunityStatus({
                statusId: candidate.candidateStatusId,
                status: "Rejected for Role",
              })
            }
          >
            Decline
          </Button>
          <Button
            variant="secondary"
            size="sm"
            disabled={isSettingCandidateOpportunityStatus}
            onClick={() =>
              setCandidateOpportunityStatus({
                statusId: candidate.candidateStatusId,
                currentStatus: candidate.opportunityStatus,
              })
            }
          >
            Advance <ChevronsRight />
          </Button>
        </div>
      )}
    </div>
  );
};

const PipelineCandidateInfo = ({
  title,
  value,
  link,
  expectLink,
}: {
  title: string;
  value?: string | number;
  link?: string;
  expectLink?: boolean;
}) => {
  if (expectLink && !link) {
    return;
  }

  return (
    <div className="flex flex-col items-start self-stretch gap-2 border-b last:border-b-0 border-[rgba(24,24,27,0.12)]">
      <div className="pt-0.5 px-1.5 flex flex-row items-center justify-between self-stretch gap-2">
        <div className="flex flex-row items-center self-stretch gap-1">
          <p className="text-[10px] text-[#141515] font-medium">{title}</p>
        </div>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        ) : (
          <p className="text-xs text-[#5c6060] font-medium">
            {value ?? "Unknown"}
          </p>
        )}
      </div>
    </div>
  );
};

export default CandidateKanban;
