import { useMutation } from "@tanstack/react-query";
import { useCompanyScheduleInterviewSheet } from "./store";
import { CompanyInterview } from "@/types";
import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

export const useCompanyScheduleInterview = () => {
  const startTime = useCompanyScheduleInterviewSheet(s => s.time);
  const opening = useCompanyScheduleInterviewSheet(s => s.opportunity);
  const interview = useCompanyScheduleInterviewSheet(s => s.interview);
  const candidate = useCompanyScheduleInterviewSheet(s => s.candidate);
  const { getAccessTokenSilently } = useAuth0();
  const invalidate = useInvalidateQuery();

  const { isPending, error, mutate } = useMutation({
    mutationFn: async (removeEntry: boolean) => {
      if (!opening?.id || !interview?.id || !candidate?.id) {
        throw new Error("Missing data");
      }

      if (removeEntry) {
        await request({
          method: "DELETE",
          path: buildPath(opening.id, candidate.candidateId, interview.id),
          token: await getAccessTokenSilently(),
        });
      }

      if (startTime.isPlaceholder) {
        throw new Error("Missing start time");
      } else if (useCompanyScheduleInterviewSheet.getState().invalid) {
        throw new Error("Invalid start time");
      }

      await request({
        method: getMethod(interview, candidate.id),
        path: buildPath(opening.id, candidate.candidateId, interview.id),
        body: JSON.stringify({
          startTime: startTime.isodatetime,
        }),
        token: await getAccessTokenSilently(),
      });
    },
    onSettled: async () => {
      await invalidate(QueryKeys.companyOpportunities);
      await invalidate(QueryKeys.companyOpportunity(opening?.id ?? ""));

      useCompanyScheduleInterviewSheet.getState().setOpen(false);
      setTimeout(
        () => useCompanyScheduleInterviewSheet.getState().setSuccessOpen(true),
        100,
      );
    },
  });

  return {
    scheduleInterview: mutate,
    scheduleInterviewPending: isPending,
    scheduleInterviewError: error,
  };
};

const getMethod = (interview: CompanyInterview, candidateID: string) => {
  const completedInterviews = interview.interviewCompleted.filter(
    i => i === candidateID,
  ).length;
  const requestedInterviews = interview.interviewRequested.filter(
    i => i === candidateID,
  ).length;

  return requestedInterviews - completedInterviews > 0 ? "PATCH" : "POST";
};

const buildPath = (
  openingID: string,
  candidateID: string,
  interviewID: string,
) =>
  `/api/jobOpening/${openingID}/candidate/${candidateID}/interview/${interviewID}/schedule` as const;
