import { useMutation } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import request from "@/lib/api/handler";
import { toast } from "sonner";
import { User } from "@/queries/user/profile";

const useResetPasswordMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      const response: User = await request({
        method: "POST",
        token,
        path: "/api/user/reset-password",
      });

      return response;
    },
    onSettled(_, error) {
      if (!error) {
        toast.success("Password reset email sent, check your inbox!");
      } else {
        toast.error("Failed to send password reset email, please try again");
      }
    },
  });
};

export default useResetPasswordMutation;
