import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

interface CompleteInterviewParams {
  openingId: string;
  candidateStatusId: string;
  interviewId: string;
}

export const useCancelInterview = () => {
  const invalidate = useInvalidateQuery();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      openingId,
      candidateStatusId,
      interviewId,
    }: CompleteInterviewParams) => {
      const token = await getAccessTokenSilently();

      await request({
        method: "DELETE",
        path: `/api/jobOpening/${openingId}/candidate/${candidateStatusId}/interview/${interviewId}/schedule`,
        token,
      });
    },
    onSettled: async (_, __, { openingId }) => {
      await invalidate(QueryKeys.companyOpportunities);
      await invalidate(QueryKeys.companyOpportunity(openingId));
    },
  });

  return {
    cancelInterview: mutate,
    isCancelling: isPending,
    cancelError: error,
  };
};
