import { z } from "zod";

const schema = z.object({
  profilePicture: z.instanceof(File).optional(),
  marketingConsent: z.boolean({
    required_error: "You must opt-in or out of marketing emails",
  }),
});

type Schema = z.infer<typeof schema>;

export { schema as settingsFormSchema, type Schema as SettingsFormSchema };
