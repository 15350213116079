import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import CompanyOpportunityForm from "@/components/forms/company/opportunity";
import { PlusCircle } from "lucide-react";

import PreprocessIcon from "@/assets/preprocess_icon.svg";

import { useMyCompanyQuery } from "@/queries/company/my-company";
import { CompanyJobOpening } from "@/types";

export const AddOpportunityDialog = ({
  open,
  setOpen,
  opening,
  trigger,
  includeMaskedJobDescription,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
  opening?: CompanyJobOpening;
  trigger?: React.ReactNode;
  includeMaskedJobDescription?: boolean;
}) => {
  const { companyLoading } = useMyCompanyQuery();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger ? (
          trigger
        ) : (
          <Button disabled={companyLoading}>
            <PlusCircle />
            Add An Opportunity
          </Button>
        )}
      </DialogTrigger>
      <DialogContent
        className="w-full p-0 sm:max-w-2xl"
        onClick={(event) => event.stopPropagation()}
      >
        <DialogHeader className="p-6">
          <div className="flex items-center gap-2">
            <img src={PreprocessIcon} alt="Preprocess Icon" className="" />
            <DialogTitle>Add a Job Opportunity</DialogTitle>
          </div>
          <DialogDescription className="sr-only">
            Enter the details of the job opportunity here and click
            &quot;Save&quot;
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="sm:max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
          <CompanyOpportunityForm
            opening={opening}
            modal
            includeMaskedJobDescription={includeMaskedJobDescription}
            onSuccess={() => setOpen(false)}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
