import { useToggle } from "@/hooks/useToggle";
import { cn } from "@/lib/utils";
import { File, Plus, X } from "lucide-react";
import { useRef, useState } from "react";

interface Props {
  className?: string;
  accept?: string;
  multiple?: boolean;
  maxFiles?: number;
  maxFileSizeBytes?: number;
  value: File[];
  onChange: (files: File[]) => void;
  description?: string;
}

const FilePond = ({
  className,
  value,
  onChange,
  maxFiles = 1,
  maxFileSizeBytes,
  description,
  ...passthrough
}: Props) => {
  const [dragging, toggleDragging] = useToggle();
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  function handleUploadEvent(fileList: FileList | null) {
    setError(null);
    if (!fileList) {
      return;
    }

    if (fileList.length + value.length > maxFiles) {
      setError(`You can only upload ${maxFiles} a total of files.`);
      inputRef.current && (inputRef.current.value = "");
      return;
    }

    const newFiles = Array.from(fileList).slice(0, maxFiles - value.length);
    inputRef.current && (inputRef.current.value = "");

    if (maxFileSizeBytes) {
      const oversizedFiles = newFiles.filter(
        file => file.size > maxFileSizeBytes * 1024 * 1024,
      );

      if (oversizedFiles.length) {
        setError(
          "The maximum file size allowed is " + formatBytes(maxFileSizeBytes),
        );
        return;
      }
    }

    onChange([...value, ...newFiles]);
  }

  function handleRemove(index: number) {
    setError(null);
    if (index >= value.length) {
      return;
    }

    const newFiles = [...value];
    newFiles.splice(index, 1);
    onChange(newFiles);
  }

  return (
    <>
      {error && (
        <p className="text-destructive text-sm text-center w-full mb-4 p-2 border border-destructive rounded-md bg-destructive/10">
          {error}
        </p>
      )}
      <div
        className={cn(
          "group w-full rounded-lg border-dashed border-2 flex justify-center items-center min-h-32 p-4 flex-col hover:cursor-pointer hover:border-accent transition-colors duration-200",
          {
            "border-accent": dragging,
            hidden: maxFiles <= value.length,
          },
          className,
        )}
        onClick={() => inputRef.current && inputRef.current.click()}
        onDragEnter={() => toggleDragging()}
        onDragLeave={() => toggleDragging()}
        onDragOver={event => {
          event.stopPropagation();
          event.preventDefault();
        }}
        onDrop={event => {
          event.preventDefault();
          toggleDragging(false);
          handleUploadEvent(event.dataTransfer.files);
        }}
      >
        <input
          ref={inputRef}
          type="file"
          className="hidden fixed top-0 left-0 -z-50"
          onChange={e => handleUploadEvent(e.target.files)}
          {...passthrough}
        />
        <div>
          <Plus className="size-8 text-accent mx-auto" />
          <p className="text-center text-lg">
            <span className="text-accent font-semibold mr-1">
              Upload a file
            </span>
            or drag and drop
          </p>
          {description && (
            <p className="text-center text-sm text-muted-foreground">
              {description}
            </p>
          )}
        </div>
      </div>
      {value.length > 0 && (
        <div
          className={cn("w-full", {
            "mt-4": maxFiles - 1 > value.length,
          })}
        >
          {value.map((file, index) => (
            <FileItem
              key={[file.name, file.size, index].join("-")}
              file={file}
              index={index}
              onRemove={handleRemove}
            />
          ))}
        </div>
      )}
    </>
  );
};

const FileItem = ({
  file,
  index,
  onRemove,
}: {
  file: File;
  index: number;
  onRemove: (index: number) => void;
}) => {
  return (
    <div
      key={file.name}
      className="flex items-center justify-between w-full gap-4 mx-auto border border-border rounded-md p-2"
    >
      <div className="flex items-center gap-2">
        <File className="size-8" />
        <div>
          <p>
            {file.name.length > 20
              ? file.name.split(".")[0]?.substring(0, 12) +
                "..." +
                file.name.split(".")[1]
              : file.name}
          </p>
          <p className="text-xs text-muted-foreground">
            {formatBytes(file.size)}
          </p>
        </div>
      </div>
      <button
        className={cn(
          "bg-transparent rounded-md hover:bg-muted p-1.5 flex items-center justify-center hover:border-none border-none",
          "focus-visible:ring-0 focus-visible:ring-offset-0",
          "focus:outline-none focus:ring-0 focus:ring-offset-0",
        )}
        onClick={() => onRemove(index)}
      >
        <X className="size-5" />
      </button>
    </div>
  );
};

function formatBytes(bytes: number) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export default FilePond;
