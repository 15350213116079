import { useEffect, useMemo, useState } from "react";

import { ChevronsRight, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  TableWrapper,
  TableBody,
  TableRow,
  TableRowItem,
  TableFooter,
  TablePagination,
} from "@/components/ui/table";
import { Avatar } from "@fluentui/react-components";

import { AdminPipelineCandidate } from "@/queries/admin/candidate-pipeline";
import { useSetCandidateStage } from "@/mutations/admin/set-candidate-stage";
import { candidateWaitingRoomStatusList } from "@/types";

const SORTABLE_FIELDS = [
  "name",
  "stage",
  "role",
  "yearsOfExperience",
  "yearsOfHealthcareExperience",
] as const;
export type SortableField = (typeof SORTABLE_FIELDS)[number];

const USERS_PER_PAGE = 10;

const CandidateTable = ({
  candidates,
  setSelectedCandidate,
}: {
  candidates?: AdminPipelineCandidate[];
  setSelectedCandidate: (v: AdminPipelineCandidate | undefined) => void;
}) => {
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState<SortableField>("name");
  const [sortDirection, setSortDirection] = useState<1 | -1>(1);

  const sortedCandidates: AdminPipelineCandidate[] | undefined = useMemo(() => {
    if (!candidates) {
      return;
    }

    switch (sortField) {
      case "name":
      case "role":
        return [...candidates].sort(
          (a, b) =>
            (a[sortField] ?? "").localeCompare(b[sortField] ?? "") *
            sortDirection,
        );
      case "yearsOfExperience":
      case "yearsOfHealthcareExperience":
        return [...candidates].sort(
          (a, b) => ((a[sortField] ?? 0) - (b[sortField] ?? 0)) * sortDirection,
        );
      case "stage":
        return [...candidates].sort(
          (a, b) =>
            (candidateWaitingRoomStatusList.indexOf(a.stage) -
              candidateWaitingRoomStatusList.indexOf(b.stage)) *
            sortDirection,
        );
    }
  }, [candidates, sortField, sortDirection]);

  const numberOfPages = useMemo(() => {
    if (!sortedCandidates) {
      return 1;
    }

    return Math.ceil(sortedCandidates.length / USERS_PER_PAGE);
  }, [sortedCandidates]);

  const currentPageCandidates = useMemo(
    () =>
      sortedCandidates?.slice(
        (page - 1) * USERS_PER_PAGE,
        page * USERS_PER_PAGE,
      ),
    [sortedCandidates, page],
  );

  const onHeadingClick = (field: SortableField) => {
    if (field !== sortField) {
      setSortField(field);
      setSortDirection(1);
    } else {
      setSortDirection((prev) => (prev === 1 ? -1 : 1));
    }
  };

  useEffect(() => {
    if (page > numberOfPages && page !== 1) {
      setPage(numberOfPages || 1);
    }
  }, [page, numberOfPages]);

  return (
    <div className="py-6 self-stretch flex flex-col lg:flex-row gap-x-6 gap-y-2">
      <div className="flex-1 min-w-0">
        <TableWrapper>
          <TableBody>
            <thead>
              <TableRow>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("name")}
                  sortDirection={
                    sortField === "name" ? sortDirection : undefined
                  }
                >
                  Name
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("stage")}
                  sortDirection={
                    sortField === "stage" ? sortDirection : undefined
                  }
                >
                  Stage
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("role")}
                  sortDirection={
                    sortField === "role" ? sortDirection : undefined
                  }
                >
                  Role
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("yearsOfExperience")}
                  sortDirection={
                    sortField === "yearsOfExperience"
                      ? sortDirection
                      : undefined
                  }
                >
                  Years of Experience
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("yearsOfHealthcareExperience")}
                  sortDirection={
                    sortField === "yearsOfHealthcareExperience"
                      ? sortDirection
                      : undefined
                  }
                >
                  Years of HC Experience
                </TableRowItem>
                <TableRowItem heading>Profile</TableRowItem>
                <TableRowItem heading>LinkedIn</TableRowItem>
                <TableRowItem heading>Resume</TableRowItem>
                <TableRowItem heading>Attio</TableRowItem>
                <TableRowItem heading button></TableRowItem>
                <TableRowItem heading button></TableRowItem>
                <TableRowItem heading button></TableRowItem>
              </TableRow>
            </thead>
            <tbody>
              {currentPageCandidates?.map((candidate) => (
                <AdminTeamMemberRow
                  key={`candidate-${candidate.candidateId}`}
                  candidate={candidate}
                  selectCandidate={() => setSelectedCandidate(candidate)}
                />
              ))}
            </tbody>
          </TableBody>
          {numberOfPages > 1 ? (
            <TableFooter>
              <TablePagination
                currentPage={page}
                totalPages={numberOfPages}
                navigateFn={setPage}
              />
            </TableFooter>
          ) : null}
        </TableWrapper>
      </div>
    </div>
  );
};

const AdminTeamMemberRow = ({
  candidate,
  selectCandidate,
}: {
  candidate: AdminPipelineCandidate;
  selectCandidate: () => void;
}) => {
  const { setCandidateStage, isSettingCandidateStage } = useSetCandidateStage();

  return (
    <TableRow>
      <TableRowItem>
        <div className="flex flex-row gap-3 items-center">
          <Avatar
            image={{ src: candidate.profilePictureLink }}
            name={candidate.name}
            size={40}
          />
          <span className="text-[#0C0D0D] text-sm font-medium">
            {candidate.name}
          </span>
        </div>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{candidate.stage}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{candidate.role}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">
          {candidate.yearsOfExperience}
        </span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">
          {candidate.yearsOfHealthcareExperience}
        </span>
      </TableRowItem>
      <TableRowItem>
        <a
          href={`/candidate/${candidate.userId}/edit`}
          target="_blank"
          rel="noreferrer"
          className="text-xs text-[#646cff] font-medium hover:underline"
        >
          Link
        </a>
      </TableRowItem>
      <TableRowItem>
        {Boolean(candidate.linkedIn) && (
          <a
            href={candidate.linkedIn}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        )}
      </TableRowItem>
      <TableRowItem>
        {Boolean(candidate.resumeLink) && (
          <a
            href={candidate.resumeLink}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        )}
      </TableRowItem>
      <TableRowItem>
        {Boolean(candidate.attioId) && (
          <a
            href={`https://app.attio.com/venturous/person/${candidate.attioId}/activity`}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        )}
      </TableRowItem>
      <TableRowItem>
        <Button
          variant="destructive"
          size="sm"
          disabled={isSettingCandidateStage}
          onClick={() =>
            setCandidateStage({
              candidateId: candidate.candidateId,
              stage: "Rejected",
            })
          }
        >
          Decline
        </Button>
      </TableRowItem>
      <TableRowItem>
        <Button
          variant="secondary"
          size="sm"
          disabled={isSettingCandidateStage}
          onClick={() =>
            setCandidateStage({
              candidateId: candidate.candidateId,
              currentStage: candidate.stage,
            })
          }
        >
          Advance <ChevronsRight />
        </Button>
      </TableRowItem>
      <TableRowItem>
        <EllipsisVertical
          className="cursor-pointer"
          size={16}
          stroke="#B1B9B8"
          onClick={selectCandidate}
        />
      </TableRowItem>
    </TableRow>
  );
};

export default CandidateTable;
