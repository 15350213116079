import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";

import { AdminPipelineCandidate } from "./candidate-pipeline";

export const useAdminApprovedCandidatesQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: adminApprovedCandidates,
    error: adminApprovedCandidatesError,
    isLoading: adminApprovedCandidatesLoading,
  } = useQuery({
    queryKey: QueryKeys.adminApprovedCandidates,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return await request<AdminPipelineCandidate[]>({
        method: "GET",
        path: "/api/admin/approved-candidates",
        token,
      });
    },
  });

  return {
    adminApprovedCandidates,
    adminApprovedCandidatesError,
    adminApprovedCandidatesLoading,
  };
};
