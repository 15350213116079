import { useMemo } from "react";

import { cn } from "@/lib/utils";
import { Avatar } from "@fluentui/react-components";
import { ChevronsRight, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";

import { AdminPipelineJob } from "@/queries/admin/job-pipeline";
import { JobOpeningStatus } from "@/types";
import dateStringToLocalDate from "@/utils/dateStringToLocalDate";
import { useSetJobStatus } from "@/mutations/admin/set-job-status";

const JobKanban = ({
  jobs,
  selectJob,
}: {
  jobs?: AdminPipelineJob[];
  selectJob: (v: AdminPipelineJob | undefined) => void;
}) => {
  const sortedJobs = useMemo(
    () =>
      jobs
        ? [...jobs].sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        : [],
    [jobs],
  );

  return (
    <div className="flex flex-row gap-2 self-stretch overflow-auto">
      <PipelineStageColumn
        stage="New Opening"
        jobs={sortedJobs}
        selectJob={selectJob}
      />
      <PipelineStageColumn
        stage="Creating Job Spec"
        jobs={sortedJobs}
        selectJob={selectJob}
      />
      <PipelineStageColumn
        stage="Identifying Candidates"
        jobs={sortedJobs}
        selectJob={selectJob}
      />
      <PipelineStageColumn
        stage="Presenting Shortlist"
        jobs={sortedJobs}
        selectJob={selectJob}
      />
    </div>
  );
};

const PipelineStageColumn = ({
  stage,
  jobs,
  selectJob,
}: {
  stage: JobOpeningStatus;
  jobs: AdminPipelineJob[];
  selectJob: (v: AdminPipelineJob | undefined) => void;
}) => {
  const filteredJobs = useMemo(
    () => jobs.filter((job) => job.status === stage),
    [stage, jobs],
  );

  let dotColor: string;
  switch (stage) {
    case "New Opening":
      dotColor = "bg-red-600";
      break;
    case "Creating Job Spec":
      dotColor = "bg-pink-600";
      break;
    case "Identifying Candidates":
      dotColor = "bg-orange-600";
      break;
    case "Presenting Shortlist":
      dotColor = "bg-blue-600";
      break;
    default:
      dotColor = "bg-violet-600";
  }

  return (
    <div className="min-w-72 p-1 flex-1 flex flex-col gap-2 bg-[#f9f9fb] border border-[#e9eaeb] rounded-[10px]">
      <div className="h-10 py-[21px] px-4 flex flex-row gap-2 self-stretch items-center">
        <div className="flex-1 flex items-center gap-2">
          <div className={cn("h-2.5 w-2.5 rounded-full", dotColor)} />
          <p className="text-base text-[#0c0d0d] font-semibold text-nowrap">
            {stage}
          </p>
          {Boolean(filteredJobs.length) && (
            <div className="p-2 min-h-2.5 min-w-2.5 flex justify-center align-middle rounded shadow-[0px_3px_12px_0px_rgba(28,28,28,0.20)]">
              <span className="text-xs text-[#0c0d0d] font-semibold tracking-[-0.24px]">
                {filteredJobs.length}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-start gap-1 self-stretch">
        {filteredJobs.map((job) => (
          <PipelineJobTile
            key={`job-${job.id}`}
            job={job}
            selectJob={() => selectJob(job)}
          />
        ))}
      </div>
    </div>
  );
};

const PipelineJobTile = ({
  job,
  selectJob,
}: {
  job: AdminPipelineJob;
  selectJob: () => void;
}) => {
  const { setJobStatus, isSettingJobStatus } = useSetJobStatus();

  return (
    <div className="p-4 flex flex-col items-start self-stretch gap-1 bg-white border border-[#e9eaeb] rounded-[10px]">
      <div className="flex flex-row items-center justify-between self-stretch">
        <div className="flex flex-row items-center gap-1">
          <Avatar
            image={{ src: job.company.logoLink }}
            name={job.company.name}
            size={24}
          />
          <p className="text-sm text-[#0c0d0d] font-semibold leading-6">
            {job.role}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <EllipsisVertical
            className="cursor-pointer"
            size={16}
            stroke="#B1B9B8"
            onClick={selectJob}
          />
        </div>
      </div>
      <div className="flex flex-col items-start self-stretch gap-1">
        <PipelineInfo title="Company" value={job.company.name} />
        <PipelineInfo
          title="Company Profile"
          link={`/company/${job.company.id}`}
          expectLink
        />
        <PipelineInfo title="Scope" value={job.scope} />
        <PipelineInfo title="Budget" value={job.budget} />
        <PipelineInfo
          title="Start Date"
          value={
            job.desiredStartDate &&
            dateStringToLocalDate(job.desiredStartDate)?.toLocaleDateString(
              "en-US",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              },
            )
          }
        />
        <PipelineInfo title="FT Potential?" value={job.convertsToFullTime} />
        <PipelineInfo title="Candidates" value={job.candidateCount} />
        <PipelineInfo
          title="Created At"
          value={dateStringToLocalDate(job.createdAt)?.toLocaleDateString(
            "en-US",
            {
              day: "numeric",
              month: "long",
              year: "numeric",
            },
          )}
        />
      </div>
      <div className="flex flex-row justify-between self-stretch gap-1.5">
        <Button
          variant="destructive"
          size="sm"
          disabled={isSettingJobStatus}
          onClick={() =>
            setJobStatus({
              jobId: job.id,
              status: "Role Filled (lost)",
            })
          }
        >
          Lost
        </Button>
        <Button
          variant="secondary"
          size="sm"
          disabled={isSettingJobStatus}
          onClick={() =>
            setJobStatus({
              jobId: job.id,
              currentStatus: job.status,
            })
          }
        >
          Advance <ChevronsRight />
        </Button>
      </div>
    </div>
  );
};

const PipelineInfo = ({
  title,
  value,
  link,
  expectLink,
}: {
  title: string;
  value?: string | number;
  link?: string;
  expectLink?: boolean;
}) => {
  if (expectLink && !link) {
    return;
  }

  return (
    <div className="flex flex-col items-start self-stretch gap-2 border-b last:border-b-0 border-[rgba(24,24,27,0.12)]">
      <div className="pt-0.5 px-1.5 flex flex-row items-center justify-between self-stretch">
        <div className="flex flex-row items-center self-stretch gap-1">
          <p className="text-[10px] text-[#141515] font-medium">{title}</p>
        </div>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-xs text-[#646cff] font-medium hover:underline"
          >
            Link
          </a>
        ) : (
          <p className="text-xs text-[#5c6060] font-medium">
            {value ?? "Unknown"}
          </p>
        )}
      </div>
    </div>
  );
};

export default JobKanban;
