import { useAvailabilitySheet } from "./store";
import { Calendar as CalendarIcon, Clock, X } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import AvailabilityDay from "./availability-day";
import { cn } from "@/lib/utils";
import { AvailabilityDate } from "../../../../lib/datetime";

const SpecificAvailability = () => {
  const entries = useAvailabilitySheet((s) => s.specificAvailabilities);
  const setEntries = useAvailabilitySheet((s) => s.setSpecificAvailabilities);

  return (
    <div>
      <div className="pt-8 pb-4">
        <div className="flex items-center gap-1">
          <CalendarIcon className="size-4" />
          <p className="font-semibold leading-none text-sm">
            Specific Dates & Times
          </p>
        </div>
        <p className="text-sm text-muted-foreground">
          Choose hours on specific days
        </p>
      </div>
      <div
        className={cn("space-y-2", {
          "pb-4": entries.length > 0,
        })}
      >
        {entries.map((e, i) => (
          <div
            key={`specific-availability-${i}`}
            className="flex items-center justify-between w-full border border-border rounded-md p-3"
          >
            <div className="space-y-1">
              <p className="text-wrap font-semibold leading-none text-sm">
                {e.startDate.sameDay(e.endDate)
                  ? e.startDate.display
                  : `${e.startDate.display} - ${e.endDate.display}`}
              </p>

              {e.times.map((t, j) => (
                <div
                  key={`specific-availability-time-${i}-${j}`}
                  className="flex items-center gap-2 text-muted-foreground"
                >
                  <Clock className="size-4" />
                  <p className="text-sm leading-none">
                    {t.start.display} - {t.end.display}
                  </p>
                </div>
              ))}
            </div>

            <X
              className="size-4 min-w-4 hover:cursor-pointer hover:opacity-80"
              onClick={() => setEntries(entries.filter((_, j) => j !== i))}
            />
          </div>
        ))}
      </div>
      <SpecificAvailabilityEntry />
    </div>
  );
};

export default SpecificAvailability;

const SpecificAvailabilityEntry = () => {
  const open = useAvailabilitySheet((s) => s.specificOpen);
  const setOpen = useAvailabilitySheet((s) => s.setSpecificOpen);
  const setForm = useAvailabilitySheet((s) => s.setSpecificAvailabilitiesForm);
  const submit = useAvailabilitySheet((s) => s.confirmSpecificAvailabilities);
  const { selectedDate, timeEntries } = useAvailabilitySheet(
    (s) => s.specificAvailabilitiesForm,
  );

  const canSubmit =
    selectedDate?.to &&
    selectedDate?.from &&
    timeEntries.length > 0 &&
    timeEntries.every(
      (te) =>
        te.start && te.end && !te.start.isPlaceholder && !te.end.isPlaceholder,
    );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={"outline"}>Add Dates & Times</Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        side="left"
        sideOffset={16}
        className="w-auto p-0 flex flex-col items-center grow-0"
      >
        <Calendar
          className="h-full w-full flex"
          classNames={{
            months:
              "flex w-full flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 flex-1",
            month: "space-y-4 w-full flex flex-col",
            table: "w-full h-full border-collapse space-y-1",
            head_row: "",
            row: "w-full mt-2",
            cell: "[&:has([aria-selected].day-outside)]:bg-background",
          }}
          mode="range"
          selected={{
            from: selectedDate?.from?.dateobj(),
            to: selectedDate?.to?.dateobj(),
          }}
          onSelect={(date) =>
            setForm((old) => ({
              ...old,
              selectedDate: {
                from: date?.from ? new AvailabilityDate(date.from) : undefined,
                to: date?.to ? new AvailabilityDate(date.to) : undefined,
              },
            }))
          }
        />
        <div className="p-4 border-t border-border w-full">
          {selectedDate?.to && selectedDate?.from && (
            <p className="text-wrap font-semibold leading-none pb-4">
              {selectedDate.from.sameDay(selectedDate.to)
                ? selectedDate.from.display
                : `${selectedDate.from.display} - ${selectedDate.to.display}`}
            </p>
          )}
          <AvailabilityDay
            entries={timeEntries}
            setEntries={(te) => setForm((f) => ({ ...f, timeEntries: te }))}
          />
          <Button
            className="mt-4 w-full"
            variant="secondary"
            disabled={!canSubmit}
            onClick={() => submit()}
          >
            Add To Schedule
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
