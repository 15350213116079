/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { SettingsFormSchema, settingsFormSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import FilePond from "@/components/ui/file-pond";
import { Button } from "@/components/ui/button";
import { Avatar, InfoLabel } from "@fluentui/react-components";

import useSettingsMutation from "./settings-mutations";
import useResetPasswordMutation from "./password-mutations";
import { User } from "@/queries/user/profile";
import { Checkbox } from "@/components/ui/checkbox";
import { LoaderCircle } from "lucide-react";

const SettingsForm = ({ user }: { user?: User }) => {
  const [editProfile, setEditProfile] = useState(false);

  const {
    mutate: updateSettings,
    isPending: settingsMutationLoading,
    isError: mutationError,
  } = useSettingsMutation();
  const { mutate: resetPassword, isPending: passwordMutationLoading } =
    useResetPasswordMutation();

  const form = useForm<SettingsFormSchema>({
    resolver: async (data, context, opts) => {
      if (user && !data.profilePicture) {
        data.profilePicture = new File([], "");
      }

      return zodResolver(settingsFormSchema)(data, context, opts);
    },
    defaultValues: {
      profilePicture: undefined,
      marketingConsent: user?.marketingConsent ?? false,
    },
  });

  return (
    <div className="max-w-5xl w-full m-auto p-2 md:p-8 flex flex-col gap-6 md:gap-12">
      <div className="flex flex-col gap-3 w-full pb-5 border-b border-b-[#E2E8F0]">
        <p className="text-lg font-semibold leading-6 text-[#1E293B]">
          Account Details
        </p>
        {mutationError && (
          <p className="text-center w-full border border-destructive rounded-md text-destructive p-2 bg-destructive/10">
            Something went wrong when updating your settings, please try again
            later.
          </p>
        )}
      </div>
      <div className="flex flex-col gap-4 md:flex-row md:gap-8 pb-5 border-b border-b-[#E2E8F0]">
        <div className="flex-shrink md:basis-64">
          <InfoLabel
            className="font-semibold"
            info="Your email address is also your user ID. To request a change, please reach out to support@venturous.work"
          >
            <span className="text-sm font-semibold text-[#1E293B]">
              Email Address
            </span>
          </InfoLabel>
        </div>
        <div className="flex flex-col gap-5">
          <p className="text-base text-[#475569]">{user?.emails[0]}</p>
          <Button
            variant="lightSecondary"
            disabled={passwordMutationLoading}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              resetPassword();
            }}
          >
            Reset Password
          </Button>
        </div>
      </div>
      <Form {...form}>
        <form className="space-y-4 w-full">
          <div className="flex flex-col gap-4 md:flex-row md:gap-8 pb-5 border-b border-b-[#E2E8F0]">
            <div className="flex-shrink md:basis-64">
              <p className="text-sm font-semibold text-[#1E293B]">
                Profile Picture
              </p>
            </div>
            <FormField
              control={form.control}
              name="profilePicture"
              render={({ field }) => (
                <FormItem className="flex flex-row gap-4">
                  <div className="flex flex-col gap-1 items-center">
                    <Avatar
                      image={{ src: user?.profilePictureLink }}
                      name={user?.name ?? "Profile picture"}
                      size={72}
                      style={{ width: "88px", height: "88px" }}
                    />
                    <Button
                      variant="link"
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setEditProfile(!editProfile);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                  {editProfile && (
                    <>
                      <FormControl>
                        <FilePond
                          description="Click here to upload your file or drag and drop"
                          maxFileSizeBytes={1000000}
                          value={field.value ? [field.value] : []}
                          accept={"image/*"}
                          onChange={(files) => {
                            field.onChange(files[0]);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </>
                  )}
                </FormItem>
              )}
            />
          </div>

          <div className="w-full flex flex-row gap-4">
            <Controller
              name="marketingConsent"
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(state) => {
                    field.onChange(typeof state === "string" ? false : state);
                  }}
                />
              )}
            />
            <p className="text-sm">
              I agree to receive automated text messages and emails from
              Venturous for marketing updates. Consent is a not a condition of
              purchase. Reply HELP for help and STOP to unsubscribe. Msg
              frequency varies. Msg and data rates may apply. Subject to Terms
              and Privacy Notice.
            </p>
          </div>

          <div className="flex justify-end">
            <Button
              variant="secondary"
              type="submit"
              className="mt-2"
              disabled={settingsMutationLoading || !form.formState.isDirty}
              onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                void form.handleSubmit((data) => updateSettings(data))();
              }}
            >
              {settingsMutationLoading && (
                <LoaderCircle className="size-6 text-white animate-spin" />
              )}
              Save
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default SettingsForm;
