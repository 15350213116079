import { useState, useEffect, useRef } from "react";

export const useDebouncedDims = (delay = 500, id?: string) => {
  const [windowSize, setWindowSize] = useState({
    width:
      (id ? document.getElementById(id)?.scrollWidth : window.innerWidth) ?? 0,
    height:
      (id ? document.getElementById(id)?.scrollHeight : window.innerHeight) ??
      0,
  });
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const ele = document.getElementById(id ?? "root");
    const handleResize = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      let width, height;
      if (id) {
        width = ele?.scrollWidth;
        height = ele?.scrollHeight;
      } else {
        width = window.innerWidth;
        height = window.innerHeight;
      }

      !width && (width = 0);
      !height && (height = 0);

      timeoutRef.current = window.setTimeout(() => {
        setWindowSize({ width, height });
      }, delay);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      window.removeEventListener("resize", handleResize);
    };
  }, [delay, id]);

  return windowSize;
};
