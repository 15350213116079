import { useNavigate } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";

import { CandidateStatus, CompanyInterview, CompanyJobOpening } from "@/types";
import { useMyCompanyQuery } from "@/queries/company/my-company";
import { useCompanyOpportunityQuery } from "@/queries/company/opportunities";

import {
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
  Accordion,
} from "../ui/accordion";
import { Avatar, AvatarImage, AvatarFallback } from "../ui/avatar";
import {
  Building,
  CalendarCheck,
  EllipsisVertical,
  FileCheck2,
  Headset,
  Loader2,
  MapPin,
  RefreshCw,
  User,
  UserCheck,
  UserSearch,
  X,
} from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { ScrollArea } from "../ui/scroll-area";
import { Tooltip } from "../ui/tooltip";

import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

import PreprocessIcon from "@/assets/preprocess_icon.svg";
import { useRejectCandidate } from "@/mutations/company/opening/reject";
import { useCompanyScheduleInterviewSheet } from "../dialogs/company/schedule-interview/store";
import CompanyScheduleInterviewSheet from "../dialogs/company/schedule-interview";
import { useMakeOfferToCandidate } from "@/mutations/company/opening/offer";
import { useCompleteInterview } from "@/mutations/company/opening/complete-interview";
import { useCancelInterview } from "@/mutations/company/opening/cancel-interview";
import { AddOpportunityDialog } from "../dialogs/company/opportunity/add-opportunity";

export const CompanyOpportunityRow = ({
  id,
  opportunity,
  highlight,
}: {
  id: number;
  opportunity: CompanyJobOpening;
  highlight?: boolean;
}) => {
  const { company, companyLoading } = useMyCompanyQuery();
  const { opportunityData, opportunityLoading } = useCompanyOpportunityQuery(
    opportunity.id!,
  );

  const [addOpportunityOpen, setAddOpportunityOpen] = useState<boolean>(false);

  return (
    <Accordion
      collapsible
      type="single"
      className="w-full"
      defaultValue={highlight ? id.toString() : undefined}
    >
      <AccordionItem
        value={id.toString()}
        className="w-full flex flex-col border-b-0"
      >
        <AccordionTrigger className="w-full flex flex-row no-underline hover:no-underline">
          <Avatar
            className={
              "relative size-16 bg-background border-2 border-background shadow-md mr-4"
            }
          >
            {companyLoading ? (
              <div className="size-full bg-muted animate-pulse" />
            ) : (
              <>
                {company?.logoLink && <AvatarImage src={company?.logoLink} />}
                <AvatarFallback>
                  <Building />
                </AvatarFallback>
              </>
            )}
          </Avatar>
          <div className="w-full flex flex-col gap-1">
            <div className="w-full flex flex-col md:flex-row items-center gap-2">
              <div className="flex flex-row items-center gap-2">
                <h4 className="text-lg font-semibold">{opportunity.role}</h4>
                <span className="rounded-full bg-border/50 border text-xs text-muted-foreground px-2 py-1">
                  {`${opportunityData?.candidates?.length ?? 0} Candidate${opportunityData?.candidates?.length !== 1 ? "s" : ""}`}
                </span>
              </div>
              <AddOpportunityDialog
                open={addOpportunityOpen}
                setOpen={setAddOpportunityOpen}
                opening={opportunityData ?? opportunity}
                trigger={
                  <Button
                    variant="link"
                    className="text-xs text-muted-foreground hover:underline"
                  >
                    View & edit details
                  </Button>
                }
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:flex lg:flex-row gap-4 items-center">
              {/* <div className="flex flex-row gap-2 items-center">
                <p className="text-xs text-muted-foreground">Type</p>
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  {opportunity.convertsToFullTime === "Yes"
                    ? "Full Time"
                    : "Part Time"}
                </span>
              </div> */}
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs text-muted-foreground">Time</p>
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  {opportunity.scope}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs text-muted-foreground">Pay Rate</p>
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  {opportunity.budget}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs text-muted-foreground">Start Date</p>
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  {opportunity.desiredStartDate
                    ? dateStringToLocalDate(
                        opportunity.desiredStartDate,
                      )?.toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    : "Not Specified"}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-xs text-muted-foreground">Status</p>
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  {opportunity.status}
                </span>
              </div>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="w-full">
          {opportunityLoading || !opportunityData ? (
            <Loader2 className="animate-spin mx-auto" />
          ) : opportunityData.candidates &&
            opportunityData.candidates.length > 0 ? (
            <div className="w-full flex flex-col rounded-xl border">
              {(opportunity.status === "Role Filled (won)"
                ? opportunityData.candidates.filter(
                    (candidate) => candidate.status === "Accepted by Client",
                  )
                : opportunityData.candidates
              ).map((candidate) => (
                <CompanyOpportunityCandidate
                  key={candidate.id}
                  candidate={candidate}
                  opportunity={opportunityData}
                />
              ))}
            </div>
          ) : (
            <p className="w-full text-xs text-muted-foreground text-center">
              No candidates yet- check back later!
            </p>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const CompanyOpportunityCandidate = ({
  candidate,
  opportunity,
}: {
  opportunity: CompanyJobOpening;
  candidate: CandidateStatus;
}) => {
  const navigate = useNavigate();
  const { setOpen, setOpportunity, setCandidate, setInterview, setIsUpdating } =
    useCompanyScheduleInterviewSheet();

  const { rejectCandidate, isRejecting } = useRejectCandidate();
  const { makeOfferToCandidate, isOffering } = useMakeOfferToCandidate();
  const { completeInterview, isCompleting } = useCompleteInterview();
  const { cancelInterview, isCancelling } = useCancelInterview();

  const [stage, setStage] = useState<string>("Matched");
  const [offerDialogOpen, setOfferDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);

  useEffect(() => {
    if (candidate.status === "Making Offer") {
      setOfferDialogOpen(false);
    } else if (candidate.status === "Rejected for Role") {
      setRejectDialogOpen(false);
    }
  }, [candidate.status]);

  useMemo(() => {
    if (candidate.status === "Rejected for Role") {
      setStage("Rejected");
    } else if (opportunity.interviews) {
      let interviewsCompleted = 0;
      let pendingInterview = false;

      for (const interview of opportunity.interviews) {
        if (
          interview.interviewCompleted.find(
            (id) => id === candidate.candidateId,
          )
        ) {
          interviewsCompleted++;
        } else if (
          interview.interviewRequested.find(
            (id) => id === candidate.candidateId,
          )
        ) {
          pendingInterview = true;
        }
      }

      if (candidate.status === "Accepted by Client") {
        setStage("Hired");
      } else if (candidate.status === "Making Offer") {
        setStage("Offer Made");
      } else if (interviewsCompleted === opportunity.interviews.length) {
        setStage("Interviews Complete");
      } else if (pendingInterview) {
        setStage(
          `Scheduled for Round ${interviewsCompleted + 1}/${opportunity.interviews.length}`,
        );
      } else if (interviewsCompleted > 0) {
        setStage(
          `Completed ${interviewsCompleted}/${opportunity.interviews.length} Interviews`,
        );
      } else {
        setStage("Matched");
      }
    }
  }, [opportunity, candidate]);

  return (
    <div
      key={candidate.id}
      className="w-full flex flex-col gap-2 md:flex-row md:gap-0 justify-between items-center p-4 border-b last-of-type:border-b-0"
    >
      <div className="flex flex-row items-center gap-4">
        <Avatar
          className={
            "relative size-12 bg-background border-2 border-background shadow-md"
          }
        >
          {candidate.profilePictureLink && (
            <AvatarImage src={candidate.profilePictureLink} />
          )}
          <AvatarFallback>
            <User />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <h5 className="text-lg font-semibold">{candidate.name}</h5>
          <div className="grid grid-cols-2 gap-2 md:flex md:flex-row items-center">
            <span className="flex flex-row items-center gap-1 border-r pr-2">
              <p
                className={`text-sm font-semibold ${stage === "Matched" ? "text-purple-600" : stage === "Hired" ? "text-green-600" : stage === "Rejected" ? "text-red-600" : "text-orange-600"}`}
              >
                {stage}
              </p>
            </span>
            <div className="flex flex-row items-center gap-2 md:border-r px-2">
              <User className="size-4 text-muted-foreground" />
              <p className="text-sm text-muted-foreground">
                {candidate.headline}
              </p>
            </div>
            <div className="col-span-2 flex flex-row items-center gap-2 md:px-2">
              <MapPin className="size-4 text-muted-foreground" />
              <p className="text-sm text-muted-foreground">
                {candidate.location}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-2 md:gap-4">
        {opportunity.status !== "Role Filled (won)" &&
          opportunity.status !== "Role Filled (lost)" &&
          stage !== "Rejected" && (
            <Dialog open={rejectDialogOpen} onOpenChange={setRejectDialogOpen}>
              <DialogTrigger asChild>
                <Tooltip content="Reject candidate">
                  <Button variant="outline" size="icon" disabled={isRejecting}>
                    {isRejecting ? <Loader2 className="animate-spin" /> : <X />}
                  </Button>
                </Tooltip>
              </DialogTrigger>
              <DialogContent className="w-full max-w-[min(calc(100vw-2rem),768px)] p-0">
                <DialogHeader className="p-6">
                  <div className="flex items-center gap-2">
                    <img
                      src={PreprocessIcon}
                      alt="Preprocess Icon"
                      className=""
                    />
                    <DialogTitle>
                      Are You Sure You Want to Decline This Candidate?
                    </DialogTitle>
                  </div>
                  <DialogDescription className="sr-only">
                    Confirm that you are ready to decline a candidate and hit
                    &quot;Confirm&quot;
                  </DialogDescription>
                </DialogHeader>
                <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
                  <p>
                    By clicking &quot;Confirm,&quot; you&apos;ll remove the
                    candidate from consideration for this role.
                  </p>
                  <br />
                  <p>
                    Please double-check your decision before proceeding—once
                    confirmed, you will no longer be able to interview or make
                    an offer to the candidate.
                  </p>
                  <br />
                  <div className="flex justify-between">
                    <Button
                      type="button"
                      className="mt-2"
                      variant="outline"
                      onClick={() => setRejectDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="mt-2"
                      disabled={isRejecting}
                      onClick={() => {
                        rejectCandidate({
                          openingId: opportunity.id!,
                          candidateStatusId: candidate.id,
                        });
                      }}
                    >
                      {isRejecting ? (
                        <Loader2 className="animate-spin" />
                      ) : null}
                      Confirm
                    </Button>
                  </div>
                </ScrollArea>
              </DialogContent>
            </Dialog>
          )}
        <Tooltip content="See details">
          <Button
            variant="outline"
            size="icon"
            onClick={() => {
              navigate(
                `/opening/${opportunity.id}/application/${candidate.id}`,
              );
            }}
          >
            <UserSearch />
          </Button>
        </Tooltip>
        {opportunity.contracts.length > 0 &&
        opportunity.status === "Role Filled (won)" ? (
          <Button
            variant="outline"
            disabled={!opportunity.contracts[0]}
            onClick={() => {
              window.open(opportunity.contracts[0]!.link, "_blank");
            }}
          >
            <FileCheck2 />
            View Contract
          </Button>
        ) : opportunity.status !== "Role Filled (lost)" &&
          opportunity.status !== "Role Filled (won)" ? (
          stage.startsWith("Completed ") || stage === "Matched" ? (
            <Button
              variant="outline"
              onClick={() => {
                if (opportunity.interviews) {
                  const interviews = opportunity.interviews?.sort(
                    (a, b) => a.step - b.step,
                  );

                  let interview: CompanyInterview = interviews[0]!;
                  for (let i = 0; i < interviews.length; i++) {
                    if (
                      interviews[i]!.interviewCompleted.find(
                        (id) => id === candidate.candidateId,
                      )
                    ) {
                      const nextInterview = interviews[i + 1];
                      if (nextInterview) {
                        interview = nextInterview;
                      }
                    } else if (
                      interviews[i]!.interviewRequested.find(
                        (id) => id === candidate.candidateId,
                      )
                    ) {
                      interview = interviews[i]!;
                      break;
                    }
                  }

                  setOpportunity(opportunity);
                  setCandidate(candidate);
                  setInterview(interview);
                  setOpen(true);
                }
              }}
            >
              <Headset />
              Schedule Interview
            </Button>
          ) : stage === "Interviews Complete" ? (
            <Dialog open={offerDialogOpen} onOpenChange={setOfferDialogOpen}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  disabled={
                    isOffering ||
                    opportunity.candidates?.some(
                      ({ status }) =>
                        status === "Making Offer" ||
                        status === "Accepted by Client",
                    )
                  }
                >
                  {isOffering ? (
                    <Loader2 className="animate-spin" />
                  ) : (
                    <UserCheck />
                  )}
                  Make Offer
                </Button>
              </DialogTrigger>
              <DialogContent className="w-full max-w-[min(calc(100vw-2rem),768px)] p-0">
                <DialogHeader className="p-6">
                  <div className="flex items-center gap-2">
                    <img
                      src={PreprocessIcon}
                      alt="Preprocess Icon"
                      className=""
                    />
                    <DialogTitle>
                      Are You Sure You Want to Make an Offer?
                    </DialogTitle>
                  </div>
                  <DialogDescription className="sr-only">
                    Confirm that you are ready to make an offer to a candidate
                    and hit &quot;Confirm&quot;
                  </DialogDescription>
                </DialogHeader>
                <ScrollArea className="max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
                  <p>
                    By clicking &quot;Confirm,&quot; you&apos;ll officially move
                    this candidate to the contract stage. This action signals
                    that you&apos;re ready to finalize terms and bring them
                    onboard as a fractional executive.
                  </p>
                  <br />
                  <p>
                    Please double-check your decision before proceeding—once
                    confirmed, the candidate will be notified and the hiring
                    process will move forward.
                  </p>
                  <br />
                  <div className="flex justify-between">
                    <Button
                      type="button"
                      className="mt-2"
                      variant="outline"
                      onClick={() => setOfferDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      type="submit"
                      className="mt-2"
                      disabled={isOffering}
                      onClick={() => {
                        makeOfferToCandidate({
                          openingId: opportunity.id!,
                          candidateStatusId: candidate.id,
                        });
                      }}
                    >
                      {isOffering ? <Loader2 className="animate-spin" /> : null}
                      Confirm
                    </Button>
                  </div>
                </ScrollArea>
              </DialogContent>
            </Dialog>
          ) : stage === "Offer Made" ? (
            <Button variant="outline" disabled>
              Offer Made
            </Button>
          ) : stage.startsWith("Scheduled ") ? (
            <>
              <Button
                variant="outline"
                onClick={() => {
                  const interview = opportunity.interviews
                    ?.sort((a, b) => a.step - b.step)
                    .filter((i) =>
                      i.interviewRequested.includes(candidate.candidateId),
                    );

                  if (interview && interview.length > 0) {
                    completeInterview({
                      openingId: opportunity.id!,
                      candidateStatusId: candidate.candidateId,
                      interviewId: interview[interview.length - 1]!.id,
                    });
                  }
                }}
                disabled={isCompleting}
              >
                {isCompleting ? (
                  <Loader2 className="animate-spin" />
                ) : (
                  <CalendarCheck />
                )}
                Complete Interview
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="icon">
                    <EllipsisVertical />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent sideOffset={8} className="mr-[2.5rem]">
                  <DropdownMenuItem
                    onClick={() => {
                      if (opportunity.interviews) {
                        const interviews = opportunity.interviews?.sort(
                          (a, b) => a.step - b.step,
                        );

                        let interview: CompanyInterview = interviews[0]!;
                        for (let i = 0; i < interviews.length; i++) {
                          if (
                            interviews[i]!.interviewCompleted.find(
                              (id) => id === candidate.candidateId,
                            )
                          ) {
                            const nextInterview = interviews[i + 1];
                            if (nextInterview) {
                              interview = nextInterview;
                            }
                          } else if (
                            interviews[i]!.interviewRequested.find(
                              (id) => id === candidate.candidateId,
                            )
                          ) {
                            interview = interviews[i]!;
                            break;
                          }
                        }

                        setOpportunity(opportunity);
                        setCandidate(candidate);
                        setInterview(interview);
                        setIsUpdating(true);
                        setOpen(true);
                      }
                    }}
                  >
                    <RefreshCw />
                    <span>Reschedule Interview</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      const interview = opportunity.interviews
                        ?.sort((a, b) => a.step - b.step)
                        .filter((i) =>
                          i.interviewRequested.includes(candidate.candidateId),
                        );

                      if (interview && interview.length > 0) {
                        cancelInterview({
                          openingId: opportunity.id!,
                          candidateStatusId: candidate.candidateId,
                          interviewId: interview[interview.length - 1]!.id,
                        });
                      }
                    }}
                    disabled={isCancelling}
                  >
                    {isCancelling ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <X />
                    )}
                    <span>Cancel Interview</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          ) : null
        ) : null}
      </div>
      <CompanyScheduleInterviewSheet />
    </div>
  );
};
