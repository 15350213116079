/* eslint-disable @typescript-eslint/no-misused-promises */
import { useFieldArray, useForm } from "react-hook-form";
import { companyAddTeamFormSchema, CompanyAddTeamFormSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { useAddTeamMember } from "@/mutations/company/team/add-team-member";
import { Button } from "@/components/ui/button";
import { Loader2, X } from "lucide-react";

const CompanyAddTeamForm = ({ onSuccess }: { onSuccess?: () => void }) => {
  const {
    addTeamMember,
    isAddingTeamMember: isPending,
    teamMemberError: mutationError,
  } = useAddTeamMember(onSuccess);

  const form = useForm<CompanyAddTeamFormSchema>({
    resolver: async (data, context, opts) => {
      return zodResolver(companyAddTeamFormSchema)(data, context, opts);
    },
    defaultValues: {
      team: [
        {
          name: "",
          email: "",
          accountType: "Collaborator",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "team",
  });

  function onSubmit(data: CompanyAddTeamFormSchema) {
    if (isPending) return;
    addTeamMember(data.team);
  }

  return (
    <Form {...form}>
      {mutationError && (
        <p className="text-center w-full border border-destructive rounded-md text-destructive p-2 bg-destructive/10">
          Something went wrong when adding team members, please try again later.
        </p>
      )}
      <form className="space-y-4 w-full" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="w-full">
          {fields.map((_, index) => {
            return (
              <div
                key={`team-form-${index}`}
                className="flex flex-row gap-2 py-3 border-b border-[#e9eaeb] last:border-b-0 md:border-b-0"
              >
                <div className="flex-1 flex flex-col md:flex-row gap-2">
                  <FormField
                    control={form.control}
                    name={`team.${index}.name`}
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel required>Name</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="John Doe" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`team.${index}.email`}
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel required>Email</FormLabel>
                        <FormControl>
                          <Input {...field} placeholder="john.doe@gmail.com" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={`team.${index}.accountType`}
                    render={({ field }) => (
                      <FormItem className="flex-1">
                        <FormLabel required>Permission</FormLabel>
                        <FormControl>
                          <Select
                            name={field.name}
                            value={field.value}
                            onValueChange={field.onChange}
                            disabled={field.disabled}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder={"Select one"} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="Admin">
                                <div className="flex flex-row items-center gap-1">
                                  <div className="h-2 w-2 bg-[#d23b15] rounded-full" />
                                  Admin
                                </div>
                              </SelectItem>
                              <SelectItem value="Collaborator">
                                <div className="flex flex-row items-center gap-1">
                                  <div className="h-2 w-2 bg-[#0d7163] rounded-full" />
                                  Collaborator
                                </div>
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {index ? (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="md:self-end"
                    onClick={(ev) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      remove(index);
                    }}
                  >
                    <X size={20} className="!stroke-[#212121]" />
                  </Button>
                ) : (
                  <div className="h-10 w-10" />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-around">
          <Button
            variant="default"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              append({
                name: "",
                email: "",
                accountType: "Collaborator",
              });
            }}
          >
            Add another team member
          </Button>
        </div>

        <div className="flex justify-between">
          <Button variant="outline" onClick={() => onSuccess?.()}>
            Cancel
          </Button>
          <Button
            disabled={isPending || !form.formState.isValid}
            variant="secondary"
            type="submit"
          >
            {isPending && <Loader2 className="animate-spin" />}
            Send Invite
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CompanyAddTeamForm;
