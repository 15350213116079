import { TimeRange } from "./store";
import { CirclePlus, X } from "lucide-react";
import { TimeInput } from "@/components/ui/input";
import { AvailabilityTime } from "../../../../lib/datetime";

const AvailabilityDay = ({
  day,
  entries,
  setEntries,
}: {
  day?: number;
  entries: TimeRange[];
  setEntries: (entries: TimeRange[]) => void;
}) => {
  const dayName = new Date(0, 0, day ?? 0).toLocaleString("en-US", {
    weekday: "long",
  });

  return (
    <div className="flex items-start justify-start gap-3 w-full">
      {day !== undefined && (
        <div
          className="flex justify-center items-center size-10 bg-secondary rounded-full min-w-10 shrink-0 leading-none"
          title={dayName}
        >
          <p className="text-secondary-foreground font-semibold leading-[0] text-lg select-none">
            {dayName.charAt(0)}
          </p>
        </div>
      )}
      <div className="space-y-1.5">
        {!entries.length ? (
          <div className="h-10 flex items-center justify-start">
            <p>{day !== undefined ? "Unavailable" : "Add your availability"}</p>
          </div>
        ) : (
          entries.map((a, i) => (
            <div
              key={`availability-entry-${day}-${i}`}
              className="flex items-center gap-2"
            >
              <TimeInput
                value={a.start.input}
                onChange={e => {
                  const input = e.target.value.toString();
                  const t = new AvailabilityTime(input, "hh:mm");
                  setEntries(
                    entries.map((a, j) =>
                      j === i
                        ? {
                            id: a.id,
                            end:
                              a.end.lessThan(t) && !a.end.isPlaceholder
                                ? t
                                : a.end,
                            start: t,
                          }
                        : a,
                    ),
                  );
                }}
              />
              <span>-</span>
              <TimeInput
                value={a.end.input}
                onChange={e => {
                  const input = e.target.value.toString();
                  const t = new AvailabilityTime(input, "hh:mm");
                  setEntries(
                    entries.map((a, j) =>
                      j === i
                        ? {
                            id: a.id,
                            end: t,
                            start:
                              t.lessThan(a.start) && !a.start.isPlaceholder
                                ? t
                                : a.start,
                          }
                        : a,
                    ),
                  );
                }}
              />
              <X
                className="size-4 min-w-4 hover:cursor-pointer hover:opacity-80"
                onClick={() => setEntries(entries.filter((_, j) => j !== i))}
              />
            </div>
          ))
        )}
      </div>
      <div className="shrink-0 h-10 flex items-center self-end">
        <CirclePlus
          className="size-4 hover:cursor-pointer hover:opacity-80"
          onClick={() =>
            setEntries([
              ...entries,
              {
                start: AvailabilityTime.newPlaceholder(),
                end: AvailabilityTime.newPlaceholder(),
              },
            ])
          }
        />
      </div>
    </div>
  );
};

export default AvailabilityDay;
