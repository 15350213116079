import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "../utils";

import request from "@/lib/api/handler";
import { CandidateJobOpening } from "@/types";

export type Opportunity = CandidateJobOpening;

export const useCandidateOpportunities = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.candidateOpportunities,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<Opportunity[]>({
        method: "GET",
        path: "/api/jobopening/self",
        token,
      });

      return resp.filter(
        opportunity => opportunity.candidateStatus.status !== "Identified",
      );
    },
  });

  return {
    candidateOpportunitiesLoading: isLoading,
    candidateOpportunities: data ?? null,
    candidateOpportunitiesError: error ?? null,
  };
};
