interface TimezoneGroup {
  name: string;
  timezones: Timezone[];
}

interface Timezone {
  abbr: string;
  name: string;
  offset: number;
}

export function getCurrentTimezone() {
  const date = new Date();
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZoneName: "short",
  });
  const parts = formatter.formatToParts(date);

  const timeZoneAbbreviation = parts.find(
    part => part.type === "timeZoneName",
  )?.value;
  if (!timeZoneAbbreviation) {
    return null;
  }

  return timeZoneAbbreviation.toLowerCase();
}

export const timezones: TimezoneGroup[] = [
  {
    name: "North America",
    timezones: [
      {
        name: "Eastern Standard Time (EST)",
        abbr: "est",
        offset: -5,
      },
      {
        name: "Eastern Daylight Time (EDT)",
        abbr: "edt",
        offset: -4,
      },
      {
        name: "Central Standard Time (CST)",
        abbr: "cst",
        offset: -6,
      },
      {
        name: "Mountain Standard Time (MST)",
        abbr: "mst",
        offset: -7,
      },
      {
        name: "Pacific Standard Time (PST)",
        abbr: "pst",
        offset: -8,
      },
      {
        name: "Alaska Standard Time (AKST)",
        abbr: "akst",
        offset: -9,
      },
      {
        name: "Hawaii Standard Time (HST)",
        abbr: "hst",
        offset: -10,
      },
    ],
  },
  {
    name: "Europe & Africa",
    timezones: [
      {
        name: "Greenwich Mean Time (GMT)",
        abbr: "gmt",
        offset: 0,
      },
      {
        name: "Central European Time (CET)",
        abbr: "cet",
        offset: 1,
      },
      {
        name: "Eastern European Time (EET)",
        abbr: "eet",
        offset: 2,
      },
      {
        name: "Western European Summer Time (WEST)",
        abbr: "west",
        offset: 1,
      },
      {
        name: "Central Africa Time (CAT)",
        abbr: "cat",
        offset: 2,
      },
      {
        name: "East Africa Time (EAT)",
        abbr: "eat",
        offset: 3,
      },
    ],
  },
  {
    name: "Asia",
    timezones: [
      {
        name: "Moscow Time (MSK)",
        abbr: "msk",
        offset: 3,
      },
      {
        name: "India Standard Time (IST)",
        abbr: "ist",
        offset: 5.5,
      },
      {
        name: "China Standard Time (CST)",
        abbr: "cst_china",
        offset: 8,
      },
      {
        name: "Japan Standard Time (JST)",
        abbr: "jst",
        offset: 9,
      },
      {
        name: "Korea Standard Time (KST)",
        abbr: "kst",
        offset: 9,
      },
      {
        name: "Indonesia Central Standard Time (WITA)",
        abbr: "ist_indonesia",
        offset: 8,
      },
    ],
  },
  {
    name: "Australia & Pacific",
    timezones: [
      {
        name: "Australian Western Standard Time (AWST)",
        abbr: "awst",
        offset: 8,
      },
      {
        name: "Australian Central Standard Time (ACST)",
        abbr: "acst",
        offset: 9.5,
      },
      {
        name: "Australian Eastern Standard Time (AEST)",
        abbr: "aest",
        offset: 10,
      },
      {
        name: "New Zealand Standard Time (NZST)",
        abbr: "nzst",
        offset: 12,
      },
      {
        name: "Fiji Time (FJT)",
        abbr: "fjt",
        offset: 12,
      },
    ],
  },
  {
    name: "South America",
    timezones: [
      {
        name: "Argentina Time (ART)",
        abbr: "art",
        offset: -3,
      },
      {
        name: "Bolivia Time (BOT)",
        abbr: "bot",
        offset: -4,
      },
      {
        name: "Brasilia Time (BRT)",
        abbr: "brt",
        offset: -3,
      },
      {
        name: "Chile Standard Time (CLT)",
        abbr: "clt",
        offset: -4,
      },
    ],
  },
] satisfies TimezoneGroup[];
