import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { AdminSummary } from "@/queries/admin/dashboard";
import { AdminCompany } from "@/queries/admin/companies";

export const useSetCompanyAcknowledged = (companyId: string) => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async () => {
      const token = await getAccessTokenSilently();

      return request<AdminCompany>({
        method: "PATCH",
        path: `/api/admin/companies/${companyId}`,
        token,
        body: JSON.stringify({ acknowledged: true }),
      });
    },
    onSuccess: (data) => {
      // Fetch any existing data
      const prev = qc.getQueryData<AdminCompany[]>(QueryKeys.adminCompanies);

      // If there is existing data, update it
      if (prev) {
        // Make a copy and check find the updated element
        const companies = [...prev];
        const index = companies.findIndex((company) => company.id === data.id);

        // If the element is found
        if (index !== -1 && companies[index]) {
          // Update it
          companies[index] = data;

          qc.setQueryData(QueryKeys.adminCompanies, companies);
        } else {
          // If the entry couldn't be found, invalidate and fetch new data
          void qc.invalidateQueries({
            queryKey: QueryKeys.adminCompanies,
          });
        }
      }

      // Update the dashboard number
      const dashboardPrev = qc.getQueryData<AdminSummary>(
        QueryKeys.adminDashboard,
      );

      if (dashboardPrev) {
        qc.setQueryData(QueryKeys.adminDashboard, {
          ...dashboardPrev,
          newCompaniesCount: dashboardPrev.newCompaniesCount - 1,
        });
      }
    },
  });

  return {
    setCompanyAcknowledged: mutate,
    isSettingCompanyAcknowledged: isPending,
    setCompanyAcknowledgedError: error,
  };
};
