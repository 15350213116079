import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { LoaderCircle } from "lucide-react";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:ring-0 focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    // TODO: Handle the icon stroke issue better
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent-og hover:text-accent-og-foreground hover:border-accent-og [&_svg.lucide]:stroke-button-icon",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        lightSecondary: "bg-secondary/30 text-[#003C32] hover:bg-secondary/40",
        ghost:
          "hover:bg-accent-og hover:text-accent-og-foreground hover:border-secondary [&_svg.lucide]:stroke-button-icon",
        ghostWithIcon:
          "hover:bg-accent-og hover:text-accent-og-foreground hover:border-secondary",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  wrapperClassName?: string;
  loaderClassName?: string;
  asChild?: boolean;
  loading?: boolean;
  childFullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading,
      children,
      wrapperClassName,
      loaderClassName,
      childFullWidth,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          "focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent",
          "focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent hover:outline-none",
          !className?.includes("hidden") && "grid grid-cols-[auto] grid-rows-1",
        )}
        ref={ref}
        {...props}
        disabled={loading ? true : props.disabled}
      >
        <>
          <div
            className={cn(
              "flex items-center justify-center gap-2 z-10",
              wrapperClassName,
              "col-start-1 row-start-1 place-self-center",
              "focus:ring-0 focus:ring-transparent focus:ring-offset-0",
              loading ? "text-transparent" : "",
              childFullWidth && "w-full",
            )}
          >
            {children}
          </div>
          {loading && (
            <LoaderCircle
              className={cn(
                "h-5 w-5 animate-spin col-start-1 row-start-1 place-self-center",
                loaderClassName,
              )}
            />
          )}
        </>
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
