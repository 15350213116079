import { z } from "zod";
import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import {
  AdminJobCandidateDetails,
  AdminJobDetails,
} from "@/queries/admin/job-details";

export const candidateOpportunityNotesFormSchema = z.object({
  notes: z.string({ required_error: "Note is required" }),
});

export type CandidateOpportunityNotesFormSchema = z.infer<
  typeof candidateOpportunityNotesFormSchema
>;

export const useSetCandidateOpportunityNotes = (
  openingId: string,
  statusId: string,
) => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({ notes }: CandidateOpportunityNotesFormSchema) => {
      const token = await getAccessTokenSilently();

      return request<AdminJobCandidateDetails>({
        method: "PATCH",
        path: `/api/admin/candidate_status/${statusId}`,
        token,
        body: JSON.stringify({
          notes,
        }),
      });
    },
    onSuccess: (data) => {
      // Fetch any existing data
      const prev = qc.getQueryData<AdminJobDetails>(
        QueryKeys.adminJobDetails(openingId),
      );

      // If there is existing data, update it
      if (prev) {
        // Make a copy and check find the updated element
        const candidates = [...prev.candidates];
        const candidateIndex = candidates.findIndex(
          (candidate) => candidate.candidateId === data.candidateId,
        );

        // If the element is found
        if (candidateIndex !== -1 && candidates[candidateIndex]) {
          // Update it
          candidates[candidateIndex] = data;

          qc.setQueryData(QueryKeys.adminJobDetails(openingId), {
            ...prev,
            candidates,
          });
        } else {
          // If the entry couldn't be found, invalidate and fetch new data
          void qc.invalidateQueries({
            queryKey: QueryKeys.adminJobDetails(openingId),
          });
        }
      }
    },
  });

  return {
    setCandidateOpportunityNotes: mutate,
    isSettingCandidateOpportunityNotes: isPending,
    setCandidateOpportunityNotesError: error,
  };
};
