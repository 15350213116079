import OpportunityExplanationDialog from "@/components/dialogs/company/opportunity/create-edit-explanation";
import CompanyOpportunityForm from "@/components/forms/company/opportunity";
import { useEffect } from "react";

export default function CreateCompanyOpportunityPage() {
  useEffect(() => {
    document.title = "Create a Job Opportunity";
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 py-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-secondary">
            New Job Opportunity
          </h2>
          <OpportunityExplanationDialog />
        </div>
        <div className="flex gap-5 self-stretch items-stretch">
          <div className="card flex-col gap-6 w-full">
            <CompanyOpportunityForm />
          </div>
        </div>
      </div>
    </>
  );
}
