import { memo, useEffect, useState } from "react";

// Memoized component to handle previewing profile pictures/logos/etc
// This displays a new image if it exists, the old image if it doesn't, and a blank background if neither exist
const ImageUploadPreview = memo(
  ({
    newImageFile,
    oldImageLink,
  }: {
    newImageFile?: File | null;
    oldImageLink?: string;
  }) => {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
      let objectUrl: string | undefined;

      if (newImageFile) {
        objectUrl = URL.createObjectURL(newImageFile);
        setImageUrl(objectUrl);
      } else {
        setImageUrl(oldImageLink);
      }

      // Cleanup function to revoke the object URL when it changes or on component unmount
      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
        }
      };
    }, [newImageFile, oldImageLink]);

    return (
      <div
        className="flex w-[100px] h-[100px] self-center justify-center items-center border-slate border-solid border-2"
        style={{
          backgroundImage: imageUrl ? `url(${imageUrl})` : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
    );
  },
);
ImageUploadPreview.displayName = "ImageUploadPreview";

export default ImageUploadPreview;
