import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";
import { useProfileQuery } from "../user/profile";
import request from "@/lib/api/handler";

export interface CompanyResource {
  id: string;
  headline: string;
  summary: string;
  image: string;
  link: string;
}

export const useCompanyResourcesQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfileQuery();

  const { isLoading, data, error } = useQuery({
    queryKey: QueryKeys.companyResources,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!profile?.companyAccounts.length,
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const resp = await request<CompanyResource[]>({
        method: "GET",
        path: "/api/resource/company",
        token,
      });

      return resp.slice(-6);
    },
  });

  return {
    companyResourcesLoading: isLoading,
    companyResources: data ?? null,
    companyResourcesError: error ?? null,
  };
};
