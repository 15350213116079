import { useMemo, useState } from "react";

import { cn } from "@/lib/utils";
import { Spinner } from "@fluentui/react-components";
import { Search } from "lucide-react";

import CompaniesTable from "./companies-table";
// import CompanyDetailsDrawer from "./company-drawer";

import {
  //   AdminCompany,
  useAdminCompaniesQuery,
} from "@/queries/admin/companies";

const Companies = () => {
  //   const [selectedCompany, setSelectedCompany] = useState<AdminCompany>();
  const [searchString, setSearchString] = useState("");

  const { adminCompanies, adminCompaniesLoading } = useAdminCompaniesQuery();

  const companies = useMemo(() => {
    if (searchString) {
      const filterString = searchString.toLowerCase();

      return adminCompanies?.filter((company) =>
        company.name?.toLowerCase().includes(filterString),
      );
    } else {
      return adminCompanies;
    }
  }, [adminCompanies, searchString]);

  const count = useMemo(() => companies?.length, [companies]);

  if (adminCompaniesLoading) {
    return (
      <div>
        <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
          Companies
        </h3>
        <div className="w-full flex-col justify-around align-middle">
          <Spinner size="huge" />
          <p className="text-center text-base font-medium text-[#727E94]">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h3 className="py-4 text-2xl font-semibold group-hover:underline text-left">
        Companies
      </h3>
      <div className="w-full flex flex-col gap-4 items-start self-stretch">
        <div className="flex flex-col md:flex-row md:items-center justify-between self-stretch">
          <p className="text-lg text-[#181d27] font-semibold">
            {`${count} ${count === 1 ? "Company" : "Companies"}`}
          </p>
          <div className="flex flex-row items-center gap-3">
            <div
              className={cn(
                "flex flex-row items-center gap-0.5",
                "border-b border-[#57576B] transition-colors focus-within:border-black",
              )}
            >
              <Search />
              <input
                type="text"
                className={cn(
                  "flex h-10 w-full min-w-48 px-3 py-2 text-base",
                  "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
                )}
                placeholder="Search for any company"
                value={searchString}
                onChange={(ev) => {
                  ev.preventDefault();
                  setSearchString(ev.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <CompaniesTable
          companies={companies}
          //   setSelectedCompany={setSelectedCompany}
        />
      </div>
      {/* <CompanyDetailsDrawer
        company={selectedCompany}
        setOpen={() => setSelectedCompany(undefined)}
      /> */}
    </div>
  );
};

export default Companies;
