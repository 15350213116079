import { useMutation } from "@tanstack/react-query";
import { SettingsFormSchema } from "./schema";
import { useAuth0 } from "@auth0/auth0-react";
import request from "@/lib/api/handler";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";
import { toast } from "sonner";
import { User } from "@/queries/user/profile";

const useSettingsMutation = () => {
  const invalidate = useInvalidateQuery();
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (data: SettingsFormSchema) => {
      const token = await getAccessTokenSilently();
      const body = buildFormData(data);

      const response: User = await request({
        method: "PATCH",
        token,
        path: "/api/user",
        body,
        contentType: "application/x-www-form-urlencoded",
      });

      return response;
    },
    async onSettled(_, error) {
      if (!error) {
        toast.success("Settings updated!");
      } else {
        toast.error("Failed to update settings");
      }

      await invalidate(QueryKeys.myProfile);
    },
  });
};

function buildFormData(data: SettingsFormSchema) {
  const body = new FormData();

  body.append("marketingConsent", data.marketingConsent.toString());

  if (data.profilePicture) {
    body.append("profilePicture", data.profilePicture);
  }

  return body;
}

export default useSettingsMutation;
