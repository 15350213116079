import { useProfileQuery } from "@/queries/user/profile";
import SettingsForm from "@/components/forms/settings";
import { Loader2 } from "lucide-react";

const Settings = () => {
  const { profile, profileLoading } = useProfileQuery();

  if (profileLoading) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center gap-8 m-8">
        <Loader2 className="animate-spin" />
        <div className="flex flex-col gap-1 items-stretch text-center">
          <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
            Fetching data
          </p>
          <p className="text-sm font-medium text-[#727E94]">
            Just a few seconds left
          </p>
        </div>
      </div>
    );
  }

  return <SettingsForm user={profile} />;
};

export default Settings;
