import { z } from "zod";

/*
    data.append("role", jobObject.role);
    data.append("scope", jobObject.scope);
    data.append("budget", jobObject.budget);
    data.append("desiredStartDate", jobObject.desiredStartDate);
    data.append("shortDescription", jobObject.shortDescription);
    data.append("keyResponsibilities", jobObject.keyResponsibilities);
    data.append("convertsToFullTime", jobObject.convertsToFullTime);
    data.append("matchCriteria1", jobObject.matchCriteria1);
    data.append("matchCriteria2", jobObject.matchCriteria2);
    data.append("matchCriteria3", jobObject.matchCriteria3);
    data.append("noteForVenturous", jobObject.noteForVenturous);
*/

const interviewSchema = z.object({
  id: z.string().optional(),
  name: z
    .string({ required_error: "Interview name is required" })
    .min(1, "Interview name is required"),
  duration: z.coerce
    .number({ required_error: "Duration is required" })
    .min(0, "Duration must be greater than 0"),
  participants: z
    .string({ required_error: "Participant is required" })
    .array()
    .optional(),
});

const schema = z.object({
  jobTitle: z
    .string({ required_error: "Job title is required" })
    .min(1, "Job title is required"),
  jobDescription: z.instanceof(File, {
    message: "Job description is required",
  }),
  maskedJobDescription: z
    .instanceof(File, {
      message: "Job description is required",
    })
    .optional(),
  briefDescription: z
    .string({
      required_error: "You must provide a brief description of the job",
    })
    .min(1, "You must provide a brief description of the job"),
  keyResponsibilities: z
    .string({
      required_error: "You must provide the key responsibilities of the job",
    })
    .min(1, "You must provide the key responsibilities of the job"),
  scope: z
    .string({ required_error: "You must provide a scope for the job" })
    .min(1, "You must provide a scope for the job"),
  canLeadToFullTime: z
    .string({
      required_error:
        "You must designate if this position could lead to a full-time position (if it is not one already)",
    })
    .refine(
      (v) => ["Yes", "No", "Unsure"].includes(v),
      "Please select a valid option",
    ),
  budget: z.string({ required_error: "Budget is required" }),
  idealStartDate: z.date({ required_error: "Ideal start date is required" }),
  matchCriteria1: z.string().optional(),
  matchCriteria2: z.string().optional(),
  matchCriteria3: z.string().optional(),
  additionalInformation: z.string().optional(),
  interviews: interviewSchema.array().optional(),
});

type InterviewSchema = z.infer<typeof interviewSchema>;
type Schema = z.infer<typeof schema>;

export {
  schema as companyOpportunityFormSchema,
  interviewSchema as companyOpportunityInterviewSchema,
  type Schema as CompanyOpportunityFormSchema,
  type InterviewSchema as CompanyOpportunityInterviewSchema,
};
