import { FormEvent, useLayoutEffect } from "react";
import { Button } from "@/components/ui/button";
import { usePaymentInfoDialogState } from "./store";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useStripePaymentMethodQuery } from "@/queries/stripe";
import { useToggle } from "@/hooks/useToggle";
import { toast } from "sonner";
import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";
import { useProfileQuery } from "@/queries/user/profile";
import { useMyCompanyQuery } from "@/queries/company/my-company";
import { Stripe } from "@stripe/stripe-js";
import { LoaderCircle } from "lucide-react";

export const CompanyPaymentInfo = ({
  stripePromise,
}: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const { paymentMethodLoading, paymentMethod } = useStripePaymentMethodQuery();
  const mode = usePaymentInfoDialogState(s => s.mode);

  useLayoutEffect(() => {
    if (
      usePaymentInfoDialogState.getState().open &&
      !paymentMethodLoading &&
      !paymentMethod
    ) {
      usePaymentInfoDialogState.getState().setMode("edit");
    }
  }, [paymentMethodLoading, paymentMethod]);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "setup",
        currency: "usd",
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: "hsl(172, 79%, 25%)",
          },
        },
      }}
    >
      {paymentMethodLoading ? (
        <div className="h-[30vh] max-h-[500px] w-full aspect-square flex items-center justify-center">
          <LoaderCircle className="size-6 text-secondary animate-spin" />
        </div>
      ) : mode === "view" ? (
        <View />
      ) : (
        <Edit />
      )}
    </Elements>
  );
};

const Edit = () => {
  const { profile } = useProfileQuery();
  const { company } = useMyCompanyQuery();
  const { paymentMethod, paymentMethodLoading } = useStripePaymentMethodQuery();
  const setMode = usePaymentInfoDialogState(s => s.setMode);
  const stripe = useStripe();
  const elements = useElements();

  const { getAccessTokenSilently } = useAuth0();
  const invalidate = useInvalidateQuery();
  const [loading, toggleLoading] = useToggle();

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    toggleLoading();

    const { error: validationError } = await elements.submit();
    if (validationError) {
      toast.error("Failed to save payment method", {
        description: validationError.message,
      });
      toggleLoading();
      return;
    }

    const clientSecret = await request<string>({
      method: "POST",
      path: `/api/company/stripe/setup-intent`,
      token: await getAccessTokenSilently(),
    }).catch(e => {
      console.error(e);

      toast.error("Failed to save payment method", {
        description: "An unexpected error occurred, please try again later",
      });
      toggleLoading();
    });
    if (!clientSecret) return;

    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.forEach((_, key) =>
      returnUrl.searchParams.delete(key),
    );
    returnUrl.searchParams.set("payment-method", "success");

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: returnUrl.href,
      },
    });

    if (error) {
      toast.error("Failed to save payment method", {
        description: error.message,
      });
      toggleLoading();
      return;
    }

    await invalidate(QueryKeys.stripePaymentMethod(true));
    toast.success("Payment method saved successfully");
    toggleLoading();
    setMode("view");
  };

  return (
    <form
      className="space-y-4"
      onSubmit={e => {
        e.preventDefault();
        void handleSave(e);
      }}
    >
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              email: profile?.emails?.[0],
              name: company?.name,
            },
          },
        }}
      />
      <div className="space-y-2">
        <Button
          type="submit"
          variant="secondary"
          className="w-full"
          loading={loading}
        >
          Save
        </Button>
        <Button
          type="button"
          variant="outline"
          className="w-full"
          onClick={() => {
            if (!paymentMethodLoading && !paymentMethod) {
              usePaymentInfoDialogState.getState().setOpen(false);
            } else {
              setMode("view");
            }
          }}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

const View = () => {
  const { paymentMethod, paymentMethodLoading } = useStripePaymentMethodQuery();
  const setMode = usePaymentInfoDialogState(s => s.setMode);
  const setOpen = usePaymentInfoDialogState(s => s.setOpen);

  useLayoutEffect(() => {
    if (
      usePaymentInfoDialogState.getState().open &&
      !paymentMethodLoading &&
      !paymentMethod
    ) {
      setMode("edit");
    }
  }, [paymentMethodLoading, paymentMethod, setMode]);

  return (
    <div className="space-y-6">
      <div>
        {[
          {
            label: "Payment Method",
            value:
              paymentMethod?.type === "card"
                ? "Credit/Debit Card"
                : "Bank Account",
          },
          ...(paymentMethod?.type === "card"
            ? [
                {
                  label: "Brand",
                  value: paymentMethod.card?.brand?.toLocaleUpperCase(),
                },
                {
                  label: "Card Number",
                  value: `Ending in ${paymentMethod.card?.last4}`,
                },
              ]
            : paymentMethod?.type === "us_bank_account"
              ? [
                  {
                    label: `Account Holder`,
                    value: paymentMethod?.billing_details?.name,
                  },
                  {
                    label: `Account Holder's Email`,
                    value: paymentMethod?.billing_details?.email,
                  },
                  {
                    label: "Bank Name",
                    value: paymentMethod?.us_bank_account?.bank_name
                      ?.split(" ")
                      .map(w => w[0]?.toUpperCase() + w.slice(1))
                      .join(" "),
                  },

                  {
                    label: "Routing Number",
                    value: paymentMethod?.us_bank_account?.routing_number,
                  },

                  {
                    label: "Account Number",
                    value: `Ending in ${paymentMethod?.us_bank_account?.last4}`,
                  },
                ]
              : []),
        ].map(({ label, value }) => (
          <div
            key={`view-payment-info-block-${label}`}
            className="flex justify-between items-center gap-2 border-b border-border py-4 last:border-b-0"
          >
            <p className="text-muted-foreground text-xs">{label}</p>
            <p className="text-sm font-semibold">{value}</p>
          </div>
        ))}
      </div>
      <div className="space-y-2">
        <Button
          variant={"secondary"}
          onClick={() => setMode("edit")}
          className="w-full"
        >
          Change Payment Method
        </Button>
        <Button
          variant={"outline"}
          onClick={() => setOpen(false)}
          className="w-full"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
