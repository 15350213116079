import { useEffect, useMemo, useState } from "react";

import { ChevronsRight, EllipsisVertical } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  TableWrapper,
  TableBody,
  TableRow,
  TableRowItem,
  TableFooter,
  TablePagination,
} from "@/components/ui/table";
import { Avatar } from "@fluentui/react-components";

import { AdminPipelineJob } from "@/queries/admin/job-pipeline";
import { jobOpeningStatusList } from "@/types";
import dateStringToLocalDate from "@/utils/dateStringToLocalDate";
import { useSetJobStatus } from "@/mutations/admin/set-job-status";

const SORTABLE_FIELDS = ["role", "company", "status", "createdAt"] as const;
export type SortableField = (typeof SORTABLE_FIELDS)[number];

const ENTRIES_PER_PAGE = 10;

const JobTable = ({
  jobs,
  selectJob,
}: {
  jobs?: AdminPipelineJob[];
  selectJob: (v: AdminPipelineJob | undefined) => void;
}) => {
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState<SortableField>("role");
  const [sortDirection, setSortDirection] = useState<1 | -1>(1);

  const sortedJobs: AdminPipelineJob[] | undefined = useMemo(() => {
    if (!jobs) {
      return;
    }

    switch (sortField) {
      case "company":
        return [...jobs].sort(
          (a, b) =>
            (a.company.name ?? "").localeCompare(b.company.name ?? "") *
            sortDirection,
        );
      case "role":
      case "createdAt":
        return [...jobs].sort(
          (a, b) =>
            (a[sortField] ?? "").localeCompare(b[sortField] ?? "") *
            sortDirection,
        );
      case "status":
        return [...jobs].sort(
          (a, b) =>
            (jobOpeningStatusList.indexOf(a.status) -
              jobOpeningStatusList.indexOf(b.status)) *
            sortDirection,
        );
    }
  }, [jobs, sortField, sortDirection]);

  const numberOfPages = useMemo(() => {
    if (!sortedJobs) {
      return 1;
    }

    return Math.ceil(sortedJobs.length / ENTRIES_PER_PAGE);
  }, [sortedJobs]);

  const currentPageEntries = useMemo(
    () =>
      sortedJobs?.slice((page - 1) * ENTRIES_PER_PAGE, page * ENTRIES_PER_PAGE),
    [sortedJobs, page],
  );

  const onHeadingClick = (field: SortableField) => {
    if (field !== sortField) {
      setSortField(field);
      setSortDirection(1);
    } else {
      setSortDirection((prev) => (prev === 1 ? -1 : 1));
    }
  };

  useEffect(() => {
    if (page > numberOfPages && page !== 1) {
      setPage(numberOfPages || 1);
    }
  }, [page, numberOfPages]);

  return (
    <div className="py-6 self-stretch flex flex-col lg:flex-row gap-x-6 gap-y-2">
      <div className="flex-1 min-w-0">
        <TableWrapper>
          <TableBody>
            <thead>
              <TableRow>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("company")}
                  sortDirection={
                    sortField === "company" ? sortDirection : undefined
                  }
                >
                  Company
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("role")}
                  sortDirection={
                    sortField === "role" ? sortDirection : undefined
                  }
                >
                  Role
                </TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("status")}
                  sortDirection={
                    sortField === "status" ? sortDirection : undefined
                  }
                >
                  Status
                </TableRowItem>
                <TableRowItem heading>Scope</TableRowItem>
                <TableRowItem heading>Budget</TableRowItem>
                <TableRowItem heading>Start Date</TableRowItem>
                <TableRowItem heading>FT Potential?</TableRowItem>
                <TableRowItem heading>Candidates</TableRowItem>
                <TableRowItem
                  heading
                  onClick={() => onHeadingClick("createdAt")}
                  sortDirection={
                    sortField === "createdAt" ? sortDirection : undefined
                  }
                >
                  Created At
                </TableRowItem>
                <TableRowItem heading button></TableRowItem>
                <TableRowItem heading button></TableRowItem>
                <TableRowItem heading button></TableRowItem>
              </TableRow>
            </thead>
            <tbody>
              {currentPageEntries?.map((job) => (
                <AdminRow
                  key={`candidate-${job.id}`}
                  job={job}
                  selectJob={() => selectJob(job)}
                />
              ))}
            </tbody>
          </TableBody>
          {numberOfPages > 1 ? (
            <TableFooter>
              <TablePagination
                currentPage={page}
                totalPages={numberOfPages}
                navigateFn={setPage}
              />
            </TableFooter>
          ) : null}
        </TableWrapper>
      </div>
    </div>
  );
};

const AdminRow = ({
  job,
  selectJob,
}: {
  job: AdminPipelineJob;
  selectJob: () => void;
}) => {
  const { setJobStatus, isSettingJobStatus } = useSetJobStatus();

  return (
    <TableRow>
      <TableRowItem>
        <div className="flex flex-row gap-3 items-center">
          <Avatar
            image={{ src: job.company.logoLink }}
            name={job.company.name}
            size={40}
          />
          <span className="text-[#0C0D0D] text-sm font-medium">
            {job.company.name}
          </span>
        </div>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.role}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.status}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.scope}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.budget}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">
          {job.desiredStartDate &&
            dateStringToLocalDate(job.desiredStartDate)?.toLocaleDateString(
              "en-US",
              {
                day: "numeric",
                month: "long",
                year: "numeric",
              },
            )}
        </span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.convertsToFullTime}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{job.candidateCount}</span>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">
          {dateStringToLocalDate(job.createdAt)?.toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      </TableRowItem>
      <TableRowItem>
        <Button
          variant="destructive"
          size="sm"
          disabled={isSettingJobStatus}
          onClick={() =>
            setJobStatus({
              jobId: job.id,
              status: "Role Filled (lost)",
            })
          }
        >
          Lost
        </Button>
      </TableRowItem>
      <TableRowItem>
        <Button
          variant="secondary"
          size="sm"
          disabled={isSettingJobStatus}
          onClick={() =>
            setJobStatus({
              jobId: job.id,
              currentStatus: job.status,
            })
          }
        >
          Advance <ChevronsRight />
        </Button>
      </TableRowItem>
      <TableRowItem>
        <EllipsisVertical
          className="cursor-pointer"
          size={16}
          stroke="#B1B9B8"
          onClick={selectJob}
        />
      </TableRowItem>
    </TableRow>
  );
};

export default JobTable;
