import { useNavigate } from "react-router-dom";

import { useAdminDashboardQuery } from "@/queries/admin/dashboard";

import Dashboard from "@/components/pages/dashboard";
import { Button } from "@/components/ui/button";
import {
  BriefcaseBusiness,
  Building2,
  LucideProps,
  SquareKanban,
} from "lucide-react";

const AdminDashboard = () => {
  const { adminDashboard } = useAdminDashboardQuery();

  return (
    <Dashboard.Collapsible defaultOpen>
      <Dashboard.CollapsibleHeader>
        <Dashboard.CollapsibleTitle>Dashboard</Dashboard.CollapsibleTitle>
      </Dashboard.CollapsibleHeader>
      <Dashboard.CollapsibleContent>
        {Boolean(adminDashboard?.openApplicationsCount) ||
        Boolean(adminDashboard?.openJobsCount) ||
        Boolean(adminDashboard?.newCompaniesCount) ? (
          <div className="w-full flex flex-col items-start border shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)] rounded-xl border-solid">
            <DashboardSection
              Icon={SquareKanban}
              singularHeading="Candidate needs your approval"
              pluralHeading="Candidates need your approval"
              subheading="There are new candidates who need your review."
              buttonText="Review Candidates"
              link="/new-admin/onboarding"
              count={adminDashboard?.openApplicationsCount}
            />
            <DashboardSection
              Icon={BriefcaseBusiness}
              singularHeading="Job posting added"
              pluralHeading="Job postings added"
              subheading="Approve job postings so candidates can be placed."
              buttonText="Review Jobs"
              link="/new-admin/job"
              count={adminDashboard?.openJobsCount}
            />
            <DashboardSection
              Icon={Building2}
              singularHeading="New company added"
              pluralHeading="New companies added"
              subheading="There are new companies who need your review."
              buttonText="Review Companies"
              link="/new-admin/company"
              count={adminDashboard?.newCompaniesCount}
            />
          </div>
        ) : (
          <p className="text-[#5c6060] text-sm">
            There are no updates at this time.
          </p>
        )}
      </Dashboard.CollapsibleContent>
    </Dashboard.Collapsible>
  );
};

const DashboardSection = ({
  Icon,
  singularHeading,
  pluralHeading,
  subheading,
  buttonText,
  link,
  count,
}: {
  Icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
  singularHeading: string;
  pluralHeading: string;
  subheading: string;
  buttonText: string;
  link: string;
  count?: number;
}) => {
  const navigate = useNavigate();

  if (!count) {
    return;
  }

  return (
    <div className="w-full py-4 px-6 flex flex-col md:flex-row border-b border-[#e9eaeb] last:border-b-0">
      <div className="flex-1 flex gap-3">
        <div className="w-12 h-12 p-2 flex justify-center items-center gap-2.5 shadow-[0px_3px_12px_0px_rgba(28,28,28,0.20)] rounded-full">
          <Icon stroke="#07574b" />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <p className="text-[#0c0d0d] text-sm font-semibold leading-6">
              <span className="text-[#0d7163] text-sm font-extrabold leading-6">
                {count}
              </span>{" "}
              {count === 1 ? singularHeading : pluralHeading}
            </p>
          </div>
          <p className="text-[#5c6060] text-sm">{subheading}</p>
        </div>
      </div>
      <Button variant="secondary" onClick={() => navigate(link)}>
        {buttonText}
      </Button>
    </div>
  );
};

export default AdminDashboard;
