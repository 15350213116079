import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { cn } from "@/lib/utils";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
} from "@/components/ui/sheet";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { AddOpportunityDialog } from "@/components/dialogs/company/opportunity/add-opportunity";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { ChevronsRight, ExternalLink, Loader2, RotateCcw } from "lucide-react";

import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

import { AdminJobDetails } from "@/queries/admin/job-details";
import { useSetJobStatus } from "@/mutations/admin/set-job-status";
import { useGenerateVjo } from "@/mutations/admin/generate-vjo";
import {
  jobNotesFormSchema,
  JobNotesFormSchema,
  useSetJobNotes,
} from "@/mutations/admin/set-job-notes";

const JobDetailsDrawer = ({
  job,
  open,
  setOpen,
}: {
  job: AdminJobDetails;
  open: boolean;
  setOpen: (v: boolean) => void;
}) => {
  const [addOpportunityOpen, setAddOpportunityOpen] = useState(false);

  const { setJobStatus, isSettingJobStatus } = useSetJobStatus();
  const { generateVjo, isGeneratingVjo } = useGenerateVjo(job.id);

  const { textColor, backgroundColor } = useMemo(() => {
    let backgroundColor: string, textColor: string;
    switch (job.status) {
      case "New Opening":
        backgroundColor = "bg-red-100";
        textColor = "text-red-600";
        break;
      case "Creating Job Spec":
        backgroundColor = "bg-pink-100";
        textColor = "text-pink-600";
        break;
      case "Identifying Candidates":
        backgroundColor = "bg-orange-100";
        textColor = "text-orange-600";
        break;
      case "Presenting Shortlist":
        backgroundColor = "bg-blue-100";
        textColor = "text-blue-600";
        break;
      default:
        backgroundColor = "bg-violet-100";
        textColor = "text-violet-600";
    }

    return { textColor, backgroundColor };
  }, [job.status]);

  const isActiveJob =
    job.status !== "Role Filled (won)" && job.status !== "Role Filled (lost)";

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="w-full max-w-full sm:max-w-md md:max-w-lg bg-background line-clamp">
        <SheetTitle className="sr-only">Job Details</SheetTitle>
        <SheetDescription className="sr-only">
          Details on a job
        </SheetDescription>
        <ScrollArea className="h-[calc(100vh-4.75rem)]" type="scroll">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col items-start gap-4">
              <div className="flex flex-col items-start gap-6 self-stretch">
                {Boolean(job.company.logoLink) && (
                  <div className="h-24 w-24 rounded-full overflow-hidden">
                    <img
                      className="h-full w-full object-cover"
                      src={job.company.logoLink}
                    />
                  </div>
                )}
                <div className="flex flex-row items-center gap-2">
                  <p className="text-xl text-[#181d27] font-semibold leading-[30px]">
                    {job.role}
                  </p>
                  <AddOpportunityDialog
                    open={addOpportunityOpen}
                    setOpen={setAddOpportunityOpen}
                    opening={job}
                    trigger={
                      <Button
                        variant="link"
                        className="text-xs text-muted-foreground hover:underline"
                      >
                        View & edit details
                      </Button>
                    }
                    includeMaskedJobDescription
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row self-stretch gap-3 flex-wrap">
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Time</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.scope ?? "Not Specified"}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Pay Rate</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.budget ?? "Not Specified"}
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <p className="text-s text-[#5C6060]">Start Date</p>
                <span className="px-2 py-1 text-xs text-[#0C0D0D] border border-[#E6E6E6] rounded-full bg-[#FAFAFA]">
                  {job.desiredStartDate
                    ? dateStringToLocalDate(
                        job.desiredStartDate,
                      )?.toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    : "Not Specified"}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex flex-row items-center gap-4">
                <p className="text-sm text-[#535862] font-medium">Stage</p>

                <div
                  className={cn(
                    "py-1 px-2.5 flex flex-row items-center gap-2 rounded-full",
                    backgroundColor,
                  )}
                >
                  <p
                    className={cn(
                      "text-xs font-semibold tracking-[-0.24px]",
                      textColor,
                    )}
                  >
                    {job.status}
                  </p>
                </div>
              </div>
              {isActiveJob && (
                <div className="flex flex-row self-stretch items-center gap-1.5">
                  <Button
                    variant="destructive"
                    disabled={isSettingJobStatus || isGeneratingVjo}
                    onClick={() =>
                      setJobStatus({
                        jobId: job.id,
                        status: "Role Filled (lost)",
                      })
                    }
                  >
                    Lost
                  </Button>
                  <Button
                    variant="secondary"
                    disabled={isSettingJobStatus || isGeneratingVjo}
                    onClick={() =>
                      setJobStatus({
                        jobId: job.id,
                        currentStatus: job.status,
                      })
                    }
                  >
                    Advance <ChevronsRight />
                  </Button>
                </div>
              )}
            </div>
            {Boolean(job.jobDescriptionLink) && (
              <div className="flex flex-col items-start gap-2 self-stretch">
                <p className="text-sm text-[#535862] font-medium">
                  Job Description
                </p>
                <a
                  href={job.jobDescriptionLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-md font-semibold text-accent hover:underline hover:cursor-pointer hover:text-accent underline-offset-2 ml-2"
                >
                  <span className="flex flex-row items-center gap-0.5">
                    Link
                    <ExternalLink size={16} />
                  </span>
                </a>
              </div>
            )}
            {(Boolean(job.matchCriteria1) ||
              Boolean(job.matchCriteria2) ||
              Boolean(job.matchCriteria3)) && (
              <>
                <div className="h-[1px] w-full bg-[#E9EAEB]" />
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-base text-[#181D27] font-medium">
                    Match Criteria
                  </p>
                  <ul className="list-disc pl-5">
                    {Boolean(job.matchCriteria1) && (
                      <li className="text-base text-[#535862] whitespace-break-spaces">
                        {job.matchCriteria1}
                      </li>
                    )}
                    {Boolean(job.matchCriteria2) && (
                      <li className="text-base text-[#535862] whitespace-break-spaces">
                        {job.matchCriteria2}
                      </li>
                    )}
                    {Boolean(job.matchCriteria3) && (
                      <li className="text-base text-[#535862] whitespace-break-spaces">
                        {job.matchCriteria3}
                      </li>
                    )}
                  </ul>
                </div>
              </>
            )}
            <div className="h-[1px] w-full bg-[#E9EAEB]" />
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex flex-row justify-between self-stretch">
                <p className="text-base text-[#181D27] font-medium">VJO</p>
                <Button
                  variant="secondary"
                  disabled={
                    !job.jobDescriptionLink ||
                    isSettingJobStatus ||
                    isGeneratingVjo
                  }
                  onClick={() => generateVjo()}
                >
                  {job.aIVjoDescription ? (
                    <>
                      {isGeneratingVjo ? (
                        <Loader2 className="animate-spin" />
                      ) : (
                        <RotateCcw />
                      )}
                      Regenerate VJO
                    </>
                  ) : (
                    <>
                      {isGeneratingVjo ? (
                        <Loader2 className="animate-spin" />
                      ) : null}
                      Generate VJO
                    </>
                  )}
                </Button>
              </div>
              {Boolean(job.aIVjoDescription) && (
                <p className="text-base text-[#535862] whitespace-break-spaces">
                  {job.aIVjoDescription}
                </p>
              )}
            </div>
            {(Boolean(job.keyResponsibilities) ||
              Boolean(job.shortDescription)) && (
              <>
                <div className="h-[1px] w-full bg-[#E9EAEB]" />
                <div className="flex flex-col items-start gap-2 self-stretch">
                  <p className="text-base text-[#181D27] font-medium">
                    Job Description
                  </p>
                  <p className="text-base text-[#535862] whitespace-break-spaces">
                    {job.shortDescription}
                  </p>
                  <p className="text-base text-[#535862] whitespace-break-spaces">
                    {job.keyResponsibilities}
                  </p>
                </div>
              </>
            )}
            <Notes job={job} />
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};

const Notes = ({ job }: { job: AdminJobDetails }) => {
  const { setJobNotes, isSettingJobNotes } = useSetJobNotes(job.id);

  const form = useForm<JobNotesFormSchema>({
    resolver: zodResolver(jobNotesFormSchema),
    defaultValues: {
      notes: job.notes ?? "",
    },
  });

  return (
    <>
      <div className="h-[1px] w-full bg-[#E9EAEB]" />
      <div className="flex flex-col gap-4">
        <Form {...form}>
          <form>
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Internal Notes</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="Internal Notes"
                      className="min-h-12"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
          <div className="w-full flex flex-row justify-end">
            <Button
              disabled={!form.formState.isValid || isSettingJobNotes}
              onClick={() => setJobNotes(form.getValues())}
            >
              {isSettingJobNotes && <Loader2 className="animate-spin" />}
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default JobDetailsDrawer;
