import { getCurrentTimezone, timezones } from "@/lib/timezone";

function iso(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
}

const timefmt = (t: string) => {
  const [hour, minute] = t.split(":");
  if (!hour || !minute) return t;
  const h = parseInt(hour);
  const suffix = h >= 12 ? "PM" : "AM";
  const h12 = h % 12 || 12;
  return `${h12}:${minute}${suffix}`;
};

export class AvailabilityTime {
  private time: Date;
  private offset: number;
  private placeholder = false;

  constructor(
    tod: string | Date,
    fmt?: "isodatetime" | "isotime" | "hh:mm",
    timezone?: string,
  ) {
    if (typeof tod === "string") {
      if (!fmt) {
        throw new Error("Format is required for string time");
      }

      if (fmt === "isodatetime") {
        this.time = new Date(tod);
        this.offset = this.time.getTimezoneOffset();
      } else if (fmt === "isotime") {
        this.time = new Date(
          `1970-01-01T${tod.substring(0, tod.includes("+") ? tod.indexOf("+") : tod.indexOf("-"))}`,
        );
        const os = (
          tod.includes("+") ? tod.split("+")[1] : tod.split("-")[1]
        )?.split(":")[0];
        if (!os) {
          throw new Error("Invalid time format");
        }

        this.offset = tod.includes("+") ? parseInt(os) : -parseInt(os);
      } else {
        if (!timezone) timezone = getCurrentTimezone() ?? "utc";

        const { offset } = timezones
          .flatMap(g => g.timezones)
          .find(tz => tz.abbr === timezone) ?? { offset: 0 };
        if (offset === undefined) {
          throw new Error(`Invalid timezone: ${timezone}`);
        }

        let [h, m] = tod.split(":").map(s => s.padStart(2, "0"));
        if (!h || !m) {
          !h && (h = "00");
          !m && (m = "00");
        }

        this.time = new Date(`1970-01-01T${h}:${m}:00.000`);
        this.offset = offset;
      }
    } else {
      this.time = tod;
      this.offset = this.time.getTimezoneOffset();
    }
  }

  public static newPlaceholder() {
    const t = new AvailabilityTime("00:00", "hh:mm");
    t.setAsPlaceholder();
    return t;
  }

  public setAsPlaceholder() {
    this.placeholder = true;
  }

  public get isPlaceholder() {
    return this.placeholder;
  }

  public get display() {
    return timefmt(this.input);
  }

  public get input() {
    if (this.placeholder) {
      return "";
    }

    return iso(this.time).slice(11, 16);
  }

  public get isotime() {
    return iso(this.time).slice(11);
  }

  public get isodatetime() {
    return iso(this.time);
  }

  public lessThan(other: AvailabilityTime) {
    return this.time < other.time;
  }

  public setOffset(offsetInput: number | string) {
    let offset: number;
    if (typeof offsetInput === "string") {
      const { offset: newOffset } = timezones
        .flatMap(g => g.timezones)
        .find(tz => tz.abbr === offsetInput) ?? { offset: 0 };
      if (newOffset === undefined) {
        throw new Error(`Invalid timezone: ${offsetInput}`);
      }

      offset = newOffset;
    } else {
      offset = offsetInput;
    }

    const diff = (this.offset - offset) * -1;
    this.time.setHours((this.time.getHours() + diff) % 24);
    this.offset = offset;
  }

  public cmp(other: AvailabilityTime) {
    if (this.isPlaceholder) {
      return 1;
    } else if (other.isPlaceholder) {
      return -1;
    }

    return this.time.getTime() - other.time.getTime();
  }

  public getTimezone() {
    return timezones
      .flatMap(g => g.timezones)
      .find(tz => tz.offset === this.offset)?.abbr;
  }

  public addMinutes(minutes: number) {
    this.time.setMinutes(this.time.getMinutes() + minutes);
  }

  public addHours(hours: number) {
    this.time.setHours(this.time.getHours() + hours);
  }

  public subtractMinutes(minutes: number) {
    this.time.setMinutes(this.time.getMinutes() - minutes);
  }

  public subtractHours(hours: number) {
    this.time.setHours(this.time.getHours() - hours);
  }
}

const datefmt = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
});

export class AvailabilityDate {
  private date: Date;

  constructor(date: Date | string, fmt?: "isodate" | "isodatetime") {
    if (typeof date === "string") {
      if (!fmt) {
        throw new Error("Format is required for string date");
      }

      if (fmt === "isodate") {
        this.date = new Date(`${date}T00:00:00.000Z`);
        this.date.setDate(parseInt(date.split("-")[2]!));
      } else {
        this.date = new Date(date);
      }
    } else {
      this.date = date;
    }

    this.date.setHours(0, 0, 0, 0);
  }

  public cmp(other: AvailabilityDate) {
    return this.date.getTime() - other.date.getTime();
  }

  public dateobj() {
    return this.date;
  }

  public get isodate() {
    return iso(this.date).slice(0, 10);
  }

  public get display() {
    return datefmt.format(this.date);
  }

  public sameDay(other: AvailabilityDate) {
    return this.isodate === other.isodate;
  }
}
