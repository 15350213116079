import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { useProfileQuery } from "@/queries/user/profile";
import { toast } from "sonner";

export const useRemoveTeamMember = () => {
  const qc = useQueryClient();
  const { profile } = useProfileQuery();
  const { getAccessTokenSilently } = useAuth0();

  const cid = profile?.companyAccounts[0]?.companyId;

  const { mutate, isPending, error } = useMutation({
    mutationFn: async (userId: string) => {
      const token = await getAccessTokenSilently();

      await request({
        method: "DELETE",
        path: `/api/company/${cid}/team/${userId}`,
        token,
      });
    },
    onSettled: async (_, error) => {
      if (error) {
        toast.error("Error removing team member, please try again");
      } else {
        toast.success("Team member removed!");
      }

      await qc.invalidateQueries({
        queryKey: QueryKeys.companyTeam,
      });
    },
  });

  return {
    removeTeamMember: mutate,
    isRemovingTeamMember: isPending,
    teamMemberError: error,
  };
};
