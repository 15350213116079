import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";

import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

import { toast } from "sonner";

interface RequestInterviewAvailabilityParams {
  openingId?: string;
  candidateId?: string;
  interviewId?: string;
}

export const useRequestInterviewAvailability = (onSuccess: () => void) => {
  const invalidate = useInvalidateQuery();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      openingId,
      candidateId,
      interviewId,
    }: RequestInterviewAvailabilityParams) => {
      if (!openingId || !candidateId || !interviewId) {
        throw new Error("Missing data");
      }

      const token = await getAccessTokenSilently();

      await request({
        method: "POST",
        path: `/api/jobOpening/${openingId}/interview/${interviewId}/requestAvailability/${candidateId}`,
        token,
      });
    },
    onSettled: async (_, error, { openingId }) => {
      if (!error) {
        toast.success("Availability requested!");
        onSuccess();
      } else {
        toast.error("Failed to request availability");
      }

      await invalidate(QueryKeys.companyOpportunities);

      if (openingId) {
        await invalidate(QueryKeys.companyOpportunity(openingId));
      }
    },
  });

  return {
    requestAvailability: mutate,
    isRequesting: isPending,
    requestingError: error,
  };
};
