import OpportunityExplanationDialog from "@/components/dialogs/company/opportunity/create-edit-explanation";
import CompanyOpportunityForm from "@/components/forms/company/opportunity";
import { useCompanyOpportunityQuery } from "@/queries/company/opportunities";
import { Spinner } from "@fluentui/react-components";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function EditCompanyOpportunityPage() {
  const { openingId } = useParams();
  const { opportunityLoading, opportunityData, opportunityError } =
    useCompanyOpportunityQuery(openingId ?? "");

  useEffect(() => {
    document.title = "Update a Job Opportunity";
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 py-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold text-secondary">
            Update Job Opportunity
          </h2>
          <OpportunityExplanationDialog />
        </div>
        <div className="flex gap-5 self-stretch items-stretch">
          {!opportunityLoading && opportunityData && !opportunityError ? (
            <div className="card flex-col gap-6 w-full">
              <CompanyOpportunityForm opening={opportunityData} />
            </div>
          ) : (
            <div className="flex-1 flex flex-col items-center justify-center gap-8">
              <Spinner size="huge" />
              <div className="flex flex-col gap-1 items-stretch text-center">
                <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                  Fetching data
                </p>
                <p className="text-sm font-medium text-[#727E94]">
                  Just a few seconds left
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
