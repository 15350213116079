import { useEffect, useMemo, useState } from "react";

import { Building2, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import { useProfileQuery } from "@/queries/user/profile";
import {
  CompanyUserAccountType,
  TeamMember,
  useCompanyTeamQuery,
} from "@/queries/company/team";
import { useRemoveTeamMember } from "@/mutations/company/team/remove-team-member";
import { useUpdateTeamMember } from "@/mutations/company/team/update-team-member";
import { useResetTeamMemberPassword } from "@/mutations/company/team/reset-team-member-password";

import Dashboard from "@/components/pages/dashboard";
import {
  TableWrapper,
  TableHeading,
  TableBody,
  TableRow,
  TableRowItem,
  TableFooter,
  TablePagination,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AddTeamMemberDialog } from "@/components/dialogs/company/team/add-team";
import { Avatar, Spinner } from "@fluentui/react-components";

const SORTABLE_FIELDS = ["name", "email", "accountType"] as const;
export type SortableField = (typeof SORTABLE_FIELDS)[number];

const USERS_PER_PAGE = 10;

const Team = () => {
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState<SortableField>("name");
  const [sortDirection, setSortDirection] = useState<1 | -1>(1);

  const { profile, profileLoading } = useProfileQuery();
  const { companyTeam, companyTeamLoading } = useCompanyTeamQuery();

  const sortedTeam: TeamMember[] | undefined = useMemo(() => {
    if (!companyTeam) {
      return;
    }

    switch (sortField) {
      case "name":
        return [...companyTeam].sort(
          (a, b) => (a.name ?? "").localeCompare(b.name ?? "") * sortDirection,
        );
      case "email":
        return [...companyTeam].sort(
          (a, b) =>
            (a.emails[0] ?? "").localeCompare(b.emails[0] ?? "") *
            sortDirection,
        );
      case "accountType":
        return [...companyTeam].sort(
          (a, b) =>
            (a.accountType ?? "").localeCompare(b.accountType ?? "") *
            sortDirection,
        );
    }
  }, [companyTeam, sortField, sortDirection]);

  const numberOfPages = useMemo(() => {
    if (!sortedTeam) {
      return 1;
    }

    return Math.ceil(sortedTeam.length / USERS_PER_PAGE);
  }, [sortedTeam]);

  const currentPageCompanyTeam = useMemo(
    () => sortedTeam?.slice((page - 1) * USERS_PER_PAGE, page * USERS_PER_PAGE),
    [sortedTeam, page],
  );

  const userType: CompanyUserAccountType | undefined = useMemo(() => {
    if (profileLoading || !profile) {
      return;
    }

    return profile.companyAccounts[0]?.accountType;
  }, [profileLoading, profile]);

  const onHeadingClick = (field: SortableField) => {
    if (field !== sortField) {
      setSortField(field);
      setSortDirection(1);
    } else {
      setSortDirection((prev) => (prev === 1 ? -1 : 1));
    }
  };

  useEffect(() => {
    if (page > numberOfPages && page !== 1) {
      setPage(numberOfPages || 1);
    }
  }, [page, numberOfPages]);

  return (
    <div className="py-6 self-stretch flex flex-col lg:flex-row gap-x-6 gap-y-2">
      {userType === "Admin" && (
        <div className="w-full lg:w-[20%] lg:min-w-60">
          <Dashboard.Message className="flex flex-col bg-border/50 rounded-xl p-6 gap-2 md:gap-4">
            <div className="size-14 bg-secondary flex flex-col items-center justify-center rounded-lg">
              <Building2 className="size-6 text-secondary-foreground" />
            </div>
            <h2 className="mt-2 text-xl">Invite Team Members</h2>
            <p className="text-md text-muted-foreground">
              Include people on your team who are involved in the hiring
              process.
            </p>
            <AddTeamMemberDialog />
          </Dashboard.Message>
        </div>
      )}
      <div className="flex-1 min-w-0">
        <TableWrapper>
          <TableHeading>
            <div className="flex-1 flex flex-col items-center justify-center gap-[2px]">
              <div className="flex gap-2 items-center self-stretch">
                <p className="text-lg font-semibold text-[#0C0D0D]">
                  Your Team
                </p>
                {!companyTeamLoading && companyTeam ? (
                  <div className="px-1.5 py-0.5 border border-[#D5D7DA] rounded-md shadow-[0px_1px_2px_0px_rgba(10,13,18,0.05)]">
                    <p className="text-xs font-medium text-[#414651] text-center">
                      {companyTeam.length === 1
                        ? "1 team member"
                        : `${companyTeam.length} team members`}
                    </p>
                  </div>
                ) : null}
              </div>
              <p className="self-stretch text-sm text-[#5c6060] overflow-hidden text-ellipsis">
                Manage your team members and their account permissions here.
              </p>
            </div>

            {userType === "Admin" && (
              <AddTeamMemberDialog
                trigger={<Button variant="outline">Add team member</Button>}
              />
            )}
          </TableHeading>
          {companyTeamLoading ||
          profileLoading ||
          !profile ||
          !currentPageCompanyTeam ? (
            <div className="py-5 flex flex-col items-center align-middle self-stretch gap-8">
              <Spinner size="huge" />
              <div className="flex flex-col gap-1 items-stretch text-center">
                <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                  Fetching your team
                </p>
                <p className="text-sm font-medium text-[#727E94]">
                  Just a few seconds left
                </p>
              </div>
            </div>
          ) : userType === "Admin" ? (
            <AdminTable
              companyTeam={currentPageCompanyTeam}
              selfId={profile.id}
              sortField={sortField}
              sortDirection={sortDirection}
              sortFn={onHeadingClick}
            />
          ) : (
            <CollaboratorTable
              companyTeam={currentPageCompanyTeam}
              sortField={sortField}
              sortDirection={sortDirection}
              sortFn={onHeadingClick}
            />
          )}
          {numberOfPages > 1 ? (
            <TableFooter>
              <TablePagination
                currentPage={page}
                totalPages={numberOfPages}
                navigateFn={setPage}
              />
            </TableFooter>
          ) : null}
        </TableWrapper>
      </div>
    </div>
  );
};

const AdminTable = ({
  companyTeam,
  selfId,
  sortField,
  sortDirection,
  sortFn,
}: {
  companyTeam: TeamMember[];
  selfId: string;
  sortField: SortableField;
  sortDirection: -1 | 1;
  sortFn: (heading: SortableField) => void;
}) => {
  return (
    <TableBody>
      <thead>
        <TableRow>
          <TableRowItem
            heading
            onClick={() => sortFn("name")}
            sortDirection={sortField === "name" ? sortDirection : undefined}
          >
            Name
          </TableRowItem>
          <TableRowItem
            heading
            onClick={() => sortFn("email")}
            sortDirection={sortField === "email" ? sortDirection : undefined}
          >
            Email address
          </TableRowItem>
          <TableRowItem
            heading
            onClick={() => sortFn("accountType")}
            sortDirection={
              sortField === "accountType" ? sortDirection : undefined
            }
          >
            Permissions
          </TableRowItem>
          <TableRowItem heading button></TableRowItem>
          <TableRowItem heading button></TableRowItem>
        </TableRow>
      </thead>
      <tbody>
        {companyTeam.map((user) => (
          <AdminTeamMemberRow
            key={`team-member-${user.id}`}
            selfId={selfId}
            user={user}
          />
        ))}
      </tbody>
    </TableBody>
  );
};

const AdminTeamMemberRow = ({
  selfId,
  user,
}: {
  selfId: string;
  user: TeamMember;
}) => {
  const { removeTeamMember, isRemovingTeamMember } = useRemoveTeamMember();
  const { updateTeamMember, isUpdatingTeamMember } = useUpdateTeamMember();
  const { resetTeamMemberPassword, isResettingTeamMemberPassword } =
    useResetTeamMemberPassword();

  // A user cannot delete themselves
  const isNotSelf = user.userId !== selfId;

  return (
    <TableRow>
      <TableRowItem>
        <div className="flex flex-row gap-3 items-center">
          <Avatar
            image={{ src: user.profilePictureLink }}
            name={user.name}
            size={40}
          />
          <span className="text-[#0C0D0D] text-sm font-medium">
            {user.name}
          </span>
        </div>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{user.emails[0]}</span>
      </TableRowItem>
      {isNotSelf ? (
        <TableRowItem>
          <Select
            name="accountType"
            value={user.accountType}
            disabled={
              isRemovingTeamMember ||
              isUpdatingTeamMember ||
              isResettingTeamMemberPassword
            }
            onValueChange={(val: CompanyUserAccountType) =>
              updateTeamMember({ userId: user.userId, accountType: val })
            }
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Admin">
                <div className="flex flex-row items-center gap-1">
                  <div className="h-2 w-2 bg-[#d23b15] rounded-full" />
                  Admin
                </div>
              </SelectItem>
              <SelectItem value="Collaborator">
                <div className="flex flex-row items-center gap-1">
                  <div className="h-2 w-2 bg-[#0d7163] rounded-full" />
                  Collaborator
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </TableRowItem>
      ) : (
        <TableRowItem>
          <div className="px-3 flex flex-row items-center gap-1">
            <div
              className={`h-2 w-2 ${user.accountType === "Admin" ? "bg-[#d23b15]" : "bg-[#0d7163]"} rounded-full`}
            />
            {user.accountType}
          </div>
        </TableRowItem>
      )}
      <TableRowItem>
        <Button
          variant="outline"
          disabled={
            isRemovingTeamMember ||
            isUpdatingTeamMember ||
            isResettingTeamMemberPassword
          }
          onClick={() => resetTeamMemberPassword(user.userId)}
        >
          Reset Password
        </Button>
      </TableRowItem>
      <TableRowItem>
        {isNotSelf ? (
          <Button
            variant="ghost"
            disabled={
              isRemovingTeamMember ||
              isUpdatingTeamMember ||
              isResettingTeamMemberPassword
            }
            onClick={() => removeTeamMember(user.userId)}
          >
            <Trash2 className="!stroke-[#A4A7AE]" />
          </Button>
        ) : null}
      </TableRowItem>
    </TableRow>
  );
};

const CollaboratorTable = ({
  companyTeam,
  sortField,
  sortDirection,
  sortFn,
}: {
  companyTeam: TeamMember[];
  sortField: SortableField;
  sortDirection: -1 | 1;
  sortFn: (heading: SortableField) => void;
}) => {
  return (
    <TableBody>
      <TableRow>
        <TableRowItem
          heading
          onClick={() => sortFn("name")}
          sortDirection={sortField === "name" ? sortDirection : undefined}
        >
          Name
        </TableRowItem>
        <TableRowItem
          heading
          onClick={() => sortFn("email")}
          sortDirection={sortField === "email" ? sortDirection : undefined}
        >
          Email address
        </TableRowItem>
        <TableRowItem
          heading
          onClick={() => sortFn("accountType")}
          sortDirection={
            sortField === "accountType" ? sortDirection : undefined
          }
        >
          Permissions
        </TableRowItem>
      </TableRow>
      {companyTeam.map((user) => (
        <CollaboratorTeamMemberRow key={`team-member-${user.id}`} user={user} />
      ))}
    </TableBody>
  );
};

const CollaboratorTeamMemberRow = ({ user }: { user: TeamMember }) => {
  const className = useMemo(
    () =>
      cn(
        "h-2 w-2 bg-[#d23b15] rounded-full",
        user.accountType === "Admin" ? "bg-[#d23b15]" : "bg-[#0d7163]",
      ),
    [user.accountType],
  );

  return (
    <TableRow>
      <TableRowItem>
        <div className="flex flex-row gap-3 items-center">
          <Avatar
            image={{ src: user.profilePictureLink }}
            name={user.name}
            size={40}
          />
          <span className="text-[#0C0D0D] text-sm font-medium">
            {user.name}
          </span>
        </div>
      </TableRowItem>
      <TableRowItem>
        <span className="text-[#5C6060] text-sm">{user.emails[0]}</span>
      </TableRowItem>
      <TableRowItem>
        <div className="flex flex-row items-center gap-1">
          <div className={className} />
          {user.accountType}
        </div>
      </TableRowItem>
    </TableRow>
  );
};

export default Team;
