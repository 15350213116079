import { useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import { Tooltip } from "@/components/ui/tooltip";
import { X, Headset, Star, Clipboard, FileCheck2 } from "lucide-react";

import { DeclineOpportunityDialog } from "../dialogs/opportunity/decline";

import { Opportunity } from "@/queries/candidate/opportunities";
import { useAvailabilitySheet } from "../dialogs/candidate/availability/store";
import { OpportunityDetailsDialog } from "../dialogs/opportunity/details";
import { ApplyDialog } from "@/elements/OpportunityTile";

import dateStringToLocalDate from "@/utils/dateStringToLocalDate";

import { CandidateInterview } from "@/types";

export const OpportunityRow = ({
  opportunity,
  highlight,
}: {
  opportunity: Opportunity;
  highlight?: boolean;
}) => {
  return (
    <div className="w-full flex flex-col px-6 p-4 border-b last-of-type:border-b-0">
      <div className="w-full flex flex-col gap-2 md:flex-row md:gap-0 justify-between items-center">
        <div className="w-full flex flex-row gap-4 items-center">
          {highlight && (
            <Star className="text-secondary fill-secondary size-4" />
          )}
          <img
            className="size-12 rounded-full"
            src={opportunity.company?.logoLink}
            alt={`${opportunity.company?.name} Logo`}
          />
          <div className="flex flex-col">
            <h4 className="text-md">{opportunity.company?.name}</h4>
            <div className="flex flex-row gap-2 items-center">
              <p className="text-sm font-semibold">{opportunity.role}</p>

              {opportunity.convertsToFullTime === "Yes" ||
              opportunity.convertsToFullTime === "Unsure" ? (
                <span className="text-xs px-2 py-1 rounded-full bg-border/50">
                  Full Time Potential
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row gap-2 md:gap-4 items-center justify-center md:justify-end">
          {opportunity.candidateStatus.status !== "Rejected for Role" &&
            opportunity.candidateStatus.status !== "Not Interested" &&
            opportunity.candidateStatus.status !== "Accepted by Client" && (
              <DeclineOpportunityDialog
                openingId={opportunity.id}
                candidateStatusId={opportunity.candidateStatus.id}
                button={
                  <Tooltip content="Decline opportunity">
                    <Button variant="outline" size="icon">
                      <X />
                    </Button>
                  </Tooltip>
                }
              />
            )}
          <OpportunityDetailsDialog opening={opportunity} />
          <OpportunityAction opportunity={opportunity} />
        </div>
      </div>
      <div className="md:ml-16 mt-4 md:mt-2 grid grid-cols-2 lg:flex lg:flex-row gap-4 items-center">
        <div className="flex flex-row gap-2 items-center">
          <p className="text-xs text-muted-foreground">Time</p>
          <span className="text-xs px-2 py-1 rounded-full bg-border/50">
            {opportunity.scope}
          </span>
        </div>
        <div className="col-span-2 flex flex-row gap-2 items-center">
          <p className="text-xs text-muted-foreground">Start Date</p>
          <span className="text-xs px-2 py-1 rounded-full bg-border/50">
            {opportunity.desiredStartDate
              ? dateStringToLocalDate(
                  opportunity.desiredStartDate,
                )?.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })
              : "Not Specified"}
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <p className="text-xs text-muted-foreground">My Status</p>
          <span className="text-xs px-2 py-1 rounded-full bg-border/50">
            {opportunity.candidateStatus.status}
          </span>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <p className="text-xs text-muted-foreground">Interview Steps</p>
          <span className="text-xs px-2 py-1 rounded-full bg-border/50">
            {
              opportunity.interviews?.filter(
                (interview) => interview.interviewCompleted,
              ).length
            }
            /{opportunity.interviews?.length}
          </span>
        </div>
      </div>
    </div>
  );
};

const OpportunityAction = ({ opportunity }: { opportunity: Opportunity }) => {
  const { setOpen, setOpportunity, setInterview } = useAvailabilitySheet();

  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);
  const [currentInterview, setCurrentInterview] =
    useState<CandidateInterview | null>(null);

  useMemo(() => {
    if (opportunity.interviews) {
      const interviews = opportunity.interviews.sort(
        (a, b) => a.step! - b.step!,
      );

      let interview = interviews[0];
      for (let i = 0; i < interviews.length; i++) {
        if (interviews[i]!.interviewCompleted) {
          interview = interviews[i + 1];
        } else if (interviews[i]!.interviewRequested) {
          interview = interviews[i];
          break;
        }
      }

      if (interview) {
        setCurrentInterview(interview);
      }
    }
  }, [opportunity]);

  switch (opportunity.candidateStatus.status) {
    case "Checking Interest":
      return (
        <>
          <Button
            variant="outline"
            onClick={() => {
              setApplyDialogOpen(true);
            }}
          >
            <Clipboard />
            Apply
          </Button>
          <ApplyDialog
            opening={opportunity}
            isOpen={applyDialogOpen}
            setIsOpen={setApplyDialogOpen}
          />
        </>
      );
    case "Screening":
    case "Possible Fit":
    case "Shortlisted":
    case "Making Offer":
      return (
        <Button
          variant="outline"
          onClick={() => {
            if (currentInterview) {
              setOpportunity(opportunity);
              setInterview(currentInterview);
              setOpen(true);
            }
          }}
          disabled={currentInterview?.interviewRequested ?? !currentInterview}
        >
          <Headset />
          {!currentInterview
            ? "No More Interviews"
            : currentInterview?.interviewRequested
              ? "Interview Scheduled"
              : "Add Availability"}
        </Button>
      );
    case "Accepted by Client":
      return (
        <Button
          variant="outline"
          disabled={!opportunity.contracts[0]}
          onClick={() => {
            if (opportunity.contracts[0]) {
              window.open(opportunity.contracts[0].link, "_blank");
            }
          }}
        >
          <FileCheck2 />
          View Contract
        </Button>
      );
    default:
      return <></>;
  }
};
