import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Loader2, Building } from "lucide-react";

import { NeedHelpMessage } from "@/components/pages/dashboard/need-help";
import Dashboard from "@/components/pages/dashboard";

import { Button } from "@/components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { AddOpportunityDialog } from "@/components/dialogs/company/opportunity/add-opportunity";

import { useToggle } from "@/hooks/useToggle";
import { useMyCompanyQuery } from "@/queries/company/my-company";
import { useCompanyOpportunitiesQuery } from "@/queries/company/opportunities";
import { CompanyJobOpening } from "@/types";
import { CompanyOpportunityRow } from "@/components/opportunity/company";

const CompanyOpportunities = () => {
  const navigate = useNavigate();
  const { companyLoading, company } = useMyCompanyQuery();
  const { opportunities } = useCompanyOpportunitiesQuery();

  const [oppDialogOpen, toggleOppDialog] = useToggle();

  const [section, setSection] = useState<"active" | "closed" | "contracts">(
    "active",
  );
  const [focus, setFocus] = useState<string | null>(null);

  return (
    <Dashboard.Root
      messages={[
        <NavSection
          key="nav"
          focus={focus}
          setSection={setSection}
          setFocus={setFocus}
        />,
        <NeedHelpMessage key="need-help" />,
      ]}
    >
      <Dashboard.ProfileRow>
        <Dashboard.ProfileInfo
          title={company?.name}
          description={company?.location}
          fallbackIcon={Building}
          src={company?.logoLink}
          loading={companyLoading}
        />
        <Dashboard.ProfileActions>
          <Button
            variant="secondary"
            disabled={companyLoading}
            onClick={() => navigate("/company/profile/edit")}
          >
            <Building />
            Update Company Profile
          </Button>
          <AddOpportunityDialog
            open={oppDialogOpen}
            setOpen={toggleOppDialog}
          />
        </Dashboard.ProfileActions>
      </Dashboard.ProfileRow>
      <Dashboard.MainContent>
        {opportunities && opportunities.length > 0 ? (
          <OpportunitiesSection
            title={
              section === "active"
                ? "Active Opportunities"
                : section === "closed"
                  ? "Closed Opportunities"
                  : "Contracts"
            }
            opportunities={
              section === "active"
                ? opportunities.filter(
                    ({ status }) =>
                      status !== "Role Filled (lost)" &&
                      status !== "Role Filled (won)",
                  )
                : section === "contracts"
                  ? opportunities.filter(
                      ({ status }) => status === "Role Filled (won)",
                    )
                  : opportunities.filter(
                      ({ status }) => status === "Role Filled (lost)",
                    )
            }
            focus={focus}
          />
        ) : (
          <p className="w-full text-sm text-muted-foreground text-center">
            No opportunities found. Create your first one!
          </p>
        )}
      </Dashboard.MainContent>
    </Dashboard.Root>
  );
};

export default CompanyOpportunities;

const NavSection = ({
  focus,
  setSection,
  setFocus,
}: {
  focus: string | null;
  setSection: (section: "active" | "closed" | "contracts") => void;
  setFocus: (focus: string | null) => void;
}) => {
  const { opportunitiesLoading, opportunities } =
    useCompanyOpportunitiesQuery();

  const [openOpportunities, setOpenOpportunities] = useState<
    CompanyJobOpening[]
  >([]);
  const [contractOpportunities, setContractOpportunities] = useState<
    CompanyJobOpening[]
  >([]);
  const [closedOpportunities, setClosedOpportunities] = useState<
    CompanyJobOpening[]
  >([]);

  useMemo(() => {
    if (opportunities !== null) {
      setOpenOpportunities(
        opportunities.filter(
          ({ status }) =>
            status !== "Role Filled (lost)" && status !== "Role Filled (won)",
        ),
      );

      setContractOpportunities(
        opportunities.filter(({ status }) => status === "Role Filled (won)"),
      );

      setClosedOpportunities(
        opportunities.filter(({ status }) => status === "Role Filled (lost)"),
      );
    }
  }, [opportunities]);

  return (
    <Dashboard.Message className="flex flex-col bg-border/50 rounded-xl p-6 gap-4">
      {opportunitiesLoading ? (
        <Loader2 className="size-4 mx-auto animate-spin" />
      ) : (
        <Accordion type="single" collapsible>
          <NavSectionItem
            id="active-opportunities"
            opportunities={openOpportunities}
            title="Active Opportunities"
            section="active"
            focus={focus}
            setFocus={setFocus}
            setSection={setSection}
          />
          <NavSectionItem
            id="contracts"
            opportunities={contractOpportunities}
            title="Contracts"
            section="contracts"
            focus={focus}
            setFocus={setFocus}
            setSection={setSection}
          />
          <NavSectionItem
            id="closed-opportunities"
            opportunities={closedOpportunities}
            title="Closed Opportunities"
            section="closed"
            focus={focus}
            setFocus={setFocus}
            setSection={setSection}
          />
        </Accordion>
      )}
    </Dashboard.Message>
  );
};

const NavSectionItem = ({
  id,
  opportunities,
  title,
  section,
  focus,
  setFocus,
  setSection,
}: {
  id: string;
  opportunities: CompanyJobOpening[];
  title: string;
  section: "active" | "closed" | "contracts";
  focus: string | null;
  setSection: (section: "active" | "closed" | "contracts") => void;
  setFocus: (focus: string | null) => void;
}) => (
  <AccordionItem value={id} className="border-b-0">
    <AccordionTrigger className="hover:no-underline">
      <div className="w-full flex flex-row items-center gap-2">
        <p className="text-lg text-left">{title}</p>
        <p className="w-fit text-xs bg-background border rounded-full px-3 py-1">
          {opportunities.length}
        </p>
      </div>
    </AccordionTrigger>
    <AccordionContent className="w-full flex flex-col gap-2">
      {opportunities.length > 0 ? (
        opportunities.map((opportunity) => (
          <div
            key={opportunity.id}
            className={
              focus === opportunity.id
                ? "w-full rounded-md px-3 py-2 bg-background text-foreground font-semibold"
                : "w-full rounded-md px-3 py-2 cursor-pointer text-muted-foreground hover:bg-background hover:text-foreground transition duration-200"
            }
            onClick={() => {
              setFocus(opportunity.id ?? null);
              setSection(section);
            }}
          >
            {opportunity.role}
          </div>
        ))
      ) : (
        <p className="text-sm text-muted-foreground mx-auto">
          No opportunities
        </p>
      )}
    </AccordionContent>
  </AccordionItem>
);

const OpportunitiesSection = ({
  opportunities,
  focus,
  title,
}: {
  opportunities: CompanyJobOpening[];
  focus: string | null;
  title: string;
}) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <div className="w-full flex flex-col gap-4">
        {opportunities.length > 0 ? (
          opportunities.map((opportunity, index) => (
            <CompanyOpportunityRow
              key={opportunity.id}
              id={index}
              opportunity={opportunity}
              highlight={focus === opportunity.id}
            />
          ))
        ) : (
          <p className="w-full text-sm text-muted-foreground text-center">
            No opportunities found. Please check back later.
          </p>
        )}
      </div>
    </div>
  );
};
