import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import {
  DayAvailabilities,
  SpecificAvailabilities,
  useAvailabilitySheet,
} from "./store";
import request from "@/lib/api/handler";
import { weekdayList } from "@/types";
import { useCandidateAvailabilityQuery } from "@/queries/candidate/availability";
import { useProfileQuery } from "@/queries/user/profile";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

export const useSaveCandidateAvailability = (
  openingID: string,
  interviewID: string,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useProfileQuery();
  const { candidateAvailability: old } = useCandidateAvailabilityQuery(
    openingID,
    interviewID,
  );
  const invalidate = useInvalidateQuery();

  const { isPending, error, isSuccess, mutate } = useMutation({
    mutationFn: async () => {
      const { dayAvailabilities, specificAvailabilities } =
        useAvailabilitySheet.getState();

      // Filter out placeholder entries
      for (let i = 0; i < dayAvailabilities.length; i++) {
        dayAvailabilities[i] =
          dayAvailabilities[i]?.filter(
            (e) => !(e.start.isPlaceholder || e.end.isPlaceholder),
          ) ?? [];
      }

      const promises: Promise<void>[] = [];
      const token = await getAccessTokenSilently();
      const uid = profile?.id;
      if (!uid) {
        throw new Error("No user ID found");
      }

      dayAvailabilities.forEach((d, i) => {
        d.forEach((entry) => {
          if (!entry.id) {
            promises.push(
              dayHandler(
                entry,
                i,
                token,
                uid,
                "create",
                openingID,
                interviewID,
              ),
            );
            return;
          }

          const oldDay = old?.general[i]?.find((e) => e.id === entry.id);
          if (!oldDay) {
            return;
          }

          if (
            entry.start.isotime !== oldDay.start.isotime ||
            entry.end.isotime !== oldDay.end.isotime
          ) {
            promises.push(
              dayHandler(
                entry,
                i,
                token,
                uid,
                "update",
                openingID,
                interviewID,
              ),
            );
          }
        });
      });

      old?.general.forEach((d, i) => {
        d.forEach((e) => {
          if (!e.id) return;

          const newDay = dayAvailabilities[i]?.find((n) => n.id === e.id);
          if (!newDay) {
            promises.push(
              dayHandler(e, i, token, uid, "delete", openingID, interviewID),
            );
          }
        });
      });

      specificAvailabilities.forEach((s) => {
        s.times.forEach((entry) => {
          if (!entry.id) {
            promises.push(
              specificHandler(
                entry,
                s.startDate,
                s.endDate,
                token,
                uid,
                "create",
                openingID,
                interviewID,
              ),
            );
            return;
          }

          const oldSpecific = old?.specific.find(
            (o) =>
              o.startDate.isodate === s.startDate.isodate &&
              o.endDate.isodate === s.endDate.isodate,
          );

          if (!oldSpecific) {
            return;
          }

          const oldEntry = oldSpecific.times.find((e) => e.id === entry.id);
          if (!oldEntry) {
            return;
          }

          if (
            entry.start.isotime !== oldEntry.start.isotime ||
            entry.end.isotime !== oldEntry.end.isotime
          ) {
            promises.push(
              specificHandler(
                entry,
                s.startDate,
                s.endDate,
                token,
                uid,
                "update",
                openingID,
                interviewID,
              ),
            );
          }
        });
      });

      old?.specific.forEach((s) => {
        s.times.forEach((entry) => {
          if (!entry.id) return;

          const newSpecific = specificAvailabilities.find(
            (n) =>
              n.startDate.isodate === s.startDate.isodate &&
              n.endDate.isodate === s.endDate.isodate,
          );

          if (!newSpecific) {
            promises.push(
              specificHandler(
                entry,
                s.startDate,
                s.endDate,
                token,
                uid,
                "delete",
                openingID,
                interviewID,
              ),
            );
          }
        });
      });

      await Promise.allSettled(promises);
    },
    onSettled: async () => {
      await invalidate(QueryKeys.candidateOpportunities);
      await invalidate(QueryKeys.candidateAvailability(openingID, interviewID));

      useAvailabilitySheet.getState().setOpen(false);
      setTimeout(
        () => useAvailabilitySheet.getState().setSuccessOpen(true),
        100,
      );
    },
  });

  return {
    candidateAvailabilityMutPending: isPending,
    candidateAvailabilityMutError: error,
    candidateAvailabilityMutSuccess: isSuccess,
    saveCandidateAvailability: mutate,
  };
};

function getMethod(action: "create" | "update" | "delete") {
  return action === "create"
    ? "POST"
    : action === "update"
      ? "PATCH"
      : "DELETE";
}

function getURL(
  action: "create" | "update" | "delete",
  type: "specific" | "day",
  openingID?: string,
  availabilityID?: string,
) {
  return action === "create"
    ? `api/jobOpening/${openingID}/interview_availability`
    : `api/jobOpening/${openingID}/interview_availability/${availabilityID}/${type === "day" ? "general" : "specific"}`;
}

async function specificHandler(
  entry: SpecificAvailabilities["times"][number],
  startDate: SpecificAvailabilities["startDate"],
  endDate: SpecificAvailabilities["endDate"],
  token: string,
  uid: string,
  action: "create" | "update" | "delete",
  openingID: string,
  interviewID: string,
) {
  const body =
    action === "create"
      ? {
          user: uid,
          interview: interviewID,
          startTime: entry.start.isotime,
          endTime: entry.end.isotime,
          dates: [startDate.isodate, endDate.isodate],
        }
      : action === "update"
        ? {
            startTime: entry.start.isotime,
            endTime: entry.end.isotime,
            dates: [startDate.isodate, endDate.isodate],
          }
        : {};

  await request({
    method: getMethod(action),
    path: `/${getURL(action, "specific", openingID, entry.id)}`,
    token,
    body: JSON.stringify(body),
  });
}

async function dayHandler(
  entry: DayAvailabilities[number],
  weekday: number,
  token: string,
  uid: string,
  action: "create" | "update" | "delete",
  openingID: string,
  interviewID: string,
) {
  const body =
    action === "create"
      ? {
          user: uid,
          interview: interviewID,
          startTime: entry.start.isotime,
          endTime: entry.end.isotime,
          weekday: weekdayList[weekday],
        }
      : action === "update"
        ? {
            startTime: entry.start.isotime,
            endTime: entry.end.isotime,
            weekday: weekdayList[weekday],
          }
        : {};

  await request({
    method: getMethod(action),
    path: `/${getURL(action, "day", openingID, entry.id)}`,
    token,
    body: JSON.stringify(body),
  });
}
