import request from "@/lib/api/handler";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../utils";

export interface AdminSummary {
  openApplicationsCount: number;
  openJobsCount: number;
  newCompaniesCount: number;
}

export const useAdminDashboardQuery = () => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: adminDashboard,
    error: adminDashboardError,
    isLoading: adminDashboardLoading,
  } = useQuery({
    queryKey: QueryKeys.adminDashboard,
    enabled: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    async queryFn() {
      const token = await getAccessTokenSilently();
      return await request<AdminSummary>({
        method: "GET",
        path: "/api/admin/dashboard",
        token,
      });
    },
  });

  return { adminDashboard, adminDashboardError, adminDashboardLoading };
};
