import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { FileUploader } from "react-drag-drop-files";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { engagementTypeList } from "../redux/features/candidateProfileSlice";
import {
  addExecutive,
  CompanyAddRequest,
  deleteExecutive,
  Executive,
  getCompanyProfile,
  reorderExecutives,
  resetPostingStatus,
  updateCompanyProfile,
  updateExecutive,
} from "../redux/features/companyProfileSlice";
import ImageUploadPreview from "@/components/ui/image-upload-preview";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Field,
  Input,
  mergeClasses,
  Option,
  Skeleton,
  SkeletonItem,
  Textarea,
} from "@fluentui/react-components";
import {
  useButtonStyles,
  useDialogStyles,
  useFieldStyles,
  useInputStyles,
} from "../fluentStyles";
import {
  CalendarSVG,
  CloseCircleSVG,
  DollarSquareSVG,
  ExportMissingSVG,
  ExportSVG,
  LinkedInSVG,
  LocationSVG,
  MoneyReceiveSVG,
  MonitorMobileSVG,
  PeopleSVG,
  ReceiptEditSVG,
  SocialIconSVG,
  VerifiedTickSVG,
} from "../assets/svgs";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import localDateToDateString from "../utils/localDateToDateString";
import Error401Page from "./Error401Page";
import ReorderableExecutiveTeam from "../elements/ReorderableExecutiveTeam";
import ReadMoreSection from "@/elements/ReadMore";

import "@/styles.css";
import { QueryKeys, useInvalidateQuery } from "@/queries/utils";

const CompanyProfile = ({ mode }: { mode: "edit" | "view" }) => {
  const [dataRequested, setDataRequested] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const status = useAppSelector((state) => state.companyProfile.status);
  const company = useAppSelector((state) => state.companyProfile.company);
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const loading = !dataRequested || status !== "succeeded";
  const id = companyId ?? user?.companyAccounts[0]?.companyId;
  const enableEdit = mode === "edit";

  useEffect(() => {
    if (!id) {
      return;
    }

    const controller = new AbortController();

    void dispatch(
      getCompanyProfile({
        id,
        signal: controller.signal,
      }),
    );
    setDataRequested(true);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    document.title = company.name ?? "Venturous";
  }, [company]);

  const executives = (
    <Executives
      id={id}
      executives={company.executiveTeam}
      loading={loading}
      enableEdit={enableEdit}
    />
  );

  if (mode === "edit" && !id) {
    return <Error401Page />;
  }

  return (
    <div className="min-h-[calc(100vh-48px)] -mx-6 -mt-4 bg-[#f3f5f7] px-6 py-4">
      <div className="flex flex-col gap-5 mt-4">
        <div className="flex gap-5 self-stretch items-stretch">
          <div className="flex flex-col gap-8 self-stretch items-stretch flex-1 w-full lg:max-w-[33%]">
            <NameLocation
              id={id}
              companyName={company.name}
              location={company.location}
              logoLink={company.logoLink}
              loading={loading}
              enableEdit={enableEdit}
            />
            <div className="flex flex-col gap-5 items-stretch">
              <TextSection
                id={id}
                field="aboutUs"
                title="About Us"
                text={company.aboutUs}
                loading={loading}
                enableEdit={enableEdit}
              />
              <TextSection
                id={id}
                field="missionStatement"
                title="Mission Statement"
                text={company.missionStatement}
                loading={loading}
                enableEdit={enableEdit}
              />
              <Background
                id={id}
                foundedYear={company.foundedYear}
                companySize={company.companySize}
                fundsRaised={company.fundsRaised}
                lastFundraise={company.lastFundraise}
                loading={loading}
                enableEdit={enableEdit}
              />
              <TextSection
                id={id}
                field="cultureStatements"
                title="Culture"
                text={company.cultureStatements}
                loading={loading}
                enableEdit={enableEdit}
              />
              <Links
                id={id}
                linkedIn={company.linkedIn}
                website={company.websiteLink}
                loading={loading}
                enableEdit={enableEdit}
              />
              <div className="lg:hidden">{executives}</div>
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-5 self-stretch items-start flex-[2_0_0%]">
            {executives}
          </div>
        </div>
      </div>
    </div>
  );
};

const NameLocation = ({
  id,
  companyName,
  location,
  logoLink,
  loading,
  enableEdit,
}: {
  id?: string;
  companyName?: string;
  location?: string;
  logoLink?: string;
  loading: boolean;
  enableEdit?: boolean;
}) => {
  if (loading) {
    return (
      <Skeleton className="card flex-row gap-4 w-full">
        <div className="flex flex-col items-start rounded-full relative">
          <SkeletonItem
            shape="circle"
            style={{ height: "88px", width: "88px" }}
          />
        </div>
        <div className="flex flex-col self-center items-start gap-1 flex-1">
          <div className="flex flex-col gap-[2px] items-start">
            <SkeletonItem style={{ height: "32px", width: "150px" }} />
            <SkeletonItem style={{ height: "20px", width: "100px" }} />
          </div>
        </div>
      </Skeleton>
    );
  }

  return (
    <div className="card flex-row gap-4 w-full">
      <div className="flex flex-col items-start rounded-full relative">
        <Avatar
          image={{ src: logoLink }}
          name={companyName}
          size={72}
          style={{ width: "88px", height: "88px" }}
        />
        <VerifiedTickSVG className="absolute bottom-0 right-0" />
      </div>
      <div className="flex flex-col self-center items-start gap-1 flex-1">
        <div className="flex flex-col gap-[2px] items-start">
          <p className="text-2xl font-semibold tracking-[-0.24px] text-[#22234A]">
            {companyName}
          </p>
          {location ? (
            <div className="flex items-center gap-1">
              <LocationSVG />
              <p className="text-base font-medium text-[#727E94]">{location}</p>
            </div>
          ) : null}
        </div>
      </div>
      {enableEdit && id ? (
        <NameLocationEditDialog
          id={id}
          companyName={companyName}
          location={location}
          logoLink={logoLink}
        />
      ) : null}
    </div>
  );
};

const NameLocationEditDialog = ({
  id,
  companyName,
  location,
  logoLink,
}: {
  id: string;
  companyName?: string;
  location?: string;
  logoLink?: string;
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newLogo, setNewLogo] = useState<File>();

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setNewCompanyName(companyName ?? "");
      setNewLocation(location ?? "");
      setNewLogo(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data = new FormData();
    data.append("name", newCompanyName);
    data.append("location", newLocation);

    if (newLogo) {
      data.append("companyLogo", newLogo);
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(updateCompanyProfile({ id, data, token }));
      await invalidate(QueryKeys.myCompany);
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <p className="edit-text self-end">Edit</p>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Name and Location
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Name" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newCompanyName}
                  onChange={(_evt, { value }) => setNewCompanyName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Logo" className={fieldStyle.editField}>
                <ImageUploadPreview
                  newImageFile={newLogo}
                  oldImageLink={logoLink}
                />
                <FileUploader
                  classes="flex-1 !min-w-[200px]"
                  multiple={false}
                  handleChange={(file?: File) => setNewLogo(file)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Location" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newLocation}
                  onChange={(_evt, { value }) => setNewLocation(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const TextSection = ({
  id,
  field,
  title,
  text,
  loading,
  enableEdit,
}: {
  id?: string;
  field: "cultureStatements" | "aboutUs" | "missionStatement";
  title: string;
  text?: string;
  loading: boolean;
  enableEdit?: boolean;
}) => {
  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardHeader text={title} />
        <SkeletonItem style={{ height: "128px", width: "100%" }} />
      </Skeleton>
    );
  }

  if (!text && !enableEdit) {
    return;
  }

  return (
    <div className="card gap-3 w-full">
      <CardHeader
        text={title}
        editDialog={
          enableEdit && id ? (
            <TextSectionEditDialog
              id={id}
              label={title}
              field={field}
              value={text}
            />
          ) : undefined
        }
      />
      <div className="p-3 self-stretch bg-[#F2F5F5] rounded-xl">
        <ReadMoreSection>{text}</ReadMoreSection>
      </div>
    </div>
  );
};

const TextSectionEditDialog = ({
  id,
  label,
  field,
  value,
}: {
  id: string;
  label: string;
  field: "cultureStatements" | "aboutUs" | "missionStatement";
  value?: string;
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [newValue, setNewValue] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();
  const inputStyle = useInputStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setNewValue(value ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data: CompanyAddRequest = {
      [field]: newValue,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(updateCompanyProfile({ id, data, token }));
      await invalidate(QueryKeys.myCompany);
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <p className="edit-text self-end">Edit</p>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit {label}
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field className={fieldStyle.editField}>
                <Textarea
                  className={inputStyle.hugeTextArea}
                  value={newValue}
                  onChange={(_evt, { value: newValue }) =>
                    setNewValue(newValue)
                  }
                  resize="vertical"
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Background = ({
  id,
  foundedYear,
  companySize,
  fundsRaised,
  lastFundraise,
  loading,
  enableEdit,
}: {
  id?: string;
  foundedYear?: string;
  companySize?: string;
  fundsRaised?: string;
  lastFundraise?: string;
  loading: boolean;
  enableEdit?: boolean;
}) => {
  return (
    <div className="card gap-3 w-full">
      <CardHeader
        text="Background"
        editDialog={
          !loading && enableEdit && id ? (
            <BackgroundEditDialog
              id={id}
              foundedYear={foundedYear}
              companySize={companySize}
              fundsRaised={fundsRaised}
              lastFundraise={lastFundraise}
            />
          ) : undefined
        }
      />
      <div className="flex flex-col content-center items-start self-stretch">
        <ContentRow
          text="Founded"
          Icon={
            <ReceiptEditSVG className="flex-shrink-0" height="24" width="24" />
          }
          response={
            dateStringToLocalDate(foundedYear)?.toLocaleDateString("en-US", {
              year: "numeric",
            }) ?? undefined
          }
          loading={loading}
        />
        <ContentRow
          text="Company Size"
          Icon={<PeopleSVG className="flex-shrink-0" height="24" width="24" />}
          response={companySize}
          loading={loading}
        />
        <ContentRow
          text="Funds Raised"
          Icon={
            <DollarSquareSVG className="flex-shrink-0" height="24" width="24" />
          }
          response={fundsRaised}
          loading={loading}
        />
        <ContentRow
          text="Last Fundraise"
          Icon={
            <MoneyReceiveSVG className="flex-shrink-0" height="24" width="24" />
          }
          response={lastFundraise}
          loading={loading}
          isLast
        />
      </div>
    </div>
  );
};

const BackgroundEditDialog = ({
  id,
  foundedYear,
  companySize,
  fundsRaised,
  lastFundraise,
}: {
  id: string;
  foundedYear?: string;
  companySize?: string;
  fundsRaised?: string;
  lastFundraise?: string;
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [newFoundedYear, setNewFoundedYear] = useState("");
  const [newCompanySize, setNewCompanySize] = useState("");
  const [newFundsRaised, setNewFundsRaised] = useState("");
  const [newLastFundraise, setNewLastFundraise] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setNewFoundedYear(foundedYear ?? "");
      setNewCompanySize(companySize ?? "");
      setNewFundsRaised(fundsRaised ?? "");
      setNewLastFundraise(lastFundraise ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data: CompanyAddRequest = {
      foundedYear: newFoundedYear,
      companySize: newCompanySize,
      fundsRaised: newFundsRaised,
      lastFundraise: newLastFundraise,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(updateCompanyProfile({ id, data, token }));
      await invalidate(QueryKeys.myCompany);
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <p className="edit-text self-end">Edit</p>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Background Information
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="w-fit flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Year Founded" className={fieldStyle.editField}>
                <ReactDatePicker
                  className="py-3 px-2 w-fit border border-[#E8E8E8] rounded-xl"
                  selected={
                    newFoundedYear
                      ? dateStringToLocalDate(newFoundedYear)
                      : null
                  }
                  onChange={(date) =>
                    setNewFoundedYear(date ? localDateToDateString(date) : "")
                  }
                  dateFormat="yyyy"
                  showYearPicker
                  showIcon
                  icon={<CalendarSVG className="top-[2px]" />}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Company Size (Headcount)"
                className={fieldStyle.editField}
              >
                <Input
                  type="text"
                  value={newCompanySize}
                  onChange={(_evt, { value }) => setNewCompanySize(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Funds Raised" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={newFundsRaised}
                  onChange={(_evt, { value }) => setNewFundsRaised(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Last Fundraise (Round)"
                className={fieldStyle.editField}
              >
                <Input
                  type="text"
                  value={newLastFundraise}
                  onChange={(_evt, { value }) => setNewLastFundraise(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Links = ({
  id,
  linkedIn,
  website,
  loading,
  enableEdit,
}: {
  id?: string;
  linkedIn?: string;
  website?: string;
  loading: boolean;
  enableEdit?: boolean;
}) => {
  if (loading) {
    return (
      <Skeleton className="card gap-3 w-full">
        <CardHeader text="Links" />
        <div className="flex flex-col content-center items-start self-stretch">
          <div className="flex flex-wrap gap-x-3 self-stretch items-center justify-between">
            <ContentRow
              Icon={
                <MonitorMobileSVG
                  className="flex-shrink-0"
                  height="20"
                  width="20"
                />
              }
              text="Website"
              response={website}
              loading={loading}
              isLast
            />
            <ContentRow
              Icon={
                <LinkedInSVG className="flex-shrink-0" height="20" width="20" />
              }
              text="Linkedin"
              response={linkedIn}
              loading={loading}
              isLast
            />
          </div>
        </div>
      </Skeleton>
    );
  }

  return (
    <div className="card gap-3 w-full">
      <CardHeader
        text="Links"
        editDialog={
          enableEdit && id ? (
            <LinksEditDialog id={id} linkedIn={linkedIn} website={website} />
          ) : undefined
        }
      />
      <div className="flex flex-col content-center items-start self-stretch">
        <ContentRow
          Icon={
            <MonitorMobileSVG
              className="flex-shrink-0"
              height="20"
              width="20"
            />
          }
          text="Website"
          responseIcon={
            website ? (
              <a
                className="cursor-pointer"
                href={
                  website.startsWith("http") ? website : `https://${website}`
                }
                target="_blank"
                rel="noreferrer"
              >
                <ExportSVG height="20" width="20" stroke="#ED5C38" />
              </a>
            ) : (
              <ExportMissingSVG height="20" width="20" />
            )
          }
          isLast
        />
        <ContentRow
          Icon={
            <LinkedInSVG className="flex-shrink-0" height="20" width="20" />
          }
          text="Linkedin"
          responseIcon={
            linkedIn ? (
              <a
                className="cursor-pointer"
                href={linkedIn}
                target="_blank"
                rel="noreferrer"
              >
                <ExportSVG height="20" width="20" stroke="#ED5C38" />
              </a>
            ) : (
              <ExportMissingSVG height="20" width="20" />
            )
          }
          isLast
        />
      </div>
    </div>
  );
};

const LinksEditDialog = ({
  id,
  linkedIn,
  website,
}: {
  id: string;
  linkedIn?: string;
  website?: string;
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [newLinkedIn, setNewLinkedIn] = useState("");
  const [newWebsite, setNewWebsite] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setNewLinkedIn(linkedIn ?? "");
      setNewWebsite(website ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data: CompanyAddRequest = {
      linkedIn: newLinkedIn,
      websiteLink: newWebsite,
    };

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(updateCompanyProfile({ id, data, token }));
      await invalidate(QueryKeys.myCompany);
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <p className="edit-text self-end">Edit</p>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Edit Links
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="LinkedIn" className={fieldStyle.editField}>
                <Input
                  type="url"
                  value={newLinkedIn}
                  onChange={(_evt, { value }) => setNewLinkedIn(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Website" className={fieldStyle.editField}>
                <Input
                  type="url"
                  value={newWebsite}
                  onChange={(_evt, { value }) => setNewWebsite(value)}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <div className="h-10 py-[10px] px-4 rounded-full border border-solid border-[#DBDFE5] flex-1 cursor-pointer">
                  <p className="text-sm font-semibold text-[#636E82] text-center">
                    Cancel
                  </p>
                </div>
              </DialogTrigger>
              <div
                className="h-10 py-3 px-6 rounded-full bg-[#19A594] flex-1 cursor-pointer"
                onClick={onSubmit}
              >
                <p className="text-sm font-medium text-white text-center">
                  Save
                </p>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const Executives = ({
  id,
  executives,
  loading,
  enableEdit,
}: {
  id?: string;
  executives: Executive[];
  loading: boolean;
  enableEdit?: boolean;
}) => {
  if (loading) {
    return (
      <div className="flex flex-col gap-3 self-stretch items-start">
        <h2 className="text-lg font-semibold text-[#22234A] leading-6 tracking-[-0.18px]">
          Our Executives
        </h2>
        <ExecutiveCard loading />
        <ExecutiveCard loading />
        <ExecutiveCard loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 self-stretch items-start">
      <div className="flex gap-5 self-stretch justify-between">
        <h2 className="text-lg font-semibold text-[#22234A] leading-6 tracking-[-0.18px]">
          Our Executives
        </h2>
        {enableEdit && id ? (
          <div className="flex gap-3 self-stretch items-center">
            <ExecutiveReorderDialog id={id} executives={executives} />
            <ExecutiveEditDialog id={id} />
          </div>
        ) : null}
      </div>
      {executives.map((executive, idx) => (
        <ExecutiveCard
          id={id}
          key={`executive-${idx}`}
          executive={executive}
          enableEdit={enableEdit}
        />
      ))}
    </div>
  );
};

const ExecutiveCard = ({
  id,
  executive,
  loading,
  enableEdit,
}: {
  id?: string;
  executive?: Executive;
  loading?: boolean;
  enableEdit?: boolean;
}) => {
  if (loading) {
    return (
      <div className="p-6 flex flex-col gap-4 self-stretch bg-white rounded-xl">
        <div className="flex gap-3 self-stretch items-center">
          <SkeletonItem size={48} shape="circle" />
          <div className="flex-1 flex flex-col gap-1">
            <div className="flex gap-1 items-start">
              <SkeletonItem size={32} style={{ width: "150px" }} />
            </div>
            <SkeletonItem size={24} style={{ width: "100px" }} />
          </div>
        </div>
        <div className="w-full h-[1px] min-h-[1px] bg-[#F2F2F2]" />
        <SkeletonItem size={72} className="w-full" />
      </div>
    );
  } else if (!executive) {
    return;
  }

  return (
    <div className="p-6 flex flex-col gap-4 self-stretch bg-white rounded-xl">
      <div className="flex gap-3 self-stretch items-center flex-wrap">
        <Avatar
          image={{ src: executive.profilePicture }}
          name={executive.name}
          size={48}
        />
        <div className="flex-grow flex-shrink-0 flex flex-col gap-1">
          <div className="flex gap-1 items-start">
            <p className="text-base font-semibold text-[#22234A] leading-6">
              {executive.name}
            </p>
            {executive.engagementType ? (
              <div className="py-1 px-2 flex gap-2 items-center justify-center bg-[#F2F2F2] rounded-[20px]">
                <p className="text-xs font-medium text-[#22234A]">
                  {executive.engagementType}
                </p>
              </div>
            ) : null}
          </div>
          {executive.role ? (
            <p className="text-base font-normal text-[#727E94] leading-5">
              {executive.role}
            </p>
          ) : null}
        </div>
        <div className="flex gap-3 items-start">
          {executive.linkedIn ? (
            <a
              className="py-2 px-4 flex gap-2 items-center justify-center bg-[#F2F2F2] rounded-full cursor-pointer"
              href={executive.linkedIn}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInSVG height="16" width="16" />
              <p className="text-sm font-semibold text-[#4E4F6C]">Linkedin</p>
            </a>
          ) : null}
          {executive.cultureLink ? (
            <a
              className="py-2 px-4 flex gap-2 items-center justify-center bg-[#F2F2F2] rounded-full cursor-pointer"
              href={executive.cultureLink}
              target="_blank"
              rel="noreferrer"
            >
              <SocialIconSVG height="16" width="16" />
              <p className="text-sm font-semibold text-[#4E4F6C]">
                Talent Insights
              </p>
            </a>
          ) : null}
          {enableEdit && id ? (
            <ExecutiveEditDialog id={id} executive={executive} />
          ) : null}
        </div>
      </div>
      <div className="w-full h-[1px] min-h-[1px] bg-[#F2F2F2]" />

      {
        /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
        executive.experience || executive.startDate ? (
          <div className="flex gap-6 self-stretch">
            <ExecutiveCardKeyPoint
              point="Experience"
              value={executive.experience}
            />
            <ExecutiveCardKeyPoint
              point="Start Date"
              value={executive.startDate}
            />
          </div>
        ) : null
        /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
      }
      {executive.biography ? (
        <div className="p-3 self-stretch bg-[#F2F5F5] rounded-xl">
          <ReadMoreSection maxLines={4}>{executive.biography}</ReadMoreSection>
        </div>
      ) : null}
    </div>
  );
};

const ExecutiveCardKeyPoint = ({
  point,
  value,
}: {
  point: "Experience" | "Start Date";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1 items-start">
      <p className="text-sm font-medium text-[#727E94]">{point}:</p>
      <p className="text-sm font-semibold text-[#22234A]">
        {point === "Experience" ? `${value} Years` : value}
      </p>
    </div>
  );
};

const ExecutiveEditDialog = ({
  id,
  executive,
}: {
  id: string;
  executive?: Executive;
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [picture, setPicture] = useState<File>();
  const [role, setRole] = useState("");
  const [engagementType, setEngagementType] = useState("");
  const [experience, setExperience] = useState("");
  const [startDate, setStartDate] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [biography, setBiography] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const buttonStyle = useButtonStyles();
  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setName(executive?.name ?? "");
      setPicture(undefined);
      setRole(executive?.role ?? "");
      setEngagementType(executive?.engagementType ?? "");
      setExperience(executive?.experience ?? "");
      setStartDate(executive?.startDate ?? "");
      setLinkedIn(executive?.linkedIn ?? "");
      setBiography(executive?.biography ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    const data = new FormData();
    data.append("name", name);
    data.append("role", role);
    data.append("engagementType", engagementType);
    data.append("experience", experience);
    data.append("linkedIn", linkedIn);
    data.append("biography", biography);
    data.append("startDate", startDate);

    if (picture) {
      data.append("profilePicture", picture);
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      if (executive) {
        await dispatch(
          updateExecutive({
            companyId: id,
            executiveId: executive.id,
            data,
            token,
          }),
        );
      } else {
        await dispatch(addExecutive({ id, data, token }));
      }

      await invalidate(QueryKeys.myCompany);
    })();
  };

  const onDelete = () => {
    if (posting || !executive) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(
        deleteExecutive({
          companyId: id,
          executiveId: executive.id,
          token,
        }),
      );

      await invalidate(QueryKeys.myCompany);
    })();
  };

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="primary" shape="circular">
          {executive ? "Edit" : "Add Executive"}
        </Button>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              {executive ? "Edit Executive" : "Add Executive"}
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Name" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={name}
                  onChange={(_evt, { value }) => setName(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Picture" className={fieldStyle.editField}>
                <ImageUploadPreview
                  newImageFile={picture}
                  oldImageLink={executive?.profilePicture}
                />
                <FileUploader
                  classes="flex-1 !min-w-[200px]"
                  multiple={false}
                  handleChange={(file?: File) => setPicture(file)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Job Title" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={role}
                  onChange={(_evt, { value }) => setRole(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Engagement Type" className={fieldStyle.editField}>
                <Dropdown
                  value={engagementType}
                  selectedOptions={[engagementType]}
                  onOptionSelect={(_evt, data) => {
                    setEngagementType(data.optionValue ?? "");
                  }}
                >
                  {engagementTypeList.map((type) => (
                    <Option key={`engagement-type-${type}`}>{type}</Option>
                  ))}
                </Dropdown>
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field
                label="Years of Experience"
                className={fieldStyle.editField}
              >
                <Input
                  type="text"
                  value={experience}
                  onChange={(_evt, { value }) => setExperience(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Year Hired" className={fieldStyle.editField}>
                <Input
                  type="text"
                  value={startDate}
                  onChange={(_evt, { value }) => setStartDate(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="LinkedIn" className={fieldStyle.editField}>
                <Input
                  type="url"
                  value={linkedIn}
                  onChange={(_evt, { value }) => setLinkedIn(value)}
                />
              </Field>
            </div>
            <div className="flex flex-col gap-2 self-stretch items-stretch">
              <Field label="Biography" className={fieldStyle.editField}>
                <Textarea
                  value={biography}
                  onChange={(_evt, { value }) => setBiography(value)}
                  resize="vertical"
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex-1 flex flex-col gap-2 self-stretch items-stretch pt-4 px-6 border-top-line">
              {executive ? (
                <>
                  <div className="flex gap-2 items-center self-stretch">
                    <Button
                      className={mergeClasses(buttonStyle.redButton, "flex-1")}
                      appearance="secondary"
                      onClick={onDelete}
                      disabled={posting}
                    >
                      Delete
                    </Button>
                    <Button
                      className="flex-1"
                      appearance="primary"
                      onClick={onSubmit}
                      disabled={posting}
                    >
                      Save
                    </Button>
                  </div>
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      className="flex-1"
                      appearance="outline"
                      disabled={posting}
                    >
                      Cancel
                    </Button>
                  </DialogTrigger>
                </>
              ) : (
                <div className="flex gap-2 items-center self-stretch">
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      className="flex-1"
                      appearance="outline"
                      disabled={posting}
                    >
                      Cancel
                    </Button>
                  </DialogTrigger>
                  <Button
                    className="flex-1"
                    appearance="primary"
                    onClick={onSubmit}
                    disabled={posting}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const ExecutiveReorderDialog = ({
  id,
  executives,
}: {
  id: string;
  executives: Executive[];
}) => {
  const invalidate = useInvalidateQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [team, setTeam] = useState<Executive[]>([]);

  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();

  const posting = postingStatus !== "idle";

  useEffect(() => {
    if (isOpen) {
      setTeam([...executives]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
      setIsOpen(false);
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const onSubmit = () => {
    if (posting) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      await dispatch(
        reorderExecutives({
          companyId: id,
          data: { orderedExecutiveIds: team.map(({ id }) => id) },
          token,
        }),
      );

      await invalidate(QueryKeys.myCompany);
    })();
  };

  if (executives.length < 2) {
    return;
  }

  return (
    <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="secondary" shape="circular">
          Change Team Order
        </Button>
      </DialogTrigger>
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              Change Team Order
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <ReorderableExecutiveTeam executives={team} update={setTeam} />
          </DialogContent>
          <DialogActions>
            <div className="flex-1 flex flex-col gap-2 self-stretch items-stretch pt-4 px-6 border-top-line">
              <div className="flex gap-2 items-center self-stretch">
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    className="flex-1"
                    appearance="outline"
                    disabled={posting}
                  >
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button
                  className="flex-1"
                  appearance="primary"
                  onClick={onSubmit}
                  disabled={posting}
                >
                  Save
                </Button>
              </div>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const CardHeader = ({
  text,
  editDialog,
}: {
  text: string;
  editDialog?: JSX.Element;
}) => {
  return (
    <div className="flex self-stretch items-center justify-between pb-4 border-bottom-line">
      <div className="flex gap-4 self-stretch items-center">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          {text}
        </p>
      </div>
      {editDialog}
    </div>
  );
};

const ContentRow = ({
  Icon,
  text,
  response,
  responseIcon,
  isLast,
  loading,
}: {
  Icon?: JSX.Element;
  text: string;
  response?: string;
  responseIcon?: JSX.Element;
  isLast?: boolean;
  loading?: boolean;
}) => {
  return (
    <div
      className={`flex gap-1 self-stretch items-center justify-between min-h-12 py-4 ${isLast ? "" : "border-bottom-line"}`}
    >
      <div className="flex gap-3 items-center">
        {Icon}
        <p className="text-sm font-semibold text-[#22234A]">{text}</p>
      </div>
      {loading ? (
        response ? (
          <Skeleton style={{ height: "20px", width: "30%" }}>
            <SkeletonItem style={{ height: "100%", width: "100%" }} />
          </Skeleton>
        ) : (
          <Skeleton style={{ height: "24px", width: "24px" }}>
            <SkeletonItem
              shape="circle"
              style={{ height: "100%", width: "100%" }}
            />
          </Skeleton>
        )
      ) : (
        <>
          <p className="max-w-[70%] text-sm font-medium text-[#22234A] text-nowrap overflow-hidden overflow-ellipsis hover:overflow-visible hover:text-wrap">
            {response}
          </p>
          {responseIcon}
        </>
      )}
    </div>
  );
};

export default CompanyProfile;
