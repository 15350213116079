import request from "@/lib/api/handler";

import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/queries/utils";
import { AdminPipelineCandidate } from "@/queries/admin/candidate-pipeline";
import { CandidateWaitingRoomStatus } from "@/types";
import { AdminSummary } from "@/queries/admin/dashboard";

type SetCandidateStageParams =
  | {
      candidateId: string;
      stage?: CandidateWaitingRoomStatus;
      currentStage: CandidateWaitingRoomStatus;
    }
  | {
      candidateId: string;
      stage: CandidateWaitingRoomStatus;
      currentStage?: CandidateWaitingRoomStatus;
    };

const NEXT_STAGE_MAP: Record<
  CandidateWaitingRoomStatus,
  CandidateWaitingRoomStatus
> = {
  New: "Invited to Apply",
  "Invited to Apply": "Application Under Review",
  "Application Under Review": "Interviewing",
  Interviewing: "Interview Under Review",
  "Interview Under Review": "Taking/Sent MAP",
  "Taking/Sent MAP": "MAP Under Review",
  "MAP Under Review": "Joined",
  Joined: "Joined",
  "Dropped out": "Dropped out",
  Rejected: "Rejected",
};

export const useSetCandidateStage = () => {
  const qc = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const { mutate, isPending, error } = useMutation({
    mutationFn: async ({
      candidateId,
      stage,
      currentStage,
    }: SetCandidateStageParams) => {
      const token = await getAccessTokenSilently();

      return request<AdminPipelineCandidate>({
        method: "PATCH",
        path: `/api/admin/candidate/${candidateId}/stage`,
        token,
        body: JSON.stringify({ stage: stage ?? NEXT_STAGE_MAP[currentStage] }),
      });
    },
    onSuccess: (data) => {
      // Check if the candidate reached a final stage and should be dropped from the pipeline
      const candidateDone =
        data.stage === "Joined" ||
        data.stage === "Rejected" ||
        data.stage === "Dropped out";

      // Fetch any existing data
      const prev = qc.getQueryData<AdminPipelineCandidate[]>(
        QueryKeys.adminCandidatePipeline,
      );

      // If there is existing data, update it
      if (prev) {
        // Make a copy and check find the updated element
        const candidates = [...prev];
        const candidateIndex = candidates.findIndex(
          (candidate) => candidate.candidateId === data.candidateId,
        );

        // If the element is found
        if (candidateIndex !== -1 && candidates[candidateIndex]) {
          // Remove it if the candidate reached a final stage
          if (candidateDone) {
            candidates.splice(candidateIndex, 1);
          } else {
            // Update it otherwise
            candidates[candidateIndex] = data;
          }

          qc.setQueryData(QueryKeys.adminCandidatePipeline, candidates);
        } else {
          // If the entry couldn't be found, invalidate and fetch new data
          void qc.invalidateQueries({
            queryKey: QueryKeys.adminCandidatePipeline,
          });
        }
      }

      // If the candidate reached a final stage, update the dashboard number
      if (candidateDone) {
        const dashboardPrev = qc.getQueryData<AdminSummary>(
          QueryKeys.adminDashboard,
        );

        if (dashboardPrev) {
          qc.setQueryData(QueryKeys.adminDashboard, {
            ...dashboardPrev,
            openApplicationsCount: dashboardPrev.openApplicationsCount - 1,
          });
        }
      }
    },
  });

  return {
    setCandidateStage: mutate,
    isSettingCandidateStage: isPending,
    setCandidateStageError: error,
  };
};
