import { useEffect, useState } from "react";
import { toast } from "sonner";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useCompanyScheduleInterviewSheet } from "./store";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useShallow } from "zustand/shallow";
import { Skeleton } from "@/components/ui/skeleton";
import { timezones } from "@/lib/timezone";
import { Fragment } from "react/jsx-runtime";
import { weekdayList } from "@/types";
import { Calendar } from "@/components/ui/calendar";
import { TimeInput } from "@/components/ui/input";
import { AvailabilityTime } from "../../../../lib/datetime";
import { cn } from "@/lib/utils";
import { useCompanyScheduleInterview } from "./mutations";
import { useRequestInterviewAvailability } from "./request-availability";
import { Check, Loader2 } from "lucide-react";
import { useCompanyCandidateAvailabilityQuery } from "@/queries/company/availability";

const CompanyScheduleInterviewSheet = () => {
  const time = useCompanyScheduleInterviewSheet((s) => s.time);
  const open = useCompanyScheduleInterviewSheet((s) => s.open);
  const setOpen = useCompanyScheduleInterviewSheet((s) => s.setOpen);
  const isInvalid = useCompanyScheduleInterviewSheet((s) => s.invalid);
  const successOpen = useCompanyScheduleInterviewSheet((s) => s.successOpen);
  const setSuccessOpen = useCompanyScheduleInterviewSheet(
    (s) => s.setSuccessOpen,
  );

  const isUpdating = useCompanyScheduleInterviewSheet((s) => s.isUpdating);
  const {
    scheduleInterview,
    scheduleInterviewPending,
    scheduleInterviewError,
  } = useCompanyScheduleInterview();

  return (
    <>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className="w-full max-w-full sm:max-w-md md:max-w-lg bg-background">
          <SheetHeader className="pb-6">
            <SheetTitle>Schedule an Interview</SheetTitle>
            <SheetDescription className="sr-only">
              Enter your availability for an interview for the selected
              opportunity
            </SheetDescription>
            {scheduleInterviewError && (
              <div className="border border-destructive text-destructive p-2 rounded-md text-sm text-center w-full bg-destructive/10">
                <p>
                  An error occurred while trying to schedule the interview,
                  please try again later
                </p>
              </div>
            )}
          </SheetHeader>
          <ScrollArea className="h-[calc(100vh-4.75rem)]" type="scroll">
            <CandidateAvailability />
            <InterviewCalendar />
            <TimeEntry />
            <Button
              variant={"secondary"}
              className="mt-8 w-full"
              disabled={isInvalid || time.isPlaceholder}
              loading={scheduleInterviewPending}
              onClick={() => scheduleInterview(isUpdating)}
            >
              Mark As Scheduled
            </Button>
            <RequestAvailability closeParent={() => setOpen(false)} />
            <Button
              variant="outline"
              className="mt-2 w-full"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </ScrollArea>
        </SheetContent>
      </Sheet>
      <Dialog open={successOpen} onOpenChange={setSuccessOpen}>
        <DialogContent>
          <DialogHeader>
            <div className="space-y-4">
              <div className="flex items-center justify-center size-12 box-content border-[6px] border-accent-og rounded-full">
                <Check className="size-7 text-secondary" />
              </div>
              <DialogTitle>
                Your interview has been marked as scheduled!
              </DialogTitle>
              <DialogDescription>
                Once your interview is complete, return to the dashboard to
                proceed the candidate to the next step.
              </DialogDescription>
            </div>

            <DialogFooter className="w-full justify-center">
              <DialogClose asChild>
                <Button
                  variant="secondary"
                  className="mt-4 w-full"
                  onClick={() => setOpen(false)}
                >
                  Return To Dashboard
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompanyScheduleInterviewSheet;

const CandidateAvailability = () => {
  const candidate = useCompanyScheduleInterviewSheet((s) => s.candidate);
  const [opportunityID, interviewID] = useCompanyScheduleInterviewSheet(
    useShallow((s) => [s.opportunity?.id, s.interview?.id]),
  );
  const { candidateAvailability, candidateAvailabilityLoading } =
    useCompanyCandidateAvailabilityQuery(
      opportunityID,
      interviewID,
      candidate?.candidateId,
    );

  const timezone = timezones
    .flatMap((tz) => tz.timezones)
    .find((tz) => {
      if (candidateAvailability?.general?.find((a) => a.length > 0)) {
        return (
          candidateAvailability.general
            .find((a) => a.length > 0)![0]!
            .start.getTimezone() === tz.abbr
        );
      } else if (
        candidateAvailability?.specific?.find((a) => a.times.length > 0)
      ) {
        return (
          candidateAvailability.specific
            .find((a) => a.times.length > 0)!
            .times[0]!.start.getTimezone() === tz.abbr
        );
      }
    });
  if (!timezone) {
    return null;
  }

  const hasRealGeneralAvailability = !!candidateAvailability?.general?.filter(
    (e) => e.some((t) => !t.start.isPlaceholder && !t.end.isPlaceholder),
  )?.length;

  return (
    <div className="border border-border shadow-md rounded-lg p-4 space-y-4">
      {candidateAvailabilityLoading ? (
        <Skeleton className="w-4/5 h-4" />
      ) : (
        <h5 className="font-semibold text-sm">
          {candidate?.name}
          {candidate?.name?.at(-1) === "s" ? "' " : "'s "}
          Availability in {timezone.name}
        </h5>
      )}
      {candidateAvailabilityLoading ? (
        <div className="space-y-1">
          {Array.from({ length: 3 }).map((_, i) => (
            <div
              key={`avail-${i}`}
              className="flex justify-between items-center text-muted-foreground text-sm"
            >
              <Skeleton className="w-3/5 h-4" />
              <Skeleton className="w-1/4 h-4" />
            </div>
          ))}
        </div>
      ) : !hasRealGeneralAvailability &&
        !candidateAvailability?.specific?.length ? (
        <div>
          <p className="py-2 text-sm text-muted-foreground text-center w-full">
            {candidate?.name
              ? `${candidate?.name} has not added their availability yet`
              : "Candidate's availability has not been added yet"}
          </p>
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-[auto,auto] justify-between items-center text-muted-foreground text-sm">
            {candidateAvailability?.general
              .map((d) =>
                d.filter((e) => !e.start.isPlaceholder || !e.end.isPlaceholder),
              )
              .map((day, i) =>
                day.length ? (
                  <Fragment key={`general-avail-${i}`}>
                    <p>{weekdayList[i]}s</p>
                    <div>
                      {day.map((time, j) => (
                        <p key={j}>
                          {time.start.display.toLowerCase()} -{" "}
                          {time.end.display.toLowerCase()}
                        </p>
                      ))}
                    </div>
                  </Fragment>
                ) : null,
              )}
          </div>
          {!!candidateAvailability?.specific.length && (
            <>
              <div className="h-px bg-border w-full my-2"></div>
              <div className="grid grid-cols-[auto,auto] justify-between items-center text-muted-foreground text-sm">
                {candidateAvailability?.specific.map((entry, i) => (
                  <Fragment key={`specific-avail-${i}`}>
                    <p>
                      {entry.startDate.sameDay(entry.endDate)
                        ? entry.startDate.display
                        : `${entry.startDate.display} - ${entry.endDate.display}`}
                    </p>
                    <div>
                      {entry.times.map((time, j) => (
                        <p key={j}>
                          {time.start.display.toLowerCase()} -{" "}
                          {time.end.display.toLowerCase()}
                        </p>
                      ))}
                    </div>
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      <Button
        variant="outline"
        className="w-full"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `mailto:${candidate?.email}`;
          void (async () => {
            if (candidate?.email) {
              try {
                await navigator.clipboard.writeText(candidate?.email ?? "");
                toast.success("Candidate email copied to clipboard");
              } catch (_) {
                // Swallow error
              }
            }
          })();
        }}
      >
        Email to schedule an interview
      </Button>
    </div>
  );
};

const InterviewCalendar = () => {
  const selectedDate = useCompanyScheduleInterviewSheet((s) => s.selectedDate);
  const setSelectedDate = useCompanyScheduleInterviewSheet(
    (s) => s.setSelectedDate,
  );
  const candidate = useCompanyScheduleInterviewSheet((s) => s.candidate);
  const [opportunityID, interviewID] = useCompanyScheduleInterviewSheet(
    useShallow((s) => [s.opportunity?.id, s.interview?.id]),
  );
  const { candidateAvailability } = useCompanyCandidateAvailabilityQuery(
    opportunityID,
    interviewID,
    candidate?.candidateId,
  );

  return (
    <div className="border border-border shadow-md rounded-lg p-4 space-y-4 mt-6">
      <h5 className="text-sm font-semibold">
        When is the interview scheduled?
      </h5>
      <Calendar
        selected={selectedDate}
        onSelect={setSelectedDate}
        className="h-full w-full flex"
        classNames={{
          root: "!p-0",
          months:
            "flex w-full flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 flex-1",
          month: "space-y-4 w-full flex flex-col",
          table: "w-full h-full border-collapse space-y-1",
          head_row: "",
          row: "w-full mt-2",
          cell: "[&:has([aria-selected].day-outside)]:bg-background text-center",
        }}
        mode="single"
        disabled={(d) => {
          if (
            candidateAvailability?.general[d.getDay()]?.find(
              (e) => !e.start.isPlaceholder && !e.end.isPlaceholder,
            )
          ) {
            return false;
          }

          return !candidateAvailability?.specific.find(
            (e) =>
              e.startDate.dateobj().getDate() <= d.getDate() &&
              e.endDate.dateobj().getDate() >= d.getDate() &&
              e.startDate.dateobj().getMonth() <= d.getMonth() &&
              e.endDate.dateobj().getMonth() >= d.getMonth() &&
              d.getFullYear() >= e.startDate.dateobj().getFullYear() &&
              d.getFullYear() <= e.endDate.dateobj().getFullYear(),
          );
        }}
      />
    </div>
  );
};

const TimeEntry = () => {
  const invalid = useCompanyScheduleInterviewSheet((s) => s.invalid);
  const toggleInvalid = useCompanyScheduleInterviewSheet((s) => s.setInvalid);
  const time = useCompanyScheduleInterviewSheet((s) => s.time);
  const setTime = useCompanyScheduleInterviewSheet((s) => s.setTime);
  const selectedDate = useCompanyScheduleInterviewSheet((s) => s.selectedDate);
  const candidate = useCompanyScheduleInterviewSheet((s) => s.candidate);
  const interview = useCompanyScheduleInterviewSheet((s) => s.interview);
  const [opportunityID, interviewID] = useCompanyScheduleInterviewSheet(
    useShallow((s) => [s.opportunity?.id, s.interview?.id]),
  );
  const { candidateAvailability } = useCompanyCandidateAvailabilityQuery(
    opportunityID,
    interviewID,
    candidate?.candidateId,
  );

  useEffect(() => {
    if (selectedDate && !time.isPlaceholder) {
      if (!candidateAvailability) {
        toggleInvalid(true);
      }

      let valid = false;
      const specific = candidateAvailability?.specific.find(
        (e) =>
          e.startDate.dateobj().getDate() <= selectedDate.getDate() &&
          e.endDate.dateobj().getDate() >= selectedDate.getDate() &&
          e.startDate.dateobj().getMonth() <= selectedDate.getMonth() &&
          e.endDate.dateobj().getMonth() >= selectedDate.getMonth() &&
          selectedDate.getFullYear() >= e.startDate.dateobj().getFullYear() &&
          selectedDate.getFullYear() <= e.endDate.dateobj().getFullYear(),
      );

      if (specific) {
        valid = !!specific.times.find((e) => {
          const startCopy = new AvailabilityTime(e.start.isotime, "isotime");
          startCopy.subtractMinutes(1);
          if (time.lessThan(startCopy)) {
            return false;
          }

          const timeCopy = new AvailabilityTime(time.isotime, "isotime");
          timeCopy.addMinutes(interview?.duration ?? 45);

          return !e.end.lessThan(timeCopy);
        });

        if (valid) {
          toggleInvalid(false);
          return;
        }
      } else {
        valid = !!candidateAvailability?.general[selectedDate.getDay()]?.find(
          (e) => {
            const startCopy = new AvailabilityTime(e.start.isotime, "isotime");
            startCopy.subtractMinutes(1);
            if (time.lessThan(startCopy)) {
              return false;
            }

            const timeCopy = new AvailabilityTime(time.isotime, "isotime");
            timeCopy.addMinutes(interview?.duration ?? 45);

            return !e.end.lessThan(timeCopy);
          },
        );

        if (valid) {
          toggleInvalid(false);
          return;
        }
      }

      toggleInvalid(true);
    } else {
      toggleInvalid(false);
    }
  }, [candidateAvailability, selectedDate, time, toggleInvalid, interview]);

  return (
    <div className="border border-border shadow-md rounded-lg p-4 space-y-4 mt-6">
      {!selectedDate ? (
        <h5 className="text-sm font-semibold">
          Select a date from the calendar above to enter the agreed upon time
          for the interview
        </h5>
      ) : (
        <>
          <h5 className="text-sm font-semibold">
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            }).format(selectedDate)}{" "}
            at
          </h5>
          <div className="space-y-2">
            <TimeInput
              value={time.input}
              className={cn({
                "border-destructive": invalid,
              })}
              onChange={(e) => {
                const input = e.target.value.toString();
                setTime(new AvailabilityTime(input, "hh:mm"));
              }}
            />
            {invalid && (
              <p className="text-destructive text-sm leading-none">
                Start time for a {interview?.duration ?? 45}-minute interview
                must be within the candidate&apos;s given availability
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const RequestAvailability = ({ closeParent }: { closeParent: () => void }) => {
  const [open, setOpen] = useState(false);

  const candidate = useCompanyScheduleInterviewSheet((s) => s.candidate);
  const [opportunityID, interviewID] = useCompanyScheduleInterviewSheet(
    useShallow((s) => [s.opportunity?.id, s.interview?.id]),
  );

  const { scheduleInterviewPending } = useCompanyScheduleInterview();
  const { requestAvailability, isRequesting } = useRequestInterviewAvailability(
    () => setOpenValue(false),
  );

  const { candidateAvailability, candidateAvailabilityLoading } =
    useCompanyCandidateAvailabilityQuery(
      opportunityID,
      interviewID,
      candidate?.candidateId,
    );

  const hasNoAvailability =
    !candidateAvailabilityLoading &&
    !candidateAvailability?.general.length &&
    !candidateAvailability?.specific.length;

  const setOpenValue = (open: boolean) => {
    setOpen(open);

    if (!open) {
      closeParent();
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpenValue}>
      <DialogTrigger asChild>
        <Button className="mt-2 w-full" loading={scheduleInterviewPending}>
          {hasNoAvailability
            ? "Request Availability"
            : "Request Additional Availability"}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="w-full p-0 sm:max-w-2xl"
        onClick={(event) => event.stopPropagation()}
        disableOverlayColor
      >
        <DialogHeader className="p-6">
          <div className="flex items-center gap-2">
            <DialogTitle>Request Candidate Availability</DialogTitle>
          </div>
          <DialogDescription className="sr-only">
            {hasNoAvailability
              ? 'Confirm that you need the candidate to provide availability for an interview and hit "Confirm"'
              : 'Confirm that you need the candidate to provide additional availability for an interview and hit "Confirm"'}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="sm:max-h-[calc(100vh-10rem)] md:max-h-[70vh] px-6 pb-6">
          <p>
            By clicking &quot;Confirm,&quot; Venturous will reach out on your
            behalf to request the candidate update their availability for this
            interview.
          </p>
          <div className="flex justify-between">
            <Button
              type="button"
              className="mt-2"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              type="submit"
              className="mt-2"
              disabled={isRequesting}
              onClick={() => {
                requestAvailability({
                  openingId: opportunityID,
                  candidateId: candidate?.candidateId,
                  interviewId: interviewID,
                });
              }}
            >
              {isRequesting ? <Loader2 className="animate-spin" /> : null}
              Confirm
            </Button>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
