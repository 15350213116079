import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./redux/store.ts";
import { Provider } from "react-redux";
import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import "./index.css";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  release: `${import.meta.env.VITE_REACT_APP_NAME}@${import.meta.env.VITE_REACT_APP_VERSION}`,
  environment: import.meta.env.MODE,
  dsn: "https://ab6efe3db74c72f703618efd96d13b2d@o4506038770270208.ingest.us.sentry.io/4507171423846400",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^localhost:5000\/api/,
    /^https:\/\/www\.app\.myventurous\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const hashParams = new URLSearchParams(window.location.hash.substring(1));
const distinct_id = hashParams.get("distinct_id");
const session_id = hashParams.get("session_id");
const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  cross_subdomain: true,
  cookie_domain: ".venturous.work",
  cross_subdomain_cookie: true,
  bootstrap: {
    sessionID: session_id ?? "",
    distinctID: distinct_id ?? "",
  },
};

// eslint-disable-next-line
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
);
