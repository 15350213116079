import CompanyLogo1 from "@/assets/companies/healthgrades.svg";
import CompanyLogo2 from "@/assets/companies/bluecross_blueshield.svg";
import CompanyLogo3 from "@/assets/companies/allscripts.svg";
import CompanyLogo4 from "@/assets/companies/sutter_health.svg";
import { cn } from "@/lib/utils";
import DotPattern from "../ui/dot-pattern";

export interface InfoCardProps {
  background: string;
  title?: string;
  subtitle?: string;
  description?: string;
  source?: string;
  footer?: true;
  backgroundClass?: string;
}

export const InfoCard = ({
  background,
  title,
  subtitle,
  description,
  source,
  footer,
  backgroundClass,
}: InfoCardProps) => {
  return (
    <div className="relative w-full h-full rounded-lg flex flex-col gap-8 items-center justify-center text-center p-12 border-2 border-secondary overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-accent to-secondary-dark to-40% -z-[3]" />
      <DotPattern
        width={24}
        height={24}
        className="absolute inset-0 min-w-full opacity-50 -z-[1]"
      />
      {title && (
        <h1
          className="max-w-[75%] text-secondary-foreground text-3xl"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      {subtitle && (
        <p className="w-full text-secondary-foreground text-lg">{subtitle}</p>
      )}
      <img
        src={background}
        alt="Onboarding Info Card Image"
        className={cn(
          "w-full h-fit max-h-[min(50vh,50%)] rounded-md object-contain object-center",
          backgroundClass,
        )}
      />
      {description && (
        <p className="w-full text-secondary-foreground text-lg">
          {description}
        </p>
      )}
      {source && (
        <p className="w-full text-secondary-foreground text-md">{source}</p>
      )}
      {footer && (
        <div className="w-full min-h-fit flex flex-col gap-4 items-center justify-center overflow-hidden">
          <p className="text-secondary-foreground opacity-65">
            Our executives have worked at
          </p>
          <div className="relative w-full flex flex-row items-center justify-between max-h-8 -z-[2] gap-4">
            <div className="absolute w-full h-full bg-gradient-to-r from-secondary-dark via-50% via-secondary-foreground/0 to-secondary-dark" />
            <img src={CompanyLogo1} alt="Healthgrades" />
            <img src={CompanyLogo2} alt="BlueCross BlueShield" />
            <img src={CompanyLogo3} alt="Allscripts" />
            <img src={CompanyLogo4} alt="Sutter Health" />
          </div>
        </div>
      )}
    </div>
  );
};
