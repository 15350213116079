import { DataProvider, fetchUtils, Options } from "react-admin";
import { Auth0Client } from "@auth0/auth0-spa-js";

function stringify(data: Record<string, string>) {
  return new URLSearchParams(data).toString();
}

// Create an auth0 client
const auth0Client = new Auth0Client({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: "localstorage",
  authorizationParams: {
    audience: "http://localhost:5000",
  },
});

const apiUrl = "/api/admin";

// Create an http client which adds the Authorization header
const httpClient = async (
  url: string,
  options: Options | undefined,
): Promise<{
  status: number;
  headers: Headers;
  body: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any;
}> => {
  let token: string | null = null;
  try {
    token = await auth0Client.getTokenSilently();
  } catch (error) {
    // If failed, try getting auth with a popup
    try {
      token = (await auth0Client.getTokenWithPopup()) ?? null;
    } catch (error) {
      // If that also fails, nothing to be done
    }
  }
  const requestHeaders = getAuth0Headers(token, options);
  return fetchUtils.fetchJson(url, {
    ...options,
    headers: requestHeaders,
  });
};

export const getAuth0Headers = (
  token: string | null,
  options: fetchUtils.Options | undefined,
): Headers => {
  const headers = (options?.headers ??
    new Headers({
      Accept: "application/json",
    })) as Headers;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const dataProvider: DataProvider = {
  getList: async (resource, params) => {
    const query: {
      filter?: string;
      range?: string;
      sort?: string;
      embed?: string;
    } = {
      filter: JSON.stringify(params.filter),
    };

    if (params.pagination) {
      const { page, perPage } = params.pagination;
      query.range = JSON.stringify([(page - 1) * perPage, page * perPage - 1]);
    }

    if (params.sort) {
      const { field, order } = params.sort;
      query.sort = JSON.stringify([field, order]);
    }

    if (params.meta?.embed) {
      query.embed = JSON.stringify(params.meta?.embed);
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url, {
      signal: params.signal,
    });
    return {
      data: json,
      total: parseInt(
        headers?.get("content-range")?.split("/")?.pop() ?? "0",
        10,
      ),
    };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, { signal: params.signal });
    return { data: json };
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, { signal: params.signal });
    return { data: json };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json, headers } = await httpClient(url, {
      signal: params.signal,
    });
    return {
      data: json,
      total: parseInt(
        headers.get("content-range")?.split("/")?.pop() ?? "0",
        10,
      ),
    };
  },

  create: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
    });
    return { data: json };
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url, {
      method: "DELETE",
      body: JSON.stringify(params.ids),
    });
    return { data: json };
  },
};
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

export default dataProvider;
