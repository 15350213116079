import { companyUserAccountTypeList } from "@/queries/company/team";
import { z } from "zod";

const schema = z.object({
  team: z
    .object({
      name: z
        .string({ required_error: "Team member name is required" })
        .min(1, "Team member name is required"),
      email: z.string({ required_error: "Email is required" }).email(),
      accountType: z.enum(companyUserAccountTypeList, {
        required_error: "Permission is required",
      }),
    })
    .array(),
});

type Schema = z.infer<typeof schema>;

export {
  schema as companyAddTeamFormSchema,
  type Schema as CompanyAddTeamFormSchema,
};
